import { colour, systemColour } from './colour';
import { layer } from './layer';
import { motion, motionMilliseconds } from './motion';
import { typography } from './typography';

export default {
  colour,
  layer,
  motion,
  motionMilliseconds,
  systemColour,
  typography,
};
