import { useAccountContext } from '@seek/hirer-graphql-react';

import { AdCentreHeader } from '../AdCentreHeader/AdCentreHeader';

export interface HeaderProps {
  activeTabId?: 'analytics';
  activeSubTabId?: 'adUsage';
  hirerId: string;
  currentPage?: string;
}

export const Header = ({
  activeTabId = 'analytics',
  activeSubTabId,
  hirerId,
  currentPage = '',
}: HeaderProps) => {
  const { accountContext } = useAccountContext();

  const accountNumber =
    accountContext?.headerFooterContext?.advertiser?.billingId || '';

  return (
    <AdCentreHeader
      activeTabId={activeTabId}
      activeSubTabId={activeSubTabId}
      accountNumber={accountNumber}
      advertiserId={hirerId}
      currentPage={currentPage}
    />
  );
};
