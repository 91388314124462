import { useCallback, useState } from 'react';

import type { AdRatingChartData } from './AdRatingChart.types';
import { useAdRatingChartRenderer } from './useAdRatingChartRenderer';

export interface AdRatingChartProps {
  data: AdRatingChartData;
}

export const AdRatingChart = ({ data }: AdRatingChartProps) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const containerRefHandler = useCallback((node: HTMLDivElement | null) => {
    setContainerRef(node);
  }, []);
  const height = 195;
  const initialWidth = 534;
  useAdRatingChartRenderer({
    containerRef,
    data,
    height,
    width: initialWidth,
  });

  return (
    <div
      ref={containerRefHandler}
      style={{ height, pointerEvents: 'all', textAlign: 'left', width: '100%' }}
    />
  );
};
