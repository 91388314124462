import { getLocalizedRoutes } from '@seek/melways-sites';

const routes = [
  { name: '/', route: '/' }, // <-- Generate a single index.html (can be removed when we use multiple entry points)
  // Used by local webpack dev server
  { name: '/analytics', route: '/analytics' },
  {
    name: '/analytics/ad-performance/$jobId',
    route: '/analytics/ad-performance/$jobId',
  },
  { name: '/analytics/ad-usage', route: '/analytics/ad-usage' },
  { name: '/analytics/discover', route: '/analytics/discover' },
  { name: '/analytics/login', route: '/analytics/login' },
  {
    name: '/analytics/advertiser/$advertiserId',
    route: '/analytics/advertiser/$advertiserId',
  },
  { name: '/analytics/job/$jobId', route: '/analytics/job/$jobId' },
  { name: '/analytics/myseekreview', route: '/analytics/myseekreview' },
  { name: '/analytics/myyearwithseek', route: '/analytics/myyearwithseek' },
];
export const localizeRoutesForSite = getLocalizedRoutes(routes);
