import { gql } from '@apollo/client';

export const similarAdsQuery = gql`
  query GetSimilarData($input: SimilarAdsWidgetInput!) {
    analyticsSimilarAdsDetails(input: $input) {
      ads {
        adType
        advertiserName
        hasKeySellingPoints
        isPrivateAdvertiser
        isSalaryHidden
        jobCreatedTimestampUTC
        jobId
        jobLocationLabel
        jobTitle
        productDisplayName
        serpLogoUrl
      }
      benchmarkLocationLabel
      benchmarkScenario
      classificationName
      normalisedRoleTitle
      subClassificationName
      benchmarkLocationCountry
      lastUpdatedTimestampUTC
    }
  }
`;
