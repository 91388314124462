import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Columns,
  Column,
  Text,
  Divider,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import { useHidden } from '../../hooks/useHidden';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialog';

import translations from './.vocab';

export interface JobAdListTableHeaderProps {
  lastUpdatedAgo: ReactNode | string | null;
}

export const JobAdListTableHeader = ({
  lastUpdatedAgo,
}: JobAdListTableHeaderProps) => {
  const { t } = useTranslations(translations);

  // Configuration for mobile and tablet view
  const isMobileView = useHidden([true, false, false, false]);
  const isTabletView = useHidden([true, true, false, false]);

  const JobAdDescriptionHeaderTitle = () => (
    <Box paddingLeft="xsmall">
      <Text tone="secondary">{t('Job')}</Text>
    </Box>
  );

  const JobAdApplicationHeaderTitle = () => (
    <DisclosureDialog
      tooltip={
        <Stack space="medium">
          <Text size="small">
            {t(
              'This number reflects how many candidates completed an application when the application takes place on SEEK.',
            )}
          </Text>
          {lastUpdatedAgo !== null && (
            <Box data-testid="lastUpdatedAgo">
              <Text size="small">
                {t(
                  'Candidate application numbers may be different to other pages as this page was last updated',
                  {
                    HighLight: () => lastUpdatedAgo,
                  },
                )}
              </Text>
            </Box>
          )}
        </Stack>
      }
    >
      <Stack space="xxsmall">
        <Text align="left" tone="secondary">
          {t('Applications')}
        </Text>
      </Stack>
    </DisclosureDialog>
  );

  const JobAdPerformanceHeaderTitle = () => (
    <DisclosureDialog
      tooltip={t('Performance tooltip', {
        Stack: (children: ReactNodeNoStrings) => (
          <Stack space="medium">{children}</Stack>
        ),
        Text: (children) => <Text size="small">{children}</Text>,
      })}
    >
      <Text tone="secondary">{t('Performance')}</Text>
    </DisclosureDialog>
  );

  const JobAdPostByHeaderTitle = () => (
    <Box>
      <Text tone="secondary">{t('Posted by')}</Text>
    </Box>
  );

  const JobCostTypeHeaderTitle = () => (
    <Box>
      <Text tone="secondary">{t('Cost & type')}</Text>
    </Box>
  );

  if (isMobileView) {
    return (
      <Box paddingBottom="xxsmall">
        <Columns space="large" alignY="bottom">
          <Column width="3/5">
            <JobAdDescriptionHeaderTitle />
          </Column>
          <Column width="2/5">
            <JobAdPerformanceHeaderTitle />
          </Column>
        </Columns>
        <Box width="full" paddingTop="medium">
          <Divider weight="strong" />
        </Box>
      </Box>
    );
  } else if (isTabletView) {
    return (
      <Box paddingBottom="xxsmall">
        <Columns space="large" alignY="bottom">
          <Column width="3/5">
            <JobAdDescriptionHeaderTitle />
          </Column>
          <Column width="2/5">
            <Columns space="none">
              <Column width="1/2">
                <JobAdApplicationHeaderTitle />
              </Column>
              <Column width="1/2">
                <JobAdPerformanceHeaderTitle />
              </Column>
            </Columns>
          </Column>
        </Columns>
        <Box width="full" paddingTop="medium">
          <Divider weight="strong" />
        </Box>
      </Box>
    );
  }
  return (
    <Box paddingBottom="xxsmall">
      <Columns space="large" alignY="bottom">
        <Column width="1/3">
          <JobAdDescriptionHeaderTitle />
        </Column>
        <Column width="2/3">
          <Columns space="large" alignY="bottom">
            <Column width="1/5">
              <JobAdApplicationHeaderTitle />
            </Column>
            <Column width="1/4">
              <JobAdPerformanceHeaderTitle />
            </Column>
            <Column width="1/5">
              <JobAdPostByHeaderTitle />
            </Column>
            <Column>
              <JobCostTypeHeaderTitle />
            </Column>
          </Columns>
        </Column>
      </Columns>
      <Box width="full" paddingTop="medium">
        <Divider weight="strong" />
      </Box>
    </Box>
  );
};
