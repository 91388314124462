import { gql } from '@apollo/client';

export const marketPerformanceQuery = gql`
  query GetMarketPerformanceData($input: MarketPerformanceWidgetInput!) {
    analyticsMarketPerformance(input: $input) {
      highAds
      lowAds
      normalAds
      noRatingAds
      totalAds
    }
  }
`;
