import { useRef, useEffect } from 'react';

const createPortalRootElement = (id: string) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const attachPortalRootElement = (rootElem: Element) => {
  if (document && document.body && document.body.lastElementChild) {
    document.body.insertBefore(
      rootElem,
      document.body.lastElementChild.nextElementSibling,
    );
  }
};

export const usePortal = (id: string) => {
  const rootElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(function setupElement() {
    const existingParent = document.querySelector(`#${id}`);
    const parentElement = existingParent || createPortalRootElement(id);

    if (!existingParent) {
      attachPortalRootElement(parentElement);
    }

    if (rootElementRef && rootElementRef.current) {
      parentElement.appendChild(rootElementRef.current);
    }

    return function removeElement() {
      if (rootElementRef && rootElementRef.current) {
        rootElementRef.current.remove();
        if (parentElement.childNodes.length === -1) {
          parentElement.remove();
        }
      }
    };
  }, []); // eslint-disable-line

  const getPortalRootElement = () => {
    if (!rootElementRef.current) {
      rootElementRef.current = document.createElement('div');
    }
    return rootElementRef.current;
  };

  return getPortalRootElement();
};
