import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from '../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../context/adUsageFilters';
import type { LocationOption } from '../../../types/AdUsageFiltersResponse';
import {
  trackEvent,
  mapDropdownFilterMethod,
} from '../../../utils/tealiumAUREventTracker';
import {
  getDisplayValue,
  shouldClearAllPreviousSelectedKeys,
} from '../Filters.formatters';

import translations from './.vocab';
import { buildLocationTreeData } from './LocationFilter.formatters';

export interface LocationFilterProps {
  locations: LocationOption[];
}

export const LocationFilter = ({ locations }: LocationFilterProps) => {
  const {
    adUsageFilters: { locationIds: selectedLeafNodeKeys },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  // The leafNodeKeysMap is the map for all the non leaf location to leaf locations
  // The key is the key of non leaf location, and the value will be all the leaf location
  // keys under this non leaf location
  // This map will be used as a reference when select a location
  const leafNodeKeysMap: Record<string, string[]> = {};

  const locationTreeData = buildLocationTreeData({
    data: locations,
    leafNodeKeysMap,
    othersText: t('Others suffix'),
    restOfTheWorldText: t('Rest of the world'),
  });

  const displayedValue = getDisplayValue(
    selectedLeafNodeKeys,
    leafNodeKeysMap,
    locationTreeData,
    t,
    t('All locations'),
  );

  const {
    setIsFilterLocation,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const leafNodeKeys = leafNodeKeysMap?.[value];
    let newSelectedKeys: string[] = [];

    // this means we are selecting a non leaf location
    if (leafNodeKeys) {
      if (
        selectedLeafNodeKeys.find((leafNodeKey: string) =>
          leafNodeKeys.includes(leafNodeKey),
        )
      ) {
        const newSelectedLeafNodeKeys = [...selectedLeafNodeKeys].filter(
          (leafNodeKey: string) => !leafNodeKeys.includes(leafNodeKey),
        );

        newSelectedKeys = shouldClearAllPreviousSelectedKeys(
          leafNodeKeysMap,
          newSelectedLeafNodeKeys,
        )
          ? []
          : newSelectedLeafNodeKeys;
      } else {
        newSelectedKeys = [...selectedLeafNodeKeys, ...leafNodeKeys];
      }
    } else if (selectedLeafNodeKeys.includes(value)) {
      const newSelectedLeafNodeKeys = [...selectedLeafNodeKeys].filter(
        (leafNodeKey) => leafNodeKey !== value,
      );
      newSelectedKeys = shouldClearAllPreviousSelectedKeys(
        leafNodeKeysMap,
        newSelectedLeafNodeKeys,
      )
        ? []
        : newSelectedLeafNodeKeys;
    } else {
      newSelectedKeys = [...selectedLeafNodeKeys, value];
    }

    trackEvent('filter_pressed', {
      isFilterLocation: newSelectedKeys.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'location',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_LOCATIONS,
      value: newSelectedKeys,
    });

    setIsFilterLocation(newSelectedKeys.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_LOCATIONS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={locationTreeData}
      label={t('Location')}
      selected={selectedLeafNodeKeys}
      value={displayedValue}
      onSelect={onSelect}
      isMultipleLevel
      searchPlaceholder={`${t('Search locations')}...`}
      clearSelection={clearSelection}
    />
  );
};
