import { memo, useEffect, useRef } from 'react';
import CountUp, { type CountUpProps } from 'react-countup';

import type { Money } from '../../gql/generated';
import { formatCurrency } from '../../utils/currency';
import { formatNumber } from '../../utils/number';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';

const defaultCountUpProps: Partial<CountUpProps> = {
  duration: 0.8,
};

const AnimatedTextInternal = ({
  end,
  formattingFn,
  decimals = 0,
}: {
  end: CountUpProps['end'];
  formattingFn: CountUpProps['formattingFn'];
  decimals?: number;
}) => {
  const startRef = useRef(0);

  // Preserve the value between renders so it can be used as "start"
  // Note: CountUp's `preserveValue` prop doesn't work with custom `formattingFn`
  useEffect(() => {
    if (typeof end === 'number' && startRef.current !== end) {
      startRef.current = end;
    }
  }, [end]);

  return (
    <CountUp
      {...defaultCountUpProps}
      start={startRef.current}
      end={end}
      formattingFn={formattingFn}
      decimals={decimals}
    />
  );
};

export const AnimatedNumber = memo(({ value }: { value: number }) => (
  <AnimatedTextInternal
    end={value}
    formattingFn={(num) => formatNumber(num, { round: true })}
  />
));

export const AnimatedPercentage = memo(({ value }: { value: number }) => (
  <AnimatedTextInternal
    end={value}
    formattingFn={(num) => formatPercentage(num, 1)}
    decimals={1}
  />
));

export const AnimatedCurrency = memo((currency: Money) => (
  <AnimatedTextInternal
    end={currency.value}
    formattingFn={(value) => formatCurrency({ ...currency, value })}
  />
));
