import type { MultiSelectDropdownOption } from '@seek/multi-select-dropdown';

const shouldPushToDisplayedLabel = (
  selectedKeys: string[],
  option: MultiSelectDropdownOption,
  leafNodeKeysMap: Record<string, string[]>,
): boolean =>
  Boolean(
    (leafNodeKeysMap[option.key] &&
      !leafNodeKeysMap[option.key].find(
        (key) => !selectedKeys.includes(key),
      )) ||
      (!leafNodeKeysMap[option.key] && selectedKeys.includes(option.key)),
  );

/**
 *
 * @param options the options we use to find the label to display
 * @param selectedKeys the selected keys from the classification options
 * @param acc the result array
 *
 * This is a recursive function to find the correct displayed value in the MultiSelect
 * The basic concept is if all the children and grand children of a classification have been
 * selected, then we will only use this classification's value as part of the label, otherwise,
 * we find all the values of the selected classifications.
 */
const getDisplayLabels = (
  options: MultiSelectDropdownOption[],
  selectedKeys: string[],
  acc: string[] = [],
  leafNodeKeysMap: Record<string, string[]>,
) => {
  options.map((option) => {
    if (
      Boolean(leafNodeKeysMap[option.key]) &&
      Boolean(
        leafNodeKeysMap[option.key].find((key) => selectedKeys.includes(key)),
      ) &&
      Boolean(
        leafNodeKeysMap[option.key].find((key) => !selectedKeys.includes(key)),
      ) &&
      option.children
    ) {
      getDisplayLabels(option.children, selectedKeys, acc, leafNodeKeysMap);
    } else if (
      shouldPushToDisplayedLabel(selectedKeys, option, leafNodeKeysMap)
    ) {
      acc.push(option.value);
    }
  });
};

/**
 *
 * @param selected the selected leaf classification keys
 * @returns { string } the value to display in MultiSelect
 */
export const getDisplayValue = (
  selected: string[],
  leafNodeKeysMap: Record<string, string[]>,
  classificationTreeData: MultiSelectDropdownOption[],
  t: (value: string) => string,
  defaultValue: string,
) => {
  const displayedElements: string[] = [];
  getDisplayLabels(
    classificationTreeData,
    selected,
    displayedElements,
    leafNodeKeysMap,
  );

  if (displayedElements.length > 1) {
    return `${displayedElements[0]} + ${displayedElements.length - 1} ${
      displayedElements.length > 2 ? t('Others') : t('Other')
    }`;
  }
  if (displayedElements.length === 1) {
    return displayedElements[0];
  }
  return defaultValue;
};

/**
 *
 * @param dataSource the dropdown options data source
 * @param selected the selected dropdown option keys
 * @returns { string } the value to display in MultiSelect
 */
export const getOptionLabel = (
  dataSource: MultiSelectDropdownOption[],
  selected: string[],
  t: (value: string) => string,
  defaultValue: string,
) => {
  const displayedElements = dataSource
    .filter((data) => selected.includes(data.key))
    .map((data) => data.value);

  const labelSuffix = displayedElements.length > 2 ? t('Others') : t('Other');

  if (displayedElements.length > 1) {
    return `${displayedElements[0]} + ${
      displayedElements.length - 1
    } ${labelSuffix}`;
  }
  if (displayedElements.length === 1) {
    return displayedElements[0];
  }
  return defaultValue;
};

// this function is to determine if we should remove all the keys for a filter.
// this is useful when we apply multiple filters together. the filters selected
// first may have some selected keys that are no longer in the new filter data
// anymore. so we need to clear them in this case
export const shouldClearAllPreviousSelectedKeys = (
  leafNodeKeysMap: Record<string, string[]>,
  selectedKeys: string[],
) => {
  const allAvailableKeys = Object.values(leafNodeKeysMap).reduce(
    (keys: string[], current: string[]) => [...keys, ...current],
    [],
  );

  return !selectedKeys.find((key: string) => allAvailableKeys.includes(key));
};
