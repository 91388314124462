import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from '../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../context/adUsageFilters';
import type { ClassificationOption } from '../../../types/AdUsageFiltersResponse';
import {
  trackEvent,
  mapDropdownFilterMethod,
} from '../../../utils/tealiumAUREventTracker';
import {
  getDisplayValue,
  shouldClearAllPreviousSelectedKeys,
} from '../Filters.formatters';

import translations from './.vocab';
import { buildClassificationData } from './ClassificationFilter.formatters';

export interface ClassificationFilterProps {
  classifications: ClassificationOption[];
}

export const ClassificationFilter = ({
  classifications,
}: ClassificationFilterProps) => {
  const {
    adUsageFilters: { subClassificationIds: selectedLeafNodeKeys },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  const leafNodeKeysMap: Record<string, string[]> = {};

  const classificationTreeData = buildClassificationData(
    classifications,
    leafNodeKeysMap,
  );

  const displayedValue = getDisplayValue(
    selectedLeafNodeKeys,
    leafNodeKeysMap,
    classificationTreeData,
    t,
    t('All classifications'),
  );

  const {
    setIsFilterClassification,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const leafNodeKeys = leafNodeKeysMap?.[value];
    let newSelectedKeys: string[] = [];

    // this if means we are selecting a non leaf classification
    if (leafNodeKeys) {
      if (
        selectedLeafNodeKeys.find((leafNodeKey: string) =>
          leafNodeKeys.includes(leafNodeKey),
        )
      ) {
        const newSelectedLeafNodeKeys = [...selectedLeafNodeKeys].filter(
          (leafNodeKey: string) =>
            !leafNodeKeys.includes(leafNodeKey) && leafNodeKey !== value,
        );

        newSelectedKeys = shouldClearAllPreviousSelectedKeys(
          leafNodeKeysMap,
          newSelectedLeafNodeKeys,
        )
          ? []
          : newSelectedLeafNodeKeys;
      } else {
        newSelectedKeys = [...selectedLeafNodeKeys, ...leafNodeKeys, value];
      }
    } else if (selectedLeafNodeKeys.includes(value)) {
      const newSelectedLeafNodeKeys = [...selectedLeafNodeKeys].filter(
        (leafNodeKey) => leafNodeKey !== value,
      );
      newSelectedKeys = shouldClearAllPreviousSelectedKeys(
        leafNodeKeysMap,
        newSelectedLeafNodeKeys,
      )
        ? []
        : newSelectedLeafNodeKeys;
    } else {
      newSelectedKeys = [...selectedLeafNodeKeys, value];
    }

    trackEvent('filter_pressed', {
      isFilterClassification: newSelectedKeys.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'classification',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_SUB_CLASSIFICATIONS,
      value: newSelectedKeys,
    });

    setIsFilterClassification(newSelectedKeys.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_SUB_CLASSIFICATIONS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={classificationTreeData}
      label={t('Classification')}
      selected={selectedLeafNodeKeys}
      value={displayedValue}
      onSelect={onSelect}
      isMultipleLevel
      searchPlaceholder={`${t('Search classifications')}...`}
      clearSelection={clearSelection}
    />
  );
};
