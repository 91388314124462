import { getLocale } from '../../config';

export const formatPercentage = (
  percentage: number,
  decimals: 0 | 1 | 2,
  locale = getLocale(),
): string =>
  new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals,
  }).format(percentage / 100);

export const calculatePercentage = (
  numerator: number,
  denominator: number,
): number => {
  if (numerator === 0 && denominator === 0) {
    return 0;
  }

  return (numerator / denominator) * 100;
};
