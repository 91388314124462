import type { ScaleLinear } from 'd3-scale';
import type { BaseType, Selection } from 'd3-selection';

import type {
  ChartLabels,
  PerformancePredictionChartLabel,
} from './ApplicationPredictionChart.types';

export function getApplicantsPerformance(
  label: string,
  chartLabels: ChartLabels,
) {
  return label.toLocaleLowerCase() === 'application starts'
    ? chartLabels.yourApplicationStartsPerformance
    : chartLabels.yourCandidatesPerformance;
}

export function textCapitalize(str: string | null) {
  if (!str) {
    return null;
  }

  if (str.length > 1) {
    const strLowerCase = str.toLocaleLowerCase();
    if (str.indexOf('_') !== -1) {
      const deUnderscore = strLowerCase.replace('_', ' ');
      return deUnderscore.charAt(0).toUpperCase() + deUnderscore.slice(1);
    }
    return strLowerCase.charAt(0).toUpperCase() + strLowerCase.slice(1);
  }
  return str;
}

export function renderBox(
  yScale: ScaleLinear<number, number, never>,
  value: number,
  labelWidth: number,
  labelHeight: number,
) {
  return `translate(${-labelWidth / 2}, ${
    (yScale(value) || 0) - labelHeight - 50
  })`;
}

export function renderArrorDownPath(labelWidth: number, labelHeight: number) {
  return `translate(${labelWidth / 2}, ${labelHeight - 1})`;
}

export function renderTriangleBottomPath(
  labelWidth: number,
  labelHeight: number,
) {
  return `translate(${labelWidth / 2}, ${labelHeight})`;
}

export function textWrappper(
  selection: Selection<
    SVGGElement | BaseType,
    PerformancePredictionChartLabel,
    SVGGElement,
    unknown
  >,
  labelWidth: number,
  {
    textMargin,
    textLineHeight,
  }: { textMargin: number; textLineHeight: number },
  yScale: ScaleLinear<number, number, never>,
) {
  selection.each(function (d) {
    const selectors = {
      selectedBox: selection.select('g.box'),
      appsText: selection.select('text tspan.line2'),
      similarAdText: selection.select('text tspan.line3'),
      selectedRect: selection.select('rect'),
      arrowDown: selection.select('path.arrow-down'),
      triangleBottom: selection.select('path.triangle-bottom'),
    };
    const originalLineHeight = Number(textLineHeight.toFixed(1));
    const lineHeight = originalLineHeight + 2;
    let offset = 1;
    const firstParaLines = handleTextLineHeight(
      selectors.appsText,
      labelWidth,
      lineHeight,
      offset,
    );
    // If first paragraph has only one line, no 'dy' is attached to next line,
    // so don't need adjust line height
    offset = getLinesOffset(firstParaLines, lineHeight);
    const secondParaLines = handleTextLineHeight(
      selectors.similarAdText,
      labelWidth,
      lineHeight,
      offset,
    );

    // If first paragraph has only one line and send paragraph has more than one line
    // should have gibber space between first and second paragraphs
    if (secondParaLines > 1) {
      handleTextLineHeight(
        selectors.similarAdText,
        labelWidth,
        lineHeight,
        firstParaLines * (lineHeight / 10),
      );
    }
    const totalLines = firstParaLines + secondParaLines;
    const labelHeight = getLabelHeight(
      textMargin,
      originalLineHeight,
      lineHeight,
      totalLines,
    );

    // re-render popup
    selectors.selectedBox.attr(
      'transform',
      renderBox(yScale, d.value, labelWidth, labelHeight),
    );
    selectors.selectedRect.attr('height', labelHeight);
    selectors.arrowDown.attr(
      'transform',
      renderArrorDownPath(labelWidth, labelHeight),
    );
    selectors.triangleBottom.attr(
      'transform',
      renderTriangleBottomPath(labelWidth, labelHeight),
    );
  });
}

function handleTextLineHeight(
  textElement: Selection<
    BaseType,
    PerformancePredictionChartLabel,
    SVGGElement,
    unknown
  >,
  labelWidth: number,
  lineHeight: number,
  offset: number,
) {
  const words = textElement.text().split(/\s+/).reverse();
  let word;
  let lineChar: string[] = [];
  let lineNumber = 0;
  const x = textElement.attr('x');
  const y = parseFloat(textElement.attr('y'));

  let tspan = textElement
    .text(null)
    .append('tspan')
    .attr('x', x)
    .attr('y', y + 10 * offset);

  while ((word = words?.pop())) {
    lineChar.push(word);
    tspan.text(lineChar.join(' '));
    const textLength = tspan?.node()?.getComputedTextLength() ?? 0;
    // Container padding is 8
    if (textLength > labelWidth - Number(x) - 5) {
      lineNumber += 1;
      lineChar.pop();
      tspan.text(lineChar.join(' '));
      lineChar = [word];

      tspan = textElement
        .append('tspan')
        .attr('class', `paragraph-${lineNumber}`)
        .attr('x', x)
        .attr('dy', `${lineHeight}px`)
        .text(word);
    }
  }

  return lineNumber + 1;
}

export function getLinesOffset(lines: number, lineHeight: number) {
  return lines > 1 ? lines * (lineHeight / 10) : lines;
}

export function getLabelHeight(
  textMargin: number,
  originalLineHeight: number,
  lineHeight: number,
  lines: number,
) {
  return (
    textMargin * 2 +
    (lines <= 2 ? originalLineHeight : lineHeight) *
      (lines <= 2 ? 3 : lines + 1.2)
  );
}
