import { type ApolloError, useQuery } from '@apollo/client';

import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import { marketPerformanceQuery } from '../queries/marketPerformanceQuery';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';
import type { MarketPerformanceResponse } from '../types/MarketPerformanceResponse';

interface MarketPerformanceQueryParams {
  input: {
    filters: AdUsageQueryFilters;
  };
}

export const useMarketPerformanceData = (): {
  data: MarketPerformanceResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const filters = useAdUsageQueryFilters();
  const { data, loading, error, previousData } = useQuery<
    { analyticsMarketPerformance: MarketPerformanceResponse },
    MarketPerformanceQueryParams
  >(marketPerformanceQuery, {
    variables: {
      input: {
        filters,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsMarketPerformance,
    isLoading: loading,
    error,
  };
};
