import { useTranslations } from '@vocab/react';
import { Loader, Stack, Divider, Box } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { useConfig } from '../../App/ConfigContext';
import { BrandedAdRecommendation } from '../../components/BrandedAdRecommendation/BrandedAdRecommendation';
import { GenericTitleContainer } from '../../components/GenericTitleContainer/GenericTitleContainer';
import { JobDescriptionRecommendation } from '../../components/JobDescriptionRecommendation/JobDescriptionRecommendation';
import { LowSalaryRecommendation } from '../../components/LowSalaryRecommendation/LowSalaryRecommendation';
import { NoRecommendation } from '../../components/NoRecommendation/NoRecommendation';
import { PageSectionTracker } from '../../components/PageSectionTracker/PageSectionTracker';
import { PrimarySection } from '../../components/PrimarySection/PrimarySection';
import { RevealSalaryRecommendation } from '../../components/RevealSalaryRecommendation/RevealSalaryRecommendation';
import { RoleRequirementsRecommendation } from '../../components/RoleRequirementsRecommendation/RoleRequirementsRecommendation';
import { SearchRecommendation } from '../../components/SearchRecommendation/SearchRecommendation';
import { SpecificLocationRecommendation } from '../../components/SpecificLocationRecommendation/SpecificLocationRecommendation';
import { TalentSearchRecommendation } from '../../components/TalentSearchRecommendation/TalentSearchRecommendation';
import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';
import { useFeatureToggleData, FEATURE_BENCHMARKS } from '../../featureToggle';
import { useRecommendations } from '../../hooks/useRecommendations';
import type { AdReportDataLayer } from '../../types/TealiumDataLayer';
import {
  getTrackerData,
  trackEvent,
  trackWidgetView,
} from '../../utils/tealiumEventTracker';

import translations from './.vocab';

export const Recommendations = () => {
  const { t } = useTranslations(translations);
  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );
  const { urlResolver } = useConfig();
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();

  const { isLoading, data, error } = useRecommendations({
    jobId: jobIdParam!,
  });

  const editAdUrl = urlResolver(
    `/job/managejob/express/edit?jobId=${jobIdParam}`,
  );

  const onEditAdButtonClicked = () => {
    trackEvent('edit_ad_pressed', {
      objectInteraction: 'apr-edit-ad',
    });
  };

  const onEditAdIndirectButtonClicked = () => {
    trackEvent('edit_ad_pressed', {
      objectInteraction: 'apr-edit-ad',
    });
  };

  const renderContent = () => {
    if (isLoading && !data) {
      return (
        <Stack align="center" space="large">
          <Loader />
        </Stack>
      );
    }
    const getAnalyticsData: AdReportDataLayer =
      getTrackerData() as AdReportDataLayer;

    if (error || !data) {
      trackWidgetView(getAnalyticsData, 'Recommendations');
      return (
        <Box>
          <WidgetErrorNvl />
          <PageSectionTracker
            eventName="ad_performance_page_section_displayed"
            props={{
              currentPageSection: 'ad tips',
            }}
          />
        </Box>
      );
    }
    const { isExpired, postTypeCategory, recommendations } = data;

    const trackingData: AdReportDataLayer = {
      ...getAnalyticsData,
      adPostingType: postTypeCategory === 'DIRECT' ? 'direct' : 'indirect',
      adTipsCount: recommendations.length,
      adStatus: isExpired ? 'expired' : 'active',
      adTips: recommendations.map((r) =>
        r.type?.toLocaleLowerCase().replace('_', ' '),
      ),
    };

    if (recommendations.length === 0) {
      trackWidgetView(trackingData, 'Recommendations');
      return (
        <>
          <NoRecommendation />
          <PageSectionTracker
            eventName="ad_performance_page_section_displayed"
            props={{
              currentPageSection: 'ad tips',
            }}
          />
        </>
      );
    }

    trackWidgetView(trackingData, 'Recommendations');

    return (
      <Box>
        <PageSectionTracker
          eventName="ad_performance_page_section_displayed"
          props={{
            currentPageSection: 'ad tips',
          }}
        />
        <Stack space="large">
          <Divider />
          {recommendations.map((r, i) =>
            ['LOW_APPLY', 'LOW_CLICK', 'TALENT_SEARCH'].includes(r.type) &&
            !isBenchmarksFeatureEnabled ? null : (
              <Stack key={`recommendation-${i}`} space="large">
                {r.type === 'GRANULAR_LOCATION' && (
                  <SpecificLocationRecommendation
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                    nonSpecificLocation={r.nonSpecificLocation}
                    specificLocation={r.specificLocation}
                  />
                )}
                {r.type === 'REVEAL_SALARY' && (
                  <RevealSalaryRecommendation
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    currency={r.currency}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'LOW_SALARY' && (
                  <LowSalaryRecommendation
                    jobTitle={r.normalisedRoleTitle}
                    salaryRangeMin={r.salaryRangeMin}
                    salaryRangeMax={r.salaryRangeMax}
                    salaryType={r.salaryType}
                    suggestedRangeMin={r.suggestedRangeMin}
                    suggestedRangeMax={r.suggestedRangeMax}
                    suggestedAverageRangeMin={r.suggestedAverageRangeMin}
                    suggestedAverageRangeMax={r.suggestedAverageRangeMax}
                    location={r.benchmarkLocationLabel}
                    currency={r.currency}
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'LOW_CLICK' && (
                  <SearchRecommendation
                    comparisonConversionRate={r.averageConversionRate}
                    conversionRate={r.conversionRate}
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'LOW_APPLY' && (
                  <JobDescriptionRecommendation
                    comparisonConversionRate={r.averageConversionRate}
                    conversionRate={r.conversionRate}
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'BRANDED_AD' && (
                  <BrandedAdRecommendation
                    bulletPointsUsed={r.bulletPointsUsed}
                    logoUsed={r.logoUsed}
                    coverImageUsed={r.coverImageUsed}
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'ROLE_REQUIREMENTS' && (
                  <RoleRequirementsRecommendation
                    roleReqsQuestions={r.roleReqsQuestions}
                    isExpired={isExpired}
                    postTypeCategory={postTypeCategory}
                    editAdUrl={editAdUrl}
                    onEditAdButtonClicked={onEditAdButtonClicked}
                    onEditAdIndirectButtonClicked={
                      onEditAdIndirectButtonClicked
                    }
                  />
                )}
                {r.type === 'TALENT_SEARCH' && (
                  <TalentSearchRecommendation
                    jobId={r.jobId}
                    advertiserId={r.hirerId}
                  />
                )}
                {i < recommendations.length - 1 ? <Divider /> : null}
              </Stack>
            ),
          )}
          <Divider />
        </Stack>
      </Box>
    );
  };

  return (
    <PrimarySection data-id="ui-recommendations-section">
      <GenericTitleContainer title={t('Tips for your ad')} weight="regular">
        {renderContent()}
      </GenericTitleContainer>
    </PrimarySection>
  );
};
