import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from '../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../context/adUsageFilters';
import type { UserOption } from '../../../types/AdUsageFiltersResponse';
import {
  mapDropdownFilterMethod,
  trackEvent,
} from '../../../utils/tealiumAUREventTracker';
import { getDisplayValue } from '../Filters.formatters';

import translations from './.vocab';
import { buildUserFilterData } from './UserFilter.formatters';

export interface UserFilterProps {
  users: UserOption[];
  enableLazyLoading?: boolean;
}

export const UserFilter = ({
  users,
  enableLazyLoading = false,
}: UserFilterProps) => {
  const {
    adUsageFilters: { userHashes: selectedUsers },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  const notSpecifiedLabel = t('Unspecified user');
  const userData = buildUserFilterData(users, notSpecifiedLabel);

  const displayedValue = getDisplayValue(
    selectedUsers,
    {},
    userData,
    t,
    t('All users'),
  );
  const {
    setIsFilterUser,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterBudget,
  } = useContext(IsFilterContext);
  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const newSelected = selectedUsers.includes(value)
      ? [...selectedUsers.filter((singleSelected) => singleSelected !== value)]
      : [...selectedUsers, value];
    trackEvent('filter_pressed', {
      isFilterUser: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterBudget,
      filterType: 'user',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_USERS,
      value: newSelected,
    });
    setIsFilterUser(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_USERS,
      value: [],
    });
  return (
    <MultiSelectDropdown
      options={userData}
      label={t('User')}
      selected={selectedUsers}
      value={displayedValue}
      onSelect={onSelect}
      lazyLoadingEnabled={enableLazyLoading}
      searchPlaceholder={`${t('Search users')}...`}
      clearSelection={clearSelection}
    />
  );
};
