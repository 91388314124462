import { Box } from 'braid-design-system';
import type React from 'react';

import * as styles from './recessedPill.css';

export interface RecessedPillProps {
  children: ReactNodeNoStrings;
}

export const RecessedPill = ({ children }: RecessedPillProps) => (
  <Box padding="medium" className={styles.recessedPill}>
    {children}
  </Box>
);
