import type { FeatureConfigValue } from '../types';

export const incrementalRolloutStrategy = (
  advertiserId: string,
  value: FeatureConfigValue,
): boolean => {
  if (!value) {
    return false;
  }
  // If "enabled" is specifically "false" then none of the other rules apply
  if (value.enabled === false) {
    return false;
  }

  // "advertisers" has precedence over percentage
  if (value.advertisers && value.advertisers.includes(advertiserId)) {
    return true;
  }

  // Use "percentage" if its a number and check against advertiser id
  if (typeof value.percent === 'number') {
    return Number(advertiserId) % 100 <= value.percent - 1;
  }

  // Otherwise if "enabled" is true then use it, else return false
  return Boolean(value.enabled);
};
