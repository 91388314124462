import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
  type MultiSelectDropdownOption,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from '../../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../../context/adUsageFilters';
import {
  mapDropdownFilterMethod,
  trackEvent,
} from '../../../../utils/tealiumAUREventTracker';
import { getDisplayValue } from '../../Filters.formatters';

import translations from './.vocab';

export interface AdIdFilterProps {
  adIds: string[];
  enableLazyLoading?: boolean;
}

export const AdIdFilter = ({
  adIds,
  enableLazyLoading = false,
}: AdIdFilterProps) => {
  const {
    adUsageFilters: { adIds: selectedAdIds },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  const adIdOptions = adIds
    .map((id) => ({
      key: id,
      value: id,
    }))
    .sort(
      (a: MultiSelectDropdownOption, b: MultiSelectDropdownOption) =>
        Number(b.value) - Number(a.value),
    ) as MultiSelectDropdownOption[];

  const displayedValue = getDisplayValue(
    selectedAdIds,
    {},
    adIdOptions,
    t,
    t('All ad IDs'),
  );

  const {
    setIsFilterAdId,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const newSelected = selectedAdIds.includes(value)
      ? [...selectedAdIds.filter((singleSelected) => singleSelected !== value)]
      : [...selectedAdIds, value];

    trackEvent('filter_pressed', {
      isFilterAdId: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'jobId',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_IDS,
      value: newSelected,
    });

    setIsFilterAdId(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_IDS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={adIdOptions}
      label={t('Ad ID')}
      selected={selectedAdIds}
      value={displayedValue}
      onSelect={onSelect}
      searchPlaceholder={`${t('Search ad IDs')}...`}
      lazyLoadingEnabled={enableLazyLoading}
      clearSelection={clearSelection}
    />
  );
};
