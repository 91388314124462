import type { JobTitleOption } from '../../../types/AdUsageFiltersResponse';

export const buildJobTitleData = (data: JobTitleOption[]) => {
  const sortedData = [...(data ?? [])].sort(
    (a: JobTitleOption, b: JobTitleOption) => (a.key <= b.key ? -1 : 1),
  );

  return sortedData.map((option) => ({
    ...option,
    value: option.key,
  }));
};
