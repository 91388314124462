import { gql } from '@apollo/client';

export const candidateSupplyQuery = gql`
  query GetCandidateSupplyData($input: CandidateSupplyWidgetInput!) {
    analyticsCandidateSupply(input: $input) {
      comparisonPeriod {
        count
        calendarMonth
      }
      currentPeriod {
        count
        calendarMonth
      }
      hasCandidateSupply
    }
  }
`;
