import { type RefObject, useEffect, useState } from 'react';
// add support for Safari
import smoothScroll from 'smoothscroll-polyfill';
smoothScroll.polyfill();

export function scrollTo({
  element,
  offset,
}: {
  element: HTMLElement;
  offset: number;
}) {
  window.scrollTo({
    top: element.getBoundingClientRect().top - offset,
    behavior: 'smooth',
  });
}

export function useScrollToHash({
  elementId,
  offset,
}: {
  elementId: string;
  offset: number;
}) {
  useEffect(() => {
    const { hash } = window.location;
    if (hash && typeof document !== 'undefined') {
      const id = hash.replace('#', '');
      if (id !== elementId) {
        return;
      }
      const element = document.getElementById(id);
      if (element) {
        scrollTo({ element, offset });
      }
    }
  }, [elementId, offset]);
}

export function useScrollToElement(elementRef: RefObject<HTMLElement>) {
  const [clientRectTop, setClientRectTop] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      const targetOffsetTop = elementRef.current.getBoundingClientRect().top;
      const parentOffsetTop = elementRef.current.parentElement
        ? elementRef.current.parentElement.getBoundingClientRect().top
        : 0;
      const gap = targetOffsetTop - parentOffsetTop;
      setClientRectTop(targetOffsetTop - gap);
    }
  }, [elementRef]);

  return clientRectTop;
}
