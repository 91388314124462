import { siteNames } from '@seek/melways-sites';

// Helper utils specifically about knowing the launch dates and if we are within them
export const LAUNCH_DATES: Record<string, string> = {
  [siteNames.employer.seek.hk]: '2024-01-20T13:53:00Z',
  [siteNames.employer.seek.id]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.my]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.ph]: '2023-10-14T12:15:00Z',
  [siteNames.employer.seek.sg]: '2023-11-18T17:35:00Z',
  [siteNames.employer.seek.th]: '2024-01-20T13:53:00Z',
};

export const isHistoricalDataAvailable: (
  site: string,
  lastUpdated: string,
) => boolean = (site, lastUpdated) =>
  new Date().getTime() >= new Date(LAUNCH_DATES[site]).getTime() &&
  new Date(lastUpdated).getTime() > new Date(LAUNCH_DATES[site]).getTime();
