import { Box } from 'braid-design-system';
import type React from 'react';
import { createPortal } from 'react-dom';

import { useClickExternal } from '../../hooks/useClickExternal';
import { usePortal } from '../../hooks/usePortal';

import * as styles from './modal.css';

interface ModalProps {
  id?: string;
  children: React.ReactNode;
  width?: number;
  onModalShouldDismiss?: () => void;
}

export const Modal = ({
  id = 'modal-root',
  children,
  width = 940,
  onModalShouldDismiss,
}: ModalProps) => {
  const target = usePortal(id);
  const targetRef = useClickExternal(() => onModalShouldDismissEvent());

  function onModalShouldDismissEvent() {
    if (onModalShouldDismiss) {
      onModalShouldDismiss();
    }
  }

  return createPortal(
    <Box ref={targetRef}>
      <Box className={styles.modalScrollMaskParent}>
        <Box className={styles.modalScrollMaskChild} />
      </Box>
      <Box className={styles.modalContentContainerParent}>
        <Box
          className={styles.modalContentContainerBackdrop}
          onClick={onModalShouldDismissEvent}
        />
        <Box
          className={styles.modalContentContainerChild}
          style={{ maxWidth: width }}
        >
          {children}
        </Box>
      </Box>
    </Box>,
    target,
  );
};
