import type { ReactNode } from 'react';

import { ChartThemeContext } from './ChartThemeContext';
import { chartTheme } from './chartTheme';

export interface ChartThemeProviderProps {
  children: ReactNode;
}

export const ChartThemeProvider = ({ children }: ChartThemeProviderProps) => (
  <ChartThemeContext.Provider value={chartTheme}>
    {children}
  </ChartThemeContext.Provider>
);
