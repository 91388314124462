import { type ApolloError, useQuery } from '@apollo/client';

import type { AdPerformanceReportResponse } from '../gql/generated';
import { adPerformanceReportQuery } from '../queries/adPerformanceReportQuery';

interface queryResponse {
  analyticsAdPerformanceReport: AdPerformanceReportResponse;
}

export const useAdPerformanceReport = ({
  jobId = '',
}): {
  data: AdPerformanceReportResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { loading, data, error, previousData } = useQuery<queryResponse>(
    adPerformanceReportQuery,
    {
      errorPolicy: 'all',
      variables: {
        input: {
          jobId,
        },
      },
    },
  );

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdPerformanceReport,
    isLoading: loading,
    error,
  };
};
