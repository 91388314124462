import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import { useCallback, useEffect, useRef, useState } from 'react';

import translations from './.vocab';

import * as styles from './disclosureDialog.css';

export interface DisclosureDialogProps {
  children: ReactNodeNoStrings;
  isOpen?: boolean;
  label?: string;
  tooltip: ReactNodeNoStrings;
  onOpenChanged?: (isOpen: boolean) => void;
}

export const DisclosureDialog = ({
  children,
  isOpen: initialIsOpen = false,
  label,
  tooltip,
  onOpenChanged,
}: DisclosureDialogProps) => {
  const { t } = useTranslations(translations);
  const ref = useRef<HTMLElement>(null);
  const [anchor, setAnchor] = useState<'center' | 'left' | 'right'>('center');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const isIntersectingLeft =
          entry.boundingClientRect.left - entry.boundingClientRect.width / 2 <
          (entry.rootBounds?.left || 0);
        if (isIntersectingLeft) {
          setAnchor('left');
        } else {
          const isIntersectingRight =
            entry.boundingClientRect.right +
              entry.boundingClientRect.width / 2 >
            (entry.rootBounds?.right || 0);
          if (isIntersectingRight) {
            setAnchor('right');
          } else {
            setAnchor('center');
          }
        }
      },
      {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  const [isOpen, setIsOpen] = useState(initialIsOpen);

  useEffect(() => {
    setIsOpen(initialIsOpen);
  }, [initialIsOpen]);

  useEffect(() => {
    if (onOpenChanged) {
      onOpenChanged(isOpen);
    }

    if (isOpen) {
      const handleKeyDown = ({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
          setIsOpen(false);
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isOpen, onOpenChanged]);

  const onTriggerClicked = useCallback((e: any) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <Box className={styles.container} position="relative" open={isOpen}>
      <Box
        aria-expanded={isOpen}
        aria-modal="false"
        className={styles.summary}
        cursor="pointer"
        position="relative"
        role="button"
        title={
          isOpen
            ? t('Click to close dialog')
            : label ?? t('Click to open dialog')
        }
        type="button"
        onClick={onTriggerClicked}
      >
        {isOpen && (
          <Box
            bottom={0}
            cursor="default"
            display="block"
            left={0}
            position="fixed"
            right={0}
            top={0}
            zIndex="dropdownBackdrop"
          />
        )}
        {children}
      </Box>
      <Box
        className={styles.detailsWrapper}
        data-anchor={anchor}
        display={isOpen ? 'block' : 'none'}
        position="relative"
        zIndex="dropdown"
      >
        <Box
          background="surface"
          className={styles.details}
          marginTop="small"
          padding="medium"
          position="absolute"
          ref={ref}
          zIndex="dropdown"
        >
          {tooltip}
        </Box>
      </Box>
    </Box>
  );
};
