import { Box, Divider, Loader, Stack } from 'braid-design-system';
import {
  type ReactNode,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { JobAdListItem } from '../../components/JobAdListItem/JobAdListItem';
import { JobAdListTableHeader } from '../../components/JobAdListTableHeader/JobAdListTableHeader';
import { Pagination } from '../../components/Pagination/Pagination';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { useAdUsageQueryFilters } from '../../context/adUsageFilters';
import { useJobAdListData } from '../../hooks/useJobAdListData';
import { useJobAdListPayload } from '../../hooks/useJobAdListPayload';
import { useScrollToElement } from '../../hooks/useScrollToHash';
import type { AdUsageSearchResultsSummaryProps } from '../../pages/AdUsageReport/AdUsageReport';
import type { AdRatingType, AdStatus } from '../../types/AdUsageTypes';
import type { JobAd } from '../../types/JobAdListResponse';
import { formatDate } from '../../utils/date';
import {
  trackEvent,
  trackWidgetView,
} from '../../utils/tealiumAUREventTracker';

interface JobAdListProps {
  updateSearchResultsSummary: (
    summary: AdUsageSearchResultsSummaryProps | undefined,
  ) => void;
}

const JobAdListContainer = ({
  jobAdListRef,
  testId,
  lastUpdatedAgo,
  children,
}: {
  jobAdListRef: RefObject<HTMLElement>;
  testId?: string;
  lastUpdatedAgo: ReactNode | string | null;
  children: ReactNode;
}) => (
  <Box
    ref={jobAdListRef}
    paddingX="gutter" // TODO: Remove this when swapping to NVL version
    background="surface" // TODO: Remove this when swapping to NVL version
    borderRadius="standard"
    paddingY="large"
    marginBottom="large"
    data-testid={testId}
  >
    <JobAdListTableHeader lastUpdatedAgo={lastUpdatedAgo} />
    {children}
  </Box>
);

export const JobAdList = ({ updateSearchResultsSummary }: JobAdListProps) => {
  const jobAdListRef = useRef<HTMLElement>(null);
  const clientRectTop = useScrollToElement(jobAdListRef);
  const itemsPerPage = 10;
  const adUsageQueryFilters = useAdUsageQueryFilters();

  const {
    payload,
    payload: { pageNumber },
    setPayload,
  } = useJobAdListPayload(adUsageQueryFilters);
  const { data, isLoading, error } = useJobAdListData({
    ...payload,
    size: itemsPerPage,
  });

  const [hoveredGroupKey, setHoveredGroupKey] = useState<string | undefined>(
    undefined,
  );

  const onPageClick = ({ page }: { page: number }) => {
    setPayload({
      pageNumber: page,
      filters: adUsageQueryFilters,
    });
    window.scrollTo({
      top: clientRectTop,
    });
  };

  const onItemClicked = () => {
    trackEvent('job_link_pressed', {
      objectInteraction: 'ad-performance-job-interacted',
      pageNumber,
    });
  };

  const onJobAdHover = (key?: string) => {
    setHoveredGroupKey(key);
  };

  useEffect(() => {
    updateSearchResultsSummary(
      error || !data
        ? undefined
        : {
            count: data.count,
            lastUpdated: data.lastUpdated,
          },
    );
  }, [error, isLoading, data, updateSearchResultsSummary]);

  if (isLoading && !data) {
    return (
      <JobAdListContainer
        jobAdListRef={jobAdListRef}
        testId="loader"
        lastUpdatedAgo={null}
      >
        <Box display="flex" justifyContent="center" paddingTop="large">
          <Loader />
        </Box>
      </JobAdListContainer>
    );
  }

  if (error || !data) {
    trackWidgetView(
      {
        searchResultsTotal: '0',
      },
      'JobAdList',
    );

    return (
      <JobAdListContainer
        jobAdListRef={jobAdListRef}
        lastUpdatedAgo={null}
        testId="error-state"
      >
        <Box paddingTop="medium">
          <WidgetError />
        </Box>
      </JobAdListContainer>
    );
  }

  if (!data.count) return null;

  const { count, lastUpdated, jobAds } = data;
  const dateFormat = 'd MMM yyyy';
  const lastUpdatedAgo = lastUpdated
    ? formatDate({
        date: new Date(lastUpdated),
        dateFormat,
      })
    : null;
  const totalPages = Math.ceil(count / itemsPerPage);

  const isMultiplePages: boolean = totalPages > 1;

  trackWidgetView(
    {
      searchResultsTotal: `${count}`,
    },
    'JobAdList',
  );

  /* Sort jobAds by latest jobCreatedTimestampUTC */
  const sortedJobAds = [...(jobAds ?? [])].sort(
    (a: JobAd, b: JobAd) =>
      new Date(b.jobCreatedTimestampUTC).getTime() -
      new Date(a.jobCreatedTimestampUTC).getTime(),
  );

  return (
    <JobAdListContainer
      jobAdListRef={jobAdListRef}
      lastUpdatedAgo={lastUpdatedAgo}
    >
      <Stack space="xxsmall">
        {sortedJobAds.map((jobAd, index) => (
          <Box key={jobAd.groupKeyApac} data-testid={`job-ad-${index}`}>
            <JobAdListItem
              adRating={jobAd.adRating as AdRatingType}
              applications={jobAd.applications}
              jobCreatedDate={jobAd.jobCreatedTimestampUTC}
              jobId={jobAd.jobId}
              jobTitle={jobAd.jobTitle}
              groupKeyApac={jobAd.groupKeyApac}
              hidePrice={jobAd.hidePrice}
              isLatestGroupApac={jobAd.isLatestGroupApac}
              isRefresh={jobAd.isRefresh}
              isRepost={jobAd.isRepost}
              locationLabel={jobAd.locationLabel}
              status={jobAd.status as AdStatus}
              totalNetAmount={jobAd.totalNetAmount}
              otherBudgetAmount={jobAd.otherBudgetAmount}
              userFullName={jobAd.userFullName}
              onItemClicked={onItemClicked}
              onJobAdHover={onJobAdHover}
              hoveredGroupKey={hoveredGroupKey}
              productDisplayName={jobAd.productDisplayName}
            />
            <Box paddingTop="xxsmall">
              <Divider />
            </Box>
          </Box>
        ))}
      </Stack>
      <Box
        paddingTop={isMultiplePages ? 'large' : 'none'}
        paddingBottom={isMultiplePages ? 'none' : 'none'}
      >
        {isMultiplePages ? (
          <Pagination
            totalPages={totalPages}
            onPageClick={onPageClick}
            currentPage={pageNumber}
          />
        ) : null}
      </Box>
    </JobAdListContainer>
  );
};
