import { Box } from 'braid-design-system';
import type React from 'react';

import * as styles from './standardTextBox.css';

export interface StandardTextBoxProps {
  children?: React.ReactNode;
}

export const StandardTextBox = ({ children }: StandardTextBoxProps) => (
  <Box className={styles.standardTextBox}>{children}</Box>
);
