import { gql } from '@apollo/client';

export const conversionAdQuery = gql`
  query GetConversionData($input: AdUsageConversionWidgetInput!) {
    analyticsAdUsageConversion(input: $input) {
      jobSearchViews {
        total
        similarAdAverage
        adConversionRate
        similarAdConversionRate
        mobileRate
      }
      adClicks {
        total
        similarAdAverage
        adConversionRate
        similarAdConversionRate
        mobileRate
      }
      applicationsStarted {
        total
        similarAdAverage
        adConversionRate
        similarAdConversionRate
        mobileRate
      }
      applicationsCompleted {
        total
        similarAdAverage
        mobileRate
      }
      adsWithoutRatingPercentage
      linkOutAds
    }
  }
`;
