import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { adUsageFiltersQuery } from '../queries/adUsageFiltersQuery';
import type { AdUsageFilters } from '../types/AdUsageFiltersResponse';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';

interface AdUsageFiltersQueryParams {
  input: {
    locale: string;
    filters: AdUsageQueryFilters;
  };
}

export const useAdUsageFilters = ({
  filters,
}: {
  filters: AdUsageQueryFilters;
}): {
  data: AdUsageFilters | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();

  const { data, loading, error, previousData } = useQuery<
    { analyticsAdUsageFilters: AdUsageFilters },
    AdUsageFiltersQueryParams
  >(adUsageFiltersQuery, {
    variables: {
      input: {
        locale,
        filters,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdUsageFilters,
    isLoading: loading,
    error,
  };
};
