import { useAccountContext } from '@seek/hirer-graphql-react';
import { Box, Page } from 'braid-design-system';

import { AdUsageHeader } from '../../blocks/AdUsageHeaderOld/AdUsageHeader';
import { AdCentreFooter } from '../AdCentreFooter/AdCentreFooter';
import { Header } from '../Header/Header';
import { SystemBanner } from '../SystemBanner/SystemBanner';

export interface AdUsagePageFrameProps {
  numOfAds?: number;
  children: ReactNodeNoStrings;
}

export const AdUsagePageFrame = ({
  children,
  numOfAds,
}: AdUsagePageFrameProps) => {
  const { accountContext } = useAccountContext();
  const hasAccess = Boolean(
    accountContext?.headerFooterContext?.advertiser?.permissionOptions
      ?.canViewManagerReports,
  );

  return (
    <Page
      footerPosition="belowFold"
      footer={<AdCentreFooter currentPage="ad usage report" />}
    >
      <Header
        hirerId=""
        activeSubTabId="adUsage"
        currentPage="ad usage report"
      />
      <SystemBanner />
      <AdUsageHeader hasAccess={hasAccess} numOfAds={numOfAds} />
      {children}
      <Box id="modal-base" />
    </Page>
  );
};
