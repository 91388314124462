import { type ApolloError, useQuery } from '@apollo/client';

import { clicksOnYourAdQuery } from '../queries/clicksOnYourAdQuery';
import type { ClicksOnYourAdResponse } from '../types/ClicksOnYourAdResponse';

interface ClicksOnYourAdQueryParams {
  input: {
    jobId: string;
  };
}

export const useClicksOnYourAdData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: ClicksOnYourAdResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { data, loading, error, previousData } = useQuery<
    { analyticsClicksOnYourAd: ClicksOnYourAdResponse },
    ClicksOnYourAdQueryParams
  >(clicksOnYourAdQuery, {
    variables: {
      input: {
        jobId,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsClicksOnYourAd,
    isLoading: loading,
    error,
  };
};
