import { useTranslations } from '@vocab/react';
import { Text, Loader, Stack } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { MarketInsight } from '../../components/MarketInsight/MarketInsight';
import { Medium } from '../../components/Medium/Medium';
import { RoundedCard } from '../../components/RoundedCard/RoundedCard';
import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';
import { useHasMarketInsights } from '../../context/hasMarketInsights';
import { useCandidateDemandData } from '../../hooks/useCandidateDemandData';

import translations from './.vocab';

export const CandidateDemand = () => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();
  const { hasMarketInsights, updateHasMarketInsights } = useHasMarketInsights();

  const {
    data: candidateDemandData,
    error,
    isLoading,
  } = useCandidateDemandData({
    jobId: jobIdParam!,
  });

  const widgetTitle = t('Demand for candidates (job ads)', {
    Medium: (text) => <Medium>{text}</Medium>,
  });

  const WidgetDefaultWrapper = ({
    children,
  }: {
    children: ReactNodeNoStrings;
  }) => (
    <RoundedCard
      boxShadow="borderInfoLight"
      height="full"
      paddingX="gutter"
      paddingY="large"
    >
      <Stack space="gutter">
        <Text>{widgetTitle}</Text>
        {children}
      </Stack>
    </RoundedCard>
  );

  if (isLoading && !candidateDemandData) {
    return (
      <WidgetDefaultWrapper>
        <Stack align="center" space="large">
          <Loader />
        </Stack>
      </WidgetDefaultWrapper>
    );
  }

  if (error || !candidateDemandData) {
    updateHasMarketInsights(false);
    return <WidgetErrorNvl />;
  }

  const { comparisonPeriod, currentPeriod, hasCandidateDemand } =
    candidateDemandData;
  updateHasMarketInsights(hasCandidateDemand);

  const hasCandidateDemandInsights =
    hasMarketInsights && comparisonPeriod && currentPeriod;

  if (hasCandidateDemandInsights) {
    return (
      <MarketInsight
        title={widgetTitle}
        comparisonPeriod={comparisonPeriod}
        currentPeriod={currentPeriod}
      />
    );
  }

  return <WidgetErrorNvl />;
};
