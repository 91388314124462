
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoi4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBsb2NhdGlvbnMiOiLguITguYnguJnguKvguLLguIrguLfguYjguK3guKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkiLCJMb2NhdGlvbiI6IuC4quC4luC4suC4meC4l+C4teC5iOC4l+C4s+C4h+C4suC4mSIsIk90aGVycyBzdWZmaXgiOiLguK3guLfguYjguJkg4LmGIiwiUmVzdCBvZiB0aGUgd29ybGQiOiLguKrguYjguKfguJnguJfguLXguYjguYDguKvguKXguLfguK3guILguK3guIfguYLguKXguIEifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoi4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBsb2NhdGlvbnMiOiLguITguYnguJnguKvguLLguIrguLfguYjguK3guKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkiLCJMb2NhdGlvbiI6IuC4quC4luC4suC4meC4l+C4teC5iOC4l+C4s+C4h+C4suC4mSIsIk90aGVycyBzdWZmaXgiOiLguK3guLfguYjguJkg4LmGIiwiUmVzdCBvZiB0aGUgd29ybGQiOiLguKrguYjguKfguJnguJfguLXguYjguYDguKvguKXguLfguK3guILguK3guIfguYLguKXguIEifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiU2VtdWEgbG9rYXNpIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIlNlYXJjaCBsb2NhdGlvbnMiOiJDYXJpIGxva2FzaSIsIkxvY2F0aW9uIjoiTG9rYXNpIiwiT3RoZXJzIHN1ZmZpeCI6IkxhaW5ueWEiLCJSZXN0IG9mIHRoZSB3b3JsZCI6IlNlbHVydWggZHVuaWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiU2VtdWEgbG9rYXNpIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIlNlYXJjaCBsb2NhdGlvbnMiOiJDYXJpIGxva2FzaSIsIkxvY2F0aW9uIjoiTG9rYXNpIiwiT3RoZXJzIHN1ZmZpeCI6IkxhaW5ueWEiLCJSZXN0IG9mIHRoZSB3b3JsZCI6IlNlbHVydWggZHVuaWEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoi4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBsb2NhdGlvbnMiOiLguITguYnguJnguKvguLLguIrguLfguYjguK3guKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkiLCJMb2NhdGlvbiI6IuC4quC4luC4suC4meC4l+C4teC5iOC4l+C4s+C4h+C4suC4mSIsIk90aGVycyBzdWZmaXgiOiLguK3guLfguYjguJkg4LmGIiwiUmVzdCBvZiB0aGUgd29ybGQiOiLguKrguYjguKfguJnguJfguLXguYjguYDguKvguKXguLfguK3guILguK3guIfguYLguKXguIEifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoi4Liq4LiW4Liy4LiZ4LiX4Li14LmI4LiX4Liz4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiIsIlNlYXJjaCBsb2NhdGlvbnMiOiLguITguYnguJnguKvguLLguIrguLfguYjguK3guKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJkiLCJMb2NhdGlvbiI6IuC4quC4luC4suC4meC4l+C4teC5iOC4l+C4s+C4h+C4suC4mSIsIk90aGVycyBzdWZmaXgiOiLguK3guLfguYjguJkg4LmGIiwiUmVzdCBvZiB0aGUgd29ybGQiOiLguKrguYjguKfguJnguJfguLXguYjguYDguKvguKXguLfguK3guILguK3guIfguYLguKXguIEifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiU2VtdWEgbG9rYXNpIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIlNlYXJjaCBsb2NhdGlvbnMiOiJDYXJpIGxva2FzaSIsIkxvY2F0aW9uIjoiTG9rYXNpIiwiT3RoZXJzIHN1ZmZpeCI6IkxhaW5ueWEiLCJSZXN0IG9mIHRoZSB3b3JsZCI6IlNlbHVydWggZHVuaWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiU2VtdWEgbG9rYXNpIiwiT3RoZXJzIjoiTGFpbm55YSIsIk90aGVyIjoiTGFpbm55YSIsIlNlYXJjaCBsb2NhdGlvbnMiOiJDYXJpIGxva2FzaSIsIkxvY2F0aW9uIjoiTG9rYXNpIiwiT3RoZXJzIHN1ZmZpeCI6IkxhaW5ueWEiLCJSZXN0IG9mIHRoZSB3b3JsZCI6IlNlbHVydWggZHVuaWEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiQWxsIGxvY2F0aW9ucyIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIiLCJTZWFyY2ggbG9jYXRpb25zIjoiU2VhcmNoIGxvY2F0aW9ucyIsIkxvY2F0aW9uIjoiTG9jYXRpb24iLCJPdGhlcnMgc3VmZml4IjoiT3RoZXJzIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJSZXN0IG9mIHRoZSB3b3JsZCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiW+G6rMaaxpogxprDtsO2w7bDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIk90aGVycyI6IlvDtsO2w7bhua/huKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua/huKnhur3hur3FmV0iLCJTZWFyY2ggbG9jYXRpb25zIjoiW8Wg4bq94bq94bq9xIPEg8SDxZnDp+G4qSDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiTG9jYXRpb24iOiJbxYHDtsO2w6fEg8SD4bmvw6zDrMO2w7bguIHguLXguYldIiwiT3RoZXJzIHN1ZmZpeCI6IlvDluG5r+G4qeG6veG6veG6veG6vcWZxaFdIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJbxZjhur3hur3hur3FoeG5ryDDtsO2w7bGkiDhua/huKnhur3hur3hur0gxbXDtsO2w7bFmcaaxoxdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgbG9jYXRpb25zIjoiW+G6rMaaxpogxprDtsO2w7bDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIk90aGVycyI6IlvDtsO2w7bhua/huKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua/huKnhur3hur3FmV0iLCJTZWFyY2ggbG9jYXRpb25zIjoiW8Wg4bq94bq94bq9xIPEg8SDxZnDp+G4qSDGmsO2w7bDtsOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwiTG9jYXRpb24iOiJbxYHDtsO2w6fEg8SD4bmvw6zDrMO2w7bguIHguLXguYldIiwiT3RoZXJzIHN1ZmZpeCI6IlvDluG5r+G4qeG6veG6veG6veG6vcWZxaFdIiwiUmVzdCBvZiB0aGUgd29ybGQiOiJbxZjhur3hur3hur3FoeG5ryDDtsO2w7bGkiDhua/huKnhur3hur3hur0gxbXDtsO2w7bFmcaaxoxdIn0=!"
        )
      )
      });
  
      export { translations as default };
    