import { useEffect, useState, useCallback } from 'react';

export interface WindowSizeResult {
  width?: number;
  height?: number;
}

export function useWindowSize(): WindowSizeResult {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const getSizeMemo = useCallback(getSize, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSizeMemo());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getSizeMemo, isClient]);

  return windowSize;
}
