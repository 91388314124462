import { Box } from 'braid-design-system';

import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';

export const PerformancePredictionFallback = () => (
  <Box
    background="surface"
    borderRadius="standard"
    height="full"
    padding="gutter"
  >
    <WidgetErrorNvl />
  </Box>
);
