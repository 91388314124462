export const ClockIcon = () => (
  <svg fill="transparent" width="100" height="100" viewBox="0 0 115 115">
    <g id="save time">
      <path
        id="Path"
        d="M69.7821 13.4376V9.83998H77.2378C78.5841 9.83998 79.6755 8.74862 79.6755 7.40236V2.47668C79.6755 1.13042 78.5841 0.0390625 77.2378 0.0390625H57.8125C56.4663 0.0390625 55.3749 1.13042 55.3749 2.47668V7.40236C55.3749 8.74862 56.4663 9.83998 57.8125 9.83998H65.2683V13.4376C41.451 14.6615 22.9495 34.6457 23.5616 58.4865C24.1738 82.3273 43.6765 101.336 67.5252 101.336C91.3739 101.336 110.877 82.3273 111.489 58.4865C112.101 34.6457 93.5993 14.6615 69.7821 13.4376Z"
        fill="#EAF0FA"
      />
      <circle
        id="Oval"
        cx="67.5176"
        cy="57.3565"
        r="37.0098"
        stroke="white"
        strokeWidth="2.32"
        strokeLinecap="round"
      />
      <path
        id="Path_2"
        d="M1.5 73.2093H14.3774"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M9.375 82.6322H22.2524"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M17.25 92.055H30.1274"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M96.5086 57.432H93.5078"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M41.5236 57.2308H38.5312"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_7"
        d="M67.4281 86.3137V83.3213"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_8"
        d="M67.6156 31.341V28.3486"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        id="Oval_2"
        cx="67.5226"
        cy="57.1632"
        r="6.07724"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_9"
        d="M86.4202 38.4521L71.8281 52.8425"
        stroke="#2765CF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
