import {
  EmployerHeader,
  type ActiveSubTabId,
  type AnalyticsEvent,
} from '@seek/adv-header-footer';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { useMemo } from 'react';
import { hotjar } from 'react-hotjar';

import { useConfig } from '../../App/ConfigContext';
import { isStaging, isProduction, getLocale, getSite } from '../../config';
import { trackLink } from '../../utils/tealiumEventTracker';

export interface AdCentreHeaderProps {
  accountNumber: string;
  advertiserId: string;
  activeTabId: 'analytics';
  currentPage?: string;
  activeSubTabId: ActiveSubTabId | undefined;
}

export const AdCentreHeader = ({
  accountNumber,
  activeTabId,
  activeSubTabId,
  currentPage = '',
  advertiserId,
}: AdCentreHeaderProps) => {
  // mock seek user header(similar to seekStaffHeader)
  // TODO: remove after permission control added and act returned

  const { country, language, zone } = useConfig();

  const { accountContext } = useAccountContext();
  const { headerFooterContext } = accountContext || {};

  const basicPageTrackingData = {
    siteCountry: country,
    currentPage,
    hirerUserEmail: headerFooterContext?.identityContext?.email || null,
    hirerUserFullName: headerFooterContext?.user?.name || null,
    siteSection: 'jobs',
    userId: headerFooterContext?.user?.id || null,
    siteSubsection: 'analytics',
    zone,
    siteLanguage: language,
    brand: 'seek',
    advertiserId,
  };

  const headerProps = {
    ...headerFooterContext,
    activeTabId,
    activeSubTabId,
    id: 'basic-8',
    locale: getLocale(),
    site: getSite(),
    isStaging: isStaging(),
    onTrackedItemInteraction: (analyticsEventData: AnalyticsEvent) => {
      trackLink(analyticsEventData.eventName, {
        ...basicPageTrackingData,
        ...analyticsEventData,
      });
    },
  };

  useMemo(() => {
    if (isProduction() && headerFooterContext?.user?.id) {
      const { id } = headerFooterContext.user;
      hotjar.identify(id, {
        accountNumber,
        experience: 'new',
      });
    }
  }, [headerFooterContext, accountNumber]);

  return <EmployerHeader {...headerProps} />;
};
