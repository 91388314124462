import { Heading, Stack } from 'braid-design-system';
import type { ReactNode } from 'react';

export interface GenericTitleContainerProps {
  children: ReactNodeNoStrings;
  title?: ReactNode;
  weight?: 'regular' | 'weak';
}

export const GenericTitleContainer = ({
  children,
  title,
  weight = 'regular',
}: GenericTitleContainerProps) => (
  <Stack space="large">
    {title && (
      <Heading level="3" weight={weight}>
        {title}
      </Heading>
    )}
    {children}
  </Stack>
);
