import { gql } from '@apollo/client';

export const candidateDemandQuery = gql`
  query GetCandidateDemandData($input: CandidateDemandWidgetInput!) {
    analyticsCandidateDemand(input: $input) {
      comparisonPeriod {
        count
        calendarMonth
      }
      currentPeriod {
        count
        calendarMonth
      }
      hasCandidateDemand
    }
  }
`;
