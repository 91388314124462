import { Box, Columns, Column, IconChevron } from 'braid-design-system';
import { type ReactElement, useRef, useState, useEffect } from 'react';

import * as styles from './Carousel.css';

interface SnapScrollCarouselProps {
  elements: ReactElement[];
  onNaviCallback?: (slideIndex: number) => void;
  onTipClicked?: () => void;
}

export const Carousel = ({
  elements,
  onNaviCallback,
  onTipClicked,
}: SnapScrollCarouselProps) => {
  const currentPageRef = useRef<HTMLElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slideCountrolIndex, setSlideControlIndex] = useState(0);

  const numOfPages = elements.length;
  const indexArray = Array.from(Array(numOfPages).keys());

  const moveToNext = () => {
    onNaviCallback?.(currentSlideIndex + 1);
    setCurrentSlideIndex((current) => current + 1);
    if (onTipClicked) onTipClicked();
  };

  const moveToPrev = () => {
    onNaviCallback?.(currentSlideIndex - 1);
    setCurrentSlideIndex((current) => current - 1);
  };

  useEffect(() => {
    if (currentPageRef.current) {
      currentPageRef.current.scrollLeft =
        currentSlideIndex * currentPageRef.current.clientWidth;

      setSlideControlIndex(currentSlideIndex);
    }
  }, [currentSlideIndex]);

  return (
    <Box data-testid="carousel">
      <Box display="flex" flexDirection="column" position="relative">
        <Box
          className={styles.carousel}
          ref={currentPageRef}
          data-testid="slides-container"
        >
          {elements.map((element, index) => (
            <Box
              data-testid={`carousel-slide-${index}`}
              key={index}
              style={{ minWidth: '100%', scrollSnapAlign: 'start' }}
              height="full"
            >
              {element}
            </Box>
          ))}
        </Box>
        {currentSlideIndex !== 0 && (
          <Box
            data-testid="move-to-prev-icon-button"
            className={styles.arrows.left}
            component="button"
            onClick={moveToPrev}
          >
            <Box style={{ height: 16 }}>
              <IconChevron direction="left" size="fill" tone="secondary" />
            </Box>
          </Box>
        )}
        {currentSlideIndex !== elements.length - 1 && (
          <Box
            data-testid="move-to-next-icon-button"
            className={styles.arrows.right}
            component="button"
            onClick={moveToNext}
          >
            <Box style={{ height: 16 }}>
              <IconChevron direction="right" size="fill" tone="secondary" />
            </Box>
          </Box>
        )}
      </Box>
      {elements.length > 1 && (
        <Box
          data-testid="slides-control-bullets"
          display="flex"
          justifyContent="center"
          marginTop="medium"
        >
          <Columns space="small">
            {indexArray.map((index: number) => {
              const clickOnSlideControlBullet = () => {
                setCurrentSlideIndex(index);
              };
              return (
                <Column key={index}>
                  <Box
                    className={
                      index === slideCountrolIndex
                        ? styles.bulletPoint.active
                        : styles.bulletPoint.inactive
                    }
                    data-testid={`slides-control-bullet-${index}`}
                    onClick={clickOnSlideControlBullet}
                  />
                </Column>
              );
            })}
          </Columns>
        </Box>
      )}
    </Box>
  );
};
