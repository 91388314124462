import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import classNames from 'classnames';
import type React from 'react';

import translations from './.vocab';

import * as styles from './jobSkeleton.css';

const HighlightedText = ({ y, text }: { y: number; text: string }) => (
  <foreignObject x={18.56} y={y} width={240} height={20}>
    <div className={styles.jobSkeletonHighlightBox}>
      <Text size="xsmall">
        <span className={styles.jobSkeletonHighlightText}>{text}</span>
      </Text>
    </div>
  </foreignObject>
);

export interface JobSkeletonProps {
  activeSection: 'job-title' | 'key-selling-points' | 'job-summary' | 'salary';
  salaryContent?: string;
}

export const JobSkeleton = ({
  activeSection,
  salaryContent,
}: JobSkeletonProps) => {
  const { t } = useTranslations(translations);

  return (
    <svg height="147px" width="260px">
      <rect
        stroke="#E1E1E1"
        strokeWidth="0.64"
        fill="#F7F7F7"
        x="0.32"
        y="0.32"
        width="259.36"
        height="146.36"
      />
      {activeSection !== 'job-title' && (
        <g className={styles.jobSkeletonNeutral}>
          <rect
            fill="#A8A8A8"
            x="18"
            y="15"
            width="69"
            height="3.63428571"
            rx="1.81714286"
          />
          <rect
            fill="#A8A8A8"
            x="18"
            y="24.7242857"
            width="69"
            height="3.63428571"
            rx="1.81714286"
          />
        </g>
      )}
      {activeSection === 'job-title' && (
        <HighlightedText y={16} text={t('Job title')} />
      )}
      <g className={styles.jobSkeletonNeutral}>
        <rect
          x="18.1714286"
          y="42"
          width="138.708571"
          height="3.63428571"
          rx="1.81714286"
        />
      </g>
      {activeSection !== 'salary' && (
        <g className={styles.jobSkeletonNeutral}>
          <rect
            x="18.1714286"
            y="51.7242857"
            width="138.708571"
            height="3.63428571"
            rx="1.81714286"
          />
          <rect
            x="18.1714286"
            y="61.4485714"
            width="138.708571"
            height="3.63428571"
            rx="1.81714286"
          />
        </g>
      )}
      {activeSection === 'salary' && salaryContent && (
        <HighlightedText y={52} text={salaryContent} />
      )}
      <g
        className={classNames(styles.jobSkeletonNeutral, {
          [styles.jobSkeletonActive]: activeSection === 'key-selling-points',
        })}
      >
        <rect
          x="28.1714286"
          y="79"
          width="188"
          height="3.63428571"
          rx="1.81714286"
        />
        <circle cx="19.9885714" cy="80.8171429" r="1.81714286" />
        <rect
          x="28.1714286"
          y="89.72"
          width="188"
          height="3.63428571"
          rx="1.81714286"
        />
        <circle cx="19.9885714" cy="91.5371429" r="1.81714286" />
        <rect
          x="28.1714286"
          y="100.44"
          width="188"
          height="3.63428571"
          rx="1.81714286"
        />
        <circle cx="19.9885714" cy="102.257143" r="1.81714286" />
      </g>
      <g
        className={classNames(styles.jobSkeletonNeutral, {
          [styles.jobSkeletonActive]: activeSection === 'job-summary',
        })}
      >
        <rect
          x="18.1714286"
          y="118"
          width="223"
          height="3.63428571"
          rx="1.81714286"
        />
        <rect
          x="18.1714286"
          y="127.634286"
          width="223"
          height="3.63428571"
          rx="1.81714286"
        />
      </g>
    </svg>
  );
};
