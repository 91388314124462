
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwbGljYXRpb24gc3RhcnRzIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiI4Liy4LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4Liq4Liz4LmA4Lij4LmH4LiIIFNFRUsiLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiB4LiUIFwi4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZXCIg4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwbGljYXRpb24gc3RhcnRzIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiI4Liy4LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4Liq4Liz4LmA4Lij4LmH4LiIIFNFRUsiLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiB4LiUIFwi4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZXCIg4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiS2FuZGlkYXQiLCJBcHBsaWNhdGlvbiBzdGFydHMiOiJMYW1hcmFuIGRpbXVsYWkiLCJBIGNhbmRpZGF0ZSBjb21wbGV0ZWQgdGhlaXIgYXBwbGljYXRpb24gZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW55ZWxlc2Fpa2FuIGxhbWFyYW4gdW50dWsgbG93b25nYW4ga2VyamEgQW5kYSBkaSBTRUVLLiIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW5nZWtsaWsgdG9tYm9sICdsYW1hciBwZWtlcmphYW4gaW5pJyBkaSBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiS2FuZGlkYXQiLCJBcHBsaWNhdGlvbiBzdGFydHMiOiJMYW1hcmFuIGRpbXVsYWkiLCJBIGNhbmRpZGF0ZSBjb21wbGV0ZWQgdGhlaXIgYXBwbGljYXRpb24gZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW55ZWxlc2Fpa2FuIGxhbWFyYW4gdW50dWsgbG93b25nYW4ga2VyamEgQW5kYSBkaSBTRUVLLiIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW5nZWtsaWsgdG9tYm9sICdsYW1hciBwZWtlcmphYW4gaW5pJyBkaSBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwbGljYXRpb24gc3RhcnRzIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiI4Liy4LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4Liq4Liz4LmA4Lij4LmH4LiIIFNFRUsiLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiB4LiUIFwi4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZXCIg4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoi4Lic4Li54LmJ4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQXBwbGljYXRpb24gc3RhcnRzIjoi4LiI4Liz4LiZ4Lin4LiZ4LiE4Lil4Li04LiB4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiX4Li14LmI4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZ4LiI4Liy4LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiT4Liq4Liz4LmA4Lij4LmH4LiIIFNFRUsiLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoi4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LiB4LiUIFwi4Liq4Lih4Lix4LiE4Lij4LiH4Liy4LiZXCIg4Lia4LiZ4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiS2FuZGlkYXQiLCJBcHBsaWNhdGlvbiBzdGFydHMiOiJMYW1hcmFuIGRpbXVsYWkiLCJBIGNhbmRpZGF0ZSBjb21wbGV0ZWQgdGhlaXIgYXBwbGljYXRpb24gZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW55ZWxlc2Fpa2FuIGxhbWFyYW4gdW50dWsgbG93b25nYW4ga2VyamEgQW5kYSBkaSBTRUVLLiIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW5nZWtsaWsgdG9tYm9sICdsYW1hciBwZWtlcmphYW4gaW5pJyBkaSBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiS2FuZGlkYXQiLCJBcHBsaWNhdGlvbiBzdGFydHMiOiJMYW1hcmFuIGRpbXVsYWkiLCJBIGNhbmRpZGF0ZSBjb21wbGV0ZWQgdGhlaXIgYXBwbGljYXRpb24gZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW55ZWxlc2Fpa2FuIGxhbWFyYW4gdW50dWsgbG93b25nYW4ga2VyamEgQW5kYSBkaSBTRUVLLiIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJLYW5kaWRhdCBtZW5nZWtsaWsgdG9tYm9sICdsYW1hciBwZWtlcmphYW4gaW5pJyBkaSBsb3dvbmdhbiBrZXJqYSBBbmRhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiQ2FuZGlkYXRlcyIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IkFwcGxpY2F0aW9uIHN0YXJ0cyIsIkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSyI6IkEgY2FuZGlkYXRlIGNvbXBsZXRlZCB0aGVpciBhcHBsaWNhdGlvbiBmb3IgeW91ciBqb2IgYWQgb24gU0VFSy4iLCJBIGNhbmRpZGF0ZSBjbGlja2VkIG9uIGFwcGx5IGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiQSBjYW5kaWRhdGUgY2xpY2tlZCBvbiB0aGUgJ2FwcGx5IGZvciB0aGlzIGpvYicgYnV0dG9uIG9uIHlvdXIgam9iIGFkLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiW8OHxIPEg+C4geC4teC5icaMw6zDrMaMxIPEg+G5r+G6veG6vcWhXSIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IlvhuqzGpcalxprDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDFoeG5r8SDxIPEg8WZ4bmvxaFdIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiW+G6rCDDp8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur0gw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9xowg4bmv4bip4bq94bq94bq9w6zDrMOsxZkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYkgxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMSDxIPEg8aMIMO2w7bDtuC4geC4teC5iSDFoMOLw4vhuLAuXSIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJb4bqsIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg+G5r+G6veG6veG6vSDDp8aaw6zDrMOsw6fEt+G6veG6veG6vcaMIMO2w7bDtuC4geC4teC5iSDhua/huKnhur3hur3hur0gJ8SDxIPEg8alxqXGmsO9w73DvSDGksO2w7bDtsWZIOG5r+G4qcOsw6zDrMWhIMS1w7bDtsO2w58nIMOfx5rHmsea4bmv4bmvw7bDtsO24LiB4Li14LmJIMO2w7bDtuC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMSDxIPEg8aMLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGVzIjoiW8OHxIPEg+C4geC4teC5icaMw6zDrMaMxIPEg+G5r+G6veG6vcWhXSIsIkFwcGxpY2F0aW9uIHN0YXJ0cyI6IlvhuqzGpcalxprDrMOsw6zDp8SDxIPEg+G5r8Osw6zDrMO2w7bDtuC4geC4teC5iSDFoeG5r8SDxIPEg8WZ4bmvxaFdIiwiQSBjYW5kaWRhdGUgY29tcGxldGVkIHRoZWlyIGFwcGxpY2F0aW9uIGZvciB5b3VyIGpvYiBhZCBvbiBTRUVLIjoiW+G6rCDDp8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur0gw6fDtsO2w7ZtzILGpcaa4bq94bq94bq94bmv4bq94bq94bq9xowg4bmv4bip4bq94bq94bq9w6zDrMOsxZkgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYkgxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMSDxIPEg8aMIMO2w7bDtuC4geC4teC5iSDFoMOLw4vhuLAuXSIsIkEgY2FuZGlkYXRlIGNsaWNrZWQgb24gYXBwbHkgZm9yIHlvdXIgam9iIGFkIG9uIFNFRUsiOiJb4bqsIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg+G5r+G6veG6veG6vSDDp8aaw6zDrMOsw6fEt+G6veG6veG6vcaMIMO2w7bDtuC4geC4teC5iSDhua/huKnhur3hur3hur0gJ8SDxIPEg8alxqXGmsO9w73DvSDGksO2w7bDtsWZIOG5r+G4qcOsw6zDrMWhIMS1w7bDtsO2w58nIMOfx5rHmsea4bmv4bmvw7bDtsO24LiB4Li14LmJIMO2w7bDtuC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDEtcO2w7bDtsOfIMSDxIPEg8aMLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    