import { type Country, createAbsoluteUrl } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconChevron,
  Inline,
  List,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';
import classNames from 'classnames';
import { useCallback } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { useHidden } from '../../hooks/useHidden';
import { chartUtils } from '../../seek-charts';
import { ElapsedTime } from '../ElapsedTime/ElapsedTime';

import translations from './.vocab';

import * as styles from './jobAdSummary.css';

export interface JobAdSummaryProps {
  bulletPoints: Array<string | null> | null;
  classificationTier1: string | null;
  classificationTier2: string | null;
  companyName: string;
  createDate: string;
  displayCurrency: string | null;
  isPremium: boolean;
  isPrivateAdvertiser: boolean;
  isStandOut: boolean;
  jobSummaryText: string | null;
  jobId: string;
  jobTitle: string;
  locationLabel: string;
  logoUrl: string | null;
  salaryText: string | null;
}

function rightLabelToneFromConditions({
  isPremium,
  isRecent,
}: {
  isPremium: boolean;
  isRecent: boolean;
}): 'critical' | 'link' | 'neutral' {
  if (isPremium) {
    return 'link';
  }
  if (isRecent) {
    return 'critical';
  }
  return 'neutral';
}

function tierElementsFromTierStrings(tiers: Array<string | null>): string[] {
  const nonEmptyTiers = tiers.filter((t): t is string => Boolean(t));
  return nonEmptyTiers
    .map((t, i) => (i < nonEmptyTiers.length - 1 ? [t, 'separator'] : [t]))
    .reduce((a, b) => a.concat(b), []);
}

export const JobAdSummary = ({
  bulletPoints,
  classificationTier1,
  classificationTier2,
  companyName,
  createDate,
  displayCurrency,
  isPremium,
  isPrivateAdvertiser,
  isStandOut,
  jobSummaryText,
  jobId,
  jobTitle,
  locationLabel,
  logoUrl,
  salaryText,
}: JobAdSummaryProps) => {
  const isHiddenOnMobile = useHidden([true, false]);
  const { country, language } = useConfig();
  const jobUrl = createAbsoluteUrl({
    product: 'candidate',
    country: country as Country,
    language,
    path: `/job/${jobId.replace('demo', '')}`,
  });
  const { t: localT } = useTranslations(translations);

  const isRecent = chartUtils.dateFormatter.isRecentFromString(createDate);
  const rightLabelToneFromConditionsMemo = useCallback(
    rightLabelToneFromConditions,
    [],
  );
  const rightLabelTone = rightLabelToneFromConditionsMemo({
    isPremium,
    isRecent,
  });

  const tierElementsFromTierStringsMemo = useCallback(
    tierElementsFromTierStrings,
    [],
  );
  const classificationTierElements = tierElementsFromTierStringsMemo([
    classificationTier1,
    classificationTier2,
  ]);
  const nonNullBulletPoints =
    bulletPoints?.filter((b): b is string => Boolean(b)) || [];
  return (
    <Box
      background="surface"
      paddingX="gutter"
      paddingY="large"
      className={classNames(styles.jobAdSummaryCardStyle, {
        [styles.premiumCardStyle]: isPremium,
        [styles.standOutCardStyle]: isStandOut && !isPremium,
      })}
      data={{ id: 'ui-job-ad-summary' }}
    >
      <Stack space="medium">
        <Stack space="small">
          <Box className={styles.headingWrapperStyle} paddingTop="small">
            <Text weight="strong" size="large">
              <TextLink href={jobUrl}>{jobTitle}</TextLink>

              {/* <TextLinkRenderer>  // TODO: handle classes
                {(textLinkProps) => (
                  <a
                    {...textLinkProps}
                    className={classNames(
                      textLinkProps.className,
                      styles.headingStyle,
                    )}
                    href={jobUrl}
                  >
                    {jobTitle}
                  </a>
                )}
              </TextLinkRenderer> */}
            </Text>
            <Box className={styles.headingRightLabelStyle}>
              <Text size="small" tone={rightLabelTone}>
                {isPremium ? (
                  localT('Featured')
                ) : (
                  <ElapsedTime pastTime={new Date(createDate).toISOString()} />
                )}
              </Text>
            </Box>
          </Box>
          <Text size="small">
            {isPrivateAdvertiser ? 'Private Advertiser' : companyName}
          </Text>
        </Stack>
        <Stack space="small">
          <Text size="small">{locationLabel}</Text>
          {!isHiddenOnMobile && salaryText ? (
            <Text size="small">
              {displayCurrency
                ? localT('salaryText with displayCurrency', {
                    salaryText,
                    displayCurrency,
                  })
                : localT('salaryText without displayCurrency', {
                    salaryText,
                  })}
            </Text>
          ) : null}
          {!isHiddenOnMobile && (
            <Inline space="xxsmall">
              {classificationTierElements.map((t, i) => {
                const key = `ui-job-ad-summary-classification-tier-element-${i}`;
                if (i === 0) {
                  return (
                    <Text key={key} size="small">
                      {t}
                    </Text>
                  );
                }
                if (t === 'separator') {
                  return (
                    <Text key={key} size="small">
                      <IconChevron
                        direction="right"
                        alignY="lowercase"
                        tone="secondary"
                      />
                    </Text>
                  );
                }
                return (
                  <Text key={key} size="small">
                    {t}
                  </Text>
                );
              })}
            </Inline>
          )}
        </Stack>
        {nonNullBulletPoints.length > 0 && (
          <Box data={{ id: 'ui-job-summary-bullet-points' }}>
            <List size="small" space="small">
              {nonNullBulletPoints.map((bulletPointText, i) => (
                <Text key={`ui-job-ad-summary-bullet-point-${i}`}>
                  {bulletPointText}
                </Text>
              ))}
            </List>
          </Box>
        )}
        <Stack space="small">
          <Box className={styles.jobTextStyle}>
            <Text size="small" tone="secondary">
              {jobSummaryText}
            </Text>
          </Box>
          <Box className={styles.bottomWrapperStyle}>
            <Columns space="small" alignY="bottom">
              <Column>
                <Box className={styles.actionsPlaceholderStyle}>&nbsp;</Box>
              </Column>
              {logoUrl ? (
                <Column width="content">
                  <Box className={styles.logoWrapperStyle}>
                    <img
                      className={styles.logoStyle}
                      src={logoUrl.replace('http:', 'https:')}
                    />
                  </Box>
                </Column>
              ) : null}
            </Columns>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};
