import { useTranslations } from '@vocab/react';
import {
  Text,
  Box,
  IconChevron,
  Loader,
  Notice,
  Stack,
  Badge,
  Heading,
} from 'braid-design-system';
import { useContext, type ReactNode } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { useHidden } from '../../App/hooks/useHidden';
import { AnimatedPercentage } from '../../components/AnimatedText';
import {
  ConversionCard,
  type DisclosureProps,
} from '../../components/ConversionCard/ConversionCard';
import { DisclosureDialog } from '../../components/DisclosureDialog/DisclosureDialogNew';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { IsFilterContext } from '../../context/IsFilterContext';
import {
  useFeatureToggleData,
  FEATURE_PERFORMANCE_RATING,
} from '../../featureToggle';
import type { ConversionRateData } from '../../gql/generated';
import { useConversionData } from '../../hooks/useConversionData';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';
import { trackEvent } from '../../utils/tealiumAUREventTracker';

import translations from './.vocab';
import {
  CONV_RATE_CONFIG,
  hasLinkOutAds,
  isGreaterThanMinRate,
  isLessThanMinRate,
  isNumberEqual,
  isOverNoRatingRange,
  conversionRateGreaterThanAboveMarketThreshold,
  conversionRateLessThanBelowMarketThreshold,
} from './Conversion.utils';

import * as styles from './Conversion.css';

type ConversionType =
  | 'Views in job search'
  | 'Ad clicks'
  | 'Applications started'
  | 'Completed applications';

export const Conversion = () => {
  const { t } = useTranslations(translations);
  const isPerformanceRatingFeatureEnabled = useFeatureToggleData(
    FEATURE_PERFORMANCE_RATING,
    false,
  );
  const isCompactView = useHidden([true, true, false]);

  const { locale } = useConfig();

  const numberFormatter = new Intl.NumberFormat(locale);
  const percentageFormatter = new Intl.NumberFormat(locale, {
    style: 'percent',
  });
  const numberFormatter1Digit = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 1,
  });

  const { data: analyticsConversion, isLoading, error } = useConversionData();
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  if (isLoading && !analyticsConversion) {
    return (
      <Box display="flex" justifyContent="center">
        <Loader size="small" />
      </Box>
    );
  }

  if (error || !analyticsConversion) {
    return <WidgetError />;
  }

  const {
    jobSearchViews,
    adClicks,
    applicationsStarted,
    applicationsCompleted,
    adsWithoutRatingPercentage,
    linkOutAds,
  } = analyticsConversion;

  const overNoRatingThreshold = isOverNoRatingRange(adsWithoutRatingPercentage);
  const advertiserHasLinkOutAds = hasLinkOutAds(linkOutAds);

  const conversionTranslationOptions: ReactNodeNoStrings = {
    Span: (children: string) => (
      <span className={styles.boldStyle}>{children}</span>
    ),
    Text: (children: string) => <>{children}</>,
  };

  const getConvRateStyle = (origin: number, similar: number) => {
    if (overNoRatingThreshold) return styles.neutral;
    if (isLessThanMinRate(origin!, similar!)) return styles.critical;
    if (isGreaterThanMinRate(origin!, similar!)) return styles.positive;
    return styles.neutral;
  };

  const isRangeWithin100 = (total: number, avg: number): boolean =>
    !((total - avg) / avg >= CONV_RATE_CONFIG.TIMES_MORE_RATE);

  const getConvRateMoreLess = (origin: number, similar: number) => {
    if (origin < similar) return 'less than';
    else if (origin > similar) return 'more than';
    return 'equal to';
  };

  const getConversionRateBadge = (
    conversionRate: number | undefined,
    similarAdsConversionRate: number | undefined | null,
  ) => {
    if (!similarAdsConversionRate || typeof conversionRate !== 'number') {
      return null;
    }

    if (
      conversionRateGreaterThanAboveMarketThreshold(
        conversionRate,
        similarAdsConversionRate,
      )
    ) {
      return <Badge tone="positive">{t('Above market')} </Badge>;
    }

    if (
      conversionRateLessThanBelowMarketThreshold(
        conversionRate,
        similarAdsConversionRate,
      )
    ) {
      return <Badge tone="critical">{t('Below market')}</Badge>;
    }

    return null;
  };

  const getConversionRate = (type: ConversionType, d: ConversionRateData) => {
    if (advertiserHasLinkOutAds && type === 'Applications started') {
      return undefined;
    }
    if (d.adConversionRate === null || d.adConversionRate === undefined) {
      return null;
    }
    return (
      <Box display="flex" alignItems="center">
        <Heading level="3">
          {<AnimatedPercentage value={d.adConversionRate!} />}
        </Heading>
        {!overNoRatingThreshold && (
          <Box marginLeft="xsmall">
            {getConversionRateBadge(
              d.adConversionRate,
              d.similarAdConversionRate,
            )}
          </Box>
        )}
      </Box>
    );
  };

  const getConversionRateTitle = (type: ConversionType) => {
    if (advertiserHasLinkOutAds && type === 'Applications started') {
      return null;
    }

    const titleMap: Record<string, string> = {
      'Views in job search': t('Views in job search conversion rate title'),
      'Ad clicks': t('Ad clicks conversion rate title'),
      'Applications started': t('Applications started conversion rate title'),
    };

    const titleTooltipMap: Record<string, string> = {
      'Views in job search': t('Views in job search conversion rate tooltip'),
      'Ad clicks': t('Ad clicks conversion rate tooltip'),
      'Applications started': t('Applications started conversion rate tooltip'),
    };

    const title = titleMap[type];
    const titleTooltip = titleTooltipMap[type];

    return (
      <DisclosureDialog
        id={`conversion-rate-tooltip-${type}`}
        tooltip={
          <Stack space="medium">
            <Text size="small">{titleTooltip}</Text>
          </Stack>
        }
      >
        {({ triggerProps }) => (
          <Box paddingTop={overNoRatingThreshold ? 'small' : 'none'}>
            <Text>
              <span role="button" {...triggerProps}>
                {title}
              </span>
            </Text>
          </Box>
        )}
      </DisclosureDialog>
    );
  };

  const getMainComparisonText = (
    d: ConversionRateData,
    header: ConversionType,
  ) => {
    if (advertiserHasLinkOutAds && header === 'Completed applications') {
      return null;
    }
    const { similarAdAverage, total } = d;

    if (similarAdAverage === 0 && total !== 0) return undefined;
    if (isNumberEqual(total, similarAdAverage)) {
      return t(`Number equal to similar ads`);
    }
    const moreOrLess = getConvRateMoreLess(total, similarAdAverage);

    if (isRangeWithin100(total, similarAdAverage)) {
      return t(`Number ${moreOrLess} similar ads`, {
        Span: (children: ReactNode) => (
          <span className={getConvRateStyle(total, similarAdAverage)}>
            {children}
          </span>
        ),
        number: `${percentageFormatter.format(
          Math.abs(parseFloat((total / similarAdAverage - 1).toFixed(2))),
        )}`,
      });
    }

    return t(`Times similar ads`, {
      Span: (children: ReactNode) => (
        <span className={getConvRateStyle(total, similarAdAverage)}>
          {children}
        </span>
      ),
      number: `${(
        Math.round((total / similarAdAverage) * 10) / 10
      ).toString()}x`,
    });
  };

  const getComparisonText = (d: ConversionRateData, header: ConversionType) => {
    const { similarAdAverage, total } = d;

    if (similarAdAverage === 0 && total !== 0) return undefined;

    return t(`${header} similar ads info`, {
      avgText: numberFormatter.format(similarAdAverage),
      avg: similarAdAverage,
    });
  };
  const getComparisonRateText = (
    d: ConversionRateData,
    header: ConversionType,
  ) => {
    if (
      !overNoRatingThreshold &&
      isNumberEqual(d.adConversionRate!, d.similarAdConversionRate!)
    ) {
      return (
        <Box className={styles.gapSmall}>
          <Text size="small">
            {t('Comparison equal to rate', {
              Detail: (children: ReactNode) => <>{children}</>,
              similarConvRate: numberFormatter1Digit.format(
                parseFloat(d.similarAdConversionRate!.toFixed(2)),
              ),
            })}
          </Text>
        </Box>
      );
    }

    if (
      !overNoRatingThreshold &&
      (!advertiserHasLinkOutAds ||
        (advertiserHasLinkOutAds &&
          (header === 'Ad clicks' || header === 'Views in job search'))) &&
      d.adConversionRate &&
      d.similarAdConversionRate
    ) {
      const moreOrLess = getConvRateMoreLess(
        d.adConversionRate!,
        d.similarAdConversionRate!,
      );
      return (
        <Box className={styles.gapSmall}>
          <Text size="small">
            {t(`Comparison ${moreOrLess} rate`, {
              CompareNumber: (children: ReactNode) => (
                <span
                  className={getConvRateStyle(
                    d.adConversionRate!,
                    d.similarAdConversionRate!,
                  )}
                >
                  {children}
                </span>
              ),
              Detail: (children: ReactNode) => <>{children}</>,
              numPP: numberFormatter1Digit.format(
                Math.abs(d.adConversionRate! - d.similarAdConversionRate!),
              ),
              similarConvRate: numberFormatter1Digit.format(
                parseFloat(d.similarAdConversionRate!.toFixed(1)),
              ),
            })}
          </Text>
        </Box>
      );
    }

    return <Box className={styles.paddingBox} />;
  };

  const getTotalProps = (type: ConversionType, total: number) =>
    advertiserHasLinkOutAds && type === 'Completed applications'
      ? {
          heading: t('Data not available'),
          description: t('Data not available explain'),
        }
      : {
          heading: total,
        };

  const getDisclosureProps = (
    type: ConversionType,
    comparisonText: ReactNode,
  ): DisclosureProps | undefined => {
    if (
      overNoRatingThreshold ||
      (advertiserHasLinkOutAds && type === 'Completed applications')
    ) {
      return undefined;
    }

    if (!comparisonText) return undefined;

    const getConversionType = () => {
      switch (type) {
        case 'Views in job search':
          return 'search views';
        case 'Ad clicks':
          return 'detail views';
        case 'Applications started':
          return 'apply start';
        case 'Completed applications':
          return 'apply complete';
      }
    };

    return {
      expandLabel: t('Show more'),
      collapseLabel: t('Show less'),
      content: comparisonText,
      id: `id-${type}`,
      onToggle(expanded) {
        if (expanded) {
          trackEvent('conversion_details_pressed', {
            conversionType: getConversionType(),
            isFilterAdPerformance,
            isFilterAdType,
            isFilterClassification,
            isFilterJobTitle,
            isFilterLocation,
            isFilterAccountHierachy,
            isFilterAdId,
            isFilterAdStatus,
            isFilterRepost,
            isFilterTipsToImproveAds,
            isFilterUser,
            isFilterBudget,
          });
        }
      },
    };
  };

  const conversionViewInJobSearchProps = {
    header: t('Views in job search'),
    headerTooltip: t('Views in job search tooltip'),
    mainComparisionText:
      isPerformanceRatingFeatureEnabled && !overNoRatingThreshold
        ? getMainComparisonText(jobSearchViews, 'Views in job search')
        : undefined,
    comparisonRateText: isPerformanceRatingFeatureEnabled
      ? getComparisonRateText(jobSearchViews, 'Views in job search')
      : undefined,
    totalProps: getTotalProps('Views in job search', jobSearchViews.total),
    disclosureProps: isPerformanceRatingFeatureEnabled
      ? getDisclosureProps(
          'Views in job search',
          getComparisonText(jobSearchViews, 'Views in job search'),
        )
      : undefined,
    mobileText: t('Views in job search were from mobile', {
      mobileViewsRate: formatPercentage(jobSearchViews.mobileRate * 100, 0),
    }),
    conversionRate: getConversionRate('Views in job search', jobSearchViews),
    conversionRateTitle: getConversionRateTitle('Views in job search'),
  };

  const conversionAdClicksProps = {
    header: t('Ad clicks'),
    headerTooltip: t('Ad clicks tooltip'),
    mainComparisionText:
      isPerformanceRatingFeatureEnabled && !overNoRatingThreshold
        ? getMainComparisonText(adClicks, 'Ad clicks')
        : undefined,
    comparisonRateText: isPerformanceRatingFeatureEnabled
      ? getComparisonRateText(adClicks, 'Ad clicks')
      : undefined,
    totalProps: getTotalProps('Ad clicks', adClicks.total),
    disclosureProps: isPerformanceRatingFeatureEnabled
      ? getDisclosureProps(
          'Ad clicks',
          getComparisonText(adClicks, 'Ad clicks'),
        )
      : undefined,
    mobileText: t('Ad clicks were from mobile', {
      mobileAdClicksRate: formatPercentage(adClicks.mobileRate * 100, 0),
    }),
    conversionRate: getConversionRate('Ad clicks', adClicks),
    conversionRateTitle: getConversionRateTitle('Ad clicks'),
  };

  const conversionApplicationStartedProps = {
    header: t('Applications started'),
    headerTooltip: t('Applications started tooltip'),
    mainComparisionText:
      isPerformanceRatingFeatureEnabled && !overNoRatingThreshold
        ? getMainComparisonText(applicationsStarted, 'Applications started')
        : undefined,
    comparisonRateText: isPerformanceRatingFeatureEnabled
      ? getComparisonRateText(applicationsStarted, 'Applications started')
      : undefined,
    totalProps: getTotalProps(
      'Applications started',
      applicationsStarted.total,
    ),
    disclosureProps: isPerformanceRatingFeatureEnabled
      ? getDisclosureProps(
          'Applications started',
          getComparisonText(applicationsStarted, 'Applications started'),
        )
      : undefined,
    mobileText: t('Applications started were from mobile', {
      mobileAppStartedRate: formatPercentage(
        applicationsStarted.mobileRate * 100,
        0,
      ),
    }),
    conversionRate: getConversionRate(
      'Applications started',
      applicationsStarted,
    ),
    conversionRateTitle: getConversionRateTitle('Applications started'),
  };

  const conversionApplicationCompletedProps = {
    header: t('Completed applications'),
    headerTooltip: t('Completed applications tooltip'),
    mainComparisionText:
      isPerformanceRatingFeatureEnabled && !overNoRatingThreshold
        ? getMainComparisonText(applicationsCompleted, 'Completed applications')
        : undefined,
    comparisonRateText: isPerformanceRatingFeatureEnabled
      ? getComparisonRateText(applicationsCompleted, 'Completed applications')
      : undefined,
    totalProps: getTotalProps(
      'Completed applications',
      applicationsCompleted.total,
    ),
    disclosureProps: isPerformanceRatingFeatureEnabled
      ? getDisclosureProps(
          'Completed applications',
          getComparisonText(applicationsCompleted, 'Completed applications'),
        )
      : undefined,
    mobileText: t('Completed applications were from mobile', {
      mobileAppCompletedRate: formatPercentage(
        applicationsCompleted.mobileRate * 100,
        0,
      ),
    }),
    conversionRateTitle: getConversionRateTitle('Completed applications'),
    conversionRate: getConversionRate(
      'Completed applications',
      applicationsCompleted,
    ),
  };

  const NoMarketComparisonNotice = () => (
    <Box marginTop="large" data-testid="alert-infobox-id">
      <Notice tone="info">
        <Text>{t('No market comparison', conversionTranslationOptions)}</Text>
      </Notice>
    </Box>
  );

  const view = isCompactView ? 'compact' : 'default';
  const funnelIcon = isCompactView ? (
    <Box className={styles.iconStyleOnMobile}>
      <IconChevron direction="down" />
    </Box>
  ) : (
    <Box className={styles.iconStyle}>
      <IconChevron size="fill" direction="right" />
    </Box>
  );
  const contents = (
    <>
      <ConversionCard view={view} {...conversionViewInJobSearchProps} />
      {funnelIcon}
      <ConversionCard view={view} {...conversionAdClicksProps} />
      {funnelIcon}
      <ConversionCard view={view} {...conversionApplicationStartedProps} />
      {funnelIcon}
      <ConversionCard view={view} {...conversionApplicationCompletedProps} />
    </>
  );

  return (
    <>
      <Box marginTop="small" display="flex">
        {isCompactView ? (
          <Stack space="small" align="center">
            {contents}
          </Stack>
        ) : (
          contents
        )}
      </Box>
      {isPerformanceRatingFeatureEnabled && overNoRatingThreshold ? (
        <NoMarketComparisonNotice />
      ) : null}
    </>
  );
};
