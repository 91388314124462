import { CalendarField, type CalendarFieldOutput } from '@seek/date-picker';
import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import { useContext } from 'react';

import { useConfig } from '../../../App/ConfigContext';
import { IsFilterContext } from '../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../context/adUsageFilters';
import {
  toFormatAUDate,
  trackEvent,
} from '../../../utils/tealiumAUREventTracker';

import translations from './.vocab';

interface PostingDateFilterProps {
  lastDate: Date;
  searchResultsTotal: number | undefined;
  firstDate: Date;
}

export const PostingDateFilter = ({
  searchResultsTotal,
  firstDate,
  lastDate,
}: PostingDateFilterProps) => {
  const {
    adUsageFilters: { postingDates },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();

  const { locale } = useConfig();
  const { t } = useTranslations(translations);
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const updatePostingDates = (values: CalendarFieldOutput) => {
    const { startDate, endDate, datePreset } = values;
    trackEvent('filter_pressed', {
      filterDateRange: datePreset
        ? datePreset?.replace('Last ', '')
        : `${toFormatAUDate(startDate)} to ${toFormatAUDate(endDate)}`,
      filterStartDate: toFormatAUDate(startDate),
      filterEndDate: toFormatAUDate(endDate),
      filterName: 'Date Filter',
      searchResultsTotal,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'date',
      filterMethod: 'neither',
    });
    updateAdUsageFilters({
      type: actionTypes.UPDATE_POSTING_DATES,
      value: values,
    });
  };

  return (
    <Stack space="xsmall">
      <Text weight="strong" size="small">
        {t('Posting date')}
      </Text>
      <CalendarField
        dateValues={postingDates}
        maxDate={lastDate}
        minDate={firstDate}
        updateDateValues={updatePostingDates}
        locale={locale}
      />
    </Stack>
  );
};
