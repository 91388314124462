import { useTranslations } from '@vocab/react';
import { Box, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import translations from './.vocab';

export interface JobAdListErrorBoundaryProps {
  children: ReactNode;
}

export const JobAdListErrorBoundary = ({
  children,
}: JobAdListErrorBoundaryProps) => {
  const { t } = useTranslations(translations);

  return (
    <ErrorBoundary
      fallback={
        <Box background="surface" padding="large">
          <Stack space="large">
            <Text>{t('Jobs list')}</Text>
            <WidgetError />
          </Stack>
        </Box>
      }
    >
      {children}
    </ErrorBoundary>
  );
};
