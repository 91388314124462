
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g+C4iuC5ieC4l+C4teC5iOC5hOC4oeC5iOC4o+C4sOC4muC4uOC4leC4seC4p+C4leC4mSIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiLguKPguLDguJrguJrguIjguLDguYHguKrguJTguIfguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJnguYDguKPguYfguKcg4LmGIOC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g+C4iuC5ieC4l+C4teC5iOC5hOC4oeC5iOC4o+C4sOC4muC4uOC4leC4seC4p+C4leC4mSIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiLguKPguLDguJrguJrguIjguLDguYHguKrguJTguIfguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJnguYDguKPguYfguKcg4LmGIOC4meC4teC5iSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiTWVsaWhhdCBzZW11YSBsb3dvbmdhbiBrZXJqYSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJQZW5nZ3VuYSB0aWRhayBzcGVzaWZpayIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiJMb2thc2kgYWthbiBzZWdlcmEgdGVyc2VkaWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiTWVsaWhhdCBzZW11YSBsb3dvbmdhbiBrZXJqYSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJQZW5nZ3VuYSB0aWRhayBzcGVzaWZpayIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiJMb2thc2kgYWthbiBzZWdlcmEgdGVyc2VkaWEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g+C4iuC5ieC4l+C4teC5iOC5hOC4oeC5iOC4o+C4sOC4muC4uOC4leC4seC4p+C4leC4mSIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiLguKPguLDguJrguJrguIjguLDguYHguKrguJTguIfguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJnguYDguKPguYfguKcg4LmGIOC4meC4teC5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoi4LiU4Li54Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiVW5zcGVjaWZpZWQgdXNlciI6IuC4nOC4ueC5ieC5g+C4iuC5ieC4l+C4teC5iOC5hOC4oeC5iOC4o+C4sOC4muC4uOC4leC4seC4p+C4leC4mSIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiLguKPguLDguJrguJrguIjguLDguYHguKrguJTguIfguKrguJbguLLguJnguJfguLXguYjguJfguLPguIfguLLguJnguYDguKPguYfguKcg4LmGIOC4meC4teC5iSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiTWVsaWhhdCBzZW11YSBsb3dvbmdhbiBrZXJqYSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJQZW5nZ3VuYSB0aWRhayBzcGVzaWZpayIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiJMb2thc2kgYWthbiBzZWdlcmEgdGVyc2VkaWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiTWVsaWhhdCBzZW11YSBsb3dvbmdhbiBrZXJqYSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJQZW5nZ3VuYSB0aWRhayBzcGVzaWZpayIsIkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24iOiJMb2thc2kgYWthbiBzZWdlcmEgdGVyc2VkaWEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiVmlldyBhbGwgam9iIGFkcyIsIlVuc3BlY2lmaWVkIHVzZXIiOiJVbnNwZWNpZmllZCB1c2VyIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IkxvY2F0aW9uIHdpbGwgYmUgYXZhaWxhYmxlIHNvb24ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxpogxLXDtsO2w7bDnyDEg8SDxIPGjMWhXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IlvFgcO2w7bDp8SDxIPhua/DrMOsw7bDtuC4geC4teC5iSDFtcOsw6zGmsaaIMOf4bq94bq9IMSDxIPhub3Eg8SDw6zDrMaaxIPEg8Ofxprhur3hur0gxaHDtsO2w7bDtuC4geC4teC5iV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IGFsbCBqb2IgYWRzIjoiW+G5vMOsw6zDrOG6veG6veG6vcW1IMSDxIPEg8aaxpogxLXDtsO2w7bDnyDEg8SDxIPGjMWhXSIsIlVuc3BlY2lmaWVkIHVzZXIiOiJbx5nguIHguLXguYnFocal4bq94bq94bq9w6fDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMeax5rHmsWh4bq94bq94bq9xZldIiwiTG9jYXRpb24gd2lsbCBiZSBhdmFpbGFibGUgc29vbiI6IlvFgcO2w7bDp8SDxIPhua/DrMOsw7bDtuC4geC4teC5iSDFtcOsw6zGmsaaIMOf4bq94bq9IMSDxIPhub3Eg8SDw6zDrMaaxIPEg8Ofxprhur3hur0gxaHDtsO2w7bDtuC4geC4teC5iV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    