import { useTranslations } from '@vocab/react';
import { Loader, Box } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { GenericTitleContainer } from '../../components/GenericTitleContainer/GenericTitleContainer';
import { MyJobInformation as MyJobInformationComponent } from '../../components/MyJobInformation/MyJobInformation';
import { PrimarySection } from '../../components/PrimarySection/PrimarySection';
import { useMyJobInformationData } from '../../hooks/useMyJobInformationData';

import translations from './.vocab';
import { MyJobInformationFallback } from './MyJobInformationFallback';

export const MyJobInformation = () => {
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();

  const {
    data: details,
    error,
    isLoading,
  } = useMyJobInformationData({
    jobId: jobIdParam!,
  });

  const { t } = useTranslations(translations);

  if (isLoading && !details) {
    return (
      <PrimarySection>
        <GenericTitleContainer title={t('Job information')}>
          <Box
            display="flex"
            alignItems="center"
            height="full"
            justifyContent="center"
            padding="medium"
          >
            <Loader />
          </Box>
        </GenericTitleContainer>
      </PrimarySection>
    );
  }

  if (error || !details) {
    return <MyJobInformationFallback />;
  }

  return <MyJobInformationComponent details={details} />;
};
