import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

export function useStateWithLocalStorage(
  localStorageKey: string,
): [string, Dispatch<SetStateAction<string>>] {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || '',
  );

  useEffect(() => {
    if (value) {
      localStorage.setItem(localStorageKey, value);
    } else {
      localStorage.removeItem(localStorageKey);
    }
  }, [localStorageKey, value]);

  return [value, setValue];
}
