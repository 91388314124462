const textCapitalize = (str: string | null) => {
  if (!str) {
    return null;
  }

  if (str.length > 1) {
    const strLowerCase = str.toLocaleLowerCase();
    if (str.indexOf('_') !== -1) {
      const deUnderscore = strLowerCase.replace('_', ' ');
      return deUnderscore.charAt(0).toUpperCase() + deUnderscore.slice(1);
    }
    return strLowerCase.charAt(0).toUpperCase() + strLowerCase.slice(1);
  }
  return str;
};

const displayDefaultIfUnspecified = (
  str: string | null,
  defaultText: string,
) => {
  if (!str) {
    return defaultText;
  }
  return str;
};

export { textCapitalize, displayDefaultIfUnspecified };
