
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IuC4iuC4t+C5iOC4reC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlNlYXJjaCBqb2IgdGl0bGVzIjoi4LiE4LmJ4LiZ4Lir4Liy4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiSm9iIHRpdGxlIjoi4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IuC4iuC4t+C5iOC4reC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlNlYXJjaCBqb2IgdGl0bGVzIjoi4LiE4LmJ4LiZ4Lir4Liy4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiSm9iIHRpdGxlIjoi4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlNlbXVhIGphYmF0YW4iLCJTZWFyY2ggam9iIHRpdGxlcyI6IkNhcmkgamFiYXRhbiIsIkpvYiB0aXRsZSI6IkphYmF0YW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlNlbXVhIGphYmF0YW4iLCJTZWFyY2ggam9iIHRpdGxlcyI6IkNhcmkgamFiYXRhbiIsIkpvYiB0aXRsZSI6IkphYmF0YW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IuC4iuC4t+C5iOC4reC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlNlYXJjaCBqb2IgdGl0bGVzIjoi4LiE4LmJ4LiZ4Lir4Liy4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiSm9iIHRpdGxlIjoi4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IuC4iuC4t+C5iOC4reC4leC4s+C5geC4q+C4meC5iOC4h+C4h+C4suC4meC4l+C4seC5ieC4h+C4q+C4oeC4lCIsIlNlYXJjaCBqb2IgdGl0bGVzIjoi4LiE4LmJ4LiZ4Lir4Liy4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiSm9iIHRpdGxlIjoi4LiK4Li34LmI4Lit4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZIiwiT3RoZXJzIjoi4Lit4Li34LmI4LiZIOC5hiIsIk90aGVyIjoi4Lit4Li34LmI4LiZIOC5hiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlNlbXVhIGphYmF0YW4iLCJTZWFyY2ggam9iIHRpdGxlcyI6IkNhcmkgamFiYXRhbiIsIkpvYiB0aXRsZSI6IkphYmF0YW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlNlbXVhIGphYmF0YW4iLCJTZWFyY2ggam9iIHRpdGxlcyI6IkNhcmkgamFiYXRhbiIsIkpvYiB0aXRsZSI6IkphYmF0YW4iLCJPdGhlcnMiOiJMYWlubnlhIiwiT3RoZXIiOiJMYWlubnlhIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IkFsbCBqb2IgdGl0bGVzIiwiU2VhcmNoIGpvYiB0aXRsZXMiOiJTZWFyY2ggam9iIHRpdGxlcyIsIkpvYiB0aXRsZSI6IkpvYiB0aXRsZSIsIk90aGVycyI6Im90aGVycyIsIk90aGVyIjoib3RoZXIifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlvhuqzGmsaaIMS1w7bDtsO2w58g4bmvw6zDrMOs4bmvxprhur3hur3hur3FoV0iLCJTZWFyY2ggam9iIHRpdGxlcyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxLXDtsO2w7bDnyDhua/DrMOsw6zhua/GmuG6veG6veG6vcWhXSIsIkpvYiB0aXRsZSI6IlvEtMO2w7bDtsOfIOG5r8Osw6zDrOG5r8aa4bq94bq94bq9XSIsIk90aGVycyI6IlvDtsO2w7bhua/huKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua/huKnhur3hur3FmV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgam9iIHRpdGxlcyI6IlvhuqzGmsaaIMS1w7bDtsO2w58g4bmvw6zDrMOs4bmvxprhur3hur3hur3FoV0iLCJTZWFyY2ggam9iIHRpdGxlcyI6IlvFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkgxLXDtsO2w7bDnyDhua/DrMOsw6zhua/GmuG6veG6veG6vcWhXSIsIkpvYiB0aXRsZSI6IlvEtMO2w7bDtsOfIOG5r8Osw6zDrOG5r8aa4bq94bq94bq9XSIsIk90aGVycyI6IlvDtsO2w7bhua/huKnhur3hur3hur3FmcWhXSIsIk90aGVyIjoiW8O2w7bhua/huKnhur3hur3FmV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    