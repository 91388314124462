import { gql } from '@apollo/client';

export const mostAdsByPerformanceQuery = gql`
  query GetMostAdsByPerformanceData($input: MostAdsByPerformanceWidgetInput!) {
    analyticsMostAdsByPerformance(input: $input) {
      low {
        user {
          label
          number
          percentage
        }
        classification {
          label
          number
          percentage
        }
      }
      high {
        user {
          label
          number
          percentage
        }
        classification {
          label
          number
          percentage
        }
      }
    }
  }
`;
