import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconClear,
  IconRecommended,
  IconTick,
  Stack,
  Text,
} from 'braid-design-system';
import type { ReactNode } from 'react';

import brandRec from '../../static/images/brandRec.png';
import type { PostTypeCategory } from '../../types/AdPerformanceTypes';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';

import translations from './.vocab';

export interface BrandedAdRecommendationProps {
  bulletPointsUsed: boolean;
  logoUsed: boolean;
  coverImageUsed: boolean;
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  title?: string;
  subTitle?: string;
  coverTitle?: string;
  coverSubTitle?: string | ReactNode;
  editAdUrl: string;
  direction?: 'row' | 'column';
  showSuggestedAction?: boolean;
  footerNotice?: ReactNodeNoStrings;
  trackInteractionObject?: string;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  onTipClicked?: () => void;
}

export const BrandedAdRecommendation = ({
  bulletPointsUsed,
  logoUsed,
  coverImageUsed,
  isExpired,
  postTypeCategory,
  editAdUrl,
  title,
  subTitle,
  coverTitle,
  coverSubTitle,
  direction,
  showSuggestedAction = true,
  footerNotice = null,
  trackInteractionObject,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  onTipClicked,
}: BrandedAdRecommendationProps) => {
  const { t } = useTranslations(translations);

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      if (onTipClicked) {
        onTipClicked();
      } else {
        trackEvent('view_tip_pressed', {
          tipName: 'branded ad',
          objectInteraction:
            trackInteractionObject || 'apr-recommendations-branded-ad-section',
        });
      }
    }
  };

  const featureUnusedElement = (text: string) => (
    <Box>
      <Columns space="xsmall" alignY="center">
        <Column width="content">
          <IconClear size="small" tone="critical" />
        </Column>
        <Column>
          <Text>{text}</Text>
        </Column>
      </Columns>
    </Box>
  );

  const featureUsedElement = (text: string) => (
    <Box>
      <Columns space="xsmall" alignY="center">
        <Column width="content">
          <IconTick size="small" tone="positive" />
        </Column>
        <Column>
          <Text>{text}</Text>
        </Column>
      </Columns>
    </Box>
  );

  const featuresUsedSection =
    !bulletPointsUsed && !logoUsed && !coverImageUsed ? null : (
      <Stack space="medium">
        <Text weight="strong">{t("Features you're using:")}</Text>
        {coverImageUsed && featureUsedElement(t('Company cover image'))}
        {logoUsed &&
          featureUsedElement(t('Your company logo to show your brand'))}
        {bulletPointsUsed && featureUsedElement(t('Three key selling points'))}
      </Stack>
    );

  return (
    <RecommendationSkeleton
      titleContent={title || t('Make the most of the ad you paid for')}
      subtitleContent={
        subTitle ||
        t(
          "You've purchased an ad with branding features, but haven't used all of them.",
        )
      }
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconRecommended tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-branded-ad-recommendation"
      coverTitle={coverTitle}
      coverSubTitle={coverSubTitle}
      direction={direction}
    >
      <Stack space="large">
        <Columns space="large" collapseBelow="tablet">
          <Column>
            <Stack space="large">
              <Columns space="xlarge" collapseBelow="tablet">
                <Column>
                  <Stack space="large">
                    <Stack space="medium">
                      <Text weight="strong">
                        {t("Features you've purchased but haven't used:")}
                      </Text>
                      {!coverImageUsed &&
                        featureUnusedElement(t('Company cover image'))}
                      {!logoUsed &&
                        featureUnusedElement(
                          t('Your company logo to show your brand'),
                        )}
                      {!bulletPointsUsed &&
                        featureUnusedElement(t('Three key selling points'))}
                    </Stack>
                    <Stack space="small">{featuresUsedSection}</Stack>
                  </Stack>
                </Column>
                <Column width="content">
                  <img src={brandRec} />
                </Column>
              </Columns>
              {showSuggestedAction && (
                <Text>
                  {t(
                    "Click the 'Edit ad' button to add these features to your job ad and showcase your brand ad.",
                  )}
                </Text>
              )}
              {footerNotice}
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </RecommendationSkeleton>
  );
};
