import { useTranslations } from '@vocab/react';
import { Box, Pagination as BraidPagination } from 'braid-design-system';
import type React from 'react';

import translations from './.vocab';

export interface PaginationProps {
  currentPage: number;
  pageLimit?: number;
  totalPages: number;
  onPageClick: ({ page }: { page: number }) => void;
}

export const Pagination = ({
  currentPage,
  pageLimit = 7,
  totalPages,
  onPageClick,
}: PaginationProps) => {
  const { t } = useTranslations(translations);
  const linkProps = ({ page }: { page: number }) => ({
    href: '#',
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onPageClick({
        page,
      });
    },
    role: 'list',
  });

  return (
    <Box data-testid="job-ad-list-pagination">
      <BraidPagination
        page={currentPage}
        total={totalPages}
        label="JobAdList Pagination"
        pageLimit={pageLimit}
        linkProps={linkProps}
        nextLabel={t('Next')}
        previousLabel={t('Previous')}
      />
    </Box>
  );
};
