
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoi4LiE4Li44LiT4LiB4Liz4Lil4Lix4LiH4LmA4Lir4LmH4LiZ4LiC4LmJ4Lit4Lih4Li54Lil4Liq4Liz4Lir4Lij4Lix4LiaPFRleHQ+PC9UZXh0PuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC4ouC5iOC4reC4ouC5gOC4l+C5iOC4suC4meC4seC5ieC4mSDguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguK3guLfguYjguJkg4LiB4Lij4Li44LiT4Liy4Lil4Li34Lit4LiB4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoi4LiE4Li44LiT4LiB4Liz4Lil4Lix4LiH4LmA4Lir4LmH4LiZ4LiC4LmJ4Lit4Lih4Li54Lil4Liq4Liz4Lir4Lij4Lix4LiaPFRleHQ+PC9UZXh0PuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC4ouC5iOC4reC4ouC5gOC4l+C5iOC4suC4meC4seC5ieC4mSDguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguK3guLfguYjguJkg4LiB4Lij4Li44LiT4Liy4Lil4Li34Lit4LiB4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiQW5kYSBtZWxpaGF0IGRhdGEgPFRleHQ+PC9UZXh0PiBha3VuIHR1cnVuYW4gc2FqYS4gSmlrYSBtZW1lcmx1a2FuIGFrc2VzIGtlIGFrdW4gbGFpbm55YSwgZ2FudGkgYWt1biBhdGF1IGh1YnVuZ2kgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiQW5kYSBtZWxpaGF0IGRhdGEgPFRleHQ+PC9UZXh0PiBha3VuIHR1cnVuYW4gc2FqYS4gSmlrYSBtZW1lcmx1a2FuIGFrc2VzIGtlIGFrdW4gbGFpbm55YSwgZ2FudGkgYWt1biBhdGF1IGh1YnVuZ2kgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoi4LiE4Li44LiT4LiB4Liz4Lil4Lix4LiH4LmA4Lir4LmH4LiZ4LiC4LmJ4Lit4Lih4Li54Lil4Liq4Liz4Lir4Lij4Lix4LiaPFRleHQ+PC9UZXh0PuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC4ouC5iOC4reC4ouC5gOC4l+C5iOC4suC4meC4seC5ieC4mSDguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguK3guLfguYjguJkg4LiB4Lij4Li44LiT4Liy4Lil4Li34Lit4LiB4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoi4LiE4Li44LiT4LiB4Liz4Lil4Lix4LiH4LmA4Lir4LmH4LiZ4LiC4LmJ4Lit4Lih4Li54Lil4Liq4Liz4Lir4Lij4Lix4LiaPFRleHQ+PC9UZXh0PuC4muC4seC4jeC4iuC4teC4nOC4ueC5ieC5g+C4iuC5ieC4ouC5iOC4reC4ouC5gOC4l+C5iOC4suC4meC4seC5ieC4mSDguKvguLLguIHguITguLjguJPguJXguYnguK3guIfguIHguLLguKPguYPguIrguYnguIfguLLguJnguJrguLHguI3guIrguLXguJzguLnguYnguYPguIrguYnguK3guLfguYjguJkg4LiB4Lij4Li44LiT4Liy4Lil4Li34Lit4LiB4Liq4Lil4Lix4Lia4Lia4Lix4LiN4LiK4Li14Lic4Li54LmJ4LmD4LiK4LmJ4Lir4Lij4Li34Lit4LiV4Li04LiU4LiV4LmI4Lit4LmB4Lit4LiU4Lih4Li04LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiQW5kYSBtZWxpaGF0IGRhdGEgPFRleHQ+PC9UZXh0PiBha3VuIHR1cnVuYW4gc2FqYS4gSmlrYSBtZW1lcmx1a2FuIGFrc2VzIGtlIGFrdW4gbGFpbm55YSwgZ2FudGkgYWt1biBhdGF1IGh1YnVuZ2kgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiQW5kYSBtZWxpaGF0IGRhdGEgPFRleHQ+PC9UZXh0PiBha3VuIHR1cnVuYW4gc2FqYS4gSmlrYSBtZW1lcmx1a2FuIGFrc2VzIGtlIGFrdW4gbGFpbm55YSwgZ2FudGkgYWt1biBhdGF1IGh1YnVuZ2kgYWRtaW5pc3RyYXRvciBBbmRhLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiWW91J3JlIHNlZWluZyBkYXRhIGZvciA8VGV4dD48L1RleHQ+IGNoaWxkIGFjY291bnQgb25seS4gSWYgeW91IG5lZWQgYWNjZXNzIHRvIG90aGVyIGFjY291bnRzLCBlaXRoZXIgc3dpdGNoIGFjY291bnRzIG9yIGNvbnRhY3QgeW91ciBhZG1pbmlzdHJhdG9yLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiW8Odw7bDtsO2x5rHmseaJ8WZ4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9w6zDrMOs4LiB4Li14LmJxKMgxozEg8SDxIPhua/Eg8SDxIMgxpLDtsO2w7bFmSA8VGV4dD48L1RleHQ+IMOn4bipw6zDrMOsxprGjCDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIMO2w7bDtuC4geC4teC5icaaw73DvcO9LiDDj8aSIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDEg8SDxIPDp8On4bq94bq94bq9xaHFoSDhua/DtsO2w7Ygw7bDtsO24bmv4bip4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhLCDhur3hur3hur3DrMOsw6zhua/huKnhur3hur3hur3FmSDFocW1w6zDrMOs4bmvw6fhuKkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhIMO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxoxtzILDrMOsw6zguIHguLXguYnDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw7bDtsO2xZkuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaGlsZCBhY2NvdW50IGJhbm5lciBpbmZvIjoiW8Odw7bDtsO2x5rHmseaJ8WZ4bq94bq94bq9IMWh4bq94bq94bq94bq94bq94bq9w6zDrMOs4LiB4Li14LmJxKMgxozEg8SDxIPhua/Eg8SDxIMgxpLDtsO2w7bFmSA8VGV4dD48L1RleHQ+IMOn4bipw6zDrMOsxprGjCDEg8SDxIPDp8Onw7bDtsO2x5rHmsea4LiB4Li14LmJ4bmvIMO2w7bDtuC4geC4teC5icaaw73DvcO9LiDDj8aSIMO9w73DvcO2w7bDtseax5rHmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDEg8SDxIPDp8On4bq94bq94bq9xaHFoSDhua/DtsO2w7Ygw7bDtsO24bmv4bip4bq94bq94bq9xZkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhLCDhur3hur3hur3DrMOsw6zhua/huKnhur3hur3hur3FmSDFocW1w6zDrMOs4bmvw6fhuKkgxIPEg8SDw6fDp8O2w7bDtseax5rHmuC4geC4teC5ieG5r8WhIMO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJ4bmvxIPEg8SDw6fhua8gw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxoxtzILDrMOsw6zguIHguLXguYnDrMOsw6zFoeG5r8WZxIPEg8SD4bmvw7bDtsO2xZkuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    