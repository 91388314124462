import { Box } from 'braid-design-system';
import type React from 'react';

export interface ZStackItemProps {
  align?: 'left' | 'right' | 'center';
  alignY?: 'bottom' | 'middle' | 'top';
  isRelativeLayer?: boolean;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  pointerEvents?: 'none';
  children?: ReactNodeNoStrings;
}

export const ZStackItem = ({
  align = 'center',
  alignY = 'middle',
  children,
  isRelativeLayer = false,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  pointerEvents,
}: ZStackItemProps) => (
  <Box
    position={isRelativeLayer ? 'relative' : 'absolute'}
    top={alignY === 'top' || alignY === 'middle' ? 0 : undefined}
    bottom={alignY === 'bottom' || alignY === 'middle' ? 0 : undefined}
    left={align === 'left' || align === 'center' ? 0 : undefined}
    right={align === 'right' || align === 'center' ? 0 : undefined}
    style={
      isRelativeLayer
        ? {
            paddingTop: marginTop,
            paddingLeft: marginLeft,
            paddingRight: marginRight,
            paddingBottom: marginBottom,
            pointerEvents,
          }
        : {
            marginTop,
            marginLeft,
            marginRight,
            marginBottom,
            pointerEvents,
          }
    }
  >
    {children}
  </Box>
);
