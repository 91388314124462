
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiLguKPguLLguKLguKXguLDguYDguK3guLXguKLguJTguJXguLLguKHguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJEYWlseSI6IuC4o+C4suC4ouC4p+C4seC4mSIsIk1vbnRobHkiOiLguKPguLLguKLguYDguJTguLfguK3guJkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6IuC4reC4t+C5iOC4meC5hiIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiHIHtzdGFydERhdGV9IOC4luC4tuC4hyB7ZW5kRGF0ZX0iLCJObyBhZHMgcG9zdGVkIjoi4LmE4Lih4LmI4Lih4Li14LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmMIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiLguKPguLLguKLguKXguLDguYDguK3guLXguKLguJTguJXguLLguKHguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJEYWlseSI6IuC4o+C4suC4ouC4p+C4seC4mSIsIk1vbnRobHkiOiLguKPguLLguKLguYDguJTguLfguK3guJkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6IuC4reC4t+C5iOC4meC5hiIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiHIHtzdGFydERhdGV9IOC4luC4tuC4hyB7ZW5kRGF0ZX0iLCJObyBhZHMgcG9zdGVkIjoi4LmE4Lih4LmI4Lih4Li14LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmMIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJQZXJpbmNpYW4gYmVyZGFzYXJrYW4gamVuaXMgaWtsYW4gc2VpcmluZyB3YWt0dSIsIkRhaWx5IjoiUGVyIGhhcmkiLCJNb250aGx5IjoiUGVyIGJ1bGFuIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJTVEFORE9VVCI6IlN0YW5kT3V0IiwiUFJFTUlVTSI6IlByZW1pdW0iLCJCQVNJQyI6IkJhc2ljIiwiQlJBTkRFRCI6IkJyYW5kZWQiLCJPVEhFUlMiOiJMYWlubnlhIiwiU2hvd2luZyBhZHMgcG9zdGVkIGRhdGUiOiJUYW1waWxrYW4gcG9zdGluZ2FuIGlrbGFuIGFudGFyYSB7c3RhcnREYXRlfSBzYW1wYWkge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6IlRpZGFrIGFkYSBpa2xhbiB5YW5nIGRpcG9zdGluZy4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJQZXJpbmNpYW4gYmVyZGFzYXJrYW4gamVuaXMgaWtsYW4gc2VpcmluZyB3YWt0dSIsIkRhaWx5IjoiUGVyIGhhcmkiLCJNb250aGx5IjoiUGVyIGJ1bGFuIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJTVEFORE9VVCI6IlN0YW5kT3V0IiwiUFJFTUlVTSI6IlByZW1pdW0iLCJCQVNJQyI6IkJhc2ljIiwiQlJBTkRFRCI6IkJyYW5kZWQiLCJPVEhFUlMiOiJMYWlubnlhIiwiU2hvd2luZyBhZHMgcG9zdGVkIGRhdGUiOiJUYW1waWxrYW4gcG9zdGluZ2FuIGlrbGFuIGFudGFyYSB7c3RhcnREYXRlfSBzYW1wYWkge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6IlRpZGFrIGFkYSBpa2xhbiB5YW5nIGRpcG9zdGluZy4ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiLguKPguLLguKLguKXguLDguYDguK3guLXguKLguJTguJXguLLguKHguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJEYWlseSI6IuC4o+C4suC4ouC4p+C4seC4mSIsIk1vbnRobHkiOiLguKPguLLguKLguYDguJTguLfguK3guJkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6IuC4reC4t+C5iOC4meC5hiIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiHIHtzdGFydERhdGV9IOC4luC4tuC4hyB7ZW5kRGF0ZX0iLCJObyBhZHMgcG9zdGVkIjoi4LmE4Lih4LmI4Lih4Li14LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmMIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiLguKPguLLguKLguKXguLDguYDguK3guLXguKLguJTguJXguLLguKHguJvguKPguLDguYDguKDguJfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJEYWlseSI6IuC4o+C4suC4ouC4p+C4seC4mSIsIk1vbnRobHkiOiLguKPguLLguKLguYDguJTguLfguK3guJkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6IuC4reC4t+C5iOC4meC5hiIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoi4LiB4Liz4Lil4Lix4LiH4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4Lij4Liw4Lir4Lin4LmI4Liy4LiHIHtzdGFydERhdGV9IOC4luC4tuC4hyB7ZW5kRGF0ZX0iLCJObyBhZHMgcG9zdGVkIjoi4LmE4Lih4LmI4Lih4Li14LmC4LiG4Lip4LiT4Liy4LiX4Li14LmI4LmC4Lie4Liq4LiV4LmMIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJQZXJpbmNpYW4gYmVyZGFzYXJrYW4gamVuaXMgaWtsYW4gc2VpcmluZyB3YWt0dSIsIkRhaWx5IjoiUGVyIGhhcmkiLCJNb250aGx5IjoiUGVyIGJ1bGFuIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJTVEFORE9VVCI6IlN0YW5kT3V0IiwiUFJFTUlVTSI6IlByZW1pdW0iLCJCQVNJQyI6IkJhc2ljIiwiQlJBTkRFRCI6IkJyYW5kZWQiLCJPVEhFUlMiOiJMYWlubnlhIiwiU2hvd2luZyBhZHMgcG9zdGVkIGRhdGUiOiJUYW1waWxrYW4gcG9zdGluZ2FuIGlrbGFuIGFudGFyYSB7c3RhcnREYXRlfSBzYW1wYWkge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6IlRpZGFrIGFkYSBpa2xhbiB5YW5nIGRpcG9zdGluZy4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJQZXJpbmNpYW4gYmVyZGFzYXJrYW4gamVuaXMgaWtsYW4gc2VpcmluZyB3YWt0dSIsIkRhaWx5IjoiUGVyIGhhcmkiLCJNb250aGx5IjoiUGVyIGJ1bGFuIiwiQ0xBU1NJQyI6IkNsYXNzaWMiLCJTVEFORE9VVCI6IlN0YW5kT3V0IiwiUFJFTUlVTSI6IlByZW1pdW0iLCJCQVNJQyI6IkJhc2ljIiwiQlJBTkRFRCI6IkJyYW5kZWQiLCJPVEhFUlMiOiJMYWlubnlhIiwiU2hvd2luZyBhZHMgcG9zdGVkIGRhdGUiOiJUYW1waWxrYW4gcG9zdGluZ2FuIGlrbGFuIGFudGFyYSB7c3RhcnREYXRlfSBzYW1wYWkge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6IlRpZGFrIGFkYSBpa2xhbiB5YW5nIGRpcG9zdGluZy4ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiLCJEYWlseSI6IkRhaWx5IiwiTW9udGhseSI6Ik1vbnRobHkiLCJDTEFTU0lDIjoiQ2xhc3NpYyIsIlNUQU5ET1VUIjoiU3RhbmRPdXQiLCJQUkVNSVVNIjoiUHJlbWl1bSIsIkJBU0lDIjoiQmFzaWMiLCJCUkFOREVEIjoiQnJhbmRlZCIsIk9USEVSUyI6Ik90aGVycyIsIlNob3dpbmcgYWRzIHBvc3RlZCBkYXRlIjoiU2hvd2luZyBhZHMgcG9zdGVkIGJldHdlZW4ge3N0YXJ0RGF0ZX0gdG8ge2VuZERhdGV9IiwiTm8gYWRzIHBvc3RlZCI6Ik5vIGFkcyBwb3N0ZWQuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJb4bqexZnhur3hur3hur3Eg8SDxIPEt8aMw7bDtsO2xbXguIHguLXguYkgw5/DvcO9w70gxIPEg8SDxowg4bmvw73DvcO9xqXhur3hur3hur0gw7bDtsO24bm94bq94bq94bq9xZkg4bmvw6zDrMOsbcyC4bq94bq94bq9XSIsIkRhaWx5IjoiW+G4isSDxIPDrMOsxprDvcO9XSIsIk1vbnRobHkiOiJb4bmCw7bDtsO24LiB4Li14LmJ4bmv4bipxprDvcO9w71dIiwiQ0xBU1NJQyI6IlvDh8aaxIPEg8SDxaHFocOsw6zDrMOnXSIsIlNUQU5ET1VUIjoiW8Wg4bmvxIPEg8SD4LiB4Li14LmJxozDlseax5rHmuG5r10iLCJQUkVNSVVNIjoiW8akxZnhur3hur1tzILDrMOsx5rHmm3Mgl0iLCJCQVNJQyI6Ilvhup7Eg8SDxaHDrMOsw6ddIiwiQlJBTkRFRCI6Ilvhup7FmcSDxIPEg+C4geC4teC5icaM4bq94bq94bq9xoxdIiwiT1RIRVJTIjoiW8OW4bmv4bip4bq94bq94bq94bq9xZnFoV0iLCJTaG93aW5nIGFkcyBwb3N0ZWQgZGF0ZSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgw5/hur3hur3hur3hua/FteG6veG6veG6veG6veG6veG6veC4geC4teC5iSB7c3RhcnREYXRlfSDhua/DtsO2w7Yge2VuZERhdGV9XSIsIk5vIGFkcyBwb3N0ZWQiOiJbw5HDtsO2w7YgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCcmVha2Rvd24gYnkgYWQgdHlwZSBvdmVyIHRpbWUiOiJb4bqexZnhur3hur3hur3Eg8SDxIPEt8aMw7bDtsO2xbXguIHguLXguYkgw5/DvcO9w70gxIPEg8SDxowg4bmvw73DvcO9xqXhur3hur3hur0gw7bDtsO24bm94bq94bq94bq9xZkg4bmvw6zDrMOsbcyC4bq94bq94bq9XSIsIkRhaWx5IjoiW+G4isSDxIPDrMOsxprDvcO9XSIsIk1vbnRobHkiOiJb4bmCw7bDtsO24LiB4Li14LmJ4bmv4bipxprDvcO9w71dIiwiQ0xBU1NJQyI6IlvDh8aaxIPEg8SDxaHFocOsw6zDrMOnXSIsIlNUQU5ET1VUIjoiW8Wg4bmvxIPEg8SD4LiB4Li14LmJxozDlseax5rHmuG5r10iLCJQUkVNSVVNIjoiW8akxZnhur3hur1tzILDrMOsx5rHmm3Mgl0iLCJCQVNJQyI6Ilvhup7Eg8SDxaHDrMOsw6ddIiwiQlJBTkRFRCI6Ilvhup7FmcSDxIPEg+C4geC4teC5icaM4bq94bq94bq9xoxdIiwiT1RIRVJTIjoiW8OW4bmv4bip4bq94bq94bq94bq9xZnFoV0iLCJTaG93aW5nIGFkcyBwb3N0ZWQgZGF0ZSI6IlvFoOG4qcO2w7bDtsW1w6zDrMOs4LiB4Li14LmJxKMgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgw5/hur3hur3hur3hua/FteG6veG6veG6veG6veG6veG6veC4geC4teC5iSB7c3RhcnREYXRlfSDhua/DtsO2w7Yge2VuZERhdGV9XSIsIk5vIGFkcyBwb3N0ZWQiOiJbw5HDtsO2w7YgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    