// This component is the search results message which is exclusive to [Ad usage report]

import type { Locale } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { Inline, Text } from 'braid-design-system';
import { differenceInSeconds } from 'date-fns';

import { formatDateWithUnifiedDisplay } from '../../utils/date';
import { AnimatedNumber } from '../AnimatedText';

import translations from './.vocab';

const ONE_MINUTE_IN_SECONDS = 60;
const ONE_HOUR_IN_SECONDS = 3600;
const ONE_DAY_IN_SECONDS = 86400;
const ONE_MONTH_IN_SECONDS = 2592000;

export interface AurSearchResultsMessageProps {
  count: number;
  lastUpdated: string;
  locale?: Locale;
}

const AurSearchResultsMessage = ({
  count,
  lastUpdated,
  locale,
}: AurSearchResultsMessageProps) => {
  const { t } = useTranslations(translations);

  const searchResultsSummaryTranslationCommonProps = {
    Span: (children: ReactNodeNoStrings) => (
      <Inline space="none" key={children}>
        {children}
      </Inline>
    ),
    StrongText: (children: ReactNodeNoStrings) => (
      <Text weight="strong" key={children} size="large">
        {children}
      </Text>
    ),
    Text: (children: ReactNodeNoStrings) => (
      <Text key={children} size="large">
        {children}
      </Text>
    ),
  };

  const getSummaryContent: ReactNodeNoStrings = () => {
    const distanceToNowInSeconds = differenceInSeconds(
      new Date(),
      new Date(lastUpdated),
    );

    const JobCount = () => <AnimatedNumber value={count} />;

    if (distanceToNowInSeconds < ONE_MINUTE_IN_SECONDS) {
      return t('ad just now', {
        numOfAds: count,
        JobCount,
        ...searchResultsSummaryTranslationCommonProps,
      });
    }
    if (distanceToNowInSeconds < ONE_HOUR_IN_SECONDS) {
      return t('ad minutes ago', {
        numOfAds: count,
        JobCount,
        numOfMins: Math.floor(
          distanceToNowInSeconds / ONE_MINUTE_IN_SECONDS,
        ).toString(),
        ...searchResultsSummaryTranslationCommonProps,
      });
    }

    if (distanceToNowInSeconds < ONE_DAY_IN_SECONDS) {
      return t('ad hours ago', {
        numOfAds: count,
        JobCount,
        numOfHours: Math.floor(
          distanceToNowInSeconds / ONE_HOUR_IN_SECONDS,
        ).toString(),
        ...searchResultsSummaryTranslationCommonProps,
      });
    }
    if (distanceToNowInSeconds < ONE_MONTH_IN_SECONDS) {
      return t('ad days ago', {
        numOfAds: count,
        JobCount,
        numOfDays: Math.floor(
          distanceToNowInSeconds / ONE_DAY_IN_SECONDS,
        ).toString(),
        ...searchResultsSummaryTranslationCommonProps,
      });
    }
    return t('ad specific date', {
      numOfAds: count,
      JobCount,
      date: formatDateWithUnifiedDisplay({
        date: new Date(lastUpdated),
        locale,
      }),
      ...searchResultsSummaryTranslationCommonProps,
    });
  };

  return (
    <Inline space="xxsmall" data={{ testid: 'search-results-message' }}>
      {getSummaryContent()}
    </Inline>
  );
};

export { AurSearchResultsMessage };
