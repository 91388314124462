import { chartUtils } from '@seek/charts';
import { useTranslations } from '@vocab/react';
import { Notice, Text } from 'braid-design-system';
import { useParams } from 'react-router-dom';

import { useAdRatingData } from '../../hooks/useAdRatingData';

import translations from './.vocab';

export const AdRatingNotice = () => {
  const { t } = useTranslations(translations);
  const { jobId } = useParams<{ jobId: string }>();

  const {
    data,
    isLoading: isLoadingAdRating,
    error,
  } = useAdRatingData({
    jobId: jobId!,
  });

  if (isLoadingAdRating && error) {
    return null;
  }

  const displayAdRating =
    data &&
    data.adRatingScore !== null &&
    data.applicationsBenchmarkHigh !== null &&
    data.applicationsBenchmarkLow !== null &&
    data.benchmarkScenario !== null &&
    data.benchmarkScenario !== 'COUNTRY';

  const showMarketPerformance = Boolean(data && data.adRatingScore);
  const daysLive = data
    ? chartUtils.dateFormatter.daysAgoFromString(data.jobCreatedTimestampUTC)
    : 0;
  const showMarketPerformanceUnavailable =
    !showMarketPerformance && daysLive > 4;
  const showAdRatingComingSoon =
    !showMarketPerformance && !showMarketPerformanceUnavailable;

  const message = showAdRatingComingSoon
    ? 'Market performance rating available soon message'
    : 'Market performance rating unavailable message';

  return displayAdRating ? null : (
    <Notice tone="info">
      <Text>
        {showAdRatingComingSoon
          ? null
          : t('Market performance rating unavailable')}{' '}
        {t(message)}
      </Text>
    </Notice>
  );
};
