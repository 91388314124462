import { useTranslations } from '@vocab/react';
import { Box, Inline, Text } from 'braid-design-system';
import type React from 'react';

import { useChartTheme } from '../../hooks/useChartTheme';
import type { CandidateLabel } from '../../types/PerformancePrediction';

import translations from './.vocab';

const SolidIcon = ({ fill }: { fill: string }) => (
  <svg width={8} height={8}>
    <rect width="100%" height="100%" rx="2" fill={fill} />
  </svg>
);

const DottedLinesIcon = ({
  line1Fill,
  line2Fill,
}: {
  line1Fill: string;
  line2Fill: string;
}) => (
  <svg width={16} height={6}>
    <path
      d="M0,0L16,0"
      transform="translate(0,1)"
      stroke={line1Fill}
      strokeDasharray="6 3"
      strokeWidth="2"
    />
    <path
      d="M0,0L16,0"
      transform="translate(0,5)"
      stroke={line2Fill}
      strokeDasharray="6 3"
      strokeWidth="2"
    />
  </svg>
);

const LegendEntry = ({
  icon,
  label,
}: {
  icon: ReactNodeNoStrings;
  label: string;
}) => (
  <Box display="flex" alignItems="center">
    {icon}
    <Box paddingLeft="xxsmall">
      <Text size="xsmall">{label}</Text>
    </Box>
  </Box>
);

export const ApplicationPredictionChartLegend = ({
  candidateLabel,
}: {
  candidateLabel: CandidateLabel;
}) => {
  const theme = useChartTheme();
  const { t } = useTranslations(translations);

  const yourAdColour = theme.tokens.colour.indigo[500];
  const similarAdsColour = theme.tokens.colour.grey[500];

  return (
    <Inline
      space={{
        mobile: 'xsmall',
        tablet: 'medium',
      }}
      collapseBelow="tablet"
    >
      <LegendEntry
        icon={<SolidIcon fill={yourAdColour} />}
        label={t(
          candidateLabel === 'CANDIDATES'
            ? 'Your candidates'
            : 'Your application starts',
        )}
      />
      <LegendEntry
        icon={<SolidIcon fill={similarAdsColour} />}
        label={t('Similar ads')}
      />
      <LegendEntry
        icon={
          <DottedLinesIcon
            line1Fill={yourAdColour}
            line2Fill={similarAdsColour}
          />
        }
        label={t('Prediction')}
      />
    </Inline>
  );
};
