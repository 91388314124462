import { useTranslations } from '@vocab/react';
import {
  Box,
  IconMoney,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
} from 'braid-design-system';
import { useContext } from 'react';

import { useHidden } from '../../App/hooks/useHidden';
import { ConversionIcon } from '../../assets/icons/ConversionIcon/ConversionIcon';
import { PerformanceIcon } from '../../assets/icons/PerformanceIcon/PerformanceIcon';
import { TrendTabIcon } from '../../assets/icons/TrendTabIcon/TrendTabIcon';
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { IsFilterContext } from '../../context/IsFilterContext';
import {
  FEATURE_PERFORMANCE_RATING,
  useFeatureToggleData,
} from '../../featureToggle';
import { useHistoricalDataChecker } from '../../hooks/useHistoricalDataChecker';
import { trackEvent } from '../../utils/tealiumAUREventTracker';
import { Conversion as ConversionTab } from '../../widgets/Conversion/Conversion';
// Temporary comment to hide tips tab for the day 1 of unification release
import { HistoricalConversion as HistoricalConversionTab } from '../../widgets/Conversion/HistoricalConversion';
import { Spend } from '../../widgets/SpendOld/Spend';
import { PerformanceTab } from '../PerformanceTabOld/PerformanceTab';
// import { TipsIcon } from '../../assets/icons/TipsIcon/TipsIcon';
// import { TipsToImproveAds } from '../../widgets/TipsToImproveAds/TipsToImproveAds';
import { TrendsTab, TrendsTabMobile } from '../TrendsTab/TrendsTab';

import translations from './.vocab';

export const AdUsageTabs = () => {
  const { t } = useTranslations(translations);
  const isPerformanceRatingFeatureEnabled = useFeatureToggleData(
    FEATURE_PERFORMANCE_RATING,
    false,
  );
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);
  const { isFilteringHistoricalAurData } = useHistoricalDataChecker();
  const isCompactView = useHidden([true, true, false, false]);

  const trackTabChangeEvent = (eventName: string) => {
    trackEvent(eventName, {
      objectInteraction: eventName,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
    });
  };

  const tabEventTackingWith = (selectedIndex: number) => {
    if (selectedIndex === 0) {
      trackTabChangeEvent('spend_tab_pressed');
    }
    if (selectedIndex === 1) {
      trackTabChangeEvent('ad_trends_tab_pressed');
    }
    if (selectedIndex === 2) {
      trackTabChangeEvent('performance_tab_pressed');
    }
    if (selectedIndex === 3) {
      trackTabChangeEvent('conversion_tab_pressed');
    }
    if (selectedIndex === 4) {
      trackTabChangeEvent('ad_tips_tab_pressed');
    }
  };

  return (
    <Box padding="gutter" background="surface" borderRadius="standard">
      <TabsProvider
        id="ad-usage-tabs"
        onChange={(selectedIndex) => {
          tabEventTackingWith(selectedIndex);
        }}
      >
        <Stack space="medium">
          <Tabs label="Ad usage tabs">
            <Tab icon={<IconMoney />}>{t('Spend')}</Tab>
            <Tab icon={<TrendTabIcon />}>{t('Trends')}</Tab>
            {isPerformanceRatingFeatureEnabled && (
              <Tab icon={<PerformanceIcon />}>{t('Performance')}</Tab>
            )}
            <Tab icon={<ConversionIcon />}>{t('Conversion')}</Tab>
            {/* <Tab icon={<TipsIcon />}>{t('Tips to improve ads')}</Tab> */}
          </Tabs>
          <Box paddingBottom="gutter">
            <TabPanels>
              <TabPanel>
                <ErrorBoundary
                  fallback={
                    <Box paddingY="small">
                      <WidgetError />
                    </Box>
                  }
                >
                  <Spend />
                </ErrorBoundary>
              </TabPanel>
              <TabPanel>
                {isCompactView ? <TrendsTabMobile /> : <TrendsTab />}
              </TabPanel>
              {isPerformanceRatingFeatureEnabled && (
                <TabPanel>
                  <PerformanceTab />
                </TabPanel>
              )}
              <TabPanel>
                <ErrorBoundary
                  fallback={
                    <Box paddingY="small">
                      <WidgetError />
                    </Box>
                  }
                >
                  {isFilteringHistoricalAurData ? (
                    <HistoricalConversionTab />
                  ) : (
                    <ConversionTab />
                  )}
                </ErrorBoundary>
              </TabPanel>
              {/* <TabPanel>
                <ErrorBoundary
                  fallback={
                    <Box paddingY="small">
                      <WidgetError />
                    </Box>
                  }
                >
                  <TipsToImproveAds />
                </ErrorBoundary>
              </TabPanel> */}
            </TabPanels>
          </Box>
        </Stack>
      </TabsProvider>
    </Box>
  );
};
