import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { AdTypeTrendsContainer } from './AdTypeTrends';

export interface AdTypeTrendsErrorBoundaryProps {
  children: ReactNode;
}

export const AdTypeTrendsErrorBoundary = ({
  children,
}: AdTypeTrendsErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <AdTypeTrendsContainer>
        <WidgetError />
      </AdTypeTrendsContainer>
    }
  >
    {children}
  </ErrorBoundary>
);
