import { useTranslations } from '@vocab/react';
import { Inline, Strong, Text } from 'braid-design-system';

import { ClickableTooltip } from '../../components/ClickableTooltip/ClickableTooltip';
import { useChildAccountBannerData } from '../../hooks/useChildAccountBannerData';

import translations from './.vocab';

export interface ChildAccountBannerProps {
  advertiserName: string;
  accountNumber: string;
}

export const ChildAccountBanner = ({
  advertiserName,
  accountNumber,
}: ChildAccountBannerProps) => {
  const { t } = useTranslations(translations);

  const { data, error, isLoading } = useChildAccountBannerData();

  if ((isLoading && !data) || error || !data || !data.isChild) return null;

  return (
    <Inline space="xsmall" alignY="center" align="left">
      <Text>
        {t("You're seeing data for ACCOUNT_NAME child account only", {
          Text: () => (
            <Strong>
              {advertiserName} ({accountNumber})
            </Strong>
          ),
        })}
      </Text>
      <ClickableTooltip
        id="child-account-banner-tooltip"
        title={t('Showing data for {accountName} ({accountNumber})', {
          accountName: advertiserName,
          accountNumber,
        })}
        contents={
          <Text>
            {t(
              'If you need access to other accounts, either switch accounts or contact your administrator.',
            )}
          </Text>
        }
      />
    </Inline>
  );
};
