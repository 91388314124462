export const motion: MotionTokens = {
  veryFast: '30ms',
  fast: '150ms',
  normal: '300ms',
  slow: '600ms',
  verySlow: '900ms',
};

export const motionMilliseconds: MotionNumberTokens = {
  veryFast: 30,
  fast: 150,
  normal: 300,
  slow: 600,
  verySlow: 900,
};
