import { useTranslations } from '@vocab/react';
import {
  Box,
  Columns,
  Column,
  Stack,
  Text,
  Badge,
  Inline,
} from 'braid-design-system';
import { differenceInDays, startOfDay } from 'date-fns';
import { useCallback } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { getLocale } from '../../config';
import {
  FEATURE_PERFORMANCE_RATING,
  useFeatureToggleData,
} from '../../featureToggle';
import type { Money } from '../../gql/generated';
import { useHidden } from '../../hooks/useHidden';
import type { AdStatus } from '../../types/AdPerformanceTypes';
import type { AdRatingType } from '../../types/AdUsageTypes';
import { formatCurrency } from '../../utils/currency';
import { formatDateWithUnifiedDisplay } from '../../utils/date';
import { ExternalLinkWithRef } from '../ExternalLink/ExternalLink';
import { WrapBadge } from '../WrapBadge/WrapBadge';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';
import { AdRatingLabel } from './AdRatingLabel';

import * as styles from './jobAdListItem.css';

export interface JobListItemProps {
  adRating: AdRatingType;
  applications: number;
  jobCreatedDate: string;
  locationLabel: string;
  hidePrice: boolean;
  isLatestGroupApac: boolean;
  isRefresh: boolean;
  isRepost: boolean;
  jobId: string;
  groupKeyApac: string;
  jobTitle: string;
  status: AdStatus;
  totalNetAmount: Money;
  otherBudgetAmount: Money;
  productDisplayName: string;
  userFullName: string | null;
  hoveredGroupKey?: string;
  onItemClicked?: (jobId: string) => void;
  onJobAdHover: (jobId: string | undefined) => void;
}

export const JobAdListItem = ({
  adRating,
  applications,
  jobCreatedDate,
  locationLabel,
  hidePrice,
  isLatestGroupApac,
  isRefresh,
  isRepost,
  jobId,
  jobTitle,
  status,
  totalNetAmount,
  otherBudgetAmount,
  hoveredGroupKey,
  groupKeyApac,
  productDisplayName,
  userFullName = null,
  onJobAdHover,
  onItemClicked,
}: JobListItemProps) => {
  const { t } = useTranslations(translations);
  const isPerformanceRatingFeatureEnabled = useFeatureToggleData(
    FEATURE_PERFORMANCE_RATING,
    false,
  );
  const { urlResolver, locale } = useConfig();
  const jobAdReportUrl = urlResolver(`/analytics/ad-performance/${jobId}`);
  // const [ratingHovered, setRatingHovered] = useState(false);
  const daysLive = differenceInDays(
    startOfDay(new Date()),
    startOfDay(new Date(jobCreatedDate)),
  );
  const EXPIRING_DAYS = 30; // based on business rule
  const daysRemaining = Math.max(0, EXPIRING_DAYS - daysLive);
  const prettyJobCreatedDate = formatDateWithUnifiedDisplay({
    date: new Date(jobCreatedDate),
    locale,
  });
  const numberFormatter = new Intl.NumberFormat(getLocale());

  // Configuration for mobile and tablet layout
  const isMobileView = useHidden([true, false, false, false]);
  const isTabletView = useHidden([true, true, false, false]);

  const onItemClickedEvent = () => {
    if (onItemClicked) {
      onItemClicked(jobId);
    }
  };

  const onMouseEnterRating = useCallback(() => {
    onJobAdHover(groupKeyApac);
  }, [groupKeyApac, onJobAdHover]);

  const onMouseLeaveRating = useCallback(() => {
    onJobAdHover(undefined);
  }, [onJobAdHover]);

  const hasAdPerformanceLink = isLatestGroupApac && adRating !== 'UNAVAILABLE';

  const JobAdDescription = () => (
    <Box paddingLeft="xsmall">
      <Stack space="xsmall">
        <Stack space="xsmall">
          {isRepost || isRefresh ? (
            <Inline space="xxsmall">
              {isRepost && <Badge tone="promote">{t('Repost')}</Badge>}
              {isRefresh && <Badge tone="positive">{t('Refresh')}</Badge>}
            </Inline>
          ) : null}
          <Text weight="strong" maxLines={1}>
            {jobTitle}
          </Text>
        </Stack>
        <Stack space="xsmall">
          <Text maxLines={1}>{locationLabel}</Text>
          {status === 'ACTIVE' && (
            <Text tone="secondary" maxLines={2}>
              {t('Live job posted date with remaining days', {
                prettyJobCreatedDate,
                daysRemaining,
                daysRemainingText: numberFormatter.format(daysRemaining),
              })}
            </Text>
          )}
          {status === 'EXPIRED' && (
            <Text tone="secondary" maxLines={1}>
              {t('Expired job posted date', {
                prettyJobCreatedDate,
              })}
            </Text>
          )}
        </Stack>
      </Stack>
    </Box>
  );

  const JobAdApplication = () => (
    <Box style={{ height: '52px' }}>
      <Text align="left">{numberFormatter.format(applications)}</Text>
    </Box>
  );

  const JobAdPerformance = () => (
    <Inline align="left" space="none">
      {isPerformanceRatingFeatureEnabled && (
        <AdRatingLabel
          adRating={adRating}
          hoveredGroupKey={hoveredGroupKey}
          jobId={jobId}
          onMouseEnterRating={onMouseEnterRating}
          onMouseLeaveRating={onMouseLeaveRating}
        />
      )}
      {!isPerformanceRatingFeatureEnabled && (
        <Box
          style={{
            textDecorationLine: 'underline',
          }}
        >
          <Text maxLines={1}>{t('View stats')}</Text>
        </Box>
      )}
    </Inline>
  );

  const JobAdPostedBy = () => (
    <Text maxLines={1}>
      {userFullName === null ? t('Unspecified user') : userFullName}
    </Text>
  );

  const JobAdCostType = () => (
    <Stack space="xsmall">
      {!hidePrice && (
        <Stack space="xsmall">
          <Text>{formatCurrency(totalNetAmount)}</Text>
          {otherBudgetAmount.value > 0 ? (
            <WrapBadge tone="neutral">
              {t('Amount from other budget', {
                amount: formatCurrency(otherBudgetAmount),
              })}
            </WrapBadge>
          ) : null}
        </Stack>
      )}
      <Text maxLines={1}>
        {t('Ad name', {
          adName: productDisplayName,
        })}
      </Text>
    </Stack>
  );

  const AdPerformanceLink = () => (
    <ExternalLinkWithRef
      className={styles.link}
      data-id="ui-job-menu-view-report-button"
      href={jobAdReportUrl}
      target="_blank"
      title={t('Click to view detailed report for this Job ad')}
      onClick={onItemClickedEvent}
    />
  );

  if (isMobileView) {
    return (
      <ZStack height="full" width="full">
        <ZStackItem isRelativeLayer>
          <Box
            background="surface"
            cursor={hasAdPerformanceLink ? 'pointer' : 'default'}
            paddingY="large"
            className={styles.adListPadding}
          >
            <Columns space="large" alignY="center" align="left">
              <Column width="3/5">
                <JobAdDescription />
              </Column>
              <Column width="2/5">
                <JobAdPerformance />
              </Column>
            </Columns>
          </Box>
        </ZStackItem>
        {hasAdPerformanceLink && (
          <ZStackItem>
            <AdPerformanceLink />
          </ZStackItem>
        )}
      </ZStack>
    );
  } else if (isTabletView) {
    return (
      <ZStack height="full" width="full">
        <ZStackItem isRelativeLayer>
          <Box
            background="surface"
            cursor={hasAdPerformanceLink ? 'pointer' : 'default'}
            paddingY="large"
            className={styles.adListPadding}
          >
            <Columns space="large" alignY="center" align="left">
              <Column width="3/5">
                <JobAdDescription />
              </Column>
              <Column width="2/5">
                <Columns space="none">
                  <Column width="1/2">
                    <JobAdApplication />
                  </Column>
                  <Column width="1/2">
                    <JobAdPerformance />
                  </Column>
                </Columns>
              </Column>
            </Columns>
          </Box>
        </ZStackItem>
        {hasAdPerformanceLink && (
          <ZStackItem>
            <AdPerformanceLink />
          </ZStackItem>
        )}
      </ZStack>
    );
  }
  return (
    <ZStack height="full" width="full">
      <ZStackItem isRelativeLayer>
        <Box
          background="surface"
          cursor={hasAdPerformanceLink ? 'pointer' : 'default'}
          paddingY="large"
          className={styles.adListPadding}
        >
          <Columns space="large" alignY="center" align="left">
            <Column width="1/3">
              <JobAdDescription />
            </Column>
            <Column width="2/3">
              <Columns space="large" alignY="top" align="left">
                <Column width="1/5">
                  <JobAdApplication />
                </Column>
                <Column width="1/4">
                  <JobAdPerformance />
                </Column>
                <Column width="1/5">
                  <JobAdPostedBy />
                </Column>
                <Column>
                  <JobAdCostType />
                </Column>
              </Columns>
            </Column>
          </Columns>
        </Box>
      </ZStackItem>
      {hasAdPerformanceLink && (
        <ZStackItem>
          <AdPerformanceLink />
        </ZStackItem>
      )}
    </ZStack>
  );
};
