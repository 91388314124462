import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import { adTypeTrendQuery } from '../queries/adTypeTrendsQuery';
import type { AdTypeTrendsResponse } from '../types/AdTypeTrends';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';

interface AdTypeTrendsQueryParams {
  input: {
    filters: AdUsageQueryFilters;
    locale: string;
    timezone: string;
  };
}

export const useAdTypeTrendsData = (): {
  data: AdTypeTrendsResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const filters = useAdUsageQueryFilters();
  const { locale } = useConfig();
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { data, loading, error, previousData } = useQuery<
    { analyticsAdTypeTrend: AdTypeTrendsResponse },
    AdTypeTrendsQueryParams
  >(adTypeTrendQuery, {
    variables: {
      input: {
        filters,
        locale,
        timezone,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)?.analyticsAdTypeTrend,
    isLoading: loading,
    error,
  };
};
