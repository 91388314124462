import { useWindowSize } from './useWindowSize';

const TABLET_BREAKPOINT = 740;
const DESKTOP_BREAKPOINT = 992;
const WIDE_BREAKPOINT = 1200;

export const useHidden = (
  hideOnBreakpoints = [true, true, true, true],
): boolean => {
  const paddedBreakpoints = [
    ...hideOnBreakpoints,
    ...Array(4 - hideOnBreakpoints.length).fill(false),
  ];
  const [hideOnMobile, hideOnTablet, hideOnDesktop, hideOnWide] =
    paddedBreakpoints;

  const windowSize = useWindowSize();
  const windowWidth = windowSize.width || 1024;

  return (
    (hideOnMobile && windowWidth < TABLET_BREAKPOINT) ||
    (hideOnTablet && windowWidth < DESKTOP_BREAKPOINT) ||
    (hideOnDesktop && windowWidth < WIDE_BREAKPOINT) ||
    hideOnWide
  );
};
