import { gql } from '@apollo/client';

export const marketInsightsTitleQuery = gql`
  query GetMarketInsightsTitleData($input: MarketInsightsTitleWidgetInput!) {
    analyticsMarketInsightsTitle(input: $input) {
      benchmarkLocationLabel
      benchmarkScenario
      classificationName
      normalisedRoleTitle
      subClassificationName
      benchmarkLocationCountry
    }
  }
`;
