import { useTranslations } from '@vocab/react';
import { Box, Text, Badge } from 'braid-design-system';

import type { AdRatingType } from '../../types/AdUsageTypes';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialog';

import translations from './.vocab';

export interface AdRatingLabelProps {
  adRating: AdRatingType;
  onMouseEnterRating: () => void;
  onMouseLeaveRating: () => void;
  jobId: string;
  hoveredGroupKey: string | undefined;
}
export const AdRatingLabel = ({
  adRating,
  onMouseEnterRating,
  onMouseLeaveRating,
  jobId,
  hoveredGroupKey,
}: AdRatingLabelProps) => {
  const { t } = useTranslations(translations);

  const TooltipLabel = ({
    label,
    tooltip,
  }: {
    label: string;
    tooltip: string;
  }) => (
    <Box
      position="relative"
      zIndex={hoveredGroupKey === jobId ? 'dropdownBackdrop' : 1}
      onMouseEnter={onMouseEnterRating}
      onMouseLeave={onMouseLeaveRating}
    >
      <DisclosureDialog tooltip={<Text size="small">{tooltip}</Text>}>
        <Text>{label}</Text>
      </DisclosureDialog>
    </Box>
  );

  const components = {
    LOW: <Badge tone="critical">{t('Low')}</Badge>,
    NORMAL: <Badge tone="info">{t('Normal')}</Badge>,
    HIGH: <Badge tone="positive">{t('High')}</Badge>,
    TOO_SOON_TO_RATE: (
      <TooltipLabel
        label={t('No rating yet')}
        tooltip={t('It is too early to see how your ad is doing.')}
      />
    ),
    UNABLE_TO_RATE: (
      <TooltipLabel
        label={t('Unable to rate')}
        tooltip={t('There is not enough information')}
      />
    ),
    UNAVAILABLE: (
      <TooltipLabel
        label={t('Unavailable')}
        tooltip={t(
          'Only job ads posted on the new website have performance reports.',
        )}
      />
    ),
  };

  return components[adRating];
};
