import { Badge, Box, Text, Stack } from 'braid-design-system';
import type React from 'react';

export interface PerformanceRatingCardProps {
  rating: string | ReactNodeNoStrings;
  ratingTone?:
    | 'caution'
    | 'critical'
    | 'info'
    | 'neutral'
    | 'positive'
    | 'promote';
  description: string | ReactNodeNoStrings;
  caption: string | ReactNodeNoStrings;
  height?: number;
  width?: number | string;
}

export const PerformanceRatingCard = ({
  rating,
  ratingTone,
  description,
  caption,
  height = 264,
  width = 173,
}: PerformanceRatingCardProps) => (
  <Box
    style={{
      height,
      minWidth: width,
      overflowY: 'auto',
      width: 'calc(100% / 3)',
    }}
    padding="small"
    boxShadow="borderNeutralLight"
    borderRadius="large"
    data-testid="performance-rating-card"
  >
    <Stack space="gutter">
      <Badge tone={ratingTone}>{rating}</Badge>
      <Box>
        <Text component="p" size="small">
          {description}
        </Text>
      </Box>
      <Text component="p" size="small" tone="secondary">
        {caption}
      </Text>
    </Stack>
  </Box>
);
