import { useLayoutEffect, useCallback, useState } from 'react';

export function useChartSize({
  containerRef,
  width: initialWidth,
  widthBehaviour,
}: {
  containerRef: HTMLDivElement | null;
  width: number;
  widthBehaviour: 'fixed' | 'dynamic';
}): {
  width: number;
} {
  const [width, setWidth] = useState(initialWidth);

  const onResize = useCallback(() => {
    if (!containerRef) {
      return;
    }

    setWidth(containerRef?.clientWidth || 0);
  }, [containerRef]);

  useLayoutEffect(() => {
    onResize();
  }, [containerRef, onResize, widthBehaviour]);

  useLayoutEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient || widthBehaviour !== 'dynamic') {
      setWidth(initialWidth);
      return;
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [initialWidth, onResize, widthBehaviour]);

  return {
    width,
  };
}
