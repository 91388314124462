import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import { useContext, type ReactElement } from 'react';

import { IsFilterContext } from '../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../context/adUsageFilters';
import type { AdRatingOption } from '../../../types/AdUsageFiltersResponse';
import type { FiltersAdRatingType } from '../../../types/AdUsageTypes';
import { trackEvent } from '../../../utils/tealiumAUREventTracker';
import { getOptionLabel } from '../Filters.formatters';

import translations from './.vocab';
import { sortAdPerformanceTypes } from './AdPerformanceFilter.formatter';

export interface AdPerformanceFilterProps {
  adPerformanceTypes: AdRatingOption[];
}

export const AdPerformanceFilter = ({
  adPerformanceTypes,
}: AdPerformanceFilterProps) => {
  const { t } = useTranslations(translations);
  const {
    adUsageFilters: { adRatings: selectedAdPerformanceTypes },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();

  const {
    setIsFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const typedValue = value as FiltersAdRatingType;
    const newSelected = selectedAdPerformanceTypes.includes(typedValue)
      ? [
          ...selectedAdPerformanceTypes.filter(
            (singleSelected) => singleSelected !== typedValue,
          ),
        ]
      : [...selectedAdPerformanceTypes, typedValue];

    trackEvent('filter_pressed', {
      isFilterAdPerformance: newSelected.length > 0,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad performance',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_PERFORMANCE_TYPES,
      value: newSelected,
    });

    setIsFilterAdPerformance(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_PERFORMANCE_TYPES,
      value: [],
    });

  const formattedAdPerformanceTypes = sortAdPerformanceTypes(
    adPerformanceTypes,
  ).map((data) => ({
    ...data,
    value: t(data.key),
  }));

  const displayedValue = getOptionLabel(
    formattedAdPerformanceTypes,
    selectedAdPerformanceTypes,
    t,
    t('All ratings'),
  );

  const translationRendererOptions: any = {
    Stack: (children: ReactElement) => <Stack space="medium">{children}</Stack>,
    Text: (children: string) => <Text key={children}>{children}</Text>,
  };

  const tooltip = t('We rate the performance of your ads label tooltip', {
    ...translationRendererOptions,
  });

  return (
    <MultiSelectDropdown
      options={formattedAdPerformanceTypes}
      label={t('Ad performance')}
      labelTooltip={tooltip}
      selected={selectedAdPerformanceTypes}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      searchPlaceholder="search"
      clearSelection={clearSelection}
    />
  );
};
