export const colour: ColourTokens = {
  accent: {
    50: '#FEEFFA',
    100: '#FCD9EF',
    200: '#F8B1DC',
    300: '#F470BD',
    400: '#EE399E',
    500: '#E60278',
    600: '#C6005B',
    700: '#A00043',
    800: '#76002C',
    900: '#520018',
  },
  brand: {
    50: '#F0F6FC',
    100: '#DCE5F2',
    200: '#B6C7E0',
    300: '#7795C2',
    400: '#4166A4',
    500: '#0D3880',
    600: '#072462',
    700: '#051A49',
    800: '#020F30',
    900: '#00061B',
  },
  grey: {
    50: '#F7F8FB',
    100: '#EAECF1',
    200: '#D2D7DF',
    300: '#ABB3C1',
    400: '#838FA5',
    500: '#5A6881',
    600: '#414C63',
    700: '#2E3849',
    800: '#1C2330',
    900: '#0E131B',
  },
  yellow: {
    50: '#FEFDF1',
    100: '#FEF8DE',
    200: '#FEF0BC',
    300: '#FEE384',
    400: '#FDD651',
    500: '#FDC221',
    600: '#DFA414',
    700: '#B9800D',
    800: '#945B07',
    900: '#723D02',
  },
  orange: {
    50: '#FEF9F2',
    100: '#FEEEE1',
    200: '#FEDAC2',
    300: '#FEBB90',
    400: '#FD9C60',
    500: '#FD7532',
    600: '#E05821',
    700: '#BB4116',
    800: '#95290C',
    900: '#751704',
  },
  red: {
    50: '#FEF3F3',
    100: '#FFE3E2',
    200: '#FDC8C8',
    300: '#FB999A',
    400: '#FA6D6C',
    500: '#F94344',
    600: '#DC2C2D',
    700: '#B91E1E',
    800: '#951012',
    900: '#750606',
  },
  purple: {
    50: '#FCF5FE',
    100: '#F7E8FD',
    200: '#EED1F9',
    300: '#DFACF5',
    400: '#D184F2',
    500: '#BB5CEB',
    600: '#9D41CE',
    700: '#7C2EAA',
    800: '#581B87',
    900: '#3C0B65',
  },
  violet: {
    50: '#FAF5FE',
    100: '#F1E8FD',
    200: '#E1D1F9',
    300: '#C6ACF5',
    400: '#AD84F2',
    500: '#8B5CEB',
    600: '#6C41CE',
    700: '#512EAA',
    800: '#341B87',
    900: '#1E0B65',
  },
  indigo: {
    50: '#F4F6FE',
    100: '#E6EAFC',
    200: '#CBD5F9',
    300: '#9FB0F5',
    400: '#748CF0',
    500: '#4964E9',
    600: '#3148C9',
    700: '#2433A7',
    800: '#152081',
    900: '#08105F',
  },
  blue: {
    50: '#F4FBFE',
    100: '#E3F2FB',
    200: '#C8E1F7',
    300: '#98C9F1',
    400: '#6AB0EA',
    500: '#3E8FE0',
    600: '#2A71C1',
    700: '#1D559D',
    800: '#123976',
    900: '#072254',
  },
  cyan: {
    50: '#F2FDFD',
    100: '#E1F8FA',
    200: '#BEEFF3',
    300: '#88E2E9',
    400: '#56D3DF',
    500: '#28BFCE',
    600: '#19A1AF',
    700: '#137D8B',
    800: '#095863',
    900: '#053842',
  },
  green: {
    50: '#F4FDF4',
    100: '#E3F7E3',
    200: '#C8EDC8',
    300: '#98DE97',
    400: '#6ACF6A',
    500: '#3EB73D',
    600: '#289927',
    700: '#1E771D',
    800: '#125113',
    900: '#073408',
  },
};

export const systemColour: SystemColourTokens = {
  black: '#000000',
  white: '#FFFFFF',
};
