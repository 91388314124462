
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IktsaWsgdW50dWsgbWVtYnVrYSBkaWFsb2ciLCJDbGljayB0byBjbG9zZSBkaWFsb2ciOiJLbGlrIHVudHVrIG1lbnV0dXAgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IktsaWsgdW50dWsgbWVtYnVrYSBkaWFsb2ciLCJDbGljayB0byBjbG9zZSBkaWFsb2ciOiJLbGlrIHVudHVrIG1lbnV0dXAgZGlhbG9nIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC5gOC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IuC4hOC4peC4tOC4geC5gOC4nuC4t+C5iOC4reC4m+C4tOC4lOC5hOC4lOC4reC4sOC4peC5h+C4reC4gSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IktsaWsgdW50dWsgbWVtYnVrYSBkaWFsb2ciLCJDbGljayB0byBjbG9zZSBkaWFsb2ciOiJLbGlrIHVudHVrIG1lbnV0dXAgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IktsaWsgdW50dWsgbWVtYnVrYSBkaWFsb2ciLCJDbGljayB0byBjbG9zZSBkaWFsb2ciOiJLbGlrIHVudHVrIG1lbnV0dXAgZGlhbG9nIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IkNsaWNrIHRvIG9wZW4gZGlhbG9nIiwiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIjoiQ2xpY2sgdG8gY2xvc2UgZGlhbG9nIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IlvDh8aaw6zDrMOsw6fEtyDhua/DtsO2w7Ygw7bDtsO2xqXhur3hur3hur3guIHguLXguYkgxozDrMOsw6zEg8SDxIPGmsO2w7bDtsSjXSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IlvDh8aaw6zDrMOsw6fEtyDhua/DtsO2w7Ygw6fGmsO2w7bDtsWh4bq94bq94bq9IMaMw6zDrMOsxIPEg8SDxprDtsO2w7bEo10ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDbGljayB0byBvcGVuIGRpYWxvZyI6IlvDh8aaw6zDrMOsw6fEtyDhua/DtsO2w7Ygw7bDtsO2xqXhur3hur3hur3guIHguLXguYkgxozDrMOsw6zEg8SDxIPGmsO2w7bDtsSjXSIsIkNsaWNrIHRvIGNsb3NlIGRpYWxvZyI6IlvDh8aaw6zDrMOsw6fEtyDhua/DtsO2w7Ygw6fGmsO2w7bDtsWh4bq94bq94bq9IMaMw6zDrMOsxIPEg8SDxprDtsO2w7bEo10ifQ==!"
        )
      )
      });
  
      export { translations as default };
    