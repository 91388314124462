import {
  getScriptTag,
  adapter as createTealiumAdapter,
} from '@seek/tealium-adapter';

import { setDataLayer, getDataLayer } from './dataLayer';
import type { DataLayer } from './dataLayer-types';

let tealiumAdapter: TealiumAdapter | undefined;
let pageLoaded = false;

export const getTrackerScriptTag = (
  environment: 'prod' | 'dev' | 'qa',
): string => {
  const preventViewOnLoad =
    '<script>window.utag_cfg_ovrd = { noview: true };</script>';

  const compositeTagString = `${preventViewOnLoad}${getScriptTag({
    account: 'seek',
    profile: 'hirer',
    environment,
  })}`;

  return compositeTagString.replace('async', 'defer');
};

export const initTracker = (environment: 'prod' | 'dev' | 'qa') => {
  if (environment === 'dev') {
    // TODO: process object is unavailable in window context?
    // } && process.env.IS_TEST !== 'true') {
    // window.ANALYTICS_DEBUG = true;
  }
  tealiumAdapter = createTealiumAdapter(undefined);
};

export const trackEvent = <T>(eventName: string | string[], props: T) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  const dataLayer = getDataLayer();
  tealiumAdapter.link(eventName, { ...dataLayer, ...props });
};

export const trackPageView = (dataLayer: DataLayer, eventName?: string) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  setDataLayer(dataLayer);
  if (!pageLoaded) {
    tealiumAdapter.view(eventName || `${dataLayer.page}-viewed`, dataLayer);
    pageLoaded = true;
  }
};
