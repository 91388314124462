
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJMZXNzIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguJnguYnguK3guKLguKXguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJMZXNzIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguJnguYnguK3guKLguKXguIcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJMZWJpaCBiYW55YWsgZmlsdGVyIiwiTGVzcyBmaWx0ZXJzIjoiTGViaWggc2VkaWtpdCBmaWx0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJMZWJpaCBiYW55YWsgZmlsdGVyIiwiTGVzcyBmaWx0ZXJzIjoiTGViaWggc2VkaWtpdCBmaWx0ZXIifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJMZXNzIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguJnguYnguK3guKLguKXguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguYDguJ7guLTguYjguKHguYDguJXguLTguKEiLCJMZXNzIGZpbHRlcnMiOiLguJXguLHguKfguIHguKPguK3guIfguJnguYnguK3guKLguKXguIcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJMZWJpaCBiYW55YWsgZmlsdGVyIiwiTGVzcyBmaWx0ZXJzIjoiTGViaWggc2VkaWtpdCBmaWx0ZXIifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJMZWJpaCBiYW55YWsgZmlsdGVyIiwiTGVzcyBmaWx0ZXJzIjoiTGViaWggc2VkaWtpdCBmaWx0ZXIifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJNb3JlIGZpbHRlcnMiLCJMZXNzIGZpbHRlcnMiOiJMZXNzIGZpbHRlcnMifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJb4bmCw7bDtsO2xZnhur3hur3hur0gxpLDrMOsw6zGmuG5r+G6veG6veG6vcWZxaFdIiwiTGVzcyBmaWx0ZXJzIjoiW8WB4bq94bq94bq9xaHFoSDGksOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJNb3JlIGZpbHRlcnMiOiJb4bmCw7bDtsO2xZnhur3hur3hur0gxpLDrMOsw6zGmuG5r+G6veG6veG6vcWZxaFdIiwiTGVzcyBmaWx0ZXJzIjoiW8WB4bq94bq94bq9xaHFoSDGksOsw6zDrMaa4bmv4bq94bq94bq9xZnFoV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    