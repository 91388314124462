import { gql } from '@apollo/client';

export const recommendationsQuery = gql`
  query GetRecommendations($input: RecommendationsWidgetInput!) {
    analyticsRecommendations(input: $input) {
      isExpired
      postTypeCategory
      lastUpdatedTimestampUTC
      recommendations {
        ... on LowClick {
          type
          conversionRate
          averageConversionRate
        }
        ... on LowApply {
          type
          conversionRate
          averageConversionRate
        }
        ... on RevealSalary {
          type
          currency
        }
        ... on LowSalary {
          type
          currency
          salaryType
          salaryRangeMin
          salaryRangeMax
          suggestedRangeMin
          suggestedRangeMax
          suggestedAverageRangeMin
          suggestedAverageRangeMax
          normalisedRoleTitle
          benchmarkLocationLabel
        }
        ... on GranularLocation {
          type
          nonSpecificLocation
          specificLocation
        }
        ... on BrandedAd {
          type
          bulletPointsUsed
          logoUsed
          coverImageUsed
        }
        ... on RoleRequirements {
          type
          roleReqsQuestions
        }
        ... on TalentSearch {
          type
          jobId
          hirerId
        }
      }
    }
  }
`;
