import { isAfter, startOfDay } from 'date-fns';

import { useConfig } from '../App/ConfigContext';
import { useAdUsageFiltersContext } from '../context/adUsageFilters';
import { LAUNCH_DATES } from '../featureToggle/config/historicalDataFeature';

export const useHistoricalDataChecker = () => {
  const { site } = useConfig();
  const {
    adUsageFilters: {
      postingDates: { startDate },
    },
  } = useAdUsageFiltersContext();

  // true when filtering days that include historical data, i.e. launch date and the days prior
  const isFilteringHistoricalAurData = Boolean(
    LAUNCH_DATES[site] &&
      !isAfter(startOfDay(startDate), startOfDay(new Date(LAUNCH_DATES[site]))),
  );

  return {
    isFilteringHistoricalAurData,
  };
};
