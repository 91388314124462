import { Box, Stack } from 'braid-design-system';
import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { MarketPerformanceTitle } from './MarketPerformance';

export interface MarketPerformanceErrorBoundaryProps {
  children: ReactNode;
}

export const MarketPerformanceErrorBoundary = ({
  children,
}: MarketPerformanceErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <Box paddingRight="large">
        <Stack space="large">
          <MarketPerformanceTitle />
          <WidgetError />
        </Stack>
      </Box>
    }
  >
    {children}
  </ErrorBoundary>
);
