import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import { mostAdsByPerformanceQuery } from '../queries/mostAdsByPerformanceQuery';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';
import type { MostAdsByPerformanceResponse } from '../types/MostAdsByPerformanceResponse';

interface MostAdsByPerformanceQueryParam {
  input: {
    locale: string;
    filters: AdUsageQueryFilters;
  };
}

export const useMostAdsByPerformanceData = (): {
  data: MostAdsByPerformanceResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const filters = useAdUsageQueryFilters();
  const { data, loading, error, previousData } = useQuery<
    { analyticsMostAdsByPerformance: MostAdsByPerformanceResponse },
    MostAdsByPerformanceQueryParam
  >(mostAdsByPerformanceQuery, {
    variables: {
      input: {
        locale,
        filters,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsMostAdsByPerformance,
    isLoading: loading,
    error,
  };
};
