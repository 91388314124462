import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import type { AdUsageTrendClassificationsAndUsersResponse } from '../gql/generated';
import { classificationsAndUsersTrendQuery } from '../queries/classificationsAndUsersTrendQuery';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';

interface ClassificationsUsersAndTrendsQueryParams {
  input: {
    filters: AdUsageQueryFilters;
    locale: string;
  };
}

export const useClassificationsAndUsersTrendsData = (): {
  data: AdUsageTrendClassificationsAndUsersResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const filters = useAdUsageQueryFilters();
  const { data, loading, error, previousData } = useQuery<
    {
      analyticsClassificationsAndUsersTrend: AdUsageTrendClassificationsAndUsersResponse;
    },
    ClassificationsUsersAndTrendsQueryParams
  >(classificationsAndUsersTrendQuery, {
    variables: {
      input: {
        filters,
        locale,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsClassificationsAndUsersTrend,
    isLoading: loading,
    error,
  };
};
