import 'braid-design-system/reset';
import { HirerAuthProvider } from '@seek/hirer-auth-react';
import { HirerApolloProvider } from '@seek/hirer-graphql-react';
import { useIsNVLEnabled } from '@seek/uiv-banner';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { logError } from '../api/hirer-api/hirerApi.loggers';
import type { Config } from '../config';
import { FeatureToggleProvider } from '../featureToggle';
import { region } from '../utils/locale';

import { ConfigContext, useConfig } from './ConfigContext';
import { Router } from './Router';

export interface AppProps {
  config: Config;
}

export const App = ({ config }: AppProps) => (
  <StrictMode>
    <ConfigContext.Provider value={config}>
      <AppWithConfig />
    </ConfigContext.Provider>
  </StrictMode>
);

export const AppWithConfig = () => {
  const { locale, site, graphUrlOverride, environment } = useConfig();
  const isNVLEnabled = useIsNVLEnabled(false);
  region.initialise(locale);

  return (
    <VocabProvider language={locale ?? 'en-AU'}>
      <HirerAuthProvider
        authenticationLevel={environment === 'mock' ? 'Mocked' : 'Hirer'}
      >
        <HirerApolloProvider
          appName="hirer-ad-usage-ui"
          site={site}
          uri={graphUrlOverride}
          logError={logError}
        >
          <BraidProvider theme={isNVLEnabled ? seekJobs : apac}>
            <FeatureToggleProvider>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </FeatureToggleProvider>
          </BraidProvider>
        </HirerApolloProvider>
      </HirerAuthProvider>
    </VocabProvider>
  );
};
