import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import * as styles from './contentBlock.css';

export interface ContentBlockProps {
  children?: ReactNode;
}

export const ContentBlock = ({ children }: ContentBlockProps) => (
  <Box className={styles.contentBlock}> {children}</Box>
);
