import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { adRatingQuery } from '../queries/adRatingQuery';
import type { AdRatingResponse } from '../types/AdRatingResponse';

interface AdRatingQueryParams {
  input: {
    jobId: string;
    locale: string;
  };
}

interface queryResponse {
  analyticsAdRating: AdRatingResponse;
}

export const useAdRatingData = ({
  jobId = '',
}: {
  jobId: string;
}): {
  data: AdRatingResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();

  const { data, loading, error, previousData } = useQuery<
    queryResponse,
    AdRatingQueryParams
  >(adRatingQuery, {
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)?.analyticsAdRating,
    isLoading: loading,
    error,
  };
};
