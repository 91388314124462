import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import { useContext } from 'react';

import { IsFilterContext } from '../../../../context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from '../../../../context/adUsageFilters';
import type { RepostOption } from '../../../../types/AdUsageFiltersResponse';
import type { AdRepostType } from '../../../../types/AdUsageTypes';
import { trackEvent } from '../../../../utils/tealiumAUREventTracker';
import { getDisplayValue } from '../../Filters.formatters';

import translations from './.vocab';
import { buildRepostData } from './RepostFilter.formatters';

interface RepostFilterProps {
  reposts: RepostOption[];
}

export const RepostFilter = ({ reposts }: RepostFilterProps) => {
  const {
    adUsageFilters: { repostTypes: selectedReposts },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  const repostData = buildRepostData(reposts, t);

  const displayedValue = getDisplayValue(
    selectedReposts,
    {},
    repostData,
    t,
    t('All posted ads'),
  );

  const {
    setIsFilterRepost,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const typedValue = value as AdRepostType;
    const newlySelected = selectedReposts.includes(typedValue)
      ? selectedReposts.filter((ids: string) => ids !== typedValue)
      : [...selectedReposts, typedValue];
    trackEvent('filter_pressed', {
      isFilterRepost: newlySelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAdId,
      isFilterAdStatus,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'repost',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_REPOST,
      value: newlySelected,
    });

    setIsFilterRepost(newlySelected.length > 0);
  };

  const translationOption: any = {
    Text: (children: string) => <Text key={children}>{children}</Text>,
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_REPOST,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={repostData}
      label={t('Repost')}
      selected={selectedReposts}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      labelTooltip={t('We consider a new ad to be reposted', translationOption)}
      clearSelection={clearSelection}
    />
  );
};
