import { Box, Disclosure, Heading, Stack, Text } from 'braid-design-system';

import { AnimatedNumber } from '../AnimatedText';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialog';

import * as styles from './ConversionCard.css';

export interface BadgeProps {
  tone: 'critical' | 'neutral' | 'positive';
  text: string;
}

export interface DisclosureProps {
  expandLabel: string;
  collapseLabel: string;
  content: ReactNodeNoStrings;
  id: string;
  onToggle?: ((expanded: boolean) => void) | undefined;
}

export interface TotalProps {
  heading: string | number;
  description?: string;
}

export interface ConversionCardProps {
  view: 'default' | 'compact';
  header: string;
  headerTooltip: string;
  comparisonRateText: ReactNodeNoStrings;
  totalProps: TotalProps;
  badgeProps?: BadgeProps;
  disclosureProps?: DisclosureProps;
  mobileText?: ReactNodeNoStrings;
  mainComparisionText?: ReactNodeNoStrings;
  conversionRateTitle?: ReactNodeNoStrings;
  conversionRate?: ReactNodeNoStrings;
}

export const ConversionCard = ({
  view,
  header,
  headerTooltip,
  comparisonRateText,
  disclosureProps,
  totalProps,
  mobileText,
  mainComparisionText,
  conversionRateTitle,
  conversionRate,
}: ConversionCardProps) => {
  const getDisclosure = (props: DisclosureProps) => (
    <Box marginBottom="gutter">
      <Disclosure
        expandLabel={props.expandLabel}
        collapseLabel={props.collapseLabel}
        id={props.id}
        onToggle={props.onToggle}
      >
        <Box style={{ minHeight: 50 }} data-testid="disclosure-content">
          <Stack space="medium">
            <Text size="small">{props.content}</Text>
            {mobileText && <Text size="small">{mobileText}</Text>}
          </Stack>
        </Box>
      </Disclosure>
    </Box>
  );

  const totalHeading =
    typeof totalProps.heading === 'number' ? (
      <AnimatedNumber value={Number(totalProps.heading)} />
    ) : (
      totalProps.heading
    );

  const total = totalProps.description ? (
    <Stack space="gutter">
      <Heading
        level="3"
        data={{
          testid: 'total-heading',
        }}
      >
        {totalHeading}
      </Heading>
      <Text size="small" data={{ testid: 'total-description' }}>
        {totalProps.description}
      </Text>
    </Stack>
  ) : (
    <>
      <Heading level="3" data={{ testid: 'total-heading' }}>
        {totalHeading}
      </Heading>
      {!disclosureProps && mobileText && <Text size="small">{mobileText}</Text>}
    </>
  );

  const isCompactView = view === 'compact';
  return (
    <Box
      className={isCompactView ? undefined : styles.conversionCard}
      width={isCompactView ? 'full' : undefined}
      height={isCompactView ? 'full' : undefined}
      background="surface"
      boxShadow="borderNeutralLight"
      padding="gutter"
      borderRadius="large"
      data-testid={header.toLocaleLowerCase().split(' ').join('-')}
    >
      <Stack space="medium">
        <DisclosureDialog
          tooltip={
            <Stack space="medium">
              <Text size="small">{headerTooltip}</Text>
            </Stack>
          }
        >
          <Text>{header}</Text>
        </DisclosureDialog>
        {total}
        {mainComparisionText && <Text>{mainComparisionText}</Text>}
        {disclosureProps && getDisclosure(disclosureProps)}
        {Boolean(conversionRateTitle) && conversionRateTitle}
        {Boolean(conversionRate) && conversionRate}
        {comparisonRateText}
      </Stack>
    </Box>
  );
};
