import { Box } from 'braid-design-system';
import type React from 'react';

export interface ZStackProps {
  height: number | 'full';
  width: number | 'full';
  children?: ReactNodeNoStrings;
}

export const ZStack = ({ children, height, width }: ZStackProps) => (
  <Box
    data={{ id: 'primitive-zstack' }}
    position="relative"
    style={{
      height: height === 'full' ? '100%' : `${height}px`,
      width: width === 'full' ? '100%' : `${width}px`,
    }}
  >
    {children}
  </Box>
);
