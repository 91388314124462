import { getCapHeight } from '@capsizecss/core';

const fontMetrics = {
  capHeight: 1456,
  ascent: 1900,
  descent: -500,
  lineGap: 0,
  unitsPerEm: 2048,
};

export const typography: TypographyTokens = {
  caption1: {
    capHeight: getCapHeight({
      fontSize: 12,
      fontMetrics,
    }),
    pointSize: 12,
  },
  caption2: {
    capHeight: getCapHeight({
      fontSize: 10,
      fontMetrics,
    }),
    pointSize: 10,
  },
};
