import { useTranslations } from '@vocab/react';
import { Box, Column, Columns, Loader, Stack, Text } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApplicationPredictionChart } from '../../components/ApplicationPredictionChart/ApplicationPredictionChart';
import { PredictionSection } from '../../components/PredictionSection/PredictionSection';
import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';
import { useMobileAndTabletView } from '../../hooks/useMobileAndTabletView';
import { useMobileView } from '../../hooks/useMobileView';
import { usePerformancePrediction } from '../../hooks/usePerformancePrediction';

import translations from './.vocab';

export interface PerformancePredictionProps {
  onQueryError?: () => void;
}

export const PerformancePrediction = ({
  onQueryError,
}: PerformancePredictionProps) => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();

  const isMobile = useMobileView();

  const isMobileAndTablet = useMobileAndTabletView();

  const {
    isLoading,
    data: chartData,
    error,
  } = usePerformancePrediction({
    jobId: jobIdParam!,
  });

  const hasQueryError = error || !chartData;

  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  if (isLoading && !chartData) {
    return (
      <Box
        display="flex"
        alignItems="center"
        height="full"
        justifyContent="center"
        padding="medium"
      >
        <Loader />
      </Box>
    );
  }

  if (hasQueryError) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        height="full"
        padding="gutter"
      >
        <WidgetErrorNvl />
      </Box>
    );
  }

  const {
    xRange,
    events,
    predictionDate,
    yRange,
    xAxisLabels,
    valueLabel,
    predictionDayOptions,
  } = chartData;

  const isChartRendered =
    xRange &&
    events &&
    yRange &&
    xAxisLabels &&
    valueLabel &&
    predictionDayOptions;

  return (
    <Box
      data={{ id: 'ui-performance-prediction-chart' }}
      width="full"
      padding={isMobile ? 'none' : 'gutter'}
      height="full"
    >
      {isChartRendered ? (
        <>
          <Columns
            space={isMobileAndTablet ? 'large' : 'none'}
            collapseBelow="desktop"
          >
            <Column width="2/3">
              <Stack space="small">
                <Text size="standard" weight="strong">
                  {t('Application trends')}
                </Text>
                <ApplicationPredictionChart
                  data={{
                    xRange,
                    events,
                    predictionDate,
                    yRange,
                    xAxisLabels,
                    valueLabel,
                  }}
                  height={164}
                  width={514}
                  widthBehaviour="dynamic"
                />
              </Stack>
            </Column>
            <Column width="1/3">
              {predictionDayOptions.length !== 0 && (
                <PredictionSection
                  title={valueLabel}
                  predictionOptions={predictionDayOptions}
                />
              )}
            </Column>
          </Columns>
        </>
      ) : null}
    </Box>
  );
};
