import { type ReactNode, lazy, useEffect, useState } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { isClient } from '../../config';
import { useGetAdvertiserId } from '../../hooks/useGetAdvertiserId';
import { featureConfigs } from '../config';
import { isOverridesAllowed } from '../controller/utils';
import type { Feature } from '../types';
import {
  getLocalStorageFeatureState,
  setLocalStorageFeatureState,
} from '../utils/localStorageFeatureState';

import { FeatureToggleContext } from './FeatureToggleContext';

const FeatureToggleController = lazy(() =>
  import('../controller').then((module) => ({
    default: module.FeatureToggleController,
  })),
);

interface ProviderProps {
  children: ReactNode;
}

export const FeatureToggleProvider = ({ children }: ProviderProps) => {
  const { environment } = useConfig();
  const advertiserId = useGetAdvertiserId();

  // Feature flags can be turned on/off in local storage (for dev/testing)
  const [overrides, setOverrides] = useState<Feature[]>(
    getLocalStorageFeatureState(),
  );
  // Listen to changes to update local storage with the latest value
  useEffect(() => setLocalStorageFeatureState(overrides), [overrides]);

  // Calculate the value of the features based on configuration
  const features: Feature[] = featureConfigs.map((config) =>
    config.toFeature({
      advertiserId,
      env: environment,
    }),
  );

  return (
    <FeatureToggleContext.Provider
      value={{
        features,
        overrides: isOverridesAllowed() ? overrides : [],
        setOverrides,
      }}
    >
      {children}
      {isClient() && isOverridesAllowed() && <FeatureToggleController />}
    </FeatureToggleContext.Provider>
  );
};
