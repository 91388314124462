import { type ApolloError, useQuery } from '@apollo/client';

import { useConfig } from '../App/ConfigContext';
import { jobAdListQuery } from '../queries/jobAdListQuery';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';
import type { JobAdListResponse } from '../types/JobAdListResponse';

interface JobAdListQueryParams {
  input: {
    locale: string;
    pageNumber: number;
    size: number;
    filters: AdUsageQueryFilters;
  };
}

interface useJobAdListDataParams {
  pageNumber: number;
  size: number;
  filters: AdUsageQueryFilters;
}

export const useJobAdListData = ({
  pageNumber,
  size,
  filters,
}: useJobAdListDataParams): {
  data: JobAdListResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery<
    { analyticsJobAdList: JobAdListResponse },
    JobAdListQueryParams
  >(jobAdListQuery, {
    variables: {
      input: {
        locale,
        pageNumber,
        size,
        filters,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)?.analyticsJobAdList,
    isLoading: loading,
    error,
  };
};
