import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import type { CSSProperties } from 'react';

import translations from './.vocab';
import { salaryBarFormatter } from './SalaryBar.formatters';
import type { SalaryBarProps } from './SalaryBar.types';

import * as styles from './SalaryBar.css';

interface ScaleLabelProps {
  min: string;
  averageMin: string;
  averageMax: string;
  max: string;
}

interface IndicatorProps {
  style: CSSProperties;
  testId?: string;
}

export const SalaryBar = ({
  salaryRangeMin,
  salaryRangeMax,
  suggestedRangeMin,
  suggestedRangeMax,
  suggestedAverageRangeMin,
  suggestedAverageRangeMax,
  currency,
}: SalaryBarProps) => {
  const { t } = useTranslations(translations);

  const indicatorSize = 22;

  const salaryRangeChartData = salaryBarFormatter({
    salaryRangeMin,
    salaryRangeMax,
    suggestedRangeMin,
    suggestedRangeMax,
    suggestedAverageRangeMin,
    suggestedAverageRangeMax,
    currency,
  });

  const IndicatorFill = ({ style, testId }: IndicatorProps) => (
    <Box
      position="absolute"
      zIndex={2}
      className={styles.indicatorFill}
      style={style}
      data={{ testid: testId || '' }}
    />
  );

  const Indicator = ({ style, testId }: IndicatorProps) => (
    <Box
      position="absolute"
      zIndex={1}
      className={styles.indicator}
      style={style}
      data={{ testid: testId || '' }}
    />
  );

  const BarLabel = () => (
    <Box display="flex" className={styles.barLabel} marginBottom="xsmall">
      <Box flexGrow={1} className="category">
        <Text weight="strong" align="center" size="xsmall">
          {t('Low')}
        </Text>
      </Box>
      <Box flexGrow={1} className="category">
        <Text weight="strong" align="center" size="xsmall">
          {t('Average')}
        </Text>
      </Box>
      <Box flexGrow={1} className="category">
        <Text weight="strong" align="center" size="xsmall">
          {t('High')}
        </Text>
      </Box>
    </Box>
  );

  const Bar = () => (
    <Box display="flex">
      <Box className={[styles.beginning, styles.barDefaults]} />
      <Box flexGrow={1} className={[styles.middle, styles.barDefaults]} />
      <Box flexGrow={1} className={[styles.middle, styles.barDefaults]} />
      <Box flexGrow={1} className={[styles.middle, styles.barDefaults]} />
      <Box className={[styles.end, styles.barDefaults]} />
    </Box>
  );

  const ScaleLabel = ({
    min,
    averageMin,
    averageMax,
    max,
  }: ScaleLabelProps) => (
    <Box display="flex" paddingTop="xsmall">
      <Box flexGrow={1} textAlign="left" paddingLeft="medium" className="tick">
        <Text size="small">{min}</Text>
      </Box>
      <Box textAlign="center" className={[styles.middleValues, 'tick']}>
        <Text size="small">{averageMin}</Text>
      </Box>
      <Box textAlign="center" className={[styles.middleValues, 'tick']}>
        <Text size="small">{averageMax}</Text>
      </Box>
      <Box
        textAlign="right"
        flexGrow={1}
        paddingRight="medium"
        className="tick"
      >
        <Text size="small">{max}</Text>
      </Box>
    </Box>
  );

  const { maxPosition, minPosition, width } = salaryRangeChartData.marker;

  const indicatorFillStyles = {
    left: `calc(${minPosition} + ${indicatorSize / 2}px)`,
    width,
  };

  const maxIndicatorStyles = {
    left: maxPosition,
  };

  const minIndicatorStyles = {
    left: minPosition,
  };

  return (
    <>
      <BarLabel />
      <Box position="relative">
        <Bar />
        <Box
          position="absolute"
          width="full"
          style={{ top: '-2px', color: 'transparent' }}
        >
          <Indicator testId="min-indicator" style={minIndicatorStyles} />
          <IndicatorFill testId="fill-indicator" style={indicatorFillStyles} />
          <Indicator testId="max-indicator" style={maxIndicatorStyles} />
        </Box>
      </Box>
      <ScaleLabel {...salaryRangeChartData.xAxisLabels} />
    </>
  );
};
