
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKgiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IuC4o+C4p+C4oeC5gOC4ieC4nuC4suC4sOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5guC4nuC4quC4leC5jOC4muC4meC5gOC4p+C5h+C4muC5hOC4i+C4leC5jOC5g+C4q+C4oeC5iOC4guC4reC4h+C5gOC4o+C4suC4leC4seC5ieC4h+C5geC4leC5iCB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKgiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IuC4o+C4p+C4oeC5gOC4ieC4nuC4suC4sOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5guC4nuC4quC4leC5jOC4muC4meC5gOC4p+C5h+C4muC5hOC4i+C4leC5jOC5g+C4q+C4oeC5iOC4guC4reC4h+C5gOC4o+C4suC4leC4seC5ieC4h+C5geC4leC5iCB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJUYW5nZ2FsIHBvc3RpbmciLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IkhhbnlhIG1lbnllcnRha2FuIGlrbGFuIHlhbmcgZGlwYXNhbmcgZGkgc2l0dXMgd2ViIGJhcnUga2FtaSBzZWphayB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJUYW5nZ2FsIHBvc3RpbmciLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IkhhbnlhIG1lbnllcnRha2FuIGlrbGFuIHlhbmcgZGlwYXNhbmcgZGkgc2l0dXMgd2ViIGJhcnUga2FtaSBzZWphayB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKgiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IuC4o+C4p+C4oeC5gOC4ieC4nuC4suC4sOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5guC4nuC4quC4leC5jOC4muC4meC5gOC4p+C5h+C4muC5hOC4i+C4leC5jOC5g+C4q+C4oeC5iOC4guC4reC4h+C5gOC4o+C4suC4leC4seC5ieC4h+C5geC4leC5iCB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKgiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IuC4o+C4p+C4oeC5gOC4ieC4nuC4suC4sOC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4l+C4teC5iOC5guC4nuC4quC4leC5jOC4muC4meC5gOC4p+C5h+C4muC5hOC4i+C4leC5jOC5g+C4q+C4oeC5iOC4guC4reC4h+C5gOC4o+C4suC4leC4seC5ieC4h+C5geC4leC5iCB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJUYW5nZ2FsIHBvc3RpbmciLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IkhhbnlhIG1lbnllcnRha2FuIGlrbGFuIHlhbmcgZGlwYXNhbmcgZGkgc2l0dXMgd2ViIGJhcnUga2FtaSBzZWphayB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJUYW5nZ2FsIHBvc3RpbmciLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6IkhhbnlhIG1lbnllcnRha2FuIGlrbGFuIHlhbmcgZGlwYXNhbmcgZGkgc2l0dXMgd2ViIGJhcnUga2FtaSBzZWphayB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJQb3N0aW5nIGRhdGUiLCJMYXVuY2ggZGF0ZSBub3RpY2UgbWVzc2FnZSI6Ik9ubHkgaW5jbHVkZXMgYWRzIHBvc3RlZCBvbiBvdXIgbmV3IHdlYnNpdGUgZnJvbSB7bGF1bmNoRGF0ZX0ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJbxqTDtsO2w7bFoeG5r8Osw6zDrOC4geC4teC5icSjIMaMxIPEg8SD4bmv4bq94bq94bq9XSIsIkxhdW5jaCBkYXRlIG5vdGljZSBtZXNzYWdlIjoiW8OW4LiB4Li14LmJxprDvcO9w70gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9xaEgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOC4geC4teC5ieG6veG6veG6vcW1IMW14bq94bq94bq9w5/FocOsw6zDrOG5r+G6veG6veG6vSDGksWZw7bDtsO2bcyCIHtsYXVuY2hEYXRlfV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQb3N0aW5nIGRhdGUiOiJbxqTDtsO2w7bFoeG5r8Osw6zDrOC4geC4teC5icSjIMaMxIPEg8SD4bmv4bq94bq94bq9XSIsIkxhdW5jaCBkYXRlIG5vdGljZSBtZXNzYWdlIjoiW8OW4LiB4Li14LmJxprDvcO9w70gw6zDrMOs4LiB4Li14LmJw6fGmseax5rHmsaM4bq94bq94bq9xaEgxIPEg8SDxozFoSDGpcO2w7bDtsWh4bmv4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIMO2w7bDtseax5rHmsWZIOC4geC4teC5ieG6veG6veG6vcW1IMW14bq94bq94bq9w5/FocOsw6zDrOG5r+G6veG6veG6vSDGksWZw7bDtsO2bcyCIHtsYXVuY2hEYXRlfV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    