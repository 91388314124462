import { RoundedCard, type RoundedCardProps } from '../RoundedCard/RoundedCard';

export const PrimarySection = ({
  children,
  ...otherProps
}: RoundedCardProps) => (
  <RoundedCard data-id="primitive-primary-section" {...otherProps}>
    {children}
  </RoundedCard>
);
