import { useTranslations } from '@vocab/react';
import { Notice, Text } from 'braid-design-system';

import translations from './.vocab';

export const WidgetErrorNvl = () => {
  const { t } = useTranslations(translations);

  return (
    <Notice tone="critical">
      <Text>
        {t(
          "We can't load this right now. Refresh the page or check back later.",
        )}
      </Text>
    </Notice>
  );
};
