import { Box, Text } from 'braid-design-system';
import classNames from 'classnames';
import { type MouseEvent, useEffect, useRef, useState } from 'react';

import * as styles from './verticalTabBar.css';

export interface VerticalTabBarProps<T> {
  activeTab: T;
  tabs: Array<{
    id: T;
    label: string;
  }>;
  onTabButtonClicked?: (e: MouseEvent<HTMLButtonElement>) => void;
  onTabButtonChanged?: (tab: T) => void;
}

export const VerticalTabBar = <T,>({
  activeTab: initialActiveTab,
  tabs,
  onTabButtonClicked,
  onTabButtonChanged,
}: VerticalTabBarProps<T>) => {
  const [activeTab, setActiveTab] = useState<T>(initialActiveTab);
  const [currentTab, setCurrentTab] = useState<T>(initialActiveTab);
  const previousTabRef = useRef<T | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onTabButtonClickedEvent = (e: MouseEvent<HTMLButtonElement>) => {
    const currentTarget = e.currentTarget as HTMLButtonElement;
    const tab = currentTarget.dataset.tab as T | undefined;
    if (tab && tabs.find((t) => t.id === tab)) {
      const isNewTab = activeTab !== tab;
      setActiveTab(tab);
      if (onTabButtonChanged && isNewTab) {
        onTabButtonChanged(tab);
      }
    }
    if (onTabButtonClicked) {
      onTabButtonClicked(e);
    }
  };

  const activeTabIndex = tabs.findIndex((t) => t.id === activeTab);
  const currentTabIndex = tabs.findIndex((t) => t.id === currentTab);
  const previousTabIndex = tabs.findIndex(
    (t) => t.id === previousTabRef.current,
  );
  const direction = activeTabIndex > previousTabIndex ? 'bottom' : 'top';
  const transformOrigin = direction === 'bottom' ? 'top' : 'bottom';

  useEffect(() => {
    previousTabRef.current = currentTab;
  }, [currentTab]);

  useEffect(() => {
    if (currentTabIndex < activeTabIndex) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setCurrentTab(tabs[currentTabIndex + 1].id);
      }, 150);
    } else if (currentTabIndex > activeTabIndex) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setCurrentTab(tabs[currentTabIndex - 1].id);
      }, 150);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [tabs, currentTabIndex, activeTabIndex]);

  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [initialActiveTab]);

  return (
    <Box className={styles.verticalTabBar}>
      {tabs.map((tab, i) => {
        const isCurrentTab = currentTab === tab.id;
        const isActiveTab = activeTab === tab.id;
        const isTransformingTab =
          i >= currentTabIndex - 1 && i <= currentTabIndex + 1;
        return (
          <Box
            component="button"
            cursor="pointer"
            outline="none"
            position="relative"
            className={classNames(styles.tabButton)}
            data={{
              id: `ui-tab-button-bar-button-${tab.id}`,
              tab: tab.id as unknown as string | number,
            }}
            key={`ui-tab-button-bar-button-${tab.id}`}
            tabIndex={0}
            type="button"
            onClick={onTabButtonClickedEvent}
          >
            <Text
              align="left"
              weight={isActiveTab ? 'strong' : 'regular'}
              tone={isActiveTab ? 'neutral' : 'secondary'}
            >
              <span className={styles.tabButtonText}>{tab.label}</span>
            </Text>
            <Box
              className={styles.tabIndicator}
              component="span"
              style={{
                opacity: isTransformingTab ? 1 : 0,
                transform: `scale(1, ${
                  isCurrentTab ? 1 : 0
                }) translate(0, -50%)`,
                transformOrigin: isCurrentTab ? transformOrigin : direction,
                transitionDuration:
                  isTransformingTab && isActiveTab ? '150ms' : '300ms',
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
