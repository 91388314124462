import { Box, Stack } from 'braid-design-system';
import type React from 'react';

import { AdTypeTrends } from '../../widgets/AdTypeTrends/AdTypeTrends';
import { AdTypeTrendsErrorBoundary } from '../../widgets/AdTypeTrends/AdTypeTrendsErrorBoundary';
import { ClassificationsAndUsersTrends } from '../../widgets/ClassificationsAndUsersTrends/ClassificationsAndUsersTrends';
import { ClassificationsAndUsersTrendsErrorBoundary } from '../../widgets/ClassificationsAndUsersTrends/ClassificationsAndUsersTrendsErrorBoundary';

export const TrendsTab = () => (
  <Box display="flex" style={{ gap: 20 }}>
    <Box width="full" style={{ maxWidth: '50%' }}>
      <AdTypeTrendsErrorBoundary>
        <AdTypeTrends />
      </AdTypeTrendsErrorBoundary>
    </Box>
    <Box width="full" style={{ maxWidth: '50%' }}>
      <ClassificationsAndUsersTrendsErrorBoundary>
        <ClassificationsAndUsersTrends />
      </ClassificationsAndUsersTrendsErrorBoundary>
    </Box>
  </Box>
);

export const TrendsTabMobile = () => (
  <Stack space="large">
    <Box width="full">
      <AdTypeTrendsErrorBoundary>
        <AdTypeTrends />
      </AdTypeTrendsErrorBoundary>
    </Box>
    <Box width="full">
      <ClassificationsAndUsersTrendsErrorBoundary>
        <ClassificationsAndUsersTrends />
      </ClassificationsAndUsersTrendsErrorBoundary>
    </Box>
  </Stack>
);
