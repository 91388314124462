export const PerformanceIcon = () => (
  <svg fill="currentColor" width="22" height="22">
    <g>
      <path
        d="M16,22c-1.3,0-1.5-0.8-2.5-9.9c-0.2-1.6-0.4-3.5-0.6-5.1c-0.2,0.9-0.3,1.8-0.4,2.6C11.6,15.6,11.3,17,10,17
      c-1.3,0-1.7-1.6-2.5-4.8c-0.2-0.8-0.5-1.9-0.7-2.6c-0.2,0.3-0.3,0.6-0.4,0.8C5.7,11.8,5.1,13,4,13H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h0.8
      c0.2-0.3,0.6-1,0.8-1.4C5.3,8.2,5.9,7,7,7c1.3,0,1.7,1.6,2.5,4.8c0.1,0.5,0.3,1,0.4,1.5c0.2-1.2,0.5-2.8,0.6-3.9
      C11.4,3.4,11.7,2,13,2c1.3,0,1.5,0.8,2.5,9.9c0.2,1.9,0.5,4.1,0.7,5.8c0.1-0.5,0.2-1,0.3-1.5c0.8-3.6,1.2-5.3,2.5-5.3h2
      c0.6,0,1,0.4,1,1s-0.4,1-1,1h-1.6c-0.3,0.7-0.7,2.5-1,3.7C17.7,20.3,17.3,22,16,22z"
      />
    </g>
  </svg>
);
