import { type Badge, Box, Text } from 'braid-design-system';
import type { ComponentProps, PropsWithChildren } from 'react';

type WrapBadgeProps = PropsWithChildren<{
  id?: string;
  tone?: ComponentProps<typeof Badge>['tone'];
  weight?: ComponentProps<typeof Badge>['weight'];
}>;

const lightModeBackgroundForTone = {
  positive: 'positiveLight',
  critical: 'criticalLight',
  info: 'infoLight',
  promote: 'promoteLight',
  neutral: 'neutralLight',
  caution: 'cautionLight',
} as const;

const WrapBadge = ({
  id = 'wrap-badge',
  tone = 'info',
  weight = 'regular',
  children,
}: WrapBadgeProps) => (
  <Box
    data={{ testid: id }}
    background={weight === 'strong' ? tone : lightModeBackgroundForTone[tone]}
    display="inlineBlock"
    paddingX="xsmall"
    paddingY="xxsmall"
    borderRadius="large"
  >
    <Text size="xsmall" weight="medium" maxLines={2}>
      {children}
    </Text>
  </Box>
);

export { WrapBadge, type WrapBadgeProps };
