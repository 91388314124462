import { useTranslations } from '@vocab/react';
import { Box, Dropdown, Inline, Stack, Text } from 'braid-design-system';
import { useState } from 'react';

import { useMobileAndTabletView } from '../../hooks/useMobileAndTabletView';
import { useTabletOnlyView } from '../../hooks/useTabletOnlyView';
import type {
  CandidateLabel,
  PredictionDayOption,
} from '../../types/PerformancePrediction';
import { formatNumber } from '../../utils/number';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { ClickableTooltip } from '../ClickableTooltip/ClickableTooltip';
import { Medium } from '../Medium/Medium';

import translations from './.vocab';

export interface PredictionProps {
  title: CandidateLabel | null;
  predictionOptions: PredictionDayOption[] | null;
}

interface DropdownOptions {
  text: string;
  prediction: number | null;
  value: string;
}

export const PredictionSection = ({
  title,
  predictionOptions,
}: PredictionProps) => {
  const { t } = useTranslations(translations);
  const [optionValue, setOptionValue] = useState(
    predictionOptions?.[0]?.text ?? '',
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isMobileAndTablet = useMobileAndTabletView();

  const isTabletOnly = useTabletOnlyView();

  const options: DropdownOptions[] =
    predictionOptions?.map((option) => {
      if (option.text === 'WHEN_AD_EXPIRES') {
        return {
          text: t('When the ad expires'),
          value: option.text,
          prediction: option.prediction,
        };
      }
      const days = option.text.replace('_DAYS_FROM_NOW', '');
      return {
        text: t('X day(s) from now', { days: Number(days) }),
        value: option.text,
        prediction: option.prediction,
      };
    }) ?? [];

  const onPredictionDayDropdownChanged = (
    event: React.FormEvent<HTMLSelectElement>,
  ) => {
    const value = event.currentTarget.value;
    if (!isDropdownOpen) {
      trackEvent('prediction_day_pressed', {
        objectInteraction: 'apr-prediction-day',
      });
    }
    setIsDropdownOpen(false);
    setOptionValue(value);
  };

  const getValue = (value: string): number =>
    options?.find((data) => data.value === value)?.prediction ?? 0;

  const PredictionDropdown = () => (
    <Dropdown
      id="ui-prediction-section-day-selector"
      data={{ id: 'ui-prediction-section-day-selector' }}
      label=""
      placeholder={t('- Please select -')}
      onChange={onPredictionDayDropdownChanged}
      value={optionValue}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </Dropdown>
  );

  return (
    <Box
      style={{ paddingLeft: isMobileAndTablet ? '0px' : '40px' }}
      data={{ id: 'ui-prediction-section' }}
      zIndex={1}
    >
      {options.length !== 0 ? (
        <Stack space="small">
          <Inline space="xsmall" alignY="center" align="left">
            <Text size="standard" weight="strong" maxLines={1}>
              {t('Prediction')}
            </Text>
            <ClickableTooltip
              id="prediction-tooltip"
              title={t('Prediction')}
              contents={
                <Text size="standard">
                  {t('Prediction Based On Past Performance')}
                </Text>
              }
            />
          </Inline>
          {isTabletOnly ? (
            <>
              <Inline space="medium" alignY="center">
                <Box style={{ width: '35%' }} zIndex="dropdown">
                  <PredictionDropdown />
                </Box>
                <Text>
                  {t(
                    title === 'CANDIDATES'
                      ? 'Ad Candidates Prediction'
                      : 'Ad Apps Prediction',
                    {
                      Medium: (value) => <Medium>{value}</Medium>,
                      futureApplications: getValue(optionValue),
                      futureApplicationsText: formatNumber(
                        getValue(optionValue),
                      ),
                    },
                  )}
                </Text>
              </Inline>
            </>
          ) : (
            <>
              <Box style={{ width: '65%' }} zIndex="dropdown">
                <PredictionDropdown />
              </Box>
              <Text>
                {t(
                  title === 'CANDIDATES'
                    ? 'Ad Candidates Prediction'
                    : 'Ad Apps Prediction',
                  {
                    Medium: (value) => <Medium>{value}</Medium>,
                    futureApplications: getValue(optionValue),
                    futureApplicationsText: formatNumber(getValue(optionValue)),
                  },
                )}
              </Text>
            </>
          )}
        </Stack>
      ) : null}
    </Box>
  );
};
