import { useTranslations } from '@vocab/react';

import { GenericTitleContainer } from '../../components/GenericTitleContainer/GenericTitleContainer';
import { RoundedCard } from '../../components/RoundedCard/RoundedCard';
import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';

import translations from './.vocab';

export const SimilarAdsFallback = () => {
  const { t } = useTranslations(translations);

  return (
    <RoundedCard>
      <GenericTitleContainer title={t('Similar ads with the most candidates')}>
        <WidgetErrorNvl />
      </GenericTitleContainer>
    </RoundedCard>
  );
};
