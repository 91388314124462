
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4Liq4Liz4Lir4Lij4Lix4Lia4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4Liq4Liz4Lir4Lij4Lix4Lia4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwIHVudHVrIGxvd29uZ2FuIGtlcmphIEFuZGEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwIHVudHVrIGxvd29uZ2FuIGtlcmphIEFuZGEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4Liq4Liz4Lir4Lij4Lix4Lia4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoi4LmA4LiE4Lil4LmH4LiU4Lil4Lix4Lia4Liq4Liz4Lir4Lij4Lix4Lia4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiC4Lit4LiH4LiE4Li44LiTIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwIHVudHVrIGxvd29uZ2FuIGtlcmphIEFuZGEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwIHVudHVrIGxvd29uZ2FuIGtlcmphIEFuZGEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiVGlwcyBmb3IgeW91ciBhZCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiW+G5rsOsw6zDrMalxaEgxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjF0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXBzIGZvciB5b3VyIGFkIjoiW+G5rsOsw6zDrMalxaEgxpLDtsO2w7bFmSDDvcO9w73DtsO2w7bHmseax5rFmSDEg8SDxIPGjF0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    