
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoi4LmE4Lih4LmI4Lih4Li14LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoi4LmE4Lih4LmI4Lih4Li14LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiVGlkYWsgYWRhIHNhcmFuIHVudHVrIG1lbmluZ2thdGthbiBrdWFsaXRhcyBsb3dvbmdhbiBrZXJqYSBpbmkuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiVGlkYWsgYWRhIHNhcmFuIHVudHVrIG1lbmluZ2thdGthbiBrdWFsaXRhcyBsb3dvbmdhbiBrZXJqYSBpbmkuIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoi4LmE4Lih4LmI4Lih4Li14LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoi4LmE4Lih4LmI4Lih4Li14LiE4Liz4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiB4Liy4Lij4Lib4Lij4Lix4Lia4Lib4Lij4Li44LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZ4LiZ4Li14LmJ4LmD4Lir4LmJ4LiU4Li14LiC4Li24LmJ4LiZIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiVGlkYWsgYWRhIHNhcmFuIHVudHVrIG1lbmluZ2thdGthbiBrdWFsaXRhcyBsb3dvbmdhbiBrZXJqYSBpbmkuIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiVGlkYWsgYWRhIHNhcmFuIHVudHVrIG1lbmluZ2thdGthbiBrdWFsaXRhcyBsb3dvbmdhbiBrZXJqYSBpbmkuIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiV2UgZG9uJ3QgaGF2ZSBhbnkgc3VnZ2VzdGlvbnMgdG8gaW1wcm92ZSB0aGlzIGFkLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiW8W04bq94bq94bq9IMaMw7bDtsO24LiB4Li14LmJJ+G5ryDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SD4LiB4Li14LmJw73DvcO9IMWhx5rHmseaxKPEo+G6veG6veG6vcWh4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0g4bmv4bipw6zDrMOsxaEgxIPEg8SDxowuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBkb24ndCBoYXZlIGFueSBzdWdnZXN0aW9ucyB0byBpbXByb3ZlIHRoaXMgYWQuIjoiW8W04bq94bq94bq9IMaMw7bDtsO24LiB4Li14LmJJ+G5ryDhuKnEg8SDxIPhub3hur3hur3hur0gxIPEg8SD4LiB4Li14LmJw73DvcO9IMWhx5rHmseaxKPEo+G6veG6veG6vcWh4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaEg4bmvw7bDtsO2IMOsw6zDrG3MgsalxZnDtsO2w7bhub3hur3hur3hur0g4bmv4bipw6zDrMOsxaEgxIPEg8SDxowuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    