import "src/components/JobAdSummary/jobAdSummary.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/JobAdSummary/jobAdSummary.css.ts.vanilla.css\",\"source\":\"Ll8xbnExNTNzMCB7CiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHZhcigtLWV6dG84N3QpOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1lenRvODd0KTsKICBtYXgtd2lkdGg6IDcyMHB4Owp9Ci5fMW5xMTUzczEgewogIGJvcmRlci1sZWZ0OiA0cHggc29saWQgdmFyKC0tZXp0bzg3MWYpOwp9Ci5fMW5xMTUzczIgewogIGJhY2tncm91bmQ6ICNmYmZiZmI7Cn0KLl8xbnExNTNzNCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMW5xMTUzczUgewogIG1hcmdpbi1yaWdodDogNzBweDsKICBmb250LXdlaWdodDogNzAwOwp9Ci5fMW5xMTUzczYgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKICByaWdodDogMDsKICB0b3A6IC04cHg7Cn0KLl8xbnExNTNzOCB7CiAgcG9zaXRpb246IHJlbGF0aXZlOwp9Ci5fMW5xMTUzczkgewogIGhlaWdodDogNDhweDsKfQouXzFucTE1M3NiIHsKICBkaXNwbGF5OiBibG9jazsKICB3aWR0aDogMTAwcHg7CiAgaGVpZ2h0OiBhdXRvOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc0MHB4KSB7CiAgLl8xbnExNTNzMSB7CiAgICBib3JkZXItcmlnaHQ6IDFweCBzb2xpZCB2YXIoLS1lenRvODd0KTsKICB9CiAgLl8xbnExNTNzMiB7CiAgICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHZhcigtLWV6dG84N3QpOwogICAgYm9yZGVyLXJpZ2h0OiAxcHggc29saWQgdmFyKC0tZXp0bzg3dCk7CiAgfQogIC5fMW5xMTUzczUgewogICAgZm9udC1zaXplOiAyMnB4OwogIH0KICAuXzFucTE1M3M2IHsKICAgIHRvcDogLTEwcHg7CiAgfQogIC5fMW5xMTUzczcgewogICAgcGFkZGluZy1yaWdodDogMTcwcHg7CiAgfQogIC5fMW5xMTUzc2EgewogICAgcG9zaXRpb246IGFic29sdXRlOwogICAgcmlnaHQ6IDA7CiAgICBib3R0b206IDA7CiAgfQp9\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsPlaceholderStyle = '_1nq153s9';
export var bottomWrapperStyle = '_1nq153s8';
export var headingRightLabelStyle = '_1nq153s6';
export var headingStyle = '_1nq153s5';
export var headingWrapperStyle = '_1nq153s4';
export var hideOnMobileStyle = '_1nq153s3';
export var jobAdSummaryCardStyle = '_1nq153s0';
export var jobTextStyle = '_1nq153s7';
export var logoStyle = '_1nq153sb';
export var logoWrapperStyle = '_1nq153sa';
export var premiumCardStyle = '_1nq153s1';
export var standOutCardStyle = '_1nq153s2';