import { type ApolloError, useQuery } from '@apollo/client';

import { candidateDemandQuery } from '../queries/candidateDemandQuery';
import type { CandidateDemandResponse } from '../types/MarketInsight';

interface CandidateDemandQueryParams {
  input: {
    jobId: string;
  };
}

export const useCandidateDemandData = ({
  jobId,
}: {
  jobId: string;
}): {
  data: CandidateDemandResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { data, loading, error, previousData } = useQuery<
    { analyticsCandidateDemand: CandidateDemandResponse },
    CandidateDemandQueryParams
  >(candidateDemandQuery, {
    variables: {
      input: {
        jobId,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsCandidateDemand,
    isLoading: loading,
    error,
  };
};
