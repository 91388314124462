import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { ClassificationsUsersContainer } from './ClassificationsAndUsersTrends';

export interface ClassificationsAndUsersTrendsErrorBoundaryProps {
  children: ReactNode;
}

export const ClassificationsAndUsersTrendsErrorBoundary = ({
  children,
}: ClassificationsAndUsersTrendsErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <ClassificationsUsersContainer>
        <WidgetError />
      </ClassificationsUsersContainer>
    }
  >
    {children}
  </ErrorBoundary>
);
