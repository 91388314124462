import type { BudgetOption } from '../../../types/AdUsageFiltersResponse';
import { isDateBefore, isDateEqual } from '../../../utils/date';

export const buildBudgetFilterData = (
  data: BudgetOption[],
  t: (value: string) => string,
) =>
  data
    .map((budgetOption) => ({
      ...budgetOption,
      value:
        budgetOption.key === 'Other'
          ? t('Purchased without an Ad Budget')
          : budgetOption.data.type,
    }))
    .sort((a, b) => {
      // always sort Other budgets last
      if (a.key === 'Other' || a.data.expiryTimestampUTC === null) {
        return 1;
      }
      if (b.key === 'Other' || b.data.expiryTimestampUTC === null) {
        return -1;
      }
      // sort by expiry date
      // if expiry date is the same, ignore and sort by next criteria
      if (
        !isDateEqual(
          new Date(a.data.expiryTimestampUTC),
          new Date(b.data.expiryTimestampUTC),
        )
      ) {
        // sort by expiry date
        return isDateBefore(
          new Date(a.data.expiryTimestampUTC),
          new Date(b.data.expiryTimestampUTC),
        )
          ? 1
          : -1;
      }
      // sort by type alphabetically
      if (a.data.type !== b.data.type) {
        return a.data.type.localeCompare(b.data.type);
      }
      // sort by contract no alphabetically
      return a.key.localeCompare(b.key);
    });
