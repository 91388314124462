import 'braid-design-system/reset';

import { useIsNVLEnabled } from '@seek/uiv-banner';
import { VocabProvider } from '@vocab/react';
import { Box, BraidProvider, Loader, Page } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';

import { AdCentreFooter } from '../components/AdCentreFooter/AdCentreFooter';
import { Header } from '../components/Header/Header';
import type { Config } from '../config';

import { Link } from './Link';

export interface AppProps {
  config?: Config;
}

export const AppLoader = ({ config }: AppProps) => {
  const isNVLEnabled = useIsNVLEnabled(false);

  return (
    <BraidProvider theme={isNVLEnabled ? seekJobs : apac} linkComponent={Link}>
      <VocabProvider language={config?.locale ?? 'en'}>
        <Page footerPosition="belowFold" footer={<AdCentreFooter />}>
          <Header hirerId="" />
          <Box display="flex" justifyContent="center" padding="large">
            <Loader delayVisibility />
          </Box>
        </Page>
      </VocabProvider>
    </BraidProvider>
  );
};
