import { makeLinkComponent } from 'braid-design-system';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Link = makeLinkComponent(({ href, ...restProps }, ref) =>
  // This changes here is to make sure that when clicking on the other sub tab like Talent Search Usage, it triggers a full page reload
  href.startsWith('/analytics/ad-usage') ||
  href.startsWith('/analytics/ad-performance') ? (
    <ReactRouterLink ref={ref} to={href} {...restProps} />
  ) : (
    <a ref={ref} href={href} {...restProps} />
  ),
);
