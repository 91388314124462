import { type ApolloError, useQuery } from '@apollo/client';

import { childAccountBannerQuery } from '../queries/childAccountBannerQuery';
import type { ChildAccountBannerResponse } from '../types/ChildAccountBannerResponse';

interface queryResponse {
  analyticsChildAccountBanner: {
    isChild: boolean;
  };
}

export const useChildAccountBannerData = (): {
  data: ChildAccountBannerResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { loading, data, error, previousData } = useQuery<queryResponse>(
    childAccountBannerQuery,
    {
      errorPolicy: 'all',
    },
  );

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsChildAccountBanner,
    isLoading: loading,
    error,
  };
};
