import type { ReactNode } from 'react';

import * as styleRefs from './Medium.css';

export interface MediumProps {
  children: ReactNode;
  id?: string;
}

export const Medium = ({ children, id }: MediumProps) => (
  <strong className={styleRefs.mediumWeight} id={id}>
    {children}
  </strong>
);
