import { useTranslations } from '@vocab/react';
import { Box, IconFilter, Inline, Stack, Text } from 'braid-design-system';
import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import translations from './.vocab';

export interface FiltersErrorBoundaryProps {
  children: ReactNode;
}

export const FiltersErrorBoundary = ({
  children,
}: FiltersErrorBoundaryProps) => {
  const { t } = useTranslations(translations);

  return (
    <ErrorBoundary
      fallback={
        <Box background="surface" borderRadius="standard" padding="large">
          <Stack space="large">
            <Inline space="xsmall" alignY="center">
              <IconFilter />
              <Text weight="strong">{t('Filters')}</Text>
            </Inline>
            <WidgetError />
          </Stack>
        </Box>
      }
    >
      {children}
    </ErrorBoundary>
  );
};
