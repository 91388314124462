export const TrendTabIcon = () => (
  <svg fill="currentColor" width="19.2" height="19.2" viewBox="0 0 22 22">
    <g>
      <path d="M6,18c-0.6,0-1-0.4-1-1v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6C7,17.6,6.6,18,6,18z" />
      <path d="M10,18c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v12C11,17.6,10.6,18,10,18z" />
      <path d="M14,18c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1s1,0.4,1,1v9C15,17.6,14.6,18,14,18z" />
      <path d="M18,18c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1s1,0.4,1,1v14C19,17.6,18.6,18,18,18z" />
      <path d="M21,22H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h18c0.6,0,1,0.4,1,1S21.6,22,21,22z" />
    </g>
  </svg>
);
