import { gql } from '@apollo/client';

export const adTypeTrendQuery = gql`
  query GetAdTypeTrend($input: AdUsageAdTypeTrendInput!) {
    analyticsAdTypeTrend(input: $input) {
      startDate
      endDate
      productDisplayNameDaily {
        items {
          key
          count
        }
        day
      }
      productDisplayNameMonthly {
        items {
          key
          count
        }
        month
      }
      totalProductDisplayNames {
        key
        count
      }
    }
  }
`;
