import { Box } from 'braid-design-system';

import { AdPerformancePageFrame } from '../../components/AdPerformancePageFrame/AdPerformancePageFrame';
import { PageLoadError } from '../../components/PageLoadError/PageLoadError';

export interface AdPerformanceReportFallbackProps {
  children?: ReactNodeNoStrings;
}

export const AdPerformanceReportFallback = ({
  children = <PageLoadError />,
}: AdPerformanceReportFallbackProps) => (
  <AdPerformancePageFrame>
    <Box marginX={['none', 'large', 'large']}>{children}</Box>
  </AdPerformancePageFrame>
);
