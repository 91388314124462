import { gql } from '@apollo/client';

export const classificationsAndUsersTrendQuery = gql`
  query GetClassificationsAndUsersTrend(
    $input: AdUsageTrendClassificationsAndUsersInput!
  ) {
    analyticsClassificationsAndUsersTrend(input: $input) {
      classifications {
        classificationName
        totalAds
        percentage
      }
      users {
        userFullname
        totalAds
        percentage
      }
    }
  }
`;
