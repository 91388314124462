import { Box } from 'braid-design-system';

import { useResponsiveBreakpoints } from '../../hooks/useResponsiveBreakpoints';
import { MarketPerformance } from '../../widgets/MarketPerformance/MarketPerformance';
import { MarketPerformanceErrorBoundary } from '../../widgets/MarketPerformance/MarketPerformanceErrorBoundary';
import { MostAdsByPerformance } from '../../widgets/MostAdsByPerformance/MostAdsByPerformance';
import { MostAdsByPerformanceErrorBoundary } from '../../widgets/MostAdsByPerformance/MostAdsByPerformanceErrorBoundary';

import * as styles from './PerformanceTab.css';

export const PerformanceTab = () => {
  const { isMobileOrTablet } = useResponsiveBreakpoints();

  return (
    <Box
      overflow={isMobileOrTablet ? 'scroll' : undefined}
      paddingBottom={{ mobile: 'small', tablet: 'medium', desktop: 'none' }}
      style={{ overflowY: isMobileOrTablet ? 'hidden' : undefined }}
    >
      <Box display="flex" flexWrap="nowrap" gap="medium">
        <Box
          className={styles.column}
          borderRadius="standard"
          boxShadow="borderNeutralLight"
          padding="medium"
        >
          <MarketPerformanceErrorBoundary>
            <MarketPerformance />
          </MarketPerformanceErrorBoundary>
        </Box>
        <Box
          className={styles.column}
          borderRadius="standard"
          boxShadow="borderNeutralLight"
          padding="medium"
        >
          <MostAdsByPerformanceErrorBoundary>
            <MostAdsByPerformance />
          </MostAdsByPerformanceErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};
