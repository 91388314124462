import { useTranslations } from '@vocab/react';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';

import { useChartSize } from '../../hooks/useChartSize';
import { useChartTheme } from '../../hooks/useChartTheme';
import { useTextStyles } from '../../hooks/useTextStyles';
import type { PerformancePredictionChartData } from '../../types/PerformancePrediction';

import localTranslations from './.vocab';
import { createApplicationPredictionChartRenderer } from './ApplicationPredictionChart.renderer';
import type { PerformancePredictionChartRenderer } from './ApplicationPredictionChart.types';

export function useApplicationPredictionChartRenderer({
  containerRef,
  data,
  height,
  width: initialWidth,
  widthBehaviour = 'dynamic',
}: {
  containerRef: HTMLDivElement | null;
  data: PerformancePredictionChartData;
  height: number;
  width: number;
  widthBehaviour?: 'fixed' | 'dynamic';
}): PerformancePredictionChartRenderer | null {
  const { t } = useTranslations(localTranslations);
  const theme = useChartTheme();
  const [chartRenderer, setChartRenderer] =
    useState<PerformancePredictionChartRenderer | null>(null);
  const createRendererMemo = useCallback(
    createApplicationPredictionChartRenderer,
    [],
  );
  const textStyles = useTextStyles();

  useLayoutEffect(() => {
    const newChartRenderer = createRendererMemo({
      container: containerRef,
      containerHeight: height,
      containerWidth: initialWidth,
      textStyles,
      theme,
    });
    setChartRenderer(newChartRenderer);
  }, [
    containerRef,
    createRendererMemo,
    height,
    initialWidth,
    textStyles,
    theme,
  ]);

  const { width } = useChartSize({
    containerRef,
    width: initialWidth,
    widthBehaviour,
  });

  // tokenized label
  const chartLabels = useMemo(
    () => ({
      day: (day: number) => t('Day label', { day }),
      edited: (day: number) => t('Day edited label', { day }),
      similarAdsAverage: (average: number) =>
        t('Similar ads average label', { average }),
      yourApplicationStartsPerformance: (applications: number) =>
        t('Your application starts performance', { applications }),
      yourCandidatesPerformance: (applications: number) =>
        t('Your candidates performance', { applications }),
      similarAds: t('Similar ads'),
      prediction: t('Prediction'),
    }),
    [t],
  );

  useLayoutEffect(() => {
    // update xAxis Day label of the first label only
    const resultData = {
      ...data,
      xAxisLabels: data.xAxisLabels.map((xAxisLabel, index) => ({
        ...xAxisLabel,
        label: index === 0 ? t('Day') : xAxisLabel.label,
      })),
    };

    chartRenderer?.update({ data: resultData, width, chartLabels });
  }, [chartRenderer, data, width, chartLabels, t]);

  return chartRenderer;
}
