import { Box, Stack, Text, Columns, Column, List } from 'braid-design-system';
import classNames from 'classnames';
import type { ComponentProps } from 'react';

import * as styles from './standardBox.css';

type BoxProps = ComponentProps<typeof Box>;

export interface StandardBoxProps extends BoxProps {
  accessory?: ReactNodeNoStrings;
  subTitleComponent?: ReactNodeNoStrings;
  subTitleText?: string;
  titleComponent?: ReactNodeNoStrings;
  titleText?: string;
  bulletText?: string[];
  borderTone?: 'positive' | 'negative';
}

export const StandardBox = ({
  accessory,
  subTitleComponent,
  subTitleText,
  titleComponent,
  titleText,
  bulletText,
  borderTone,
  ...otherProps
}: StandardBoxProps) => (
  <Box
    className={classNames({
      [styles.positiveBorder]: borderTone === 'positive',
      [styles.negativeBorder]: borderTone === 'negative',
    })}
    boxShadow="borderNeutralLight"
    data={{ id: 'primitive-standard-box' }}
    padding="medium"
    {...otherProps}
  >
    <Columns space="small" alignY="center">
      <Column>
        <Stack space="medium">
          {titleComponent}
          {titleText ? <Text weight="medium">{titleText}</Text> : undefined}
          {subTitleComponent}
          {subTitleText ? (
            <Text tone="secondary">{subTitleText}</Text>
          ) : undefined}
          {bulletText && bulletText.length > 0 ? (
            <List tone="secondary">
              {bulletText.map((item, index) => (
                <Text key={index}>{item}</Text>
              ))}
            </List>
          ) : undefined}
        </Stack>
      </Column>
      <Column width="content">{accessory}</Column>
    </Columns>
  </Box>
);
