export const ConversionIcon = () => (
  <svg fill="currentColor" width="19.2" height="19.2" viewBox="0 0 22 22">
    <g>
      <path
        d="M12,22c-1.1,0-2-0.8-2.2-1.8l-1.7-9.3c0-0.2-0.2-0.4-0.3-0.6l-3.5-3C3.4,6.4,3.1,5.1,3.5,3.9C4,2.8,5.1,2,6.3,2
      h11.4c1.3,0,2.4,0.8,2.8,1.9c0.4,1.2,0.1,2.5-0.8,3.3l-3.5,3c-0.2,0.1-0.3,0.4-0.3,0.6l-1.7,9.3C14,21.2,13.1,22,12,22z M6.3,4
      C5.7,4,5.4,4.5,5.4,4.6C5.3,4.8,5.2,5.3,5.7,5.8l3.5,3c0.5,0.4,0.9,1.1,1,1.7l1.7,9.3c0,0.2,0.4,0.2,0.4,0l1.7-9.3
      c0.1-0.7,0.5-1.3,1-1.7l3.5-3c0.5-0.4,0.3-1,0.3-1.1C18.6,4.5,18.3,4,17.7,4H6.3z"
      />
    </g>
  </svg>
);
