import { gql } from '@apollo/client';

export const jobAdListQuery = gql`
  query GetJobAdListData($input: JobAdListWidgetInput!) {
    analyticsJobAdList(input: $input) {
      lastUpdated
      count
      hasMultipleCurrencies
      jobAds {
        adRating
        applications
        locationLabel
        groupKeyApac
        hidePrice
        isLatestGroupApac
        isRefresh
        isRepost
        jobId
        jobTitle
        jobCreatedTimestampUTC
        status
        totalNetAmount {
          value
          currency
        }
        otherBudgetAmount {
          value
          currency
        }
        userFullName
        productDisplayName
      }
    }
  }
`;
