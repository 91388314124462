import { createContext, useContext, useState } from 'react';

interface IHasMarketInsightsContext {
  hasMarketInsights: boolean | null;
  updateHasMarketInsights: (hasMarketInsights: boolean) => void;
}

interface IHasMarketInsightsContextProvider {
  children: ReactNodeNoStrings;
}

const defaultContext = {
  hasMarketInsights: null,
  updateHasMarketInsights: () => {},
};

export const HasMarketInsightsContext =
  createContext<IHasMarketInsightsContext>(defaultContext);

export const HasMarketInsightsProvider = ({
  children,
}: IHasMarketInsightsContextProvider) => {
  const [hasMarketInsights, setHasMarketInsights] = useState<boolean | null>(
    defaultContext.hasMarketInsights,
  );

  const updateHasMarketInsights = (updatedHasMarketInsights: boolean) => {
    setHasMarketInsights((currentHasMarketInsights) => {
      const shouldUpdateMarketInsights =
        currentHasMarketInsights === null ||
        (currentHasMarketInsights === false && updatedHasMarketInsights);
      return shouldUpdateMarketInsights
        ? updatedHasMarketInsights
        : currentHasMarketInsights;
    });
  };

  return (
    <HasMarketInsightsContext.Provider
      value={{
        hasMarketInsights,
        updateHasMarketInsights,
      }}
    >
      {children}
    </HasMarketInsightsContext.Provider>
  );
};

export const useHasMarketInsights = () => useContext(HasMarketInsightsContext);
