const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MINUTE = 60000; // 60 * 1000
const MINUTES = MINUTE;
const HOUR = 60 * MINUTES;
const HOURS = HOUR;
const DAY = 24 * HOURS;
const DAYS = DAY;

function minutes(amount: number): number {
  return amount * MINUTES;
}

function hours(amount: number): number {
  return amount * HOURS;
}

function days(amount: number): number {
  return amount * DAYS;
}

function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime());
}

function addDays(d: Date, n: number): Date {
  return new Date(new Date(d).setUTCDate(d.getUTCDate() + n));
}

function addMonths(d: Date, m: number): Date {
  return new Date(new Date(d).setUTCMonth(d.getUTCMonth() + m));
}

function addYears(d: Date, n: number): Date {
  return new Date(new Date(d).setUTCFullYear(d.getUTCFullYear() + n));
}

function setDayOfMonth(d: Date, dayOfMonth: number): Date {
  return new Date(new Date(d).setUTCDate(dayOfMonth));
}

function daysFromDates(d1: Date, d2: Date): number {
  const deltaSeconds = d2.getTime() - d1.getTime();
  return Math.floor(deltaSeconds / (1000 * 3600 * 24));
}

export function daysFromDateStrings(d1: string, d2: string): number {
  return daysFromDates(new Date(d1), new Date(d2));
}

function diffFromNow(date: Date): number {
  const now = Date.now();
  return now.valueOf() - date.valueOf();
}

export function daysAgoFromString(date: string): number {
  const d = new Date(date);
  const today = new Date();
  const deltaSeconds = today.getTime() - d.getTime();
  return Math.floor(deltaSeconds / (1000 * 3600 * 24));
}

export function isoDateFromDate(d: Date) {
  return d.toISOString().split('T')[0];
}

export function isoDateFromString(d: string) {
  if (d === undefined || d.length === 0) {
    return '';
  }
  return isoDateFromDate(new Date(d));
}

export function addedAetOffsetStringFromDate(d: Date) {
  const timezoneOffset = 39600000; // 11 hours * 60 minutes * 60 seconds * 1000 ms
  const target = new Date(d.getTime() + timezoneOffset);
  const monthStr = (target.getUTCMonth() + 1).toString().padStart(2, '0');
  const dayStr = target.getUTCDate().toString().padStart(2, '0');

  return `${target.getUTCFullYear()}-${monthStr}-${dayStr}`;
}

export function prettyDateFromString(date: string): string {
  const d = new Date(date);
  if (!isValidDate(d)) {
    return '';
  }
  return `${d.getUTCDate()} ${months[d.getUTCMonth()]} ${d.getUTCFullYear()}`;
}

export function prettyMonthFromString(date: string): string {
  const d = new Date(date);
  if (!isValidDate(d)) {
    return '';
  }
  return `${months[d.getUTCMonth()]} ${d.getUTCFullYear()}`;
}

const formatDayOfTheMonth = (day: number) => {
  if (day < 4 || day > 20) {
    if (day % 10 === 1) {
      return `${day}st`;
    } else if (day % 10 === 2) {
      return `${day}nd`;
    } else if (day % 10 === 3) {
      return `${day}rd`;
    }
  }

  return `${day}th`;
};

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function prettyDateTimeFromString(date: Date) {
  const ampm = date.getHours() >= 12 ? 'pm' : 'am';
  const hourString = date.getHours() % 12 || 12;
  const minuteString = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

  return `${weekDays[date.getDay()]} the ${formatDayOfTheMonth(
    date.getDate(),
  )} of ${
    fullMonthNames[date.getMonth()]
  } at ${hourString}:${minuteString} ${ampm}`;
}

function shortListingDateFromString(date: string) {
  const diff = diffFromNow(new Date(date));
  let number;

  if (diff < minutes(5.5)) {
    number = 5;
    return `${number}m ago`;
  }

  if (diff < minutes(59.5)) {
    number = Math.round(diff / MINUTE);
    return `${number}m ago`;
  }

  if (diff < hours(23.5)) {
    number = Math.round(diff / HOUR);
    return `${number}h ago`;
  }

  if (diff < days(30.5)) {
    number = Math.round(diff / DAY);
    return `${number}d ago`;
  }

  number = 30;
  return `${number}d+ ago`;
}

function isRecentFromString(date: string): boolean {
  return diffFromNow(new Date(date)) < hours(23.5);
}

const relativeDateFromDate = (baseDate: Date) => (date: Date) => {
  const deltaSeconds = baseDate.getTime() - date.getTime();

  const deltaHours = Math.floor(deltaSeconds / (1000 * 3600));

  if (deltaHours <= 21) {
    return `${deltaHours} hours ago`;
  }

  if (deltaHours <= 35) {
    return '1 day ago';
  }

  const deltaDays = Math.round(deltaSeconds / (1000 * 3600 * 24));
  if (deltaDays <= 25) {
    return `${deltaDays} days ago`;
  }

  if (deltaDays <= 45) {
    return '1 month ago';
  }

  const deltaMonths = Math.round(deltaSeconds / (1000 * 3600 * 24 * 30));
  return `${deltaMonths} months ago`;
};

export default {
  addDays,
  addMonths,
  addYears,
  daysAgoFromString,
  daysFromDateStrings,
  fullMonthNames,
  isoDateFromDate,
  isoDateFromString,
  isRecentFromString,
  prettyDateFromString,
  prettyDateTimeFromString,
  prettyMonthFromString,
  relativeDateFromDate,
  setDayOfMonth,
  shortListingDateFromString,
  addedAetOffsetStringFromDate,
};
