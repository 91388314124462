import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { MostAdsByPerformanceContainer } from './MostAdsByPerformance';

export interface MostAdsByPerformanceErrorBoundaryProps {
  children: ReactNode;
}

export const MostAdsByPerformanceErrorBoundary = ({
  children,
}: MostAdsByPerformanceErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <MostAdsByPerformanceContainer>
        <WidgetError />
      </MostAdsByPerformanceContainer>
    }
  >
    {children}
  </ErrorBoundary>
);
