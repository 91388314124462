const downloadFile = async (content: Blob, filename: string) => {
  const url = window.URL.createObjectURL(content);
  const a = document.createElement('a');
  const contentInText = await content.text();

  a.href = `data:application/csv;charset=UTF-8,%EF%BB%BF${encodeURIComponent(
    contentInText,
  )}`;
  a.download = filename;
  document.body.appendChild(a);

  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export { downloadFile };
