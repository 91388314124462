import {
  Box,
  Button,
  ContentBlock,
  IconCritical,
  Inline,
  Stack,
  Text,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import loadable from 'sku/@loadable/component';

import { useConfig } from '../../App/ConfigContext';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

const STORAGE_KEY = 'hirer-ad-usage-ui:uiv';

export const UivBanner = loadable(() =>
  import('./UivBanner').then((module) => ({
    default: module.Banner,
  })),
);

const useShowPreview = () => {
  const [isPreview, setIsPreview] = useState(false);
  const [uiv, setUiv] = useState<string | undefined>(undefined);

  // useEffect to run client-side only
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryParamUiVersion = searchParams.get('v');
    const sessionStorageUiVersion = safeSessionStorageAccess(() =>
      sessionStorage.getItem(STORAGE_KEY),
    );

    if (!queryParamUiVersion && sessionStorageUiVersion) {
      setIsPreview(true);
      setUiv(sessionStorageUiVersion);
      searchParams.set('v', sessionStorageUiVersion);
      window.location.search = searchParams.toString();
    }

    if (queryParamUiVersion) {
      setIsPreview(true);
      setUiv(queryParamUiVersion);
      safeSessionStorageAccess(() =>
        sessionStorage.setItem(STORAGE_KEY, queryParamUiVersion),
      );
    }
  }, [setIsPreview, setUiv]);

  return [isPreview, uiv] as const;
};

export const Banner = () => {
  const [showPreview, uiv] = useShowPreview();

  const { branch, version } = useConfig();

  if (!showPreview) {
    return;
  }

  return (
    <ErrorBoundary fallback={null}>
      <Stack space="none">
        <Box width="full" background="caution" padding="medium">
          <ContentBlock>
            <Inline space="medium">
              <LabelValueBox emoji="📄" label="UIV" value={uiv} />
              <LabelValueBox emoji="🌲" label="Branch" value={branch} />
              <LabelValueBox emoji="♾️" label="Build number" value={version} />
              <Button
                tone="critical"
                icon={<IconCritical />}
                variant="transparent"
                bleed
                onClick={reset}
              >
                Reset
              </Button>
            </Inline>
          </ContentBlock>
        </Box>
      </Stack>
    </ErrorBoundary>
  );
};

type RemoveItem = () => void;
type SetItem = () => void;
type GetItem = () => string | null;

const safeSessionStorageAccess = (
  localStorageAccessFunction: RemoveItem | SetItem | GetItem,
) => {
  try {
    return localStorageAccessFunction();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error accessing localStorage', { err });
  }
};

const reset = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParamUiVersion = searchParams.get('v');

  if (queryParamUiVersion) {
    searchParams.delete('v');
    window.location.search = searchParams.toString();
    safeSessionStorageAccess(() => sessionStorage.removeItem(STORAGE_KEY));
  }
};

interface LabelValueProps {
  label?: string;
  value?: string;
  emoji?: string;
}

const LabelValueBox = ({ label, emoji, value }: LabelValueProps) => (
  <Inline space="xsmall">
    <Text>{emoji}</Text>
    <Text weight="strong">{label}:</Text>
    <Text>{value}</Text>
  </Inline>
);
