import { differenceInMonths, startOfMonth } from 'date-fns';

import type { EmployerSiteName } from '../../config';
import { LAUNCH_DATES } from '../../featureToggle/config/historicalDataFeature';
import type { MarketInsightMonth } from '../../types/MarketInsight';
import { formatDate } from '../../utils/date';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';
import type { MarketInsightTrendChartData } from '../MarketInsightTrendChart/MarketInsightTrendChart.types';

function getMarketInsightsTrendChartDataFromMarketInsights({
  domain,
  comparisonPeriod,
  currentPeriod,
  hasPreviousYearData,
}: {
  domain: [string, string];
  comparisonPeriod: MarketInsightMonth[];
  currentPeriod: MarketInsightMonth[];
  hasPreviousYearData: boolean;
}): MarketInsightTrendChartData {
  const currentPeriodSize = currentPeriod.length;
  const comparisonPeriodSize = comparisonPeriod.length;
  const allValues = [...currentPeriod, ...comparisonPeriod].map((m) => m.count);

  const minValue = allValues.reduce((a, b) => Math.min(a, b), allValues[0]);
  const maxValue = allValues.reduce((a, b) => Math.max(a, b), allValues[0]);
  const rangePadding = (16 * (maxValue - minValue)) / 100;
  const returnValue: MarketInsightTrendChartData = {
    dataSets: [
      {
        dataPoints: currentPeriod.map((m) => ({
          date: m.calendarMonth,
          value: m.count,
        })),
        tone: 'primary',
      },
    ],
    domain,
    markers: [
      {
        date: currentPeriod[currentPeriodSize - 1].calendarMonth,
        value: currentPeriod[currentPeriodSize - 1].count,
        tone: 'primary',
      },
    ],
    range: [minValue, maxValue + rangePadding],
    xAxisLabels: currentPeriod.map((m) => ({
      label: formatDate({ date: new Date(m.calendarMonth), dateFormat: 'MMM' }),
      tone: 'secondary',
      value: m.calendarMonth,
    })),
    yAxisLabels: [
      {
        label: formatDate({
          date: new Date(currentPeriod[currentPeriodSize - 1].calendarMonth),
          dateFormat: 'yyyy',
        }),
        tone: 'primary',
        value: currentPeriod[0].count,
      },
    ],
  };

  if (hasPreviousYearData) {
    returnValue.dataSets.push({
      dataPoints: comparisonPeriod.map((m, i) => ({
        date: currentPeriod[i].calendarMonth,
        value: m.count,
      })),
      tone: 'secondary',
    });

    returnValue.markers.push({
      date: currentPeriod[currentPeriodSize - 1].calendarMonth,
      value: comparisonPeriod[comparisonPeriodSize - 1].count,
      tone: 'secondary',
    });

    returnValue.yAxisLabels.push({
      label: formatDate({
        date: new Date(comparisonPeriod[currentPeriodSize - 1].calendarMonth),
        dateFormat: 'yyyy',
      }),
      tone: 'secondary',
      value: comparisonPeriod[0].count,
    });
  }

  return returnValue;
}

const getChartDataFromMarketInsights = ({
  comparisonPeriod,
  currentPeriod,
  hasPreviousYearData,
}: {
  comparisonPeriod: MarketInsightMonth[];
  currentPeriod: MarketInsightMonth[];
  hasPreviousYearData: boolean;
}): MarketInsightTrendChartData => {
  const startDate = currentPeriod[0].calendarMonth;
  const endDate = currentPeriod[currentPeriod.length - 1].calendarMonth;

  const domain: [string, string] = [startDate, endDate];

  return getMarketInsightsTrendChartDataFromMarketInsights({
    domain,
    comparisonPeriod,
    currentPeriod,
    hasPreviousYearData,
  });
};

interface MarketInsightsGenericTrend {
  type: 'INCREASED' | 'DECREASED' | 'UNCHANGED';
}

interface MarketInsightsPercentageTrend {
  type: 'PERCENTAGE';
  percentage: string;
  isPositiveTrend: boolean;
}

const mapMarketInsightsToTrendParts = (
  marketInsightsPeriod: MarketInsightMonth[],
): MarketInsightsGenericTrend | MarketInsightsPercentageTrend | null => {
  if (marketInsightsPeriod.length === 0) {
    return null;
  }

  const currentRate =
    marketInsightsPeriod[marketInsightsPeriod.length - 1].count;
  const previousRate = marketInsightsPeriod[0].count;

  if (currentRate < 0 || previousRate < 0) {
    return null;
  }

  if (currentRate > 0 && previousRate === 0) {
    return {
      type: 'INCREASED',
    };
  }

  if (currentRate === 0 && previousRate > 0) {
    return {
      type: 'DECREASED',
    };
  }

  if (currentRate === previousRate) {
    return {
      type: 'UNCHANGED',
    };
  }

  const comparisonRate = ((currentRate - previousRate) / previousRate) * 100;
  const isPositiveComparison = currentRate > previousRate;

  return {
    type: 'PERCENTAGE',
    percentage: formatPercentage(Math.abs(comparisonRate), 0),
    isPositiveTrend: isPositiveComparison,
  };
};

const hasPreviousYearHistoricalData = (site: EmployerSiteName) => {
  if (LAUNCH_DATES[site]) {
    const currentDate = new Date();
    const monthDiff = differenceInMonths(
      startOfMonth(currentDate),
      startOfMonth(new Date(LAUNCH_DATES[site])),
    );

    // show only after 1 year and 5 months
    // (4 months of data needed and 1 month of partial data need to be excluded)
    return monthDiff > 17;
  }

  return true;
};

export {
  getChartDataFromMarketInsights,
  mapMarketInsightsToTrendParts,
  hasPreviousYearHistoricalData,
};
