import {
  Box,
  IconTick,
  Inline,
  IconMinus,
  Stack,
  IconProfile,
} from 'braid-design-system';
import type React from 'react';

import { CircleBadge } from '../CircleBadge/CircleBadge';

import * as styles from './roleRequirementsSkeleton.css';

export const RoleRequirementsSkeleton = () => (
  <Box className={styles.skeletonBackground} padding="medium">
    <Stack space="small">
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <Inline
            space="small"
            alignY="center"
            key={`ui-role-requirements-skeleton-row-${i}`}
          >
            {i < 3 && (
              <CircleBadge circleSize="small" tone="positive">
                <IconTick size="small" />
              </CircleBadge>
            )}
            {i === 3 && (
              <CircleBadge circleSize="small" tone="info">
                <IconMinus size="small" />
              </CircleBadge>
            )}
            <IconProfile tone="secondary" />
            <Box display="flex" justifyContent="center">
              <svg height="24px" width="150px">
                <g className={styles.skeletonNeutral}>
                  <rect
                    fill="#A8A8A8"
                    x="0"
                    y="0"
                    width="69"
                    height="3.5"
                    rx="1.75"
                  />
                  <rect
                    fill="#A8A8A8"
                    x="0"
                    y="10"
                    width="150"
                    height="3.5"
                    rx="1.75"
                  />
                  <rect
                    fill="#A8A8A8"
                    x="0"
                    y="20"
                    width="150"
                    height="3.5"
                    rx="1.75"
                  />
                  <rect
                    fill="#A8A8A8"
                    x="18"
                    y="24.7242857"
                    width="69"
                    height="3.63428571"
                    rx="1.81714286"
                  />
                  <rect
                    x="18.1714286"
                    y="42"
                    width="138.708571"
                    height="3.63428571"
                    rx="1.81714286"
                  />
                </g>
              </svg>
            </Box>
          </Inline>
        ))}
    </Stack>
  </Box>
);
