
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYDguJ7guLTguYjguKHguILguLbguYnguJnguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguKXguJTguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYTguKHguYjguKHguLXguIHguLLguKPguYDguJvguKXguLXguYjguKLguJnguYHguJvguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYDguJ7guLTguYjguKHguILguLbguYnguJnguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguKXguJTguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYTguKHguYjguKHguLXguIHguLLguKPguYDguJvguKXguLXguYjguKLguJnguYHguJvguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJNZW5pbmdrYXQgZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIkRFQ1JFQVNFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6Ik1lbnVydW4gZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlVOQ0hBTkdFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IlRpZGFrIGJlcnViYWggZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlBDIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IGRpYmFuZGluZ2thbiBkZW5nYW4ge2NvbXBhcmlzb25Nb250aH0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJNZW5pbmdrYXQgZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIkRFQ1JFQVNFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6Ik1lbnVydW4gZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlVOQ0hBTkdFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IlRpZGFrIGJlcnViYWggZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlBDIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IGRpYmFuZGluZ2thbiBkZW5nYW4ge2NvbXBhcmlzb25Nb250aH0ifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYDguJ7guLTguYjguKHguILguLbguYnguJnguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguKXguJTguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYTguKHguYjguKHguLXguIHguLLguKPguYDguJvguKXguLXguYjguKLguJnguYHguJvguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYDguJ7guLTguYjguKHguILguLbguYnguJnguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguKXguJTguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiLguYTguKHguYjguKHguLXguIHguLLguKPguYDguJvguKXguLXguYjguKLguJnguYHguJvguKXguIfguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiDguYDguKHguLfguYjguK3guYDguJfguLXguKLguJrguIHguLHguJoge2NvbXBhcmlzb25Nb250aH0ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJNZW5pbmdrYXQgZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIkRFQ1JFQVNFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6Ik1lbnVydW4gZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlVOQ0hBTkdFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IlRpZGFrIGJlcnViYWggZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlBDIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IGRpYmFuZGluZ2thbiBkZW5nYW4ge2NvbXBhcmlzb25Nb250aH0ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJNZW5pbmdrYXQgZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIkRFQ1JFQVNFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6Ik1lbnVydW4gZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlVOQ0hBTkdFRCBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IlRpZGFrIGJlcnViYWggZGliYW5kaW5na2FuIGRlbmdhbiB7Y29tcGFyaXNvbk1vbnRofSIsIlBDIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IGRpYmFuZGluZ2thbiBkZW5nYW4ge2NvbXBhcmlzb25Nb250aH0ifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJJbmNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJERUNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJEZWNyZWFzZWQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJObyBjaGFuZ2UgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iLCJQQyBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSI6IjxUcmVuZD57cGVyY2VudGFnZX08L1RyZW5kPiBjb21wYXJlZCB0byB7Y29tcGFyaXNvbk1vbnRofSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbw4/guIHguLXguYnDp8WZ4bq94bq94bq9xIPEg8SDxaHhur3hur3hur3GjCDDp8O2w7bDtm3MgsalxIPEg8SDxZnhur3hur3hur3GjCDhua/DtsO2w7Yge2NvbXBhcmlzb25Nb250aH1dIiwiREVDUkVBU0VEIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiW+G4iuG6veG6veG6vcOnxZnhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vcaMIMOnw7bDtsO2bcyCxqXEg8SDxIPFmeG6veG6veG6vcaMIOG5r8O2w7bDtiB7Y29tcGFyaXNvbk1vbnRofV0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbw5HDtsO2w7Ygw6fhuKnEg8SDxIPguIHguLXguYnEo+G6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SDxZnhur3hur3hur3GjCDhua/DtsO2w7Yge2NvbXBhcmlzb25Nb250aH1dIiwiUEMgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IMOnw7bDtsO2bcyCxqXEg8SDxIPFmeG6veG6veG6vcaMIOG5r8O2w7bDtiB7Y29tcGFyaXNvbk1vbnRofV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJJTkNSRUFTRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbw4/guIHguLXguYnDp8WZ4bq94bq94bq9xIPEg8SDxaHhur3hur3hur3GjCDDp8O2w7bDtm3MgsalxIPEg8SDxZnhur3hur3hur3GjCDhua/DtsO2w7Yge2NvbXBhcmlzb25Nb250aH1dIiwiREVDUkVBU0VEIGNvbXBhcmVkIHRvIHtjb21wYXJpc29uTW9udGh9IjoiW+G4iuG6veG6veG6vcOnxZnhur3hur3hur3Eg8SDxIPFoeG6veG6veG6vcaMIMOnw7bDtsO2bcyCxqXEg8SDxIPFmeG6veG6veG6vcaMIOG5r8O2w7bDtiB7Y29tcGFyaXNvbk1vbnRofV0iLCJVTkNIQU5HRUQgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbw5HDtsO2w7Ygw6fhuKnEg8SDxIPguIHguLXguYnEo+G6veG6veG6vSDDp8O2w7bDtm3MgsalxIPEg8SDxZnhur3hur3hur3GjCDhua/DtsO2w7Yge2NvbXBhcmlzb25Nb250aH1dIiwiUEMgY29tcGFyZWQgdG8ge2NvbXBhcmlzb25Nb250aH0iOiJbPFRyZW5kPntwZXJjZW50YWdlfTwvVHJlbmQ+IMOnw7bDtsO2bcyCxqXEg8SDxIPFmeG6veG6veG6vcaMIOG5r8O2w7bDtiB7Y29tcGFyaXNvbk1vbnRofV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    