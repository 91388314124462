import { Box, Heading, useColor } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import type React from 'react';

import { TrendIcon } from '../../assets/icons/TrendIcon/TrendIcon';
import { Medium } from '../Medium/Medium';

export interface TrendProps {
  isPositiveTrend: boolean;
  isInlineTrend?: boolean;
  showTrendArrow?: boolean;
  size?: 'standard' | 'small' | 'large';
}

export const Trend = ({
  children,
  isPositiveTrend,
  isInlineTrend,
  showTrendArrow = true,
  size = 'standard',
}: React.PropsWithChildren<TrendProps>) => {
  const color = useColor();

  const renderLabel = () => {
    let labelColor: string;
    if (isInlineTrend) {
      labelColor = color.foreground.neutral;
    } else if (isPositiveTrend) {
      labelColor = color.foreground.positive;
    } else {
      labelColor = color.foreground.critical;
    }

    return (
      <span
        style={{
          color: labelColor,
        }}
      >
        {children}
      </span>
    );
  };

  const renderLargeLabel = () => (
    <Heading level="3" weight="weak">
      {renderLabel()}
    </Heading>
  );

  const renderRegularLabel = () =>
    isInlineTrend ? renderLabel() : <Medium>{renderLabel()}</Medium>;

  return (
    <>
      {showTrendArrow && (
        <TrendIcon
          isPositiveTrend={isPositiveTrend}
          size={size === 'small' ? 'small' : undefined}
          style={{ marginRight: vars.space.xxsmall }}
        />
      )}
      <Box
        display="inlineBlock"
        style={{
          marginRight: size === 'large' ? vars.space.xxsmall : undefined,
        }}
      >
        {size === 'large' ? renderLargeLabel() : renderRegularLabel()}
      </Box>
    </>
  );
};
