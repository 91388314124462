import { Badge, Box, Text, Stack } from 'braid-design-system';

export interface PerformanceRatingCardProps {
  rating: string | ReactNodeNoStrings;
  ratingTone?:
    | 'caution'
    | 'critical'
    | 'info'
    | 'neutral'
    | 'positive'
    | 'promote';
  description: string | ReactNodeNoStrings;
  caption: string | ReactNodeNoStrings;
}

export const PerformanceRatingCard = ({
  rating,
  ratingTone,
  description,
  caption,
}: PerformanceRatingCardProps) => (
  <Box data-testid="performance-rating-card" height="full">
    <Stack space="small">
      <Badge tone={ratingTone}>{rating}</Badge>
      <Text component="p" size="small">
        {description}
      </Text>
      <Text component="p" size="small">
        {caption}
      </Text>
    </Stack>
  </Box>
);
