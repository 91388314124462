import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconClear,
  IconLocation,
  IconTick,
  List,
  Stack,
  Text,
} from 'braid-design-system';
import type React from 'react';

import type { PostTypeCategory } from '../../types/AdPerformanceTypes';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { CircleBadge } from '../CircleBadge/CircleBadge';
import { Medium } from '../Medium/Medium';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';
import { StandardBox } from '../StandardBox/StandardBox';
import { WidgetErrorNvl } from '../WidgetError/WidgetErrorNvl';

import translations from './.vocab';

export interface SpecificLocationRecommendationProps {
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  editAdUrl: string;
  coverTitle?: string;
  coverSubTitle?: ReactNodeNoStrings;
  direction?: 'row' | 'column';
  footerNotice?: ReactNodeNoStrings;
  nonSpecificLocation: string | null;
  specificLocation: string | null;
  trackInteractionObject?: string;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  onTipClicked?: () => void;
}

export const SpecificLocationRecommendation = ({
  isExpired,
  postTypeCategory,
  editAdUrl,
  coverSubTitle,
  coverTitle,
  nonSpecificLocation,
  specificLocation,
  trackInteractionObject,
  direction = 'row',
  footerNotice = null,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  onTipClicked,
}: SpecificLocationRecommendationProps) => {
  const { t } = useTranslations(translations);

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      if (onTipClicked) {
        onTipClicked();
      } else {
        trackEvent('view_tip_pressed', {
          tipName: 'granular location',
          objectInteraction:
            trackInteractionObject ||
            'apr-recommendations-granular-location-section',
        });
      }
    }
  };

  const title = t('Include a specific location in the job details');
  const missingLocationData = !nonSpecificLocation || !specificLocation;

  return (
    <RecommendationSkeleton
      coverTitle={coverTitle}
      coverSubTitle={coverSubTitle}
      direction={direction}
      titleContent={title}
      subtitleContent={t(
        'Candidates are more likely to apply for a job with a specific location.',
      )}
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconLocation tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-specific-location-recommendation"
    >
      <Stack space="large">
        <Columns space="xlarge">
          <Column>
            <Stack space="large">
              <>
                {t('Specific location ad benefits', {
                  Stack: (children: ReactNodeNoStrings) => (
                    <Stack space="medium">{children}</Stack>
                  ),
                  Text: (children) => <Text>{children}</Text>,
                  SubList: (children: ReactNodeNoStrings) => (
                    <Box paddingLeft="small">
                      <List>{children}</List>
                    </Box>
                  ),
                })}
                {t('Specific location tagging tip', {
                  Stack: (children: ReactNodeNoStrings) => (
                    <Stack space="medium">{children}</Stack>
                  ),
                  Text: (children) => <Text>{children}</Text>,
                  Medium: (children: ReactNodeNoStrings) => (
                    <Medium>{children}</Medium>
                  ),
                })}
                {t('Most central location tagging tip', {
                  Stack: (children: ReactNodeNoStrings) => (
                    <Stack space="medium">{children}</Stack>
                  ),
                  Text: (children) => <Text>{children}</Text>,
                  Medium: (children: ReactNodeNoStrings) => (
                    <Medium>{children}</Medium>
                  ),
                })}
              </>
            </Stack>
          </Column>
        </Columns>
        <Stack space="xsmall">
          <Text>
            <Medium>{t('Example')}</Medium>
          </Text>
          {missingLocationData ? (
            <WidgetErrorNvl />
          ) : (
            <Columns space="small" collapseBelow="tablet">
              <Column width="1/2">
                <StandardBox
                  accessory={
                    <CircleBadge tone="positive">
                      <IconTick size="small" tone="positive" />
                    </CircleBadge>
                  }
                  padding="small"
                  borderTone="positive"
                  height="full"
                  subTitleComponent={
                    <Stack space="small">
                      <Text tone="secondary">{t('Specific location:')}</Text>
                      <List tone="secondary">
                        <Text>{specificLocation}</Text>
                      </List>
                    </Stack>
                  }
                />
              </Column>
              <Column width="1/2">
                <StandardBox
                  accessory={
                    <CircleBadge tone="critical">
                      <IconClear size="small" tone="critical" />
                    </CircleBadge>
                  }
                  borderTone="negative"
                  padding="small"
                  height="full"
                  subTitleComponent={
                    <Stack space="small">
                      <Text tone="secondary">
                        {t('Non-specific location:')}
                      </Text>
                      <List tone="secondary">
                        <Text>{nonSpecificLocation}</Text>
                      </List>
                    </Stack>
                  }
                />
              </Column>
            </Columns>
          )}
        </Stack>
        {footerNotice}
      </Stack>
    </RecommendationSkeleton>
  );
};
