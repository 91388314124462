import { type ApolloError, useQuery } from '@apollo/client';

import { performancePredictionQuery } from '../queries/performancePredictionQuery';
import type { PerformancePredictionResponse } from '../types/PerformancePrediction';

interface PerformancePredictionParam {
  input: {
    jobId: string;
  };
}

interface queryResponse {
  analyticsPerformancePrediction: PerformancePredictionResponse;
}

export const usePerformancePrediction = ({
  jobId = '',
}: {
  jobId: string;
}): {
  data: PerformancePredictionResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { loading, data, error, previousData } = useQuery<
    queryResponse,
    PerformancePredictionParam
  >(performancePredictionQuery, {
    variables: {
      input: {
        jobId,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsPerformancePrediction,
    isLoading: loading,
    error,
  };
};
