import assert from 'assert';

import { useTranslations } from '@vocab/react';
import {
  Box,
  Columns,
  Column,
  Stack,
  Text,
  IconTick,
} from 'braid-design-system';
import {
  useContext,
  useRef,
  useEffect,
  useCallback,
  type KeyboardEvent,
  type ComponentProps,
} from 'react';

import { useConfig } from '../../App/ConfigContext';
import { trackEvent } from '../../utils/tealiumEventTracker';
import { ElapsedTime } from '../ElapsedTime/ElapsedTime';
import { ExternalLinkWithRef } from '../ExternalLink/ExternalLink';
import { JobTitleNavContext } from '../JobTitleNav/JobTitleNav';
import { actionTypes, type Action } from '../JobTitleNav/JobTitleNav.actions';
import { normalizeKey } from '../JobTitleNav/JobTitleNav.formatters';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';

import * as styles from './jobListItem.css';

type BoxProps = ComponentProps<typeof Box>;

const {
  MENU_ITEM_UP,
  MENU_ITEM_DOWN,
  MENU_ITEM_ESCAPE,
  MENU_ITEM_TAB,
  MENU_ITEM_ENTER,
  MENU_ITEM_SPACE,
  MENU_ITEM_CLICK,
  MENU_ITEM_HOVER,
} = actionTypes;

export interface JobListItemProps extends BoxProps {
  isActive: boolean;
  jobId: string;
  jobTitle: string;
  locationLabel: string;
  postDate: string;
  postedBy: string;
  onItemClicked?: (jobId: string) => void;
}

export const JobListItem = ({
  isActive,
  jobId,
  jobTitle,
  locationLabel,
  postDate,
  postedBy,
  onItemClicked,
}: JobListItemProps) => {
  const { t } = useTranslations(translations);
  const { urlResolver } = useConfig();
  const jobTitleNavContext = useContext(JobTitleNavContext);
  const jobAdReportUrl = urlResolver(`/analytics/ad-performance/${jobId}`);
  assert(
    jobTitleNavContext !== null,
    `JobListItem must be rendered as a child of a JobTitleNav.`,
  );
  const { isHighlighted, index, dispatch, focusTrigger } = jobTitleNavContext;
  const menuItemRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isHighlighted) {
      menuItemRef.current?.focus();
    }
  }, [isHighlighted]);

  const onKeyDown = (event: KeyboardEvent<HTMLAnchorElement>) => {
    const targetKey = normalizeKey(event);

    if (targetKey === 'Tab') {
      dispatch({ type: MENU_ITEM_TAB });
    }

    const isArrowPress = targetKey.indexOf('Arrow') === 0;
    const isActionKeyPress = targetKey === 'Enter' || targetKey === ' ';

    // This prevents spacebar from scrolling the document,
    // but also prevents the click event from firing so we
    // can programmatically trigger a click event in the
    // 'onKeyUp' handler. This is to normalise behaviour
    // between buttons and links, e.g. to make spacebar
    // activate links, which is not standard behaviour.
    if (isArrowPress || isActionKeyPress) {
      event.preventDefault();
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLAnchorElement>) => {
    const targetKey = normalizeKey(event);
    const closeActionKeys = ['Enter', ' ', 'Escape'];

    const action: Record<string, Action> = {
      ArrowDown: { type: MENU_ITEM_DOWN },
      ArrowUp: { type: MENU_ITEM_UP },
      Enter: { type: MENU_ITEM_ENTER },
      ' ': { type: MENU_ITEM_SPACE },
      Escape: { type: MENU_ITEM_ESCAPE },
    };

    if (action[targetKey]) {
      dispatch(action[targetKey]);
    }

    // Because we call 'preventDefault()' on enter/spacebar in
    // the 'onKeyDown' handler, we manually trigger a click here.
    if (targetKey === 'Enter' || targetKey === ' ') {
      menuItemRef.current?.click();
    }

    if (closeActionKeys.indexOf(targetKey) > -1) {
      focusTrigger();
    }
  };

  const onMouseEnter = useCallback(() => {
    dispatch({ type: MENU_ITEM_HOVER, value: index });
  }, [dispatch, index]);

  const onItemClickedEvent = useCallback(() => {
    dispatch({ type: MENU_ITEM_CLICK });

    trackEvent('jobs_menu_item_pressed', {
      objectInteraction: 'apr-jobs-menu-item',
    });

    if (onItemClicked) {
      onItemClicked(jobId);
    }
  }, [dispatch, jobId, onItemClicked]);

  return (
    <ZStack height="full" width="full">
      <ZStackItem isRelativeLayer>
        <Box
          cursor="pointer"
          paddingY="medium"
          paddingX="small"
          {...(isHighlighted ? { background: 'formAccentSoft' } : {})}
          {...(isActive ? { background: 'formAccentActive' } : {})}
        >
          <Columns space="large" alignY="center">
            <Column>
              <Stack space="small">
                <Text weight="strong" maxLines={1}>
                  {jobTitle}
                </Text>
                <Stack space="xsmall">
                  <Text maxLines={1}>{locationLabel}</Text>
                  <Text tone="secondary" maxLines={1}>
                    {t('Posted by', { postedBy })}
                    {', '}
                    <ElapsedTime
                      pastTime={postDate}
                      showTitle={false}
                      id="ui-job-list-item-posted-date"
                    />
                  </Text>
                </Stack>
              </Stack>
            </Column>
            {isActive ? (
              <Column width="content">
                <IconTick />
              </Column>
            ) : null}
          </Columns>
        </Box>
      </ZStackItem>
      <ZStackItem>
        <ExternalLinkWithRef
          className={styles.link}
          data-id="ui-job-menu-view-report-button"
          href={jobAdReportUrl}
          onClick={onItemClickedEvent}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          ref={menuItemRef}
        />
      </ZStackItem>
    </ZStack>
  );
};
