import { gql } from '@apollo/client';

export const adUsageFiltersQuery = gql`
  query GetAdUsageFiltersData($input: AdUsageFiltersWidgetInput!) {
    analyticsAdUsageFilters(input: $input) {
      users {
        key
        value
        count
      }
      locations {
        key
        value
        level
        parentId
        count
      }
      classifications {
        key
        value
        count
        subClassifications {
          key
          value
          count
        }
      }
      jobTitles {
        key
        count
      }
      adIds
      accountHierarchies {
        key
        value
        count
      }
      adStatus {
        key
        count
      }
      adRatings {
        key
        count
      }
      tips {
        key
        count
      }
      reposts {
        key
        count
      }
      budgets {
        key
        count
        data {
          type
          expiryTimestampUTC
        }
      }
      productDisplayNames {
        key
        count
      }
      isChildAccount
    }
  }
`;
