export const NoRecommendationIcon = () => (
  <svg
    width="129"
    height="129"
    viewBox="0 0 129 129"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.6913 24.9785H38.0769C33.8643 24.9785 30.4492 28.3935 30.4492 32.6062V99.4628C30.4492 103.675 33.8643 107.09 38.0769 107.09H85.6913C89.9039 107.09 93.319 103.675 93.319 99.4628V32.6062C93.319 28.3935 89.9039 24.9785 85.6913 24.9785Z"
      fill="white"
    />
    <path
      d="M85.6914 19.2578H38.0769C30.7167 19.2578 24.7285 25.2459 24.7285 32.6062V99.4628C24.7285 106.823 30.7167 112.811 38.0769 112.811H85.6914C93.0518 112.811 99.0399 106.823 99.0399 99.4628V32.6062C99.0399 25.2459 93.0518 19.2578 85.6914 19.2578ZM93.3191 99.4628C93.3191 103.675 89.9041 107.09 85.6914 107.09H38.0769C33.8643 107.09 30.4493 103.675 30.4493 99.4628V32.6062C30.4493 28.3936 33.8643 24.9785 38.0769 24.9785H85.6914C89.9041 24.9785 93.3191 28.3936 93.3191 32.6062V99.4628Z"
      fill="#0D3880"
    />
    <path
      d="M76.0489 17.7827H70.6527C70.6527 12.8156 66.6262 8.78906 61.659 8.78906C56.6919 8.78906 52.6654 12.8156 52.6654 17.7827H47.2692C43.312 17.7827 40.0742 21.0206 40.0742 24.9777H83.2439C83.2439 21.0206 80.0061 17.7827 76.0489 17.7827Z"
      fill="#FDC221"
    />
    <path
      d="M41.4167 44.594H41.4119C41.0251 44.592 40.656 44.4348 40.3874 44.1565L37.5144 41.178C36.9656 40.6096 36.9822 39.7033 37.5505 39.1555C38.1189 38.6067 39.0251 38.6233 39.573 39.1916L41.4236 41.1106L45.8855 36.5471C46.4392 35.9836 47.3435 35.9719 47.9079 36.5246C48.4724 37.0764 48.4831 37.9826 47.9304 38.5471L42.4392 44.1633C42.1706 44.4387 41.8015 44.594 41.4167 44.594Z"
      fill="#EE399E"
    />
    <path d="M84.707 35.0078H53.4336V37.8691H84.707V35.0078Z" fill="#DCE5F2" />
    <path d="M84.707 42.8398H53.4336V45.7012H84.707V42.8398Z" fill="#DCE5F2" />
    <path
      d="M41.4167 68.6432H41.4119C41.0251 68.6412 40.656 68.484 40.3874 68.2057L37.5144 65.2281C36.9656 64.6598 36.9822 63.7545 37.5505 63.2057C38.1169 62.6578 39.0242 62.6715 39.573 63.2418L41.4236 65.1598L45.8855 60.5963C46.4392 60.0318 47.3435 60.0211 47.9079 60.5738C48.4724 61.1256 48.4831 62.0318 47.9304 62.5963L42.4392 68.2125C42.1706 68.4879 41.8015 68.6432 41.4167 68.6432Z"
      fill="#EE399E"
    />
    <path d="M84.707 59.0586H53.4336V61.9199H84.707V59.0586Z" fill="#DCE5F2" />
    <path d="M84.707 66.8906H53.4336V69.752H84.707V66.8906Z" fill="#DCE5F2" />
    <path
      d="M41.4167 93.4104H41.4119C41.0251 93.4084 40.656 93.2512 40.3874 92.9729L37.5144 89.9953C36.9656 89.427 36.9822 88.5217 37.5505 87.9729C38.1169 87.425 39.0242 87.4387 39.573 88.009L41.4236 89.927L45.8855 85.3635C46.4392 84.8 47.3435 84.7883 47.9079 85.341C48.4724 85.8928 48.4831 86.7991 47.9304 87.3635L42.4392 92.9797C42.1706 93.2551 41.8015 93.4104 41.4167 93.4104Z"
      fill="#EE399E"
    />
    <path d="M84.707 83.8262H53.4336V86.6875H84.707V83.8262Z" fill="#DCE5F2" />
    <path d="M81.186 91.6582H53.4336V94.5195H81.186V91.6582Z" fill="#DCE5F2" />
    <path
      d="M116.631 57.6785L115.748 56.7952C113.225 54.2714 109.133 54.2714 106.609 56.7952L104.143 59.2611L79.7227 83.6812L89.7452 93.7038L114.166 69.2837L114.581 68.868L116.631 66.8179C119.155 64.294 119.155 60.2022 116.631 57.6785Z"
      fill="#EE399E"
    />
    <path
      d="M83.2906 94.403L89.7391 93.6828L79.7163 83.6602L78.9961 90.1086L83.2906 94.403Z"
      fill="#F0F6FC"
    />
    <path
      d="M78.7927 91.9277C78.62 93.4733 79.9263 94.7795 81.4719 94.6069L83.2902 94.4038L78.9957 90.1094L78.7927 91.9277Z"
      fill="#0D3880"
    />
  </svg>
);
