import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { MarketPerformanceContainer } from './MarketPerformance';

export interface MarketPerformanceErrorBoundaryProps {
  children: ReactNode;
}

export const MarketPerformanceErrorBoundary = ({
  children,
}: MarketPerformanceErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <MarketPerformanceContainer>
        <WidgetError />
      </MarketPerformanceContainer>
    }
  >
    {children}
  </ErrorBoundary>
);
