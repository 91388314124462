import { useTranslations } from '@vocab/react';

import { ClockIcon } from '../../assets/icons/ClockIcon/ClockIcon';
import { PageError } from '../PageError/PageError';

import translations from './.vocab';

export const NoHistoricalDataMessage = () => {
  const { t } = useTranslations(translations);

  return (
    <PageError
      icon={<ClockIcon />}
      title={t('No historical data title')}
      description={t('No historical data description')}
    />
  );
};
