import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  List,
  Loader,
  Stack,
  Strong,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
} from 'braid-design-system';

import { SearchHeroIcon } from '../../assets/icons/SearchHeroIcon/SearchHeroIcon';
import performanceTabTranslations from '../../blocks/PerformanceTab/.vocab';
import { ClickableTooltip } from '../../components/ClickableTooltip/ClickableTooltip';
import { PageError } from '../../components/PageError/PageError';
import {
  PerformanceRatingCard,
  type PerformanceRatingCardProps,
} from '../../components/PerformanceRatingCard/PerformanceRatingCard';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { useMostAdsByPerformanceData } from '../../hooks/useMostAdsByPerformanceData';
import { formatNumber } from '../../utils/number';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';

import translations from './.vocab';

export const MostAdsByPerformanceContainer = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => {
  const { t } = useTranslations(translations);
  const { t: tPerformanceTab } = useTranslations(performanceTabTranslations);

  return (
    <Stack space="medium">
      <Box display="flex" gap="xsmall" alignItems="center" flexWrap="nowrap">
        <Text size="small" weight="strong" maxLines={1}>
          {t('Users and classifications with most ads by performance rating')}
        </Text>
        <Box flexShrink={0}>
          <ClickableTooltip
            id="most-ads-by-performance-performance-rating-tooltip"
            title={tPerformanceTab('Performance rating')}
            contents={
              <Stack space="large">
                <Text>
                  {tPerformanceTab(
                    'We rate the performance of your ads by comparing the number of applications against other ads with a similar job title and location.',
                  )}
                </Text>
                <List>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as high got more applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as normal got about the same applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      'Ads rated as low got less applications than similar ads.',
                    )}
                  </Text>
                  <Text>
                    {tPerformanceTab(
                      "Some ads have no rating because they were posted recently or ad comparisons aren't available",
                    )}
                  </Text>
                </List>
              </Stack>
            }
            size="small"
          />
        </Box>
      </Box>
      {children}
    </Stack>
  );
};

export const MostAdsByPerformance = () => {
  const { t } = useTranslations(translations);
  const { data, isLoading, error } = useMostAdsByPerformanceData();

  if (isLoading && !data) {
    return (
      <MostAdsByPerformanceContainer>
        <Box display="flex" justifyContent="center" margin="medium">
          <Loader size="small" />
        </Box>
      </MostAdsByPerformanceContainer>
    );
  }

  if (error || !data) {
    return (
      <MostAdsByPerformanceContainer>
        <WidgetError />
      </MostAdsByPerformanceContainer>
    );
  }

  const {
    low: {
      user: {
        label: userLowLabel,
        number: userLowNumber,
        percentage: userLowPercentage,
      },
      classification: {
        label: classificationLowLabel,
        number: classificationLowNumber,
        percentage: classificationLowPercentage,
      },
    },
    high: {
      user: {
        label: userHighLabel,
        number: userHighNumber,
        percentage: userHighPercentage,
      },
      classification: {
        label: classificationHighLabel,
        number: classificationHighNumber,
        percentage: classificationHighPercentage,
      },
    },
  } = data;

  const noHighOrLowRatingAds =
    userHighNumber +
      userLowNumber +
      classificationHighNumber +
      classificationLowNumber ===
    0;

  if (noHighOrLowRatingAds) {
    return (
      <MostAdsByPerformanceContainer>
        <PageError
          icon={<SearchHeroIcon />}
          title={t("We couldn't find any ads")}
          description={
            <Stack space="small">
              <Text>{t("You don't have any ads rated low or high")}</Text>
              <Text>
                {t('To expand your search, try adjusting the filters.')}
              </Text>
            </Stack>
          }
        />
      </MostAdsByPerformanceContainer>
    );
  }

  const userCards: PerformanceRatingCardProps[] = [];
  const classificationCards: PerformanceRatingCardProps[] = [];

  if (userLowNumber) {
    userCards.push({
      rating: t('Low'),
      ratingTone: 'critical',
      description:
        userLowLabel === null
          ? t('unspecified user low performance rating description', {
              adCount: userLowNumber,
              adCountText: formatNumber(userLowNumber),
              Strong: (text) => <Strong>{text}</Strong>,
            })
          : t('user low performance rating description', {
              adCount: userLowNumber,
              adCountText: formatNumber(userLowNumber),
              user: userLowLabel,
              Strong: (text) => <Strong>{text}</Strong>,
            }),
      caption: t('user performance rating card caption', {
        percentage: formatPercentage(userLowPercentage, 0),
      }),
    });
  }

  if (userHighNumber) {
    userCards.push({
      rating: t('High'),
      ratingTone: 'positive',
      description:
        userHighLabel === null
          ? t('unspecified user high performance rating description', {
              adCount: userHighNumber,
              adCountText: formatNumber(userHighNumber),
              Strong: (text) => <Strong>{text}</Strong>,
            })
          : t('user high performance rating description', {
              adCount: userHighNumber,
              adCountText: formatNumber(userHighNumber),
              user: userHighLabel,
              Strong: (text) => <Strong>{text}</Strong>,
            }),
      caption: t('user performance rating card caption', {
        percentage: formatPercentage(userHighPercentage, 0),
      }),
    });
  }

  if (classificationLowNumber) {
    classificationCards.push({
      rating: t('Low'),
      ratingTone: 'critical',
      description: t('classification low performance rating description', {
        adCount: classificationLowNumber,
        adCountText: formatNumber(classificationLowNumber),
        classification: classificationLowLabel!,
        Strong: (text) => <Strong>{text}</Strong>,
      }),
      caption: t('classification performance rating card caption', {
        percentage: formatPercentage(classificationLowPercentage, 0),
      }),
    });
  }

  if (classificationHighNumber) {
    classificationCards.push({
      rating: t('High'),
      ratingTone: 'positive',
      description: t('classification high performance rating description', {
        adCount: classificationHighNumber,
        adCountText: formatNumber(classificationHighNumber),
        classification: classificationHighLabel!,
        Strong: (text) => <Strong>{text}</Strong>,
      }),
      caption: t('classification performance rating card caption', {
        percentage: formatPercentage(classificationHighPercentage, 0),
      }),
    });
  }

  const isTabsEnabled = userCards.length > 1 || classificationCards.length > 1;

  const CardsGroup = ({ cards }: { cards: PerformanceRatingCardProps[] }) => (
    <Columns space="small">
      <Column>
        <PerformanceRatingCard {...cards[0]} />
      </Column>
      <Column width="content">
        <Box style={{ width: 2 }} height="full" background="neutralLight" />
      </Column>
      <Column>
        <PerformanceRatingCard {...cards[1]} />
      </Column>
    </Columns>
  );

  return (
    <MostAdsByPerformanceContainer>
      {isTabsEnabled ? (
        <TabsProvider id="most-ads-by-performance-tabs">
          <Stack space="medium">
            <Tabs
              label="Users and classification tabs"
              size="small"
              reserveHitArea={true}
            >
              <Tab>{t('Users')}</Tab>
              <Tab>{t('Classifications')}</Tab>
            </Tabs>
            <TabPanels>
              <TabPanel>
                <CardsGroup cards={userCards} />
              </TabPanel>
              <TabPanel>
                <CardsGroup cards={classificationCards} />
              </TabPanel>
            </TabPanels>
          </Stack>
        </TabsProvider>
      ) : (
        <CardsGroup cards={[...userCards, ...classificationCards]} />
      )}
    </MostAdsByPerformanceContainer>
  );
};
