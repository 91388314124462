import type { BenchmarkScenario } from '../types/AdPerformanceTypes';

export const doScenarioMatch = (
  benchmarkScenario: BenchmarkScenario,
  matches: BenchmarkScenario[],
) => matches.includes(benchmarkScenario);

export interface RoleTitleHelperProps {
  normalisedRoleTitle: string | null;
  benchmarkScenario: BenchmarkScenario | null;
  classificationName: string;
  subClassificationName: string;
  roleTitleSuffix: string;
  fallback: string;
}

export const roleTitleHelper = ({
  normalisedRoleTitle,
  benchmarkScenario,
  classificationName,
  subClassificationName,
  roleTitleSuffix,
  fallback,
}: RoleTitleHelperProps) => {
  if (!benchmarkScenario || benchmarkScenario === 'COUNTRY') {
    return fallback;
  }
  if (
    doScenarioMatch(benchmarkScenario, [
      'EXACT',
      'EXACT_REGIONAL',
      'EXACT_COUNTRY',
    ]) &&
    normalisedRoleTitle
  ) {
    return normalisedRoleTitle;
  }
  if (
    doScenarioMatch(benchmarkScenario, [
      'IDEAL',
      'IDEAL_REGIONAL',
      'IDEAL_COUNTRY',
    ]) &&
    normalisedRoleTitle
  ) {
    return `${normalisedRoleTitle} ${roleTitleSuffix}`;
  }
  if (
    doScenarioMatch(benchmarkScenario, [
      'SUBCLASS_LOCATION',
      'SUBCLASS_REGIONAL',
      'SUBCLASS_COUNTRY',
    ]) &&
    classificationName &&
    subClassificationName
  ) {
    return `${subClassificationName} (${classificationName})`;
  }
  return fallback;
};

export interface MapScenarioToGroupLabelTypeProps {
  normalisedRoleTitle: string | null | undefined;
  benchmarkScenario: BenchmarkScenario | null | undefined;
  classificationName: string;
  subClassificationName: string;
  benchmarkLocationCountry: string | null | undefined;
}

export interface GroupLabelParts {
  type: 'ROLE_TITLE' | 'ROLE_TITLE_CLUSTER' | 'CLASSIFICATION';
  groupLabel: string;
  isRegional: boolean;
}

export const mapScenarioToGroupLabelParts = ({
  normalisedRoleTitle,
  benchmarkScenario,
  classificationName,
  subClassificationName,
  benchmarkLocationCountry,
}: MapScenarioToGroupLabelTypeProps): GroupLabelParts | null => {
  if (!benchmarkScenario || benchmarkScenario === 'COUNTRY') {
    return null;
  }

  const isRegional =
    doScenarioMatch(benchmarkScenario, [
      'EXACT_REGIONAL',
      'IDEAL_REGIONAL',
      'SUBCLASS_REGIONAL',
    ]) && benchmarkLocationCountry !== 'PH';

  if (
    doScenarioMatch(benchmarkScenario, [
      'EXACT',
      'EXACT_REGIONAL',
      'EXACT_COUNTRY',
    ]) &&
    normalisedRoleTitle
  ) {
    return {
      type: 'ROLE_TITLE',
      groupLabel: normalisedRoleTitle,
      isRegional,
    };
  }
  if (
    doScenarioMatch(benchmarkScenario, [
      'IDEAL',
      'IDEAL_REGIONAL',
      'IDEAL_COUNTRY',
    ]) &&
    normalisedRoleTitle
  ) {
    return {
      type: 'ROLE_TITLE_CLUSTER',
      groupLabel: normalisedRoleTitle,
      isRegional,
    };
  }
  if (
    doScenarioMatch(benchmarkScenario, [
      'SUBCLASS_LOCATION',
      'SUBCLASS_REGIONAL',
      'SUBCLASS_COUNTRY',
    ]) &&
    classificationName &&
    subClassificationName
  ) {
    return {
      type: 'CLASSIFICATION',
      groupLabel: `${subClassificationName} (${classificationName})`,
      isRegional,
    };
  }
  return null;
};
