import { useRef, useEffect } from 'react';

export const useClickExternal = (callback: () => void) => {
  const targetRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        targetRef &&
        targetRef.current &&
        !targetRef.current.contains(e.target as HTMLDivElement)
      ) {
        callback();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, [callback]);

  return targetRef;
};
