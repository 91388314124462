export const Illustrations = ({
  width = '141px',
  height = '122px',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 119 103"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3949_73866)">
      <path
        d="M77.2 0.37793H56.8871H45.5929H23.4257H12.3C5.81 0.37793 0.5 5.71071 0.5 12.2286V30.5124V42.2783V43.9713V63.6941V74.1057C0.5 80.6236 5.81 85.9563 12.3 85.9563H43.9071L43.8229 78.4227H49.5543C53.6 78.4227 56.8871 75.1215 56.8871 71.0584C56.8871 66.9954 53.6 63.6941 49.5543 63.6941H43.8229V43.9713V42.2783H56.8871H61.9443V38.2153C61.9443 33.2211 65.99 29.158 70.9629 29.158C75.9357 29.158 79.9814 33.2211 79.9814 38.2153V42.2783H89V12.2286C89 5.71071 83.69 0.37793 77.2 0.37793Z"
        fill="#EAF0FA"
      />
      <path
        d="M110.409 91.2884L117.995 63.0162L105.689 59.7149L107.206 54.0436C107.712 52.1813 107.459 50.2344 106.532 48.5415C105.605 46.8486 104.003 45.6635 102.149 45.1556C100.295 44.6477 98.3561 44.9017 96.6704 45.8328C94.9847 46.7639 93.8047 48.3722 93.299 50.2344L91.7818 55.9058L74.3347 51.1656L69.9518 67.2486L73.7447 68.2643C76.1047 68.9415 78.0433 70.3805 79.3076 72.5813C80.4876 74.6975 80.8247 77.1523 80.2347 79.5224C79.5604 81.8925 78.1276 83.8394 75.9361 85.1091C73.829 86.2942 71.3847 86.6328 69.0247 86.0403L65.2318 85.0245L63.209 92.5581L93.9733 100.854C101.053 102.8 108.47 98.4834 110.409 91.2884Z"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
      <path
        d="M1.34375 43.0397H17.948V47.9493C17.948 52.5202 21.6566 56.16 26.1237 56.16C30.5909 56.16 34.2995 52.4356 34.2995 47.9493V43.0397H44.6666V27.2107H39.778C35.2266 27.2107 31.6023 23.4862 31.6023 18.9999C31.6023 14.5136 35.3109 10.7891 39.778 10.7891H44.6666L44.7509 1.30859"
        stroke="#031D44"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3949_73866">
        <rect
          width="118"
          height="102"
          fill="white"
          transform="translate(0.5 0.0390625)"
        />
      </clipPath>
    </defs>
  </svg>
);
