import classNames from 'classnames';
import type { BaseType, Selection } from 'd3-selection';

function createLayer<T extends BaseType>({
  className,
  parent,
}: {
  className?: string | string[];
  parent: Selection<T, unknown, null, undefined>;
}) {
  const layer = parent.append('g');
  if (className) {
    if (typeof className === 'string') {
      layer.attr('class', className);
    } else {
      layer.attr('class', classNames(className));
    }
  }
  return layer;
}

export default {
  createLayer,
};
