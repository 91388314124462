import { useTranslations } from '@vocab/react';
import { Stack, Text } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AdStatCard } from '../../components/AdStatCard/AdStatCard';
import { AdStatErrorOrLoading } from '../../components/AdStatErrorOrLoading/AdStatErrorOrLoading';
import { Medium } from '../../components/Medium/Medium';
import { useFeatureToggleData, FEATURE_BENCHMARKS } from '../../featureToggle';
import { useClicksOnYourAdData } from '../../hooks/useClicksOnYourAdData';
import { formatPercentage } from '../../utils/percentage/percentageFormatter';

import translations from './.vocab';

export interface ClicksOnYourAdProps {
  updateHasAdRating?: (hasAdRating: boolean) => void;
}

export const ClicksOnYourAd = ({ updateHasAdRating }: ClicksOnYourAdProps) => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{ jobId: string }>();

  const { data, error, isLoading } = useClicksOnYourAdData({
    jobId: jobIdParam!,
  });

  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );

  useEffect(() => {
    if (data?.hasAdRating) {
      updateHasAdRating?.(true);
    }
  }, [updateHasAdRating, data]);

  if ((isLoading && !data) || error || !data) {
    return (
      <AdStatErrorOrLoading
        headingLabel={t('Clicks on your ad')}
        headingTooltip={t('Candidate clicked to visit your job ad')}
        isLoading={isLoading}
      />
    );
  }

  const {
    adRatingConfidence,
    totalClicks,
    appConversionInPercentage,
    isLinkOutAd,
    mobileClicksInPercentage,
    similarAdsAverage,
    hasAdRating,
  } = data;

  return (
    <AdStatCard
      adRatingConfidence={adRatingConfidence}
      conversionLabel={t(
        isLinkOutAd
          ? '{percentage} click-to-apply-start rate'
          : '{percentage} click-to-apply rate',
        {
          percentage: formatPercentage(appConversionInPercentage, 1),
          Medium: (text) => <Medium>{text}</Medium>,
        },
      )}
      title={t('Ad clicks')}
      mobileInPercentage={mobileClicksInPercentage}
      comparisonToSimilarAds={
        similarAdsAverage !== null && similarAdsAverage > 0
          ? (totalClicks - similarAdsAverage) / similarAdsAverage
          : null
      }
      similarAdsAverage={similarAdsAverage}
      statCount={totalClicks}
      displayMarketPerformance={hasAdRating && isBenchmarksFeatureEnabled}
      tooltip={{
        id: 'ad-clicks-tooltip',
        title: t('Ad clicks'),
        contents: (
          <Stack space="large">
            <Text size="standard">
              {t('Candidate clicked to visit your job ad')}
            </Text>
            <Text size="standard">
              {t(
                isLinkOutAd
                  ? 'Click-to-apply-start rate formula'
                  : 'Click-to-apply rate formula',
              )}
            </Text>
          </Stack>
        ),
      }}
    />
  );
};
