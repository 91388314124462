import { Box, Heading, Stack } from 'braid-design-system';

import { SearchHeroIcon } from '../../assets/icons/SearchHeroIcon/SearchHeroIcon';

export interface NoResultMessageProps {
  title: string;
  description: ReactNodeNoStrings;
}

export const NoResultMessage = ({
  title,
  description,
}: NoResultMessageProps) => (
  <Box width="full" display="flex" justifyContent="center" padding="large">
    <Stack space="medium">
      <Box textAlign="center">
        <SearchHeroIcon />
      </Box>
      <Heading level="2" align="center">
        {title}
      </Heading>
      <Box>{description}</Box>
    </Stack>
  </Box>
);
