import type {
  FeatureConfig,
  FeatureConfigValue,
  SiteSpecificFeatureConfigValue,
} from '../types';

import { aprNvlFeature } from './aprNvlFeature';
import { aurNvlFeature } from './aurNvlFeature';
import { benchmarksFeature } from './benchmarksFeature';
import { performanceRatingFeature } from './performanceRatingFeature';

export const featureConfigs: Array<
  | FeatureConfig<SiteSpecificFeatureConfigValue>
  | FeatureConfig<FeatureConfigValue>
> = [aprNvlFeature, benchmarksFeature, performanceRatingFeature, aurNvlFeature];
