
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4ieC4seC4mSIsIkRvd25sb2FkIHJlcG9ydCI6IuC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4mSIsIlByZXBhcmluZyBkb3dubG9hZCI6IuC4geC4s+C4peC4seC4h+C4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lCIsIkNhbid0IGRvd25sb2FkIHJlcG9ydCI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4meC4meC4teC5ieC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoi4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4Lir4Lij4Li34Lit4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4ieC4seC4mSIsIkRvd25sb2FkIHJlcG9ydCI6IuC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4mSIsIlByZXBhcmluZyBkb3dubG9hZCI6IuC4geC4s+C4peC4seC4h+C4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lCIsIkNhbid0IGRvd25sb2FkIHJlcG9ydCI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4meC4meC4teC5ieC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoi4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4Lir4Lij4Li34Lit4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlBlbmdndW5hYW4gaWtsYW4gc2F5YSIsIkRvd25sb2FkIHJlcG9ydCI6IlVuZHVoIGxhcG9yYW4iLCJQcmVwYXJpbmcgZG93bmxvYWQiOiJNZW1wZXJzaWFwa2FuIHVuZHVoYW4iLCJDYW4ndCBkb3dubG9hZCByZXBvcnQiOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbmd1bmR1aCBsYXBvcmFuIGluaSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoiTXVhdCB1bGFuZyBoYWxhbWFuIGF0YXUgY29iYSBsYWdpIG5hbnRpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlBlbmdndW5hYW4gaWtsYW4gc2F5YSIsIkRvd25sb2FkIHJlcG9ydCI6IlVuZHVoIGxhcG9yYW4iLCJQcmVwYXJpbmcgZG93bmxvYWQiOiJNZW1wZXJzaWFwa2FuIHVuZHVoYW4iLCJDYW4ndCBkb3dubG9hZCByZXBvcnQiOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbmd1bmR1aCBsYXBvcmFuIGluaSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoiTXVhdCB1bGFuZyBoYWxhbWFuIGF0YXUgY29iYSBsYWdpIG5hbnRpLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4ieC4seC4mSIsIkRvd25sb2FkIHJlcG9ydCI6IuC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4mSIsIlByZXBhcmluZyBkb3dubG9hZCI6IuC4geC4s+C4peC4seC4h+C4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lCIsIkNhbid0IGRvd25sb2FkIHJlcG9ydCI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4meC4meC4teC5ieC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoi4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4Lir4Lij4Li34Lit4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IuC4geC4suC4o+C5g+C4iuC5ieC4h+C4suC4meC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4guC4reC4h+C4ieC4seC4mSIsIkRvd25sb2FkIHJlcG9ydCI6IuC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4mSIsIlByZXBhcmluZyBkb3dubG9hZCI6IuC4geC4s+C4peC4seC4h+C4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lCIsIkNhbid0IGRvd25sb2FkIHJlcG9ydCI6IuC5gOC4o+C4suC5hOC4oeC5iOC4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC4o+C4suC4ouC4h+C4suC4meC4meC4teC5ieC5hOC4lOC5ieC5g+C4meC4guC4k+C4sOC4meC4teC5iSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoi4Lij4Li14LmA4Lif4Lij4LiK4Lir4LiZ4LmJ4Liy4LiZ4Li14LmJ4Lir4Lij4Li34Lit4Lil4Lit4LiH4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiH4Lig4Liy4Lii4Lir4Lil4Lix4LiHIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlBlbmdndW5hYW4gaWtsYW4gc2F5YSIsIkRvd25sb2FkIHJlcG9ydCI6IlVuZHVoIGxhcG9yYW4iLCJQcmVwYXJpbmcgZG93bmxvYWQiOiJNZW1wZXJzaWFwa2FuIHVuZHVoYW4iLCJDYW4ndCBkb3dubG9hZCByZXBvcnQiOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbmd1bmR1aCBsYXBvcmFuIGluaSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoiTXVhdCB1bGFuZyBoYWxhbWFuIGF0YXUgY29iYSBsYWdpIG5hbnRpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlBlbmdndW5hYW4gaWtsYW4gc2F5YSIsIkRvd25sb2FkIHJlcG9ydCI6IlVuZHVoIGxhcG9yYW4iLCJQcmVwYXJpbmcgZG93bmxvYWQiOiJNZW1wZXJzaWFwa2FuIHVuZHVoYW4iLCJDYW4ndCBkb3dubG9hZCByZXBvcnQiOiJTYWF0IGluaSBrYW1pIHRpZGFrIGRhcGF0IG1lbmd1bmR1aCBsYXBvcmFuIGluaSIsIlJlZnJlc2ggdGhlIHBhZ2Ugb3IgdHJ5IGFnYWluIGxhdGVyIjoiTXVhdCB1bGFuZyBoYWxhbWFuIGF0YXUgY29iYSBsYWdpIG5hbnRpLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6Ik15IGFkIHVzYWdlIiwiRG93bmxvYWQgcmVwb3J0IjoiRG93bmxvYWQgcmVwb3J0IiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiUHJlcGFyaW5nIGRvd25sb2FkIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiV2UgY2FuJ3QgZG93bmxvYWQgdGhpcyByZXBvcnQgcmlnaHQgbm93IiwiUmVmcmVzaCB0aGUgcGFnZSBvciB0cnkgYWdhaW4gbGF0ZXIiOiJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlci4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlvhuYLDvcO9IMSDxIPGjCDHmseaxaHEg8SDxKPhur3hur1dIiwiRG93bmxvYWQgcmVwb3J0IjoiW+G4isO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDFmeG6veG6veG6vcalw7bDtsO2xZnhua9dIiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiW8akxZnhur3hur3hur3GpcSDxIPEg8WZw6zDrMOs4LiB4Li14LmJxKMgxozDtsO2w7bFteC4geC4teC5icaaw7bDtsO2xIPEg8SDxoxdIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ+G5ryDGjMO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDhua/huKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlciI6IlvFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDhua/FmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDGmsSDxIPEg+G5r+G6veG6veG6vcWZLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBZCB1c2FnZSB0aXRsZSI6IlvhuYLDvcO9IMSDxIPGjCDHmseaxaHEg8SDxKPhur3hur1dIiwiRG93bmxvYWQgcmVwb3J0IjoiW+G4isO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDFmeG6veG6veG6vcalw7bDtsO2xZnhua9dIiwiUHJlcGFyaW5nIGRvd25sb2FkIjoiW8akxZnhur3hur3hur3GpcSDxIPEg8WZw6zDrMOs4LiB4Li14LmJxKMgxozDtsO2w7bFteC4geC4teC5icaaw7bDtsO2xIPEg8SDxoxdIiwiQ2FuJ3QgZG93bmxvYWQgcmVwb3J0IjoiW8W04bq94bq94bq9IMOnxIPEg8SD4LiB4Li14LmJJ+G5ryDGjMO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDhua/huKnDrMOsw6zFoSDFmeG6veG6veG6vcalw7bDtsO2xZnhua8gxZnDrMOsw6zEo+G4qeG5ryDguIHguLXguYnDtsO2w7bFtV0iLCJSZWZyZXNoIHRoZSBwYWdlIG9yIHRyeSBhZ2FpbiBsYXRlciI6IlvFmOG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qSDhua/huKnhur3hur3hur0gxqXEg8SDxIPEo+G6veG6veG6vSDDtsO2w7bFmSDhua/FmcO9w73DvSDEg8SDxIPEo8SDxIPEg8Osw6zDrOC4geC4teC5iSDGmsSDxIPEg+G5r+G6veG6veG6vcWZLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    