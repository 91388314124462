import { useTranslations } from '@vocab/react';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';

import { useChartSize } from '../../hooks/useChartSize';
import { useChartTheme } from '../../hooks/useChartTheme';
import { useTextStyles } from '../../hooks/useTextStyles';

import localTranslations from './.vocab';
import { createAdRatingChartRenderer } from './AdRatingChart.renderer';
import type {
  AdRatingChartData,
  AdRatingChartRenderer,
  GaugeLabels,
} from './AdRatingChart.types';

import * as styles from './adRatingChart.css';

export function useAdRatingChartRenderer({
  containerRef,
  data,
  height,
  width: initialWidth,
}: {
  containerRef: HTMLDivElement | null;
  data: AdRatingChartData;
  height: number;
  width: number;
}): AdRatingChartRenderer | null {
  const { t } = useTranslations(localTranslations);
  const theme = useChartTheme();
  const [chartRenderer, setChartRenderer] =
    useState<AdRatingChartRenderer | null>(null);
  const createRendererMemo = useCallback(createAdRatingChartRenderer, []);
  const textStyles = useTextStyles();

  useLayoutEffect(() => {
    const newChartRenderer = createRendererMemo({
      adRatingChartStyles: styles,
      container: containerRef,
      containerHeight: height,
      containerWidth: initialWidth,
      textStyles,
    });
    setChartRenderer(newChartRenderer);
  }, [containerRef, createRendererMemo, height, initialWidth, textStyles]);

  const { width } = useChartSize({
    containerRef,
    width: initialWidth,
    widthBehaviour: 'dynamic',
  });

  const gaugeLabels: GaugeLabels = useMemo(
    () => ({
      ratingLow: t('LOW'),
      ratingNormal: t('NORMAL'),
      ratingHigh: t('HIGH'),
      contentLow: (min: string) =>
        t('LOW tooltip content for Ad Rating gauge', { min }),
      contentNormal: (min: string, max: string) =>
        t('NORMAL tooltip content for Ad Rating gauge', { min, max }),
      contentHigh: (max: string) =>
        t('HIGH tooltip content for Ad Rating gauge', { max }),
    }),
    [t],
  );

  useLayoutEffect(() => {
    chartRenderer?.update({ data, width, theme, gaugeLabels });
  }, [chartRenderer, data, width, theme, gaugeLabels]);

  return chartRenderer;
}
