
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IuC4lOC4ueC5gOC4hOC4peC5h+C4lOC4peC4seC4miIsIkVkaXQgYWQiOiLguYHguIHguYnguYTguILguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IuC5gOC4nuC4t+C5iOC4reC5geC4geC5ieC5hOC4guC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4meC4teC5iSDguITguLjguJPguJXguYnguK3guIfguIHguKXguLHguJrguYTguJvguKLguLHguIfguKPguLDguJrguJrguJfguLXguYjguITguLjguJPguYPguIrguYnguYPguJnguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IuC4lOC4ueC5gOC4hOC4peC5h+C4lOC4peC4seC4miIsIkVkaXQgYWQiOiLguYHguIHguYnguYTguILguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IuC5gOC4nuC4t+C5iOC4reC5geC4geC5ieC5hOC4guC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4meC4teC5iSDguITguLjguJPguJXguYnguK3guIfguIHguKXguLHguJrguYTguJvguKLguLHguIfguKPguLDguJrguJrguJfguLXguYjguITguLjguJPguYPguIrguYnguYPguJnguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6Ik1lbGloYXQgdGlwcyIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJVbnR1ayBtZW5nZWRpdG55YSwgQW5kYSBoYXJ1cyBrZW1iYWxpIGtlIHNpc3RlbSB0ZW1wYXQgQW5kYSBtZW1wb3N0aW5nIGxvd29uZ2FuIGtlcmphLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6Ik1lbGloYXQgdGlwcyIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJVbnR1ayBtZW5nZWRpdG55YSwgQW5kYSBoYXJ1cyBrZW1iYWxpIGtlIHNpc3RlbSB0ZW1wYXQgQW5kYSBtZW1wb3N0aW5nIGxvd29uZ2FuIGtlcmphLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IuC4lOC4ueC5gOC4hOC4peC5h+C4lOC4peC4seC4miIsIkVkaXQgYWQiOiLguYHguIHguYnguYTguILguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IuC5gOC4nuC4t+C5iOC4reC5geC4geC5ieC5hOC4guC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4meC4teC5iSDguITguLjguJPguJXguYnguK3guIfguIHguKXguLHguJrguYTguJvguKLguLHguIfguKPguLDguJrguJrguJfguLXguYjguITguLjguJPguYPguIrguYnguYPguJnguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IuC4lOC4ueC5gOC4hOC4peC5h+C4lOC4peC4seC4miIsIkVkaXQgYWQiOiLguYHguIHguYnguYTguILguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IuC5gOC4nuC4t+C5iOC4reC5geC4geC5ieC5hOC4guC4m+C4o+C4sOC4geC4suC4qOC4h+C4suC4meC4meC4teC5iSDguITguLjguJPguJXguYnguK3guIfguIHguKXguLHguJrguYTguJvguKLguLHguIfguKPguLDguJrguJrguJfguLXguYjguITguLjguJPguYPguIrguYnguYPguJnguIHguLLguKPguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6Ik1lbGloYXQgdGlwcyIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJVbnR1ayBtZW5nZWRpdG55YSwgQW5kYSBoYXJ1cyBrZW1iYWxpIGtlIHNpc3RlbSB0ZW1wYXQgQW5kYSBtZW1wb3N0aW5nIGxvd29uZ2FuIGtlcmphLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6Ik1lbGloYXQgdGlwcyIsIkVkaXQgYWQiOiJFZGl0IGxvd29uZ2FuIGtlcmphIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJVbnR1ayBtZW5nZWRpdG55YSwgQW5kYSBoYXJ1cyBrZW1iYWxpIGtlIHNpc3RlbSB0ZW1wYXQgQW5kYSBtZW1wb3N0aW5nIGxvd29uZ2FuIGtlcmphLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlZpZXcgdGlwIiwiRWRpdCBhZCI6IkVkaXQgYWQiLCJUbyBlZGl0IHRoaXMgYWQsIHlvdSdsbCBuZWVkIHRvIGdvIGJhY2sgdG8gdGhlIHN5c3RlbSB5b3UgdXNlZCB0byBwb3N0IGl0LiI6IlRvIGVkaXQgdGhpcyBhZCwgeW91J2xsIG5lZWQgdG8gZ28gYmFjayB0byB0aGUgc3lzdGVtIHlvdSB1c2VkIHRvIHBvc3QgaXQuIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlvhubzDrMOsw6zhur3hur3hur3FtSDhua/DrMOsw6zGpV0iLCJFZGl0IGFkIjoiW8OLxozDrMOsw6zhua8gxIPEg8SDxoxdIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJb4bmuw7bDtsO2IOG6veG6veG6vcaMw6zDrMOs4bmvIOG5r+G4qcOsw6zDrMWhIMSDxIPEg8aMLCDDvcO9w73DtsO2w7bHmseax5onxprGmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDhua/DtsO2w7YgxKPDtsO2w7Ygw5/Eg8SDxIPDp8S3IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gxaHDvcO9w73FoeG5r+G6veG6veG6vW3MgiDDvcO9w73DtsO2w7bHmseax5ogx5rHmseaxaHhur3hur3hur3GjCDhua/DtsO2w7YgxqXDtsO2w7bFoeG5ryDDrMOsw6zhua8uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJWaWV3IHRpcCI6IlvhubzDrMOsw6zhur3hur3hur3FtSDhua/DrMOsw6zGpV0iLCJFZGl0IGFkIjoiW8OLxozDrMOsw6zhua8gxIPEg8SDxoxdIiwiVG8gZWRpdCB0aGlzIGFkLCB5b3UnbGwgbmVlZCB0byBnbyBiYWNrIHRvIHRoZSBzeXN0ZW0geW91IHVzZWQgdG8gcG9zdCBpdC4iOiJb4bmuw7bDtsO2IOG6veG6veG6vcaMw6zDrMOs4bmvIOG5r+G4qcOsw6zDrMWhIMSDxIPEg8aMLCDDvcO9w73DtsO2w7bHmseax5onxprGmiDguIHguLXguYnhur3hur3hur3hur3hur3hur3GjCDhua/DtsO2w7YgxKPDtsO2w7Ygw5/Eg8SDxIPDp8S3IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gxaHDvcO9w73FoeG5r+G6veG6veG6vW3MgiDDvcO9w73DtsO2w7bHmseax5ogx5rHmseaxaHhur3hur3hur3GjCDhua/DtsO2w7YgxqXDtsO2w7bFoeG5ryDDrMOsw6zhua8uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    