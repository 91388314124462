import { gql } from '@apollo/client';

export const adRatingQuery = gql`
  query GetAdRatingData($input: AdRatingWidgetInput!) {
    analyticsAdRating(input: $input) {
      adRatingConfidence
      adRatingScore
      applications
      applicationsBenchmarkHigh
      applicationsBenchmarkLow
      benchmarkLocationLabel
      benchmarkScenario
      classificationName
      incompleteApplications
      isLinkOutAd
      jobCreatedTimestampUTC
      lastUpdatedTimestampUTC
      normalisedRoleTitle
      subClassificationName
      benchmarkLocationCountry
    }
  }
`;
