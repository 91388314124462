import { useFeatureToggleContext } from '../context/FeatureToggleContext';
import { getFeatureByName } from '../utils/getFeatureByName';

export const useFeatureToggleData = (name: string, fallback = false) => {
  const { features, overrides } = useFeatureToggleContext();
  return (
    getFeatureByName(overrides, name)?.enabled ??
    getFeatureByName(features, name)?.enabled ??
    fallback
  );
};
