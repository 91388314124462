export const PaintingIcon = () => (
  <svg
    width="156"
    height="156"
    viewBox="0 0 177 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Painting Icon"
  >
    <g clipPath="url(#clip0_1699_15371)">
      <path
        d="M58.7598 5.27148V5.79433C59.2648 5.61488 59.7728 5.44202 60.282 5.27148H58.7598Z"
        fill="#F0F6FC"
      />
      <path
        d="M134.639 40.971L93.2402 40.9523V55.5233H154.42V32.6914H143.412C143.161 37.3061 139.334 40.9731 134.639 40.971Z"
        fill="#DCE5F2"
      />
      <path
        d="M52.0039 49.6969V55.5231H89.7575V40.9506L60.8015 40.9375C55.9434 40.9353 52.0039 44.8577 52.0039 49.6969Z"
        fill="#DCE5F2"
      />
      <path
        d="M154.154 29.7361C150.866 26.115 147.281 22.7668 143.437 19.7305V29.7361H154.154Z"
        fill="#DCE5F2"
      />
      <path
        d="M43.2103 66.047H33.8474C28.9909 66.047 25.0538 69.9688 25.0538 74.8065C25.0538 77.3911 23.9232 79.7071 22.1357 81.3104H59.6594V58.4785H51.9133C51.3286 62.7514 47.6603 66.047 43.2103 66.047Z"
        fill="#DCE5F2"
      />
      <path
        d="M126.316 81.3104H176.678C176.001 73.3508 174.261 65.6942 171.603 58.4785H126.316L126.316 81.3104Z"
        fill="#DCE5F2"
      />
      <path
        d="M170.449 55.5226C167.276 47.79 163.034 40.6077 157.903 34.1562V55.5226H170.449Z"
        fill="#DCE5F2"
      />
      <path
        d="M122.834 58.4785H63.1426V81.3104H122.834V58.4785Z"
        fill="#DCE5F2"
      />
      <path
        d="M59.6602 110.053H2.58496C4.59622 118.164 7.73038 125.834 11.8211 132.885H59.6602V110.053Z"
        fill="#DCE5F2"
      />
      <path
        d="M13.6172 135.84C17.6776 142.246 22.5434 148.093 28.0731 153.244V135.84H13.6172Z"
        fill="#DCE5F2"
      />
      <path
        d="M28.0732 84.2656H0.13044C0.0511915 85.7829 0 87.3076 0 88.8445C0 95.1031 0.659373 101.208 1.90436 107.098H28.0732L28.0732 84.2656Z"
        fill="#DCE5F2"
      />
      <path
        d="M37.333 160.779C44.1273 165.583 51.6331 169.452 59.6599 172.207V160.779H37.333Z"
        fill="#DCE5F2"
      />
      <path
        d="M91.2465 157.824V135.84H31.5557V156.321C32.1626 156.83 32.7758 157.332 33.3965 157.824H91.2465Z"
        fill="#DCE5F2"
      />
      <path
        d="M63.1426 160.779V173.32C71.1763 175.71 79.6865 176.999 88.5001 176.999C100.676 176.999 112.276 174.549 122.834 170.118V160.779H63.1426Z"
        fill="#DCE5F2"
      />
      <path
        d="M157.903 143.544C159.863 141.079 161.692 138.508 163.383 135.84H157.903V143.544Z"
        fill="#DCE5F2"
      />
      <path
        d="M91.2465 84.2656H31.5557V107.098H91.2465V84.2656Z"
        fill="#DCE5F2"
      />
      <path
        d="M154.42 84.2656H94.7295V107.098H154.42V84.2656Z"
        fill="#DCE5F2"
      />
      <path
        d="M126.316 132.885H165.179C169.27 125.834 172.404 118.164 174.416 110.053H126.316V132.885Z"
        fill="#DCE5F2"
      />
      <path
        d="M122.834 110.053H63.1426V132.885H122.834V110.053Z"
        fill="#DCE5F2"
      />
      <path
        d="M154.42 147.656V135.84H94.7295V157.824H143.603C147.489 154.74 151.108 151.338 154.42 147.656Z"
        fill="#DCE5F2"
      />
      <path
        d="M126.316 168.563C131.007 166.351 135.473 163.745 139.667 160.779H126.316V168.563Z"
        fill="#DCE5F2"
      />
      <path
        d="M157.903 107.098H175.096C176.341 101.208 177 95.1031 177 88.8445C177 87.3081 176.954 85.7827 176.876 84.2656H157.903V107.098Z"
        fill="#DCE5F2"
      />
      <path
        d="M126.317 81.3107V58.4788H171.603C171.237 57.4847 170.85 56.5005 170.449 55.5235H157.903V34.1572C156.7 32.644 155.454 31.1665 154.155 29.7363H143.437V32.2118C143.437 32.3738 143.422 32.5318 143.413 32.6916H154.421V55.5235H93.241V40.9526L89.7583 40.951V55.5235H52.0047V57.2879C52.0047 57.6929 51.9675 58.0884 51.9141 58.4788H59.6602V81.3107H22.1366C20.5784 82.7083 18.5228 83.5662 16.261 83.5662H0.162802C0.149002 83.7986 0.143007 84.0331 0.130859 84.266H28.0736V107.098H1.90479C2.11439 108.089 2.34223 109.074 2.58496 110.053H59.6602V132.886H11.8211C12.4001 133.884 13.0009 134.868 13.6177 135.841H28.0736V153.245C29.2071 154.301 30.3683 155.327 31.5563 156.323V135.841H91.2471V157.825H33.3971C34.6814 158.845 35.9922 159.833 37.3333 160.781H59.6602V172.209C60.8103 172.603 61.9718 172.973 63.1429 173.322V160.781H122.834V170.119C124.009 169.626 125.169 169.105 126.317 168.564V160.781H139.668C141.009 159.833 142.319 158.845 143.604 157.825H94.7298V135.841H154.421V147.657C155.622 146.322 156.784 144.951 157.903 143.545V135.841H163.383C164 134.868 164.601 133.884 165.18 132.886H126.317V110.053H174.416C174.659 109.074 174.886 108.089 175.096 107.098H157.903V84.266H176.876C176.825 83.2768 176.762 82.291 176.678 81.3107H126.317ZM63.1429 58.4788H122.834V81.3107H63.1429V58.4788ZM31.5563 107.098V84.266H91.2471V107.098H31.5563ZM122.834 132.886H63.1429V110.053H122.834V132.886ZM154.421 107.098H94.7298V84.266H154.421V107.098Z"
        fill="#F0F6FC"
      />
      <path
        d="M88.4997 0.688579C42.1824 0.298249 2.52774 37.6438 0.162109 83.5656H16.2603C21.0935 83.5604 25.0488 79.6473 25.0539 74.8062C25.0539 69.9684 28.991 66.0467 33.8476 66.0467H43.2104C48.0931 66.0714 52.0071 62.027 52.004 57.2872C52.004 57.2872 52.004 55.5229 52.004 55.5229V49.6967C52.004 44.8575 55.9435 40.9351 60.8017 40.9373C79.3583 40.9452 115.851 40.9625 134.639 40.9706C139.516 40.9864 143.418 37.0006 143.437 32.2112C143.437 32.2112 143.437 29.7357 143.437 29.7357V19.7301C128.343 7.81 109.257 0.688579 88.4997 0.688579Z"
        fill="#EE399E"
      />
      <path
        d="M144.965 69.5977H143.487V73.5675H144.965C147.162 73.5675 148.95 75.3478 148.95 77.5371V89.8055C148.95 91.994 147.162 93.775 144.965 93.775H116.088C111.693 93.775 108.117 97.337 108.117 101.715V109.166H112.102V101.715C112.102 99.5255 113.89 97.7446 116.088 97.7446H144.965C149.36 97.7446 152.935 94.1833 152.935 89.8055V77.5371C152.935 73.1593 149.36 69.5977 144.965 69.5977Z"
        fill="#072462"
      />
      <path
        d="M143.488 65.2701C143.488 64.1738 142.595 63.2852 141.495 63.2852L73.7437 63.2852C72.6431 63.2852 71.751 64.1739 71.751 65.2701V85.1194C71.751 86.2156 72.6431 87.1043 73.7437 87.1043H141.495C142.595 87.1043 143.488 86.2157 143.488 85.1194V65.2701Z"
        fill="#EE399E"
      />
      <path
        d="M144.882 156.381L134.005 145.546C133.806 143.734 123.454 142.178 123.454 142.178L98.5457 145.394C95.6327 144.726 94.1376 148.389 96.63 150.382C101.081 153.942 102.143 156.055 102.143 156.055C102.143 156.055 107.518 159.849 111.229 161.666C113.712 162.881 117.545 163.724 120.074 163.511L125.515 168.931C125.782 168.808 126.05 168.688 126.316 168.563C131.007 166.351 135.473 163.745 139.667 160.78C141.008 159.831 142.319 158.844 143.603 157.824C144.095 157.434 144.57 157.023 145.054 156.622C144.997 156.542 144.945 156.458 144.879 156.385L144.882 156.381Z"
        fill="#F4BAAF"
      />
      <path
        d="M119.905 130.484C119.915 130.462 119.924 130.44 119.934 130.418V129.493C119.359 128.506 117.317 126.475 116.554 125.209C116.077 124.416 115.4 123.271 115.155 122.379C114.757 120.936 114.631 119.448 114.816 117.961C115.133 115.408 117.735 113.998 119.934 114.669V111.154C119.934 110.057 119.041 109.168 117.94 109.168H102.001C100.899 109.168 100.007 110.057 100.007 111.154V121.697L105.262 123.61C107.329 124.362 108.393 126.641 107.638 128.7C107.069 130.252 105.628 131.236 104.068 131.304C104.061 131.357 104.063 131.41 104.044 131.463C104.035 131.487 104.018 131.506 104.007 131.529C105.613 132.488 106.364 134.474 105.698 136.291C105.145 137.797 103.773 138.764 102.267 138.883C102.283 138.952 102.293 139.02 102.289 139.092L102.556 139.189C104.623 139.942 105.687 142.221 104.932 144.28C104.351 145.863 102.865 146.851 101.269 146.883C101.248 146.923 101.229 146.964 101.202 146.999C102.283 148.056 102.71 149.682 102.157 151.192C101.768 152.254 100.97 153.046 100.007 153.465V156.859C100.007 157.956 100.899 158.845 102.001 158.845H117.94C119.041 158.845 119.934 157.956 119.934 156.859V150.058C119.48 149.214 119.074 148.344 118.725 147.448C116.546 141.85 117.459 135.834 119.905 130.484Z"
        fill="#0D3880"
      />
      <path
        d="M134.417 137.001C131.53 132.091 125.549 122.357 122.231 116.573C121.653 115.547 120.826 114.941 119.934 114.669C117.735 113.997 115.133 115.407 114.816 117.96C114.631 119.447 114.757 120.935 115.155 122.378C115.4 123.27 116.077 124.416 116.554 125.208C117.317 126.474 119.359 128.505 119.934 129.492C120.036 129.668 120.098 129.816 120.09 129.915C120.077 130.091 120.007 130.255 119.934 130.417C119.924 130.439 119.915 130.461 119.905 130.483C117.459 135.833 116.546 141.849 118.725 147.447C119.074 148.343 119.48 149.213 119.934 150.057C121.224 152.462 122.91 154.652 124.854 156.573C125.622 157.332 126.702 158.007 127.74 158.731C130.485 156.632 133.236 154.347 134.792 151.253C136.832 147.199 136.025 139.74 134.417 137.001Z"
        fill="#F4BAAF"
      />
      <path
        d="M95.4914 128.35C95.6369 127.952 96.0778 127.748 96.4769 127.893L103.586 130.48C103.931 130.606 104.116 130.953 104.068 131.302C105.628 131.234 107.069 130.251 107.638 128.698C108.393 126.639 107.33 124.36 105.263 123.608L100.007 121.696L98.1536 121.021C96.0864 120.269 93.7985 121.329 93.0434 123.388C92.3138 125.378 93.2852 127.568 95.2164 128.393C95.3044 128.401 95.3922 128.409 95.4802 128.424C95.4865 128.399 95.4825 128.374 95.4914 128.35Z"
        fill="#F6BCB1"
      />
      <path
        d="M94.5017 136.322C94.656 135.928 95.1009 135.734 95.4966 135.887L101.812 138.339C102.057 138.435 102.212 138.646 102.267 138.883C103.773 138.764 105.146 137.797 105.698 136.291C106.365 134.474 105.614 132.488 104.008 131.529C103.878 131.797 103.611 131.966 103.323 131.966C103.235 131.966 103.146 131.951 103.059 131.92L95.95 129.333C95.576 129.197 95.3839 128.801 95.4806 128.425C95.3926 128.411 95.3048 128.403 95.2168 128.394C93.457 128.23 91.7382 129.249 91.1033 130.981C90.3482 133.04 91.4119 135.319 93.4791 136.071L94.2934 136.367C94.359 136.371 94.4247 136.38 94.4904 136.387C94.4967 136.365 94.4934 136.343 94.5017 136.322Z"
        fill="#F6BCB1"
      />
      <path
        d="M93.5687 143.796C93.7226 143.402 94.1682 143.207 94.5636 143.36L100.878 145.813C101.274 145.967 101.469 146.411 101.315 146.804C101.303 146.833 101.283 146.856 101.269 146.883C102.864 146.851 104.351 145.862 104.932 144.28C105.687 142.22 104.623 139.941 102.556 139.189L102.289 139.092C102.285 139.171 102.278 139.252 102.247 139.33C102.129 139.632 101.839 139.817 101.531 139.817C101.438 139.817 101.344 139.801 101.252 139.765L94.9373 137.313C94.5632 137.168 94.3787 136.763 94.4895 136.386C94.4238 136.38 94.358 136.371 94.2925 136.367C92.5885 136.276 90.9527 137.289 90.3367 138.969C89.5816 141.028 90.6452 143.307 92.7125 144.059L93.5794 144.375C93.5027 144.197 93.4924 143.99 93.5687 143.796Z"
        fill="#F6BCB1"
      />
      <path
        d="M102.156 151.192C102.71 149.682 102.283 148.056 101.202 146.998C101.056 147.183 100.833 147.291 100.599 147.291C100.506 147.291 100.412 147.274 100.32 147.239L94.0052 144.786C93.9789 144.776 93.9614 144.755 93.9369 144.742C92.7252 145.066 91.6847 145.95 91.2215 147.213C90.4664 149.272 91.53 151.551 93.5972 152.303L97.0463 153.558C98.0482 153.923 99.1007 153.859 100.007 153.464C100.97 153.045 101.767 152.253 102.156 151.192Z"
        fill="#F6BCB1"
      />
      <path
        d="M103.585 130.481L96.4766 127.895C96.0775 127.749 95.6366 127.953 95.4911 128.351C95.4822 128.376 95.4862 128.4 95.4799 128.425C95.3831 128.801 95.5753 129.196 95.9493 129.332L103.058 131.919C103.145 131.951 103.234 131.966 103.322 131.966C103.611 131.966 103.877 131.797 104.007 131.528C104.018 131.505 104.035 131.487 104.044 131.463C104.063 131.41 104.061 131.357 104.068 131.303C104.116 130.954 103.931 130.607 103.585 130.481Z"
        fill="#CE8071"
      />
      <path
        d="M101.811 138.338L95.4961 135.886C95.1005 135.733 94.6555 135.927 94.5012 136.321C94.4929 136.342 94.4962 136.364 94.4899 136.386C94.3791 136.762 94.5637 137.167 94.9377 137.312L101.253 139.764C101.345 139.8 101.439 139.817 101.532 139.817C101.839 139.817 102.13 139.632 102.248 139.329C102.278 139.251 102.285 139.171 102.289 139.091C102.293 139.019 102.283 138.951 102.267 138.882C102.212 138.645 102.057 138.434 101.811 138.338Z"
        fill="#CE8071"
      />
      <path
        d="M100.32 147.238C100.412 147.273 100.506 147.29 100.599 147.29C100.833 147.29 101.056 147.182 101.202 146.997C101.229 146.963 101.248 146.921 101.269 146.882C101.284 146.855 101.304 146.832 101.315 146.803C101.469 146.409 101.274 145.965 100.879 145.812L94.5638 143.359C94.1684 143.206 93.7228 143.401 93.5689 143.794C93.4926 143.989 93.5029 144.195 93.5796 144.373C93.6476 144.531 93.7755 144.658 93.937 144.741C93.9615 144.754 93.979 144.775 94.0054 144.785L100.32 147.238Z"
        fill="#CE8071"
      />
    </g>
    <defs>
      <clipPath id="clip0_1699_15371">
        <rect width="177" height="177" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
