import { useHirerAuth } from '@seek/hirer-auth-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Dialog,
  Heading,
  IconDownload,
  Stack,
  Text,
} from 'braid-design-system';
import { useContext, useState } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { Illustrations } from '../../assets/icons/Illustrations/Illustrations';
import { getExportUrl, isLocal } from '../../config';
import { IsFilterContext } from '../../context/IsFilterContext';
import { useAdUsageQueryFilters } from '../../context/adUsageFilters';
import {
  FEATURE_PERFORMANCE_RATING,
  useFeatureToggleData,
} from '../../featureToggle';
import { downloadFile } from '../../utils/downloadFile';
import LoggerProvider from '../../utils/logger/logger';
import { trackEvent } from '../../utils/tealiumAUREventTracker';

import translations from './.vocab';

export interface AdUsageHeaderProps {
  hasAccess: boolean;
  numOfAds?: number;
}

export const AdUsageHeader = ({ hasAccess, numOfAds }: AdUsageHeaderProps) => {
  const showExportButton = Boolean(numOfAds);
  const { locale } = useConfig();
  const filters = useAdUsageQueryFilters();
  const { getToken } = useHirerAuth();
  const { t } = useTranslations(translations);
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasExportError, setHasExportError] = useState(false);
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isPerformanceRatingFeatureEnabled = useFeatureToggleData(
    FEATURE_PERFORMANCE_RATING,
    false,
  );
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);
  const getExportHeaders = async () => {
    if (isLocal()) {
      const email = 'seekUsername@seek.com.au';
      const localHeaders = {
        'User-Id': email,
        'X-Seek-Oidc-Identity': JSON.stringify({
          sub: {
            ID: email,
            email,
            email_verified: true,
            country: 'AU',
            brand: 'seek',
            experience: 'hirer',
          },
        }),
        'Advertiser-Id': '28829855',
        'Content-Type': 'application/json',
        Authorization: `Bearer yourToken`,
      };
      // @ts-ignore as we cannot destructure due to the variable already defined above
      delete localHeaders.advertiserId;
      return {
        ...localHeaders,
        'Advertiser-Id': '28829855',
        'Content-Type': 'application/json',
      };
    }

    const token = await getToken();

    return {
      Authorization: token!,
      'Content-Type': 'application/json',
    };
  };

  const onDownloadReportClick = async () => {
    setIsDownloading(true);
    try {
      const headers = await getExportHeaders();
      trackEvent('export_pressed', {
        objectInteraction: 'export-interacted',
        isFilterAdPerformance,
        isFilterAdType,
        isFilterClassification,
        isFilterJobTitle,
        isFilterLocation,
        isFilterAccountHierachy,
        isFilterAdId,
        isFilterAdStatus,
        isFilterRepost,
        isFilterTipsToImproveAds,
        isFilterUser,
        isFilterBudget,
      });
      const exportUrl = getExportUrl();
      const response = await fetch(exportUrl, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          locale,
          filters,
          numOfAds,
          timezone,
          isPerformanceRatingEnabled: isPerformanceRatingFeatureEnabled,
          isAdBudgetFilterEnabled: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error downloading CSV, status: ${response.status}`);
      }

      const fileName =
        response.headers
          .get('content-disposition')
          ?.split(';')[1]
          .split('filename=')[1]
          .replaceAll('"', '') || 'export.csv';

      const blob = await response.blob();
      await downloadFile(blob, decodeURI(fileName));

      trackEvent('export_succeeded', {
        objectInteraction: 'export_succeeded',
        isFilterAdPerformance,
        isFilterAdType,
        isFilterClassification,
        isFilterJobTitle,
        isFilterLocation,
        isFilterAccountHierachy,
        isFilterAdId,
        isFilterAdStatus,
        isFilterRepost,
        isFilterTipsToImproveAds,
        isFilterUser,
        isFilterBudget,
      });
    } catch (err: any) {
      LoggerProvider.logger.error(err.message, {
        error: err,
      });
      setHasExportError(true);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box
      alignItems="center"
      background="surface"
      boxShadow="small"
      display="flex"
      flexDirection="row"
      justifyContent="spaceBetween"
      paddingX="gutter"
      paddingY="xlarge"
      marginBottom="xxsmall"
    >
      <Heading level="2">{t('Ad usage title')}</Heading>
      {hasAccess && showExportButton && (
        <Box>
          <Button
            icon={<IconDownload />}
            onClick={onDownloadReportClick}
            bleed
            loading={isDownloading}
          >
            {isDownloading ? t('Preparing download') : t('Download report')}
          </Button>
        </Box>
      )}
      <Dialog
        title=""
        id="ad-usage-export-error-message"
        open={hasExportError}
        onClose={() => setHasExportError(false)}
      >
        <Box background="surface">
          <Box textAlign="center" paddingBottom="medium">
            <Illustrations />
          </Box>
          <Stack space="medium">
            <Heading level="2" align="center">
              {t("Can't download report")}
            </Heading>
            <Text align="center">
              {t('Refresh the page or try again later')}
            </Text>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  );
};
