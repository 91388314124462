export const CONV_RATE_CONFIG = {
  MIN_TONE_RATE: 0.2,
  TIMES_MORE_RATE: 1.05,
  MAX_ADS_NO_RATING_RATE: 0.1,
};

const isOverNoRatingRange = (noAdsRating: number) =>
  noAdsRating >= CONV_RATE_CONFIG.MAX_ADS_NO_RATING_RATE;

const isGreaterThanMinRate = (adConvRate: number, similarAdConvRate: number) =>
  adConvRate >
  parseFloat(
    (similarAdConvRate * (1 + CONV_RATE_CONFIG.MIN_TONE_RATE)).toFixed(1),
  );

const isLessThanMinRate = (adConvRate: number, similarAdConvRate: number) =>
  adConvRate <
  parseFloat(
    (similarAdConvRate * (1 - CONV_RATE_CONFIG.MIN_TONE_RATE)).toFixed(1),
  );

const isNumberEqual = (origin: number, similar: number) =>
  Math.round(
    parseFloat(Math.abs(((origin - similar) / similar) * 100).toFixed(1)),
  ) === 0;

const hasLinkOutAds = (numLinkOutAds: number | undefined): boolean =>
  Boolean(numLinkOutAds && numLinkOutAds > 0);

const conversionRateGreaterThanAboveMarketThreshold = (
  adConvRate: number,
  similarAdConvRate: number,
) =>
  adConvRate >
  parseFloat(
    (similarAdConvRate * (1 + CONV_RATE_CONFIG.MIN_TONE_RATE)).toFixed(1),
  );

const conversionRateLessThanBelowMarketThreshold = (
  adConvRate: number,
  similarAdConvRate: number,
) =>
  adConvRate <
  parseFloat(
    (similarAdConvRate * (1 - CONV_RATE_CONFIG.MIN_TONE_RATE)).toFixed(1),
  );

export {
  isOverNoRatingRange,
  isGreaterThanMinRate,
  isLessThanMinRate,
  isNumberEqual,
  hasLinkOutAds,
  conversionRateGreaterThanAboveMarketThreshold,
  conversionRateLessThanBelowMarketThreshold,
};
