import { Box, Heading, Stack, Text } from 'braid-design-system';
import type React from 'react';

import type { Money } from '../../gql/generated';
import { AnimatedCurrency } from '../AnimatedText';
import { DisclosureDialog } from '../DisclosureDialog/DisclosureDialog';

export interface SpendCardProps {
  cost: Money;
  children?: ReactNodeNoStrings;
  description: string;
  title: string;
  titleTooltip: ReactNodeNoStrings;
}

export const SpendCard = ({
  cost,
  children,
  description,
  title,
  titleTooltip,
}: SpendCardProps) => (
  <Box
    background="surface"
    boxShadow="borderNeutralLight"
    padding="medium"
    borderRadius="large"
    width="full"
    height="full"
  >
    <Stack space="medium">
      <DisclosureDialog tooltip={titleTooltip}>
        <Text size="small">{title}</Text>
      </DisclosureDialog>
      <Stack space="gutter">
        <Heading level="3">
          <AnimatedCurrency currency={cost.currency} value={cost.value} />
        </Heading>
        <Text tone="secondary">{description}</Text>
      </Stack>
      {children}
    </Stack>
  </Box>
);
