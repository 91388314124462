import { useTranslations } from '@vocab/react';

import { GenericTitleContainer } from '../../components/GenericTitleContainer/GenericTitleContainer';
import { PrimarySection } from '../../components/PrimarySection/PrimarySection';
import { WidgetErrorNvl } from '../../components/WidgetError/WidgetErrorNvl';

import translations from './.vocab';

export const MoreStatsFallback = () => {
  const { t } = useTranslations(translations);
  return (
    <PrimarySection>
      <GenericTitleContainer title={t('More stats')}>
        <WidgetErrorNvl />
      </GenericTitleContainer>
    </PrimarySection>
  );
};
