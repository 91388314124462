import { getSite, type EmployerSiteName } from '../../config';
import type { Environment, FeatureConfigValue } from '../types';

export const getSiteEnvConfig = (
  value: {
    [key in Environment]:
      | FeatureConfigValue
      | { [site in EmployerSiteName]: FeatureConfigValue };
  },
  env: Environment,
): FeatureConfigValue => {
  const site: EmployerSiteName = getSite();
  const dict = value! as any;
  const val = dict[env][site] ? dict[env][site] : dict[env];
  return val;
};
