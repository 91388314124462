import { useTranslations } from '@vocab/react';
import type React from 'react';

import { AdStatErrorOrLoading } from '../../components/AdStatErrorOrLoading/AdStatErrorOrLoading';

import translations from './.vocab';

export interface ApplicationPerformanceProps {
  hasBorder?: boolean;
}

export const ApplicationPerformanceFallback =
  ({}: ApplicationPerformanceProps) => {
    const { t } = useTranslations(translations);
    return (
      <AdStatErrorOrLoading
        headingLabel={t('Candidates')}
        headingTooltip={t(
          'A candidate completed their application for your job ad on SEEK',
        )}
        isLoading={false}
      />
    );
  };
