import { useEffect, useState } from 'react';

import type { AdUsageQueryFilters } from '../types/AdUsageTypes';

interface JobAdListPayloadResponse {
  pageNumber: number;
  filters: AdUsageQueryFilters;
}

const INIT_PAGE_NUMBER = 1;

export const useJobAdListPayload = (
  adUsageQueryFilters: AdUsageQueryFilters,
): {
  payload: JobAdListPayloadResponse;
  setPayload: (payload: JobAdListPayloadResponse) => void;
} => {
  const [payload, setPayload] = useState({
    pageNumber: INIT_PAGE_NUMBER,
    filters: adUsageQueryFilters,
  });
  const [prevQueryFilters, setPrevQueryFilters] = useState('');

  // Watch filters data changes and reset to page 1
  useEffect(() => {
    const queryString = JSON.stringify(adUsageQueryFilters);
    if (prevQueryFilters !== queryString) {
      setPrevQueryFilters(queryString);
      setPayload({
        pageNumber: INIT_PAGE_NUMBER,
        filters: adUsageQueryFilters,
      });
    }
  }, [adUsageQueryFilters, prevQueryFilters, setPayload]);

  return {
    payload,
    setPayload,
  };
};
