import type { UserOption } from '../../../types/AdUsageFiltersResponse';

export const buildUserFilterData = (
  data: UserOption[],
  notSpecifiedLabel: string,
) =>
  data
    .map((userOption) =>
      userOption.key === 'NOT_SPECIFIED'
        ? { ...userOption, value: notSpecifiedLabel }
        : userOption,
    )
    .sort((a: UserOption, b: UserOption) => {
      if (a.key === 'NOT_SPECIFIED') {
        return 1;
      }
      return a.value <= b.value ? -1 : 1;
    });
