
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoi4LmA4LiK4Li34LmI4Lit4Lih4LiV4LmI4Lit4LmA4LiK4Li04LiH4Lij4Li44LiB4LiB4Lix4Lia4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LmE4LiU4LmJ4Lih4Liy4LiB4LiC4Li24LmJ4LiZIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+4LmA4Lij4Liy4Lie4Lia4Lin4LmI4Liy4Lih4Li1IDxTdHJvbmc+4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lih4Liy4LiB4LiW4Li24LiHIDEwMCDguYLguJvguKPguYTguJ/guKXguYw8L1N0cm9uZz7guJfguLXguYjguJXguKPguIfguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJPguYHguKXguLDguJ7guKfguIHguYDguILguLLguYDguJ7guLbguYjguIfguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYzguYPguJnguJDguLLguJnguILguYnguK3guKHguLnguKXguILguK3guIfguYDguKPguLI8L1RleHQ+PFRleHQ+4Liq4Liz4Lir4Lij4Lix4Lia4LiX4Li44LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC5gOC4o+C4i+C4ueC5gOC4oeC5iOC5geC4peC4sOC4leC4tOC4lOC4leC5iOC4reC4geC4seC4muC4nOC4ueC5ieC4q+C4suC4h+C4suC4meC4nOC5iOC4suC4mSBTRUVLIFRhbGVudCBTZWFyY2g8L1RleHQ+PC9TdGFjaz4iLCJWaWV3IG1hdGNoZXMiOiLguJTguLnguYLguJvguKPguYTguJ/guKXguYzguJfguLXguYjguJXguKPguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoi4LmA4LiK4Li34LmI4Lit4Lih4LiV4LmI4Lit4LmA4LiK4Li04LiH4Lij4Li44LiB4LiB4Lix4Lia4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LmE4LiU4LmJ4Lih4Liy4LiB4LiC4Li24LmJ4LiZIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+4LmA4Lij4Liy4Lie4Lia4Lin4LmI4Liy4Lih4Li1IDxTdHJvbmc+4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lih4Liy4LiB4LiW4Li24LiHIDEwMCDguYLguJvguKPguYTguJ/guKXguYw8L1N0cm9uZz7guJfguLXguYjguJXguKPguIfguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJPguYHguKXguLDguJ7guKfguIHguYDguILguLLguYDguJ7guLbguYjguIfguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYzguYPguJnguJDguLLguJnguILguYnguK3guKHguLnguKXguILguK3guIfguYDguKPguLI8L1RleHQ+PFRleHQ+4Liq4Liz4Lir4Lij4Lix4Lia4LiX4Li44LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC5gOC4o+C4i+C4ueC5gOC4oeC5iOC5geC4peC4sOC4leC4tOC4lOC4leC5iOC4reC4geC4seC4muC4nOC4ueC5ieC4q+C4suC4h+C4suC4meC4nOC5iOC4suC4mSBTRUVLIFRhbGVudCBTZWFyY2g8L1RleHQ+PC9TdGFjaz4iLCJWaWV3IG1hdGNoZXMiOiLguJTguLnguYLguJvguKPguYTguJ/guKXguYzguJfguLXguYjguJXguKPguIcifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiVGVyaHVidW5nIHNlY2FyYSBwcm9ha3RpZiBkZW5nYW4gbGViaWggYmFueWFrIGthbmRpZGF0IiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+S2FtaSBtZW5lbXVrYW4gPFN0cm9uZz5oaW5nZ2EgMTAwPC9TdHJvbmc+IGthbmRpZGF0IHlhbmcgYmFydS1iYXJ1IGluaSBtZW1wZXJiYXJ1aSBwcm9maWwgZGkgYmFzaXMgZGF0YSBrYW1pIHlhbmcgY29jb2sgZGVuZ2FuIGxvd29uZ2FuIGtlcmphIEFuZGEuPC9UZXh0PjxUZXh0PlNldGlhcCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpcG9zdGluZyBtZW11bmdraW5rYW4gQW5kYSBtZW5ndW5kdWggQ1YgZGFuIHRlcmh1YnVuZyBkZW5nYW4ga2FuZGlkYXQgbWVsYWx1aSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiTWVsaWhhdCB5YW5nIGNvY29rIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiVGVyaHVidW5nIHNlY2FyYSBwcm9ha3RpZiBkZW5nYW4gbGViaWggYmFueWFrIGthbmRpZGF0IiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+S2FtaSBtZW5lbXVrYW4gPFN0cm9uZz5oaW5nZ2EgMTAwPC9TdHJvbmc+IGthbmRpZGF0IHlhbmcgYmFydS1iYXJ1IGluaSBtZW1wZXJiYXJ1aSBwcm9maWwgZGkgYmFzaXMgZGF0YSBrYW1pIHlhbmcgY29jb2sgZGVuZ2FuIGxvd29uZ2FuIGtlcmphIEFuZGEuPC9UZXh0PjxUZXh0PlNldGlhcCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpcG9zdGluZyBtZW11bmdraW5rYW4gQW5kYSBtZW5ndW5kdWggQ1YgZGFuIHRlcmh1YnVuZyBkZW5nYW4ga2FuZGlkYXQgbWVsYWx1aSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiTWVsaWhhdCB5YW5nIGNvY29rIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoi4LmA4LiK4Li34LmI4Lit4Lih4LiV4LmI4Lit4LmA4LiK4Li04LiH4Lij4Li44LiB4LiB4Lix4Lia4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LmE4LiU4LmJ4Lih4Liy4LiB4LiC4Li24LmJ4LiZIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+4LmA4Lij4Liy4Lie4Lia4Lin4LmI4Liy4Lih4Li1IDxTdHJvbmc+4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lih4Liy4LiB4LiW4Li24LiHIDEwMCDguYLguJvguKPguYTguJ/guKXguYw8L1N0cm9uZz7guJfguLXguYjguJXguKPguIfguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJPguYHguKXguLDguJ7guKfguIHguYDguILguLLguYDguJ7guLbguYjguIfguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYzguYPguJnguJDguLLguJnguILguYnguK3guKHguLnguKXguILguK3guIfguYDguKPguLI8L1RleHQ+PFRleHQ+4Liq4Liz4Lir4Lij4Lix4Lia4LiX4Li44LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC5gOC4o+C4i+C4ueC5gOC4oeC5iOC5geC4peC4sOC4leC4tOC4lOC4leC5iOC4reC4geC4seC4muC4nOC4ueC5ieC4q+C4suC4h+C4suC4meC4nOC5iOC4suC4mSBTRUVLIFRhbGVudCBTZWFyY2g8L1RleHQ+PC9TdGFjaz4iLCJWaWV3IG1hdGNoZXMiOiLguJTguLnguYLguJvguKPguYTguJ/guKXguYzguJfguLXguYjguJXguKPguIcifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoi4LmA4LiK4Li34LmI4Lit4Lih4LiV4LmI4Lit4LmA4LiK4Li04LiH4Lij4Li44LiB4LiB4Lix4Lia4Lic4Li54LmJ4Lir4Liy4LiH4Liy4LiZ4LmE4LiU4LmJ4Lih4Liy4LiB4LiC4Li24LmJ4LiZIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+4LmA4Lij4Liy4Lie4Lia4Lin4LmI4Liy4Lih4Li1IDxTdHJvbmc+4LmC4Lib4Lij4LmE4Lif4Lil4LmM4Lih4Liy4LiB4LiW4Li24LiHIDEwMCDguYLguJvguKPguYTguJ/guKXguYw8L1N0cm9uZz7guJfguLXguYjguJXguKPguIfguIHguLHguJrguJvguKPguLDguIHguLLguKjguIfguLLguJnguILguK3guIfguITguLjguJPguYHguKXguLDguJ7guKfguIHguYDguILguLLguYDguJ7guLbguYjguIfguK3guLHguJvguYDguJTguJXguYLguJvguKPguYTguJ/guKXguYzguYPguJnguJDguLLguJnguILguYnguK3guKHguLnguKXguILguK3guIfguYDguKPguLI8L1RleHQ+PFRleHQ+4Liq4Liz4Lir4Lij4Lix4Lia4LiX4Li44LiB4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC4lOC4suC4p+C4meC5jOC5guC4q+C4peC4lOC5gOC4o+C4i+C4ueC5gOC4oeC5iOC5geC4peC4sOC4leC4tOC4lOC4leC5iOC4reC4geC4seC4muC4nOC4ueC5ieC4q+C4suC4h+C4suC4meC4nOC5iOC4suC4mSBTRUVLIFRhbGVudCBTZWFyY2g8L1RleHQ+PC9TdGFjaz4iLCJWaWV3IG1hdGNoZXMiOiLguJTguLnguYLguJvguKPguYTguJ/guKXguYzguJfguLXguYjguJXguKPguIcifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiVGVyaHVidW5nIHNlY2FyYSBwcm9ha3RpZiBkZW5nYW4gbGViaWggYmFueWFrIGthbmRpZGF0IiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+S2FtaSBtZW5lbXVrYW4gPFN0cm9uZz5oaW5nZ2EgMTAwPC9TdHJvbmc+IGthbmRpZGF0IHlhbmcgYmFydS1iYXJ1IGluaSBtZW1wZXJiYXJ1aSBwcm9maWwgZGkgYmFzaXMgZGF0YSBrYW1pIHlhbmcgY29jb2sgZGVuZ2FuIGxvd29uZ2FuIGtlcmphIEFuZGEuPC9UZXh0PjxUZXh0PlNldGlhcCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpcG9zdGluZyBtZW11bmdraW5rYW4gQW5kYSBtZW5ndW5kdWggQ1YgZGFuIHRlcmh1YnVuZyBkZW5nYW4ga2FuZGlkYXQgbWVsYWx1aSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiTWVsaWhhdCB5YW5nIGNvY29rIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiVGVyaHVidW5nIHNlY2FyYSBwcm9ha3RpZiBkZW5nYW4gbGViaWggYmFueWFrIGthbmRpZGF0IiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiI8U3RhY2s+PFRleHQ+S2FtaSBtZW5lbXVrYW4gPFN0cm9uZz5oaW5nZ2EgMTAwPC9TdHJvbmc+IGthbmRpZGF0IHlhbmcgYmFydS1iYXJ1IGluaSBtZW1wZXJiYXJ1aSBwcm9maWwgZGkgYmFzaXMgZGF0YSBrYW1pIHlhbmcgY29jb2sgZGVuZ2FuIGxvd29uZ2FuIGtlcmphIEFuZGEuPC9UZXh0PjxUZXh0PlNldGlhcCBsb3dvbmdhbiBrZXJqYSB5YW5nIGRpcG9zdGluZyBtZW11bmdraW5rYW4gQW5kYSBtZW5ndW5kdWggQ1YgZGFuIHRlcmh1YnVuZyBkZW5nYW4ga2FuZGlkYXQgbWVsYWx1aSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiTWVsaWhhdCB5YW5nIGNvY29rIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiUHJvYWN0aXZlbHkgY29ubmVjdCB3aXRoIG1vcmUgY2FuZGlkYXRlcyIsIk1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIGFkIGhhdmUgYmVlbiBmb3VuZCBpbiBUYWxlbnQgU2VhcmNoIjoiPFN0YWNrPjxUZXh0PldlJ3ZlIGZvdW5kIDxTdHJvbmc+dXAgdG8gMTAwPC9TdHJvbmc+IG1hdGNoaW5nIGNhbmRpZGF0ZXMgZm9yIHlvdXIgYWQgd2hvIGhhdmUgcmVjZW50bHkgdXBkYXRlZCB0aGVpciBwcm9maWxlIGluIG91ciBkYXRhYmFzZS48L1RleHQ+PFRleHQ+RWFjaCBhZCB5b3UgcG9zdCBsZXRzIHlvdSBkb3dubG9hZCBDVnMgYW5kIGNvbm5lY3Qgd2l0aCBjYW5kaWRhdGVzIHZpYSBTRUVLIFRhbGVudCBTZWFyY2guPC9UZXh0PjwvU3RhY2s+IiwiVmlldyBtYXRjaGVzIjoiVmlldyBtYXRjaGVzIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiW8akxZnDtsO2w7bEg8SDxIPDp+G5r8Osw6zDrOG5veG6veG6veG6vcaaw73DvcO9IMOnw7bDtsO24LiB4Li14LmJ4LiB4Li14LmJ4bq94bq94bq9w6fhua8gxbXDrMOsw6zhua/huKkgbcyCw7bDtsO2xZnhur3hur3hur0gw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaFdIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiJbPFN0YWNrPjxUZXh0PsW04bq94bq94bq9J+G5veG6veG6veG6vSDGksO2w7bDtseax5rHmuC4geC4teC5icaMIDxTdHJvbmc+x5rHmseaxqUg4bmvw7bDtsO2IDEwMDwvU3Ryb25nPiBtzILEg8SDxIPhua/Dp+G4qcOsw6zDrOC4geC4teC5icSjIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg+G5r+G6veG6veG6vcWhIMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxbXhuKnDtsO2w7Yg4bipxIPEg8SD4bm94bq94bq94bq9IMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua/GmsO9w73DvSDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xowg4bmv4bip4bq94bq94bq9w6zDrMOsxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0gw6zDrMOs4LiB4Li14LmJIMO2w7bDtseax5rHmsWZIMaMxIPEg8SD4bmvxIPEg8SDw5/Eg8SDxIPFoeG6veG6veG6vS48L1RleHQ+PFRleHQ+w4vEg8SDxIPDp+G4qSDEg8SDxIPGjCDDvcO9w73DtsO2w7bHmseax5ogxqXDtsO2w7bFoeG5ryDGmuG6veG6veG6veG5r8WhIMO9w73DvcO2w7bDtseax5rHmiDGjMO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDDh+G5vMWhIMSDxIPEg+C4geC4teC5icaMIMOnw7bDtsO24LiB4Li14LmJ4LiB4Li14LmJ4bq94bq94bq9w6fhua8gxbXDrMOsw6zhua/huKkgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaEg4bm9w6zDrMOsxIPEg8SDIMWgw4vDi+G4sCDhua7Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkuPC9UZXh0PjwvU3RhY2s+XSIsIlZpZXcgbWF0Y2hlcyI6IlvhubzDrMOsw6zhur3hur3hur3FtSBtzILEg8SDxIPhua/Dp+G4qeG6veG6veG6vcWhXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJQcm9hY3RpdmVseSBjb25uZWN0IHdpdGggbW9yZSBjYW5kaWRhdGVzIjoiW8akxZnDtsO2w7bEg8SDxIPDp+G5r8Osw6zDrOG5veG6veG6veG6vcaaw73DvcO9IMOnw7bDtsO24LiB4Li14LmJ4LiB4Li14LmJ4bq94bq94bq9w6fhua8gxbXDrMOsw6zhua/huKkgbcyCw7bDtsO2xZnhur3hur3hur0gw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaFdIiwiTWF0Y2hpbmcgY2FuZGlkYXRlcyBmb3IgYWQgaGF2ZSBiZWVuIGZvdW5kIGluIFRhbGVudCBTZWFyY2giOiJbPFN0YWNrPjxUZXh0PsW04bq94bq94bq9J+G5veG6veG6veG6vSDGksO2w7bDtseax5rHmuC4geC4teC5icaMIDxTdHJvbmc+x5rHmseaxqUg4bmvw7bDtsO2IDEwMDwvU3Ryb25nPiBtzILEg8SDxIPhua/Dp+G4qcOsw6zDrOC4geC4teC5icSjIMOnxIPEg8SD4LiB4Li14LmJxozDrMOsw6zGjMSDxIPEg+G5r+G6veG6veG6vcWhIMaSw7bDtsO2xZkgw73DvcO9w7bDtsO2x5rHmseaxZkgxIPEg8SDxowgxbXhuKnDtsO2w7Yg4bipxIPEg8SD4bm94bq94bq94bq9IMWZ4bq94bq94bq9w6fhur3hur3hur3guIHguLXguYnhua/GmsO9w73DvSDHmseax5rGpcaMxIPEg8SD4bmv4bq94bq94bq9xowg4bmv4bip4bq94bq94bq9w6zDrMOsxZkgxqXFmcO2w7bDtsaSw6zDrMOsxprhur3hur3hur0gw6zDrMOs4LiB4Li14LmJIMO2w7bDtseax5rHmsWZIMaMxIPEg8SD4bmvxIPEg8SDw5/Eg8SDxIPFoeG6veG6veG6vS48L1RleHQ+PFRleHQ+w4vEg8SDxIPDp+G4qSDEg8SDxIPGjCDDvcO9w73DtsO2w7bHmseax5ogxqXDtsO2w7bFoeG5ryDGmuG6veG6veG6veG5r8WhIMO9w73DvcO2w7bDtseax5rHmiDGjMO2w7bDtsW14LiB4Li14LmJxprDtsO2w7bEg8SDxIPGjCDDh+G5vMWhIMSDxIPEg+C4geC4teC5icaMIMOnw7bDtsO24LiB4Li14LmJ4LiB4Li14LmJ4bq94bq94bq9w6fhua8gxbXDrMOsw6zhua/huKkgw6fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9xaEg4bm9w6zDrMOsxIPEg8SDIMWgw4vDi+G4sCDhua7Eg8SDxIPGmuG6veG6veG6veC4geC4teC5ieG5ryDFoOG6veG6veG6vcSDxIPEg8WZw6fhuKkuPC9UZXh0PjwvU3RhY2s+XSIsIlZpZXcgbWF0Y2hlcyI6IlvhubzDrMOsw6zhur3hur3hur3FtSBtzILEg8SDxIPhua/Dp+G4qeG6veG6veG6vcWhXSJ9!"
        )
      )
      });
  
      export { translations as default };
    