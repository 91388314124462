
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0Ijoi4LiW4Lix4LiU4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0Ijoi4LiW4Lix4LiU4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiU2VsYW5qdXRueWEiLCJQcmV2aW91cyI6IlNlYmVsdW1ueWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiU2VsYW5qdXRueWEiLCJQcmV2aW91cyI6IlNlYmVsdW1ueWEifQ==!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0Ijoi4LiW4Lix4LiU4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0Ijoi4LiW4Lix4LiU4LmE4LibIiwiUHJldmlvdXMiOiLguIHguYjguK3guJnguKvguJnguYnguLIifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiU2VsYW5qdXRueWEiLCJQcmV2aW91cyI6IlNlYmVsdW1ueWEifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiU2VsYW5qdXRueWEiLCJQcmV2aW91cyI6IlNlYmVsdW1ueWEifQ==!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiTmV4dCIsIlByZXZpb3VzIjoiUHJldmlvdXMifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiW8OR4bq94bq94bq96q2V4bmvXSIsIlByZXZpb3VzIjoiW8akxZnhur3hur3hub3DrMOsw7bDtseax5rFoV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXh0IjoiW8OR4bq94bq94bq96q2V4bmvXSIsIlByZXZpb3VzIjoiW8akxZnhur3hur3hub3DrMOsw7bDtseax5rFoV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    