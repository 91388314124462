import { useResponsiveValue } from 'braid-design-system';

export const useResponsiveBreakpoints = () => {
  const responsiveValue = useResponsiveValue();

  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
    desktop: false,
    wide: false,
  });

  const isTablet = responsiveValue({
    mobile: false,
    tablet: true,
    desktop: false,
    wide: false,
  });

  const isTabletAbove = responsiveValue({
    mobile: false,
    tablet: true,
    desktop: true,
    wide: true,
  });

  const isMobileOrTablet = responsiveValue({
    mobile: true,
    tablet: true,
    desktop: false,
    wide: false,
  });

  const isDesktop = responsiveValue({
    mobile: false,
    tablet: false,
    desktop: true,
    wide: false,
  });

  const isDesktopAbove = responsiveValue({
    mobile: false,
    tablet: false,
    desktop: true,
    wide: true,
  });

  const isWide = responsiveValue({
    mobile: false,
    tablet: false,
    desktop: false,
    wide: true,
  });

  return {
    isMobile,
    isTablet,
    isTabletAbove,
    isMobileOrTablet,
    isDesktop,
    isDesktopAbove,
    isWide,
  };
};
