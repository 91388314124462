
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s+C4meC4p+C4meC4hOC4o+C4seC5ieC4h+C4guC4reC4h+C4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSkifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s+C4meC4p+C4meC4hOC4o+C4seC5ieC4h+C4guC4reC4h+C4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSkifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s+C4meC4p+C4meC4hOC4o+C4seC5ieC4h+C4guC4reC4h+C4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSkifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT7guIjguLPguJnguKfguJnguJzguLnguYnguKvguLLguIfguLLguJk8L01lZGl1bT4gKOC4iOC4s+C4meC4p+C4meC4hOC4o+C4seC5ieC4h+C4guC4reC4h+C4geC4suC4o+C4hOC5ieC4meC4q+C4suC4h+C4suC4mSkifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5KdW1sYWgga2FuZGlkYXQ8L01lZGl1bT4gKGFrdGl2aXRhcyBwZW5jYXJpYW4pIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiPE1lZGl1bT5DYW5kaWRhdGUgc3VwcGx5PC9NZWRpdW0+IChzZWFyY2ggYWN0aXZpdHkpIn0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiWzxNZWRpdW0+w4fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMWhx5rHmseaxqXGpcaaw73DvcO9PC9NZWRpdW0+ICjFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDw6fhua/DrMOsw6zhub3DrMOsw6zhua/DvcO9w70pXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDYW5kaWRhdGUgc3VwcGx5IChzZWFyY2ggYWN0aXZpdHkpIjoiWzxNZWRpdW0+w4fEg8SDxIPguIHguLXguYnGjMOsw6zDrMaMxIPEg8SD4bmv4bq94bq94bq9IMWhx5rHmseaxqXGpcaaw73DvcO9PC9NZWRpdW0+ICjFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxIPEg8SDw6fhua/DrMOsw6zhub3DrMOsw6zhua/DvcO9w70pXSJ9!"
        )
      )
      });
  
      export { translations as default };
    