/**
 * Local developoment configuration
 */
const advertiserId = 60012870;
const config = {
  devGraphUrl: 'https://talent.staging.seek.com.au/graphql',
  advertiserId,
  authRedirectUrl: `/oauth/integration/?#/?fn=loginWithScope&realm=seek-okta-global&return_uri=%2Fanalytics%2Finsights-role&scope=advertiser%3A${advertiserId}`, // /oauth/debug

  // To connect to the local graphql server
  // devGraphUrl: 'http://localhost:4000/graphql',
};

export default config;
