import type { Country, Locale } from '@seek/melways-sites';

class LocaleHelper {
  private static _instance: LocaleHelper;

  private readonly AU: Country = 'au';
  private readonly NZ: Country = 'nz';
  private _locale?: Locale;
  private _country?: Country;

  private constructor() {}

  public static getInstance(): LocaleHelper {
    if (!LocaleHelper._instance) {
      LocaleHelper._instance = new LocaleHelper();
    }
    return LocaleHelper._instance;
  }

  public initialise(locale: Locale) {
    this._locale = locale;
    this._country = this._getCountryCode();
  }

  get isANZ() {
    return [this.AU, this.NZ].includes(this._country as Country);
  }

  get isAsia() {
    return !this.isANZ;
  }

  get isID() {
    return this._country === 'id';
  }

  get isPH() {
    return this._country === 'ph';
  }

  private _getCountryCode() {
    const data = (this._locale as Locale).split('-');
    return data[1].toLocaleLowerCase() as Country;
  }
}

export const region = (() => LocaleHelper.getInstance())();
