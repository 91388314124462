import { formatMinorUnitsToParts } from '@seek/currency';

import { getLocale, getSite } from '../../config';
import type { Money } from '../../gql/generated';

import { getSiteCurrency } from './getSiteCurrency';

export const formatCurrency = (cost: Money) => {
  const { currency, value } = cost;
  const isCurrencyWhereMinorUnitsIsBanned = currency === 'IDR';

  const minorUnitParts = formatMinorUnitsToParts(value, {
    locale: getLocale(),
    currency,
    display: getSiteCurrency(getSite()) !== currency ? 'code' : 'narrowSymbol',
    notation: 'standard',
  });

  const formattedCurrency = minorUnitParts.reduce(
    (result, { type, value: partValue }) => {
      if (
        isCurrencyWhereMinorUnitsIsBanned &&
        (type === 'decimal' || type === 'fraction')
      ) {
        return result;
      }
      return result + partValue;
    },
    '',
  );

  return formattedCurrency;
};
