import { gql } from '@apollo/client';

export const performancePredictionQuery = gql`
  query GetPerformancePrediction($input: PerformancePredictionWidgetInput!) {
    analyticsPerformancePrediction(input: $input) {
      events {
        comparisonValue
        date
        day
        editMade
        value
      }
      predictionDate
      predictionDayOptions {
        text
        prediction
      }
      valueLabel
      xRange
      xAxisLabels {
        label
        date
      }
      lastUpdatedTimestampUTC
      yRange
    }
  }
`;
