import { useTranslations } from '@vocab/react';
import type React from 'react';

import { AdStatErrorOrLoading } from '../../components/AdStatErrorOrLoading/AdStatErrorOrLoading';

import translations from './.vocab';

export const ViewsInJobSearchFallback = () => {
  const { t } = useTranslations(translations);
  return (
    <AdStatErrorOrLoading
      headingLabel={t('Views in job search')}
      headingTooltip={t('Your ad snippet appeared in the SEEK job search')}
      isLoading={false}
    />
  );
};
