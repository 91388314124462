import { type ApolloError, useQuery } from '@apollo/client';

import { useAdUsageQueryFilters } from '../context/adUsageFilters';
import { conversionAdQuery } from '../queries/conversionAdQuery';
import type { AdUsageQueryFilters } from '../types/AdUsageTypes';
import type { ConversionResponse } from '../types/ConversionResponse';

interface ConversionQueryParams {
  input: {
    filters: AdUsageQueryFilters;
  };
}

export const useConversionData = (): {
  data: ConversionResponse | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const filters = useAdUsageQueryFilters();
  const { data, loading, error, previousData } = useQuery<
    { analyticsAdUsageConversion: ConversionResponse },
    ConversionQueryParams
  >(conversionAdQuery, {
    variables: {
      input: {
        filters,
      },
    },
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdUsageConversion,
    isLoading: loading,
    error,
  };
};
