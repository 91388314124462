import { ProgressBarChart } from '@seek/charts';
import { useTranslations } from '@vocab/react';
import { Box, Column, Columns, Loader, Stack, Text } from 'braid-design-system';
import { type ReactNode, useMemo } from 'react';

import { DisclosureDialog } from '../../components/DisclosureDialog/DisclosureDialogNew';
import { WidgetError } from '../../components/WidgetError/WidgetError';
import { getLocale } from '../../config';
import { useMarketPerformanceData } from '../../hooks/useMarketPerformanceData';
import {
  percentFromNumbersWithoutDecimal,
  percentWithoutDecimal,
} from '../../utils/formatters/percentFormatter';

import translations from './.vocab';

const MarketPerformanceContainer = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => (
  <Box maxWidth="small" height="full">
    <Box
      height="full"
      display="flex"
      flexDirection="column"
      style={{ gap: 32 }}
    >
      <MarketPerformanceTitle />
      {children}
    </Box>
  </Box>
);

export const MarketPerformanceTitle = () => {
  const { t } = useTranslations(translations);

  const adPerformanceTranslationOption: ReactNodeNoStrings = {
    Disclosure: (children: string) => (
      <DisclosureDialog
        id="market-performance-header-tooltip"
        tooltip={t('Ad performance tip introduction', {
          Stack: (child: ReactNodeNoStrings) => (
            <Stack space="medium">{child}</Stack>
          ),
          Text: (child: ReactNodeNoStrings) => (
            <Text key={child} size="small">
              {child}
            </Text>
          ),
        })}
        key={children}
      >
        {({ triggerProps }) => <span {...triggerProps}>{children}</span>}
      </DisclosureDialog>
    ),
  };

  return (
    <Box paddingTop="small">
      <Text>
        {t('Overall ad performance rating', adPerformanceTranslationOption)}
      </Text>
    </Box>
  );
};

export const MarketPerformance = () => {
  const { t } = useTranslations(translations);

  const {
    data: marketPerformanceData,
    isLoading,
    error,
  } = useMarketPerformanceData();

  const chartData: Array<{
    ads: number;
    color: string;
    progress: number;
    title: string;
    tooltip: ReactNode;
  }> = useMemo(() => {
    if (!marketPerformanceData) {
      return [];
    }

    const translationOption = {
      Stack: (children: ReactNodeNoStrings) => (
        <Stack space="medium">{children}</Stack>
      ),
      Text: (children: ReactNodeNoStrings) => (
        <Text key={children} size="small">
          {children}
        </Text>
      ),
    };
    const { totalAds, highAds, lowAds, normalAds } = marketPerformanceData;

    return [
      {
        ads: highAds,
        progress: percentWithoutDecimal({
          denominator: totalAds,
          numerator: highAds,
        }),
        color: 'rgb(62, 183, 61)',
        title: t('High'),
        tooltip: t('Ad performance tip High rate', translationOption),
      },
      {
        ads: normalAds,
        color: 'rgb(73, 100, 233)',
        progress: percentWithoutDecimal({
          denominator: totalAds,
          numerator: normalAds,
        }),
        title: t('Normal'),
        tooltip: t('Ad performance tip Normal rate', translationOption),
      },
      {
        ads: lowAds,
        color: 'rgb(249, 67, 68)',
        progress: percentWithoutDecimal({
          denominator: totalAds,
          numerator: lowAds,
        }),
        title: t('Low'),
        tooltip: t('Ad performance tip Low rate', translationOption),
      },
    ];
  }, [t, marketPerformanceData]);

  if (isLoading && !marketPerformanceData) {
    return (
      <MarketPerformanceContainer>
        <Box display="flex" justifyContent="center" margin="large">
          <Loader size="small" />
        </Box>
      </MarketPerformanceContainer>
    );
  }

  if (error || !marketPerformanceData) {
    return (
      <MarketPerformanceContainer>
        <Box display="flex" justifyContent="center">
          <WidgetError />
        </Box>
      </MarketPerformanceContainer>
    );
  }

  const { totalAds, noRatingAds } = marketPerformanceData;
  const numberFormatter = new Intl.NumberFormat(getLocale());

  return (
    <MarketPerformanceContainer>
      <Columns space="medium" align="left" alignY="top">
        <Column>
          <Stack space="large">
            {chartData.map((row) => (
              <Stack
                key={`ad-usage-performance-tab-market-performance-${row.title}`}
                space="medium"
              >
                <DisclosureDialog id={row.title} tooltip={row.tooltip}>
                  {({ triggerProps }) => (
                    <Text weight="medium" size="small">
                      <span {...triggerProps}>{row.title}</span>
                    </Text>
                  )}
                </DisclosureDialog>
                <ProgressBarChart
                  color={row.color}
                  progress={row.progress < 3 ? 3 : row.progress}
                />
              </Stack>
            ))}
          </Stack>
        </Column>
        <Column width="content">
          <Stack space="large">
            {chartData.map((row) => (
              <Box
                display="flex"
                alignItems="flexEnd"
                key={`ad-usage-performance-tab-market-performance-${row.title}`}
                style={{ height: 38 }}
              >
                <Text size="small">
                  {t('N ads of total', {
                    adCount: row.ads,
                    adCountText: numberFormatter.format(row.ads),
                    percentage: percentFromNumbersWithoutDecimal({
                      numerator: row.ads,
                      denominator: totalAds,
                    }),
                  })}
                </Text>
              </Box>
            ))}
          </Stack>
        </Column>
      </Columns>
      <Box style={{ marginTop: 'auto', marginBottom: 30 }}>
        <Text size="standard" tone="secondary">
          {t("N ads don't have a performance rating", {
            adCount: noRatingAds,
            adCountText: numberFormatter.format(noRatingAds),
            percentage: percentFromNumbersWithoutDecimal({
              numerator: noRatingAds,
              denominator: totalAds,
            }),
            Disclosure: (children: ReactNode[]) => (
              <DisclosureDialog
                id="no-rating-tool-tip"
                tooltip={<Text size="small">{t('No rating tooltip')}</Text>}
              >
                {({ triggerProps }) => (
                  <span {...triggerProps}>{children}</span>
                )}
              </DisclosureDialog>
            ),
          })}
        </Text>
      </Box>
    </MarketPerformanceContainer>
  );
};
