import { type ApolloError, useQuery } from '@apollo/client';

import { adUsageLastUpdatedQuery } from '../queries/adUsageLastUpdatedQuery';
import type { AdUsageLastUpdated } from '../types/AdUsageLastUpdatedResponse';

interface queryResponse {
  analyticsAdUsageLastUpdated: AdUsageLastUpdated;
}

export const useAdUsageLastUpdated = (): {
  data: AdUsageLastUpdated | undefined;
  error?: ApolloError;
  isLoading: boolean;
} => {
  const { data, loading, error, previousData } = useQuery<queryResponse>(
    adUsageLastUpdatedQuery,
    {
      errorPolicy: 'all',
    },
  );

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsAdUsageLastUpdated,
    isLoading: loading,
    error,
  };
};
