import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import translations from './.vocab';

export type ElapsedTimeTypes =
  | 'X hour(s) ago'
  | 'X day(s) ago'
  | 'X month(s) ago';

export interface ElapsedTimeParts {
  type: ElapsedTimeTypes;
  value: number;
}

export interface ElapsedTimeRenderProps {
  type: ElapsedTimeTypes;
  value: number;
}

export interface ElapsedTimeProps {
  pastTime: string | undefined;
  showTitle?: boolean;
  id?: string;
  children?: (renderProps: {
    renderProps: ElapsedTimeRenderProps;
  }) => ReactNode;
}

const mapElapsedTimeToParts = (
  pastTime: string | undefined,
): ElapsedTimeParts => {
  const current = new Date();
  const past = pastTime ? new Date(pastTime) : current;
  const deltaMilliseconds = current.getTime() - past.getTime();

  const deltaHours = Math.floor(deltaMilliseconds / (1000 * 3600));

  if (deltaHours <= 21) {
    return { type: 'X hour(s) ago', value: deltaHours };
  }

  const deltaDays = Math.round(deltaMilliseconds / (1000 * 3600 * 24));
  if (deltaDays <= 25) {
    return { type: 'X day(s) ago', value: deltaDays };
  }

  const deltaMonths = Math.round(deltaMilliseconds / (1000 * 3600 * 24 * 30));
  return { type: 'X month(s) ago', value: deltaMonths };
};

export const ElapsedTime = ({
  pastTime,
  id,
  showTitle,
  children,
}: ElapsedTimeProps) => {
  const { t } = useTranslations(translations);

  const elapsedTimeParts = mapElapsedTimeToParts(pastTime);
  const elapsedTimeLabel = children
    ? children({
        renderProps: {
          ...elapsedTimeParts,
        },
      })
    : t(elapsedTimeParts.type, { value: elapsedTimeParts.value });

  return (
    <Box
      data={{ id: id as string }}
      component="span"
      title={showTitle ? elapsedTimeLabel?.toString() : undefined}
    >
      {elapsedTimeLabel}
    </Box>
  );
};
