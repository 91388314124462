import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  Text,
  Strong,
  Inline,
  TextLink,
  Badge,
  IconPositive,
} from 'braid-design-system';

import { useMobileView } from '../../hooks/useMobileView';
import type { AdRatingScore } from '../../types/AdPerformanceTypes';
import type { AdRatingResponse } from '../../types/AdRatingResponse';
import { mapScenarioToGroupLabelParts } from '../../utils/roleTitleHelper';
import { AdRatingChart } from '../AdRatingChart/AdRatingChart';
import { AppCompletesTooltipContent } from '../AppCompletesTooltipContent/AppCompletesTooltipContent';
import { AppStartsTooltipContent } from '../AppStartsTooltipContent/AppStartsTooltipContent';
import { ClickableTooltip } from '../ClickableTooltip/ClickableTooltip';
import { ElapsedTime } from '../ElapsedTime/ElapsedTime';
import { Metric } from '../Metric/Metric';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';

import * as styles from './AdRating.css';

export interface AdRatingProps extends AdRatingResponse {
  similarAdsUrl: string;
  candidateApplicationsUrl: string;
  onMarketPerformanceTooltipClick: () => void;
  onSimilarAdsScroll: (e: HTMLElement) => void;
}

export const AdRating = ({
  adRatingScore,
  adRatingConfidence,
  applications,
  incompleteApplications,
  isLinkOutAd,
  applicationsBenchmarkHigh,
  applicationsBenchmarkLow,
  normalisedRoleTitle,
  benchmarkLocationLabel,
  lastUpdatedTimestampUTC,
  benchmarkScenario,
  classificationName,
  subClassificationName,
  similarAdsUrl,
  candidateApplicationsUrl,
  benchmarkLocationCountry,
  onSimilarAdsScroll,
}: AdRatingProps) => {
  const { t } = useTranslations(translations);

  const isMobile = useMobileView();

  const displayAdRating =
    adRatingScore !== null &&
    applicationsBenchmarkHigh !== null &&
    applicationsBenchmarkLow !== null &&
    benchmarkScenario !== null &&
    benchmarkScenario !== 'COUNTRY';

  const benchmarkGroupLabelParts = mapScenarioToGroupLabelParts({
    normalisedRoleTitle,
    benchmarkScenario,
    classificationName,
    subClassificationName,
    benchmarkLocationCountry,
  });

  const hasBenchmarkGroupLabel = benchmarkGroupLabelParts !== null;

  const isAdRatingConfidenceLow = adRatingConfidence === 'LOW';

  const adRatingScoreBadgeToneMap: Record<
    AdRatingScore,
    'positive' | 'info' | 'critical'
  > = {
    HIGH: 'positive',
    NORMAL: 'info',
    LOW: 'critical',
  };

  const badgeTone = displayAdRating
    ? adRatingScoreBadgeToneMap[adRatingScore]
    : undefined;

  return (
    <Stack space="large">
      <ZStack height={displayAdRating ? 171 : 'full'} width="full">
        {displayAdRating ? (
          <>
            <ZStackItem alignY="bottom">
              <AdRatingChart
                data={{
                  adRating: adRatingScore as AdRatingScore,
                  normalMax: applicationsBenchmarkHigh,
                  normalMin: applicationsBenchmarkLow,
                }}
              />
            </ZStackItem>
            <ZStackItem align="center" alignY="bottom">
              <Box
                data={{ id: 'ad-rating-widget' }}
                className={styles.labelOverlay}
                marginBottom="xsmall"
              >
                <Stack align="center" space="small">
                  <Metric
                    align="center"
                    metricLabelDataId="ui-rating-chart-metric"
                    metric={applications}
                    isCandidate={!isLinkOutAd}
                    metricSize="large"
                    weight="medium"
                    url={candidateApplicationsUrl}
                  />
                  <Inline space="xsmall" alignY="center" align="left">
                    <Text
                      size={isMobile ? 'small' : 'standard'}
                      weight="strong"
                      maxLines={1}
                    >
                      <TextLink href={candidateApplicationsUrl}>
                        {isLinkOutAd
                          ? t('Application start(s)', { count: applications })
                          : t('Candidate(s)', { count: applications })}
                      </TextLink>
                    </Text>
                    <ClickableTooltip
                      id="ad-rating-tooltip"
                      title={
                        isLinkOutAd
                          ? t('Application start(s)', { count: applications })
                          : t('Candidate(s)', { count: applications })
                      }
                      contents={
                        <Stack space="large">
                          {isLinkOutAd ? (
                            <AppStartsTooltipContent />
                          ) : (
                            <AppCompletesTooltipContent
                              incompleteApps={incompleteApplications}
                            />
                          )}
                          <Stack space="small">
                            <Text weight="strong" size="standard">
                              {t('Why is number different to other SEEK pages')}
                            </Text>
                            <Text size="standard">
                              {t('Number on this page may be outdated', {
                                ElapsedTime: () => (
                                  <ElapsedTime
                                    pastTime={lastUpdatedTimestampUTC}
                                  />
                                ),
                              })}
                            </Text>
                          </Stack>
                        </Stack>
                      }
                    />
                  </Inline>
                </Stack>
              </Box>
            </ZStackItem>
          </>
        ) : null}
      </ZStack>
      {displayAdRating && (
        <Stack space="medium" align="center">
          <Text align="center" size="large">
            {hasBenchmarkGroupLabel && benchmarkLocationLabel
              ? t(
                  `${adRatingScore} number of ${
                    isLinkOutAd ? 'application starts' : 'candidates'
                  } compared to benchmark ${
                    benchmarkGroupLabelParts.type
                  } group`,
                  {
                    Break: () => <br className={styles.breakStyle} />,
                    Span: (text) => <span>{text}</span>,
                    Highlight: (label) => (
                      <Badge tone={badgeTone}>{label.join('')}</Badge>
                    ),
                    Strong: (text) => <Strong>{text}</Strong>,
                    benchmarkGroupLabel: benchmarkGroupLabelParts.groupLabel,
                    benchmarkLocationLabel: benchmarkGroupLabelParts.isRegional
                      ? t('Regional {benchmarkLocationLabel}', {
                          benchmarkLocationLabel,
                        })
                      : benchmarkLocationLabel,
                  },
                )
              : t(
                  `${adRatingScore} number of ${
                    isLinkOutAd ? 'application starts' : 'candidates'
                  } compared to similar ads`,
                  {
                    Break: () => <br className={styles.breakStyle} />,
                    Span: (text) => <span>{text}</span>,
                    Highlight: (label) => (
                      <Badge tone={badgeTone}>{label.join('')}</Badge>
                    ),
                  },
                )}
          </Text>
          <Box background="neutralSoft" padding="xsmall" borderRadius="large">
            <Inline
              space={isMobile ? 'xxsmall' : 'xsmall'}
              alignY="center"
              align="left"
            >
              <Text
                tone="neutral"
                size="small"
                icon={
                  isAdRatingConfidenceLow ? undefined : (
                    <IconPositive tone="positive" />
                  )
                }
              >
                {t(
                  `Market performance based on ${
                    isAdRatingConfidenceLow ? 'broader' : 'similar'
                  } ads without tooltip`,
                )}
              </Text>
              <ClickableTooltip
                id="ad-rating-confidence-tooltip"
                title={t(
                  `Market performance based on ${
                    isAdRatingConfidenceLow ? 'broader' : 'similar'
                  } ads without tooltip`,
                )}
                contents={
                  <Text size="standard">
                    {isAdRatingConfidenceLow
                      ? t('Market performance broader tooltip')
                      : t('Market performance similar tooltip')}
                  </Text>
                }
                hashLinkProps={{
                  scroll: onSimilarAdsScroll,
                  to: similarAdsUrl,
                }}
                hashLinkLabel={t('View ads')}
              />
            </Inline>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
