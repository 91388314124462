export const SearchHeroIcon = () => (
  <svg viewBox="0 0 170 102" width="170px" height="102px">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(88 90)">
        <mask id="b" fill="#fff">
          <path d="M.074.691V2.51c0 1.65 1 3.054 2.413 3.627.434.176.905.28 1.402.28h51.704c.497 0 .969-.104 1.402-.28a3.9 3.9 0 002.413-3.627V.691H.074z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.724 10.306h66.931V-3.197H-3.724z"
          mask="url(#b)"
        />
      </g>
      <g transform="translate(30 91)">
        <mask id="d" fill="#fff">
          <path d="M.424.292v5.25h77.25c0-2.9-2.35-5.25-5.25-5.25h-72z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.326 9.292h84.75v-12.75h-84.75z"
          mask="url(#d)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M91.793 23C89.698 23 88 24.69 88 26.772v65.455C88 94.311 89.698 96 91.793 96h51.413c2.096 0 3.794-1.69 3.794-3.773V26.772c0-2.083-1.698-3.772-3.794-3.772h-22.278M140 78.5h-11M139 82.5h-18M77.207 23C79.302 23 81 24.69 81 26.772v65.455C81 94.311 79.302 96 77.207 96H25.794C23.698 96 22 94.31 22 92.227V26.772C22 24.69 23.698 23 25.794 23h19.712M38 77.5h8M37 81.5h9"
      />
      <g transform="translate(30 33)">
        <mask id="f" fill="#fff">
          <path d="M.56 11.487c0 6.219 5.096 11.26 11.382 11.26 6.287 0 11.383-5.041 11.383-11.26 0-6.218-5.096-11.26-11.383-11.26C5.656.228.56 5.27.56 11.488z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.19 26.457h30.265V-3.482H-3.19z"
          mask="url(#f)"
        />
      </g>
      <g stroke="#BBC8DC" strokeLinecap="round" strokeWidth="1.5">
        <path d="M46.319 54.916a11.555 11.555 0 01-4.322.831c-6.315 0-11.434-5.041-11.434-11.26 0-6.218 5.12-11.259 11.434-11.259 1.535 0 3 .298 4.337.838M121.832 33.931h17.71v14.35c0 3.347-2.754 6.059-6.151 6.059h-11.56" />
      </g>
      <g transform="translate(128 61)">
        <mask id="h" fill="#fff">
          <path d="M3.201.285A2.786 2.786 0 00.414 3.07v4.696a2.786 2.786 0 002.787 2.786h4.695a2.786 2.786 0 002.785-2.786V3.07A2.786 2.786 0 007.896.285H3.2z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.252 14.219h17.6v-17.6h-17.6z"
          mask="url(#h)"
        />
      </g>
      <g transform="translate(115 61)">
        <mask id="j" fill="#fff">
          <path d="M2.926.285A2.786 2.786 0 00.14 3.07v4.696a2.786 2.786 0 002.786 2.786H7.62a2.786 2.786 0 002.786-2.786V3.07A2.785 2.785 0 007.62.285H2.926z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.527 14.219h17.6v-17.6h-17.6z"
          mask="url(#j)"
        />
      </g>
      <g transform="translate(49 17)">
        <mask id="l" fill="#fff">
          <path d="M4.44.293C2.335.293.63 1.984.63 4.07v76.691c0 2.087 1.705 3.778 3.81 3.778h60.622c2.105 0 3.81-1.691 3.81-3.778V4.071c0-2.087-1.705-3.778-3.81-3.778H4.44z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.161 88.301h75.824V-3.468H-3.161z"
          mask="url(#l)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeWidth="2"
        d="M114.205 101h-60.41C51.7 101 50 99.313 50 97.233V20.767C50 18.687 51.7 17 53.796 17h60.409C116.3 17 118 18.687 118 20.767v76.466c0 2.08-1.7 3.767-3.795 3.767z"
      />
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="2"
        d="M68 71.5h32M66 83.5h9M79 83.5h9M93 83.5h9M72 87.5h9M86 87.5h9"
      />
      <g transform="translate(66 29)">
        <mask id="n" fill="#fff">
          <path d="M.252 17.416c0 9.455 7.664 17.12 17.12 17.12 9.455 0 17.119-7.665 17.119-17.12 0-9.456-7.664-17.12-17.12-17.12C7.916.296.251 7.96.251 17.416z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.553 38.339h41.848V-3.508H-3.553z"
          mask="url(#n)"
        />
      </g>
      <g transform="translate(20 51)">
        <mask id="p" fill="#fff">
          <path d="M4.983 1.422L.771 20.942c-.133.622.283 1.23.933 1.359.649.13 1.283-.272 1.418-.893l4.212-19.52C7.467 1.266 7.05.657 6.4.53a1.275 1.275 0 00-.246-.024C5.6.505 5.1.88 4.983 1.422z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.254 26.159h14.612V-3.328H-3.254z"
          mask="url(#p)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M21.869 72.977h0a1.106 1.106 0 01-.846-1.308l3.822-18.786c.122-.6.698-.984 1.286-.86.59.123.968.71.846 1.308l-3.822 18.786a1.089 1.089 0 01-1.286.86z"
      />
      <g transform="translate(15 59)">
        <mask id="r" fill="#fff">
          <path d="M3.16.306L.313 1.542l2.924 6.337 5.446-2.363L6.922 1.7C6.449.673 5.41.065 4.32.065A2.92 2.92 0 003.16.306z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.437 11.514h15.87V-3.572h-15.87z"
          mask="url(#r)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M23 64.58L17.795 67 15 60.512l2.72-1.265c1.373-.638 2.983.001 3.597 1.426L23 64.581z"
      />
      <g transform="translate(20 49)">
        <mask id="t" fill="#fff">
          <path d="M11.313 1.743L.251 32.833a1.876 1.876 0 001.14 2.396 1.874 1.874 0 002.394-1.139L14.845 3A1.874 1.874 0 0013.71.603a1.876 1.876 0 00-2.396 1.139z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.607 39.089h22.312V-3.254H-3.607z"
          mask="url(#t)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M21.263 83.89h0a1.903 1.903 0 002.425-1.143L34.89 51.515a1.88 1.88 0 00-1.153-2.406 1.903 1.903 0 00-2.426 1.144l-11.2 31.232a1.88 1.88 0 001.152 2.406zM33 56l-4-1M30 57l-1 4"
      />
      <g transform="translate(13 65)">
        <mask id="v" fill="#fff">
          <path d="M2.56.29A2.564 2.564 0 00.018 3.16l3.369 28.385h15.58l3.37-28.384A2.565 2.565 0 0019.795.289H2.56z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.71 35.266h29.775V-3.431H-3.71z"
          mask="url(#v)"
        />
      </g>
      <g transform="translate(13 65)">
        <mask id="x" fill="#fff">
          <path d="M2.556.29A2.563 2.563 0 00.018 3.16L3.38 31.547h15.556l.619-5.21h-7.867a3.522 3.522 0 01-3.491-3.115L5.48.289H2.556z" />
        </mask>
        <path
          fill="#EFF3FA"
          fillRule="nonzero"
          d="M-3.704 35.266h26.963V-3.431H-3.704z"
          mask="url(#x)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M32.515 96h-16.03L13.02 66.939A2.63 2.63 0 0115.634 64h17.732a2.63 2.63 0 012.615 2.939L32.515 96z"
      />
      <g transform="translate(79 39)">
        <mask id="z" fill="#fff">
          <path d="M.072 4.417a4.3 4.3 0 008.603 0 4.301 4.301 0 00-8.602 0H.071z" />
        </mask>
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M-3.678 12.467h16.102V-3.634H-3.678z"
          mask="url(#z)"
        />
      </g>
      <path
        stroke="#BBC8DC"
        strokeWidth="1.5"
        d="M158.06 24.318l.6 1.619a.201.201 0 00.14.125l1.68.425a.2.2 0 01.075.352l-1.36 1.07a.201.201 0 00-.077.17l.113 1.722a.201.201 0 01-.313.18l-1.442-.959a.203.203 0 00-.186-.019l-1.61.64a.201.201 0 01-.27-.241l.47-1.662a.203.203 0 00-.039-.183l-1.108-1.326a.201.201 0 01.147-.33l1.733-.068a.2.2 0 00.162-.093l.925-1.46a.203.203 0 01.36.038zM147.579 15.087a1.34 1.34 0 01-1.343 1.337 1.34 1.34 0 01-1.344-1.337 1.34 1.34 0 011.344-1.337 1.34 1.34 0 011.343 1.337zM159 10.751l-3.38.367a.202.202 0 00-.147.311l1.71 2.58c.082.128.27.12.345-.01l1.672-2.948a.202.202 0 00-.2-.3z"
      />
      <path
        fill="#E3E9F3"
        fillRule="nonzero"
        d="M134.358 10.803l-4.465 3.986a.2.2 0 00.063.338l5.115 1.95c.14.053.29-.06.274-.21l-.65-5.936a.205.205 0 00-.203-.181.197.197 0 00-.134.052zM158.723 40.758l-2.003.218a1.432 1.432 0 00-1.272 1.575l.218 1.994c.086.784.795 1.35 1.582 1.266l2.004-.218a1.43 1.43 0 001.271-1.575l-.219-1.994a1.43 1.43 0 00-1.423-1.274c-.052 0-.106.002-.158.008zM166.3 15.304l-1.623.931a.394.394 0 00-.197.341v1.865c0 .14.075.27.197.34l1.622.932c.121.07.273.07.395 0l1.621-.933c.122-.07.198-.2.198-.34v-1.864c0-.14-.076-.27-.198-.34l-1.621-.932a.395.395 0 00-.395 0z"
      />
      <g>
        <path
          stroke="#BBC8DC"
          strokeWidth="1.5"
          d="M11.86 43.447l-.601-1.618a.201.201 0 00-.14-.126l-1.68-.425a.2.2 0 01-.075-.352l1.36-1.07a.201.201 0 00.077-.17l-.113-1.722a.201.201 0 01.313-.18l1.442.96a.203.203 0 00.186.018l1.61-.64a.201.201 0 01.27.242l-.47 1.66a.203.203 0 00.039.185l1.108 1.326a.201.201 0 01-.147.329l-1.733.068a.2.2 0 00-.162.093l-.925 1.46a.203.203 0 01-.36-.038zM13.72 15.215a1.34 1.34 0 011.344-1.337 1.34 1.34 0 011.343 1.337 1.34 1.34 0 01-1.343 1.337 1.34 1.34 0 01-1.344-1.337zM1.851 25.749l3.38-.367a.202.202 0 00.148-.311L3.67 22.49a.203.203 0 00-.346.01L1.653 25.45a.202.202 0 00.198.3z"
        />
        <path
          fill="#E3E9F3"
          fillRule="nonzero"
          d="M27.355 16.698l4.465-3.987a.2.2 0 00-.063-.338l-5.115-1.95a.203.203 0 00-.274.21l.65 5.936c.013.11.107.181.203.181a.197.197 0 00.134-.052zM12.784 30.242l2.003-.218a1.432 1.432 0 001.272-1.575l-.219-1.994a1.435 1.435 0 00-1.582-1.266l-2.003.218a1.43 1.43 0 00-1.272 1.575l.22 1.995c.08.732.7 1.273 1.423 1.273.052 0 .106-.002.158-.008zM9.24 6.211l1.622-.931c.122-.07.198-.2.198-.34V3.074c0-.14-.076-.27-.198-.34L9.24 1.803a.395.395 0 00-.394 0l-1.622.932a.392.392 0 00-.197.34v1.864c0 .14.075.27.197.34l1.622.932c.06.036.13.054.197.054a.395.395 0 00.197-.054z"
        />
      </g>
      <g>
        <ellipse
          cx="17.542"
          cy="17.5"
          fill="#EFF3FA"
          rx="17.542"
          ry="17.5"
          transform="translate(66 28)"
        />
        <path
          fill="#BA6BE2"
          fillRule="nonzero"
          stroke="#BA6BE2"
          d="M91.745 52.555L88.6 49.41c1.02-1.275 1.7-2.975 1.7-4.76 0-4.25-3.4-7.65-7.65-7.65S75 40.4 75 44.65s3.4 7.65 7.65 7.65c1.785 0 3.485-.595 4.76-1.7l3.145 3.145c.17.17.425.255.595.255.17 0 .425-.085.595-.255.34-.34.34-.85 0-1.19zM76.7 44.65a5.913 5.913 0 015.95-5.95 5.913 5.913 0 015.95 5.95c0 1.615-.68 3.145-1.7 4.165-1.105 1.105-2.55 1.7-4.165 1.7-3.4.085-6.035-2.55-6.035-5.865z"
        />
      </g>
      <circle cx="82.5" cy="44.5" r="5.5" fill="#FFF" />
    </g>
  </svg>
);
