import { Box } from 'braid-design-system';
import type { ReactNode } from 'react';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import { WidgetError } from '../../components/WidgetError/WidgetError';

import { MostAdsByPerformanceTitle } from './MostAdsByPerformance';

export interface MostAdsByPerformanceErrorBoundaryProps {
  children: ReactNode;
}

export const MostAdsByPerformanceErrorBoundary = ({
  children,
}: MostAdsByPerformanceErrorBoundaryProps) => (
  <ErrorBoundary
    fallback={
      <Box paddingLeft="large">
        <MostAdsByPerformanceTitle />
        <WidgetError />
      </Box>
    }
  >
    {children}
  </ErrorBoundary>
);
