import { datadogRum } from '@datadog/browser-rum';

import type { Environment } from '../../config';

interface DatadogRumSetupConfig {
  environment: Environment;
  version: string;
  sessionReplayEnabled?: boolean;
}

const setupDatadogRum = ({
  environment,
  version,
  sessionReplayEnabled = false,
}: DatadogRumSetupConfig) => {
  datadogRum.init({
    applicationId: 'c30e4837-3a02-4bf6-baf2-faefacdf1b81',
    clientToken: 'puba3d59c2081f19b4738c82e5151ad4020',
    site: 'datadoghq.com',
    service: 'hirer-analytics-ui',
    env: environment,

    // Specify a version number to identify the deployed version of your application in Datadog
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: sessionReplayEnabled ? 20 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://talent.seek.com.au',
      'https://talent.seek.co.nz',
      'https://talent.staging.seek.com.au',
      'https://talent.staging.seek.co.nz',
      // Asia Domains
      'https://ph.employer.staging.seek.com',
      'https://ph.employer.seek.com',
      'https://th.employer.staging.seek.com',
      'https://th.employer.seek.com',
      'https://my.employer.staging.seek.com',
      'https://my.employer.seek.com',
      'https://hk.employer.staging.seek.com',
      'https://hk.employer.seek.com',
      'https://id.employer.staging.seek.com',
      'https://id.employer.seek.com',
      'https://sg.employer.staging.seek.com',
      'https://sg.employer.seek.com',
      /http:\/\/hirer-graphql-api-.*\.hirer\.dev\.outfra\.xyz/,
    ],
  });
};

export { setupDatadogRum };
export type { DatadogRumSetupConfig };
