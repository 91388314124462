import { Box } from 'braid-design-system';
import type { ReactNode, ComponentProps } from 'react';

type BoxProps = ComponentProps<typeof Box>;

export interface RoundedCardProps extends BoxProps {
  children: ReactNode;
}

export const RoundedCard = ({ children, ...otherProps }: RoundedCardProps) => (
  <Box
    background="surface"
    borderRadius="large"
    position="relative"
    {...otherProps}
  >
    {children}
  </Box>
);
