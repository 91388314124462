import { isEmpty, isNull, isUndefined } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDataPreserver = function <T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    if (!isEmpty(value) && !isNull(value) && !isUndefined(value)) {
      ref.current = value;
    }
  }, [value]);
  return ref.current;
};
