import { EmployerFooter, type AnalyticsEvent } from '@seek/adv-header-footer';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { type HTMLProps, useCallback } from 'react';

import { useConfig } from '../../App/ConfigContext';
import { getEnvironment, getLocale, getSite } from '../../config';
import { trackLink } from '../../utils/tealiumEventTracker';

export const AdCentreFooter = ({
  currentPage = '',
}: {
  currentPage?: string;
}) => {
  const { country, language, zone } = useConfig();
  const { accountContext } = useAccountContext();

  const basicPageTrackingData = {
    siteCountry: country,
    currentPage,
    hirerUserEmail:
      accountContext?.headerFooterContext?.identityContext?.email || null,
    hirerUserFullName: accountContext?.headerFooterContext?.user?.name || null,
    siteSection: 'jobs',
    userId: accountContext?.headerFooterContext?.user?.id || null,
    siteSubsection: 'analytics',
    zone,
    siteLanguage: language,
    brand: 'seek',
  };

  const linkRenderer = useCallback(
    (origin: string) => (props: HTMLProps<HTMLAnchorElement>) => {
      if (origin) {
        return <a {...props} data-analytics-origin={origin} />;
      }
      return <a {...props} />;
    },
    [],
  );

  const isStaging = getEnvironment() === 'staging';

  const onTrackedItemInteraction = (analyticsEventData: AnalyticsEvent) => {
    trackLink(analyticsEventData.eventName, {
      ...basicPageTrackingData,
      ...analyticsEventData,
    });
  };

  return (
    <EmployerFooter
      linkRenderer={linkRenderer('global-header')}
      locale={getLocale()}
      site={getSite()}
      isStaging={isStaging}
      onTrackedItemInteraction={onTrackedItemInteraction}
      {...accountContext?.headerFooterContext}
    />
  );
};
