import type { Brand, Country } from '@seek/melways-sites';
import type { MultiSelectDropdownOnSelectState } from '@seek/multi-select-dropdown';
import { adapter as createTealiumAdapter } from '@seek/tealium-adapter';

import type {
  AdUsageReportDataLayer,
  OptionSelected,
} from '../types/TealiumDataLayer';

import { formatDate } from './date';

let tealiumAdapter: TealiumAdapter | undefined;
let pageLoaded = false;
let widgetViewcalled = false;

export const IS_REGISTERED_WIDGET_LOADED: Record<string, boolean> = {
  JobAdList: false,
  Filters: false,
};

export const initTealiumAURTracker = () => {
  tealiumAdapter = createTealiumAdapter(undefined);
};

export const setTrackerData = (newDataLayer: AdUsageReportDataLayer) => {
  window.adUsageReportDataLayer = {
    ...window.adUsageReportDataLayer,
    ...newDataLayer,
  };
};

export const getTrackerData = ():
  | AdUsageReportDataLayer
  | Record<string, unknown> => window.adUsageReportDataLayer || {};
/**
 * Initialize ad_usage_report_displayed event only once on the APR page
 * @param data
 */
export const trackAURPage = (data: AdUsageReportDataLayer) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }

  setTrackerData(data);
  if (!pageLoaded) {
    tealiumAdapter.view('ad_usage_report_displayed', data);
    pageLoaded = true;
  }
};

/**
 * Initialize widget-view event when all required widgets are loaded
 * @param data
 * @param widgetName
 */
export const trackWidgetView = (
  data: AdUsageReportDataLayer,
  widgetName: string,
) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }

  setTrackerData(data);

  // trackWidgetView is triggered after widget is loaded,
  // so it is always true
  IS_REGISTERED_WIDGET_LOADED[widgetName] = true;

  const isWidgetLoaded = Object.keys(IS_REGISTERED_WIDGET_LOADED).find(
    (key) => !IS_REGISTERED_WIDGET_LOADED[key],
  );

  // make sure event is triggered only once
  if (!widgetViewcalled && isWidgetLoaded) {
    const dataLayer = getTrackerData();
    tealiumAdapter.view('widgets_displayed', { ...dataLayer, ...data });
    widgetViewcalled = true;
  }
};

/**
 * Triggered by user's mouse event click or scroll
 * @param eventName
 * @param props
 */
export const trackEvent = <T>(eventName: string, props: T) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  const dataLayer = getTrackerData();
  tealiumAdapter.view(eventName, { ...dataLayer, ...props });
};

export const mapDropdownFilterMethod = ({
  isSearchFilled,
  areOptionsExpanded,
}: MultiSelectDropdownOnSelectState) => {
  if (isSearchFilled) {
    return 'searchbar';
  }

  if (areOptionsExpanded) {
    return 'chevron';
  }

  return 'neither';
};

export const createDefaultAUREventData = ({
  country,
  userEmail,
  advertiserId,
  zone,
  language,
  brand,
  actionOrigin,
  userId,
  hirerUserFullName,
}: {
  country: string;
  userEmail: string | null;
  advertiserId: string;
  zone: string;
  language: string;
  brand: Brand;
  userId: string | null;
  hirerUserFullName: string | null;
  actionOrigin?: string;
}): AdUsageReportDataLayer => {
  const widgetAttributeNames = [
    'searchResultsTotal',
    'filterDateRange',
    'filterEndDate',
    'filterStartDate',
    'isFilterAdCost',
    'isFilterAdPerformance',
    'isFilterAdSalary',
    'isFilterAdStatus',
    'isFilterAdType',
    'isFilterCategory',
    'isFilterChildAccount',
    'isFilterLocation',
    'isFilterPaidEdits',
    'isFilterReposted',
    'isFilterSubClassification',
    'isFilterUser',
    'isFilterWorkType',
  ];
  const widgetEventProps = widgetAttributeNames.reduce(
    (acc: Record<string, null>, cur: string) => {
      acc[cur] = null;
      return acc;
    },
    {},
  );

  return {
    siteCountry: country as Country,
    currentPage: 'ad usage report',
    hirerUserEmail: userEmail,
    hirerUserFullName,
    seekAdvertiserId: Number(advertiserId),
    userId: (userId && parseInt(userId, 10)) || null,
    zone,
    siteLanguage: language,
    siteSection: 'jobs',
    siteSubsection: 'analytics',
    brand,
    actionOrigin,
    ...widgetEventProps,
  };
};

export const toFormatAUDate = (date: Date | null) =>
  date
    ? formatDate({
        date,
        locale: 'en-AU',
      })
    : null;

/**
 * we just want to pick up if the user has select one of them with this
 * @param selected
 * @returns
 */
export const isFilterSelected = <T>(selected: OptionSelected<T>) =>
  selected.length === 1;
