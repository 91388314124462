import { useRef, useState, useLayoutEffect } from 'react';

export function useCountUp(metricValue: number): number {
  const duration = metricValue < 10 ? metricValue * 30 : 900;

  // setup refs
  const requestRef = useRef<number>();
  const startTimeRef = useRef<number>();
  const previousValueRef = useRef<number>();

  // setup states
  const [currentMetricValue, setCurrentMetricValue] = useState(metricValue);

  // react to events
  useLayoutEffect(() => {
    startTimeRef.current = undefined;
    previousValueRef.current = undefined;
    const onFrame = (currentTime: number) => {
      if (
        startTimeRef.current === undefined ||
        previousValueRef.current === undefined
      ) {
        startTimeRef.current = currentTime;
        previousValueRef.current = 0;
        loopRaf();
        return;
      }
      const elapsedTime = currentTime - startTimeRef.current;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = Math.sin((progress * Math.PI) / 2);
      const calculatedValue = metricValue * easedProgress;
      setCurrentMetricValue(Math.round(calculatedValue));

      previousValueRef.current = calculatedValue;
      if (progress < 1) {
        loopRaf();
      } else {
        stopRaf();
        setCurrentMetricValue(metricValue);
      }
    };
    function loopRaf() {
      requestRef.current = window.requestAnimationFrame(onFrame);
    }
    function stopRaf() {
      if (requestRef.current !== undefined) {
        window.cancelAnimationFrame(requestRef.current);
      }
    }
    loopRaf();
    return () => {
      stopRaf();
    };
  }, [duration, metricValue]);

  return currentMetricValue;
}
