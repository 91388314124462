import {
  type HTMLProps,
  type MouseEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

export const ExternalLink = ({
  children,
  href,
  onClick,
  target,
  ...restProps
}: HTMLProps<HTMLAnchorElement>) => {
  const timerRef = useRef<number | undefined>();
  const onLinkClickedEvent = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
      if (href) {
        // this timeout should give analytics enough time to fire off an event
        timerRef.current = window.setTimeout(() => {
          if (target && target === '_blank') {
            window.open(href, '_blank');
          } else {
            window.location.assign(href);
          }
        }, 200);
      }
    },
    [href, onClick, target],
  );
  useEffect(
    () => () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    },
    [],
  );
  return (
    <a href={href} onClick={onLinkClickedEvent} {...restProps}>
      {children}
    </a>
  );
};

export const ExternalLinkWithRef = forwardRef<
  HTMLAnchorElement,
  HTMLProps<HTMLAnchorElement>
>(({ children, href, target, onClick, ...restProps }, ref) => {
  const timerRef = useRef<number | undefined>();
  const onLinkClickedEvent = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
      if (href) {
        // this timeout should give analytics enough time to fire off an event
        timerRef.current = window.setTimeout(() => {
          if (target && target === '_blank') {
            window.open(href, '_blank');
          } else {
            window.location.assign(href);
          }
        }, 200);
      }
    },
    [href, onClick, target],
  );
  return (
    <a href={href} onClick={onLinkClickedEvent} ref={ref} {...restProps}>
      {children}
    </a>
  );
});
