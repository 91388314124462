import { type Display, format } from '@seek/currency';
import type { Locale } from '@seek/melways-sites';

import { getLocale } from '../../config';

interface FormatSalaryOptions {
  currency: string;
  locale?: Locale;
  display?: Display;
}

const formatSalary = (
  salary: number,
  {
    currency,
    display = 'narrowSymbol',
    locale = getLocale(),
  }: FormatSalaryOptions,
) =>
  format(salary, {
    currency,
    display,
    locale,
    notation: salary >= 1000 ? 'compact' : 'standard',
  });

export { formatSalary, type FormatSalaryOptions };
