interface LoggerContext extends Record<string, unknown> {
  activity?: string;
  widget?: string;
}

type LoggerAppContextKeys = 'advertiserId' | 'userId' | 'experience';
type LoggerAppContext = Record<LoggerAppContextKeys, unknown>;

interface Logger {
  info: (message: string, context?: LoggerContext) => void;
  error: (errorOrMessage: Error | string, context?: LoggerContext) => void;
  configure: (context: Partial<LoggerAppContext>) => void;
}

interface LoggerProvider {
  init: (customLogger: Logger) => void;
  logger: Logger;
}

const loggerProvider = (): LoggerProvider => {
  let instance: Logger = {
    // eslint-disable-next-line no-console
    info: (message: string) => console.info(message),
    // eslint-disable-next-line no-console
    error: (errorOrMessage: Error | string) => console.error(errorOrMessage),
    configure: () => {},
  };

  const initLogger = (customLogger: Logger): void => {
    instance = customLogger;
  };

  return {
    init: initLogger,
    logger: {
      info: (message: string, context?: LoggerContext) =>
        instance.info(message, context),
      error: (errorOrMessage: Error | string, context?: LoggerContext) =>
        instance.error(errorOrMessage, context),
      configure: (context: Partial<LoggerAppContext>) =>
        instance.configure(context),
    },
  };
};

export default loggerProvider();
export type { Logger, LoggerContext, LoggerAppContext };
