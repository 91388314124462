import { useAdRatingData } from '../useAdRatingData';
import { useApplicationPerformance } from '../useApplicationPerformance';
import { useClicksOnYourAdData } from '../useClicksOnYourAdData';
import { useMyJobInformationData } from '../useMyJobInformationData';
import { usePageTitleActions } from '../usePageTitleActions';
import { useRecommendations } from '../useRecommendations';
import { useSimilarAds } from '../useSimilarAds';
import { useViewsInJobSearchData } from '../useViewsInJobSearchData';

export const useAprMostRecentLastUpdatedTimestamp = ({
  jobId,
}: {
  jobId: string;
}) => {
  const { data: pageTitleActionsData, isLoading: isLoadingPageTitleActions } =
    usePageTitleActions({ jobId });
  const { data: adRatingData, isLoading: isLoadingAdRating } = useAdRatingData({
    jobId,
  });
  const { data: viewsInJobSearchData, isLoading: isLoadingViewsInJobSearch } =
    useViewsInJobSearchData({ jobId });
  const { data: clicksOnYourAdData, isLoading: isLoadingClicksOnYourAdData } =
    useClicksOnYourAdData({ jobId });
  const {
    data: applicationPerformanceData,
    isLoading: isLoadingApplicationPerformance,
  } = useApplicationPerformance({ jobId });
  const { data: recommendationsData, isLoading: isLoadingRecommendations } =
    useRecommendations({ jobId });
  const {
    data: myJobInformationData,
    isLoading: isLoadingMyJobInformationData,
  } = useMyJobInformationData({ jobId });
  const { data: similarAdsData, isLoading: isLoadingSimilarAds } =
    useSimilarAds({
      jobId,
    });

  const isLoading = [
    isLoadingPageTitleActions,
    isLoadingAdRating,
    isLoadingViewsInJobSearch,
    isLoadingClicksOnYourAdData,
    isLoadingApplicationPerformance,
    isLoadingRecommendations,
    isLoadingMyJobInformationData,
    isLoadingSimilarAds,
  ].some(Boolean);

  const mostRecentTimestamp = isLoading
    ? ''
    : [
        pageTitleActionsData,
        adRatingData,
        viewsInJobSearchData,
        clicksOnYourAdData,
        applicationPerformanceData,
        recommendationsData,
        myJobInformationData,
        similarAdsData,
      ]
        .map((data) => data?.lastUpdatedTimestampUTC)
        .filter(Boolean)
        .sort()
        .pop();

  return { mostRecentTimestamp, isLoading };
};
