import { siteNames } from '@seek/melways-sites';

import { FEATURE_AUR_NVL } from '../constants';
import { incrementalRolloutStrategy } from '../strategies';
import type { FeatureConfig, SiteSpecificFeatureConfigValue } from '../types';
import { getSiteEnvConfig } from '../utils/getSiteEnvConfig';

export const aurNvlFeature: FeatureConfig<SiteSpecificFeatureConfigValue> = {
  name: FEATURE_AUR_NVL,
  value: {
    mock: {
      [siteNames.employer.seek.au]: { enabled: true },
      [siteNames.employer.seek.nz]: { enabled: true },
      [siteNames.employer.seek.hk]: { enabled: true },
      [siteNames.employer.seek.th]: { enabled: true },
      [siteNames.employer.seek.sg]: { enabled: true },
      [siteNames.employer.seek.my]: { enabled: true },
      [siteNames.employer.seek.id]: { enabled: true },
      [siteNames.employer.seek.ph]: { enabled: true },
    },
    development: {
      [siteNames.employer.seek.au]: { enabled: true },
      [siteNames.employer.seek.nz]: { enabled: true },
      [siteNames.employer.seek.hk]: { enabled: true },
      [siteNames.employer.seek.th]: { enabled: true },
      [siteNames.employer.seek.sg]: { enabled: true },
      [siteNames.employer.seek.my]: { enabled: true },
      [siteNames.employer.seek.id]: { enabled: true },
      [siteNames.employer.seek.ph]: { enabled: true },
    },
    staging: {
      [siteNames.employer.seek.au]: { enabled: true },
      [siteNames.employer.seek.nz]: { enabled: true },
      [siteNames.employer.seek.hk]: { enabled: true },
      [siteNames.employer.seek.th]: { enabled: true },
      [siteNames.employer.seek.sg]: { enabled: true },
      [siteNames.employer.seek.my]: { enabled: true },
      [siteNames.employer.seek.id]: { enabled: true },
      [siteNames.employer.seek.ph]: { enabled: true },
    },
    production: {
      [siteNames.employer.seek.au]: { enabled: false },
      [siteNames.employer.seek.nz]: { enabled: false },
      [siteNames.employer.seek.hk]: { enabled: false },
      [siteNames.employer.seek.th]: { enabled: false },
      [siteNames.employer.seek.sg]: { enabled: false },
      [siteNames.employer.seek.my]: { enabled: false },
      [siteNames.employer.seek.id]: { enabled: false },
      [siteNames.employer.seek.ph]: { enabled: false },
    },
  },
  toFeature({ advertiserId, env }) {
    return {
      name: this.name,
      enabled: incrementalRolloutStrategy(
        advertiserId!,
        getSiteEnvConfig(this.value!, env),
      ),
    };
  },
};
