import classNames from 'classnames';
import type { CSSProperties } from 'react';

import * as styles from './trendIcon.css';

export interface TrendIconProps {
  isPositiveTrend: boolean;
  size?: 'small' | 'standard';
  style: CSSProperties;
}

export const TrendIcon = ({
  isPositiveTrend,
  size = 'standard',
  style,
}: TrendIconProps) => (
  <div
    style={style}
    className={classNames({
      [styles.slideUpAnimation]: isPositiveTrend,
      [styles.slideDownAnimation]: !isPositiveTrend,
      [styles.smallTrendUp]: size === 'small' && isPositiveTrend,
      [styles.smallTrendDown]: size === 'small' && !isPositiveTrend,
      [styles.trendUp]: size === 'standard' && isPositiveTrend,
      [styles.trendDown]: size === 'standard' && !isPositiveTrend,
    })}
  />
);
