import { gql } from '@apollo/client';

export const spendQuery = gql`
  query GetSpendData($input: AdUsageSpendWidgetInput!) {
    analyticsAdUsageSpend(input: $input) {
      totalPostByProductDisplayNames {
        type
        adsCount
      }
      totalSpend {
        currency
        value
      }
      averageCostByProductDisplayNames {
        type
        cost {
          currency
          value
        }
      }
      averageApplications
      averageCostPerAd {
        currency
        value
      }
      totalApplications
      averageCostPerApplication {
        currency
        value
      }
      repostedAdsCount
      repostedAdsSpend {
        currency
        value
      }
      hidePrice
      totalAds
      otherCurrencyAdsCount
    }
  }
`;
